import React from 'react'
import TopBanner from '../../components/topBanner/topBanner'
import imgBanner from "../../images/banner/banner-tin-tuc.jpg"
import TitleCenter from '../../components/title/TitleCenter'
import { PSCategory } from '../../localData/PSCategory'
import { PSContent } from '../../localData/PSContent'
import { ProductSolutionMenu } from '../../localData/productSolutionMenu'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

function DetailPSContent() {
    const { categoryID, slug } = useParams();
    const [data, setData] = useState();
    const [data2, setData2] = useState();

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    useEffect(() => {
        for (var item of PSCategory) {
            if (item.slug === slug) {
                setData(item);
            }
        }
    }, [slug]);
    useEffect(() => {
        for (var item of ProductSolutionMenu) {
            if (item.slug === categoryID) {
                setData2(item);
            }
        }
    }, [categoryID]);
    return (
        <div className='DetailPSCategory'>
            <TopBanner
                titleCenter="Sản Phẩm và Giải Pháp"
                imgBanner={imgBanner}
                pageName={data2 ? data2.text : ""}
                text={data ? data.textItem : ""}
            />
            <div className='layout_container    content_pages'>
                <TitleCenter text={data ? data.textItem : ""} />
                <div className='PScategory'>
                    <img src={data ? data.img : ""} alt="" />
                    <div className="row">
                        <div className='col-xs-12 col-md-12 col-lg-12'>
                            {PSContent.map((value,index) => {
                                if (value.PScategory === data?.id) {
                                    const formattedHTML = { __html: value.text };
                                    return (
                                        <div className="ProductSolution-content" key={index}>
                                            <ul>
                                                <li><span style={{ display: "block", fontSize: "1.6rem", margin: "10px 0", fontWeight: "600" }}>{value.textItem}</span></li>
                                            </ul>

                                            <div className="">
                                                <p style={{ fontSize: "1.6rem" }} dangerouslySetInnerHTML={formattedHTML}></p>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailPSContent