import React from 'react'
import TopBanner from '../../components/topBanner/topBanner'
import imgBaner from "../../images/banner/banner---gioi-thieu.png"
import { useParams } from 'react-router-dom'
import { leaders } from '../../localData/leaderShip'
import { useEffect } from 'react'
import { useState } from 'react'
import TitleCenter from '../../components/title/TitleCenter'

export default function MemberContent() {
    const { slug, categoriId } = useParams();
    const [data, setData] = useState();
    const [gender, setGender] = useState();
    console.log(categoriId)
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    useEffect(() => {
        for (var item of leaders) {
            if (item.slug == slug && item.category == categoriId) { 
                console.log(item)  
                setData(item)
                setGender(item.gender)
            }
        }
    }, [])
    const formattedHTML = { __html: data ? data.description : "" };
    return (
        <div className='MemberContent'>
            <TopBanner
                imgBanner={imgBaner}
                pageName="Giới thiệu"
                titleCenter="Ban lãnh đạo"
                text={data ? data.name :""}
            />
            <div className='layout_container'>
                <TitleCenter
                    text="Ban lãnh đạo"
                />
                <h3>{gender === 0 ? "Bà : " : "Ông : "}
                    <b>{data ? data.name : ""}</b>
                </h3>
                <ul>
                    {data
                        ? data.position.map((value, index) => (
                            <li key={index}>{value}</li>
                        ))
                        : ""}
                </ul>
                <br /><br />
                <p style={{ fontSize: "1.6rem" }} dangerouslySetInnerHTML={formattedHTML}></p>
            </div>
        </div>
    )
}
