import React from 'react'
import logoImg from "../../images/Logo/Logo HSV_01.jpg"
import logoESMImg from "../../images/Logo/logoESM.png"
import logoBTImg from "../../images/Logo/Logo_Final_Icon.jpg"

function IntroduceCulture() {
  return (
    <div className='IntroduceCulture'>
      <p style={{ color: '#4db748', fontWeight: '600', margin: "10px 0 20px 0", fontSize: "2rem" , textAlign :"center" }}>
        Logo và ý nghĩa thương hiệu
      </p>
      <div className='IntroduceCulture-content' >
      <div>
          <img className='IntroduceCulture-content-img' src={logoImg} alt="logoHSV"/>
        </div>
        <div className='IntroduceCulture-content-left'>
          <p style={{fontStyle :"italic"}}><b style={{color :"#4db748" ,fontSize: "1.6rem",fontStyle : "normal" }}>CẤU TẠO THÀNH PHẦN :</b> Logo thuộc loại: "Iconic Logo" Được cấu tạo thành 2 phần chính.<br />
          ■ Biểu tượng "Icon" Được vẽ cách điệu các hình elip và dấu thập.<br />
          ■ Tên thương hiệu nhóm chữ "Brandname" <b style={{color :"#4db748"}}>HSV (Healthcare Solution Việt Nam)</b> <br />
          <b style={{color :"#4db748" ,fontSize: "1.6rem",fontStyle : "normal"}}>Ý NGHĨA :</b> Biểu tượng Icon lấy ý tưởng từ quả địa cầu kết hợp với biểu tượng y tế.<br />
          ■ Quả địa cầu thể hiện mục tiêu và tầm nhìn rộng lớn mà Công ty đang hướng tới.<br />
          ■ Dấu thập là biểu tượng sức khỏe và y tế, đây là lĩnh vực hoạt động chính của Công ty<br />
          ■ Các biểu tượng hình elip lồng xoắn vào nhau còn thể hiện sự liên kết vững chắc và thống nhất, cũng như thể hiện giải pháp công nghệ mà Công ty sẽ đem đến cho quý khách hàng và đối tác.<br />
          ■ Ngoài ra tên hương hiệu <b style={{color :"#4db748"}}>HSV (Healthcare Solution Việt Nam)</b> được thể hiện rõ ràng, mạnh mẽ và tinh tế thể hiện đẳng cấp và tiêu chuẩn quốc tế và thương hiệu.
          </p>
        </div>
      </div>
      <span></span>
      <div className='IntroduceCulture-content' >
      <div>
          <img className='IntroduceCulture-content-img' src={logoESMImg} alt="logoESM" />
        </div>
        <div className='IntroduceCulture-content-left'>
          <p style={{fontStyle :"italic"}}><b style={{color :"#4db748" ,fontSize: "1.6rem",fontStyle : "normal" }}>CẤU TẠO THÀNH PHẦN :</b> Logo thuộc loại: "Iconic Logo" Được cấu tạo thành 2 phần chính.<br />
          ■ Biểu tượng “Icon” Được vẽ cách điệu các hình elip và dấu thập.<br />
          ■ Tên thương hiệu nhóm chữ "Brandname" <b style={{color :"#4db748"}}>ESM (Ecosystem Smart Medic )</b> <br />
          <b style={{color :"#4db748" ,fontSize: "1.6rem",fontStyle : "normal"}}>Ý NGHĨA :</b> Biểu tượng Icon lấy ý tưởng từ quả địa cầu kết hợp với biểu tượng y tế.<br />
          ■ Quả địa cầu thể hiện mục tiêu và tầm nhìn rộng lớn mà Công ty đang hướng tới toàn cầu cho hệ sinh thái.<br />
          ■ Dấu thập là biểu tượng sức khoẻ và y tế, đây là lĩnh vực hoạt động trong hệ sinh thái.<br />
          ■ Các biểu tượng hình elip lồng xoắn vào nhau còn thể hiện sự liên kết vững chắc và thống nhất, cũng như thể hiện giải pháp công nghệ mà Công ty sẽ đem đến cho quý khách hàng và đối tác.<br />
          ■ Ngoài ra tên hương hiệu <b style={{color :"#4db748"}}>ESM (Ecosystem Smart Medic )</b> được thể về hệ sinh thái thông minh, xanh sạch đẹp,mạnh mẽ và tinh tế thể hiện đẳng cấp, tiêu chuẩn quốc tế.
          </p>
        </div>
      </div>
      <span></span>
      <div className='IntroduceCulture-content' >
      <div>
          <img className='IntroduceCulture-content-img' src={logoBTImg} alt="logo" style={{width :"60%"}} />
        </div>
        <div className='IntroduceCulture-content-left'>
          <p style={{fontStyle :"italic"}}><b style={{color :"#4db748" ,fontSize: "1.6rem",fontStyle : "normal" }}>CẤU TẠO THÀNH PHẦN :</b> Logo thuộc loại: "Iconic Logo" Được cấu tạo thành 2 phần chính.<br />
          ■ Biểu tượng “Icon” Được lấy ý tưởng của ngành y tế kết hợp với hình tượng “ i ”con người, Ý nói con người là trung tâm tâm của mọi vấn đề trong lĩnh vực y tế và chăm sóc sức khoẻ.<br />
          <b style={{color :"#4db748" ,fontSize: "1.6rem",fontStyle : "normal"}}>Ý NGHĨA :</b> Biểu tượng Icon lấy ý tưởng từ dấu Cộng  biểu tượng y tế kết hợp với hình tượng “ i ”con người là đội ngũ nhân viện  y tế đối với bệnh nhân.<br />
          ■ Về phía Công ty : Hình tượng con người thể hiện việc nhân viên làm việc chuyên nghiệp và tận tâm để cung cấp công nghệ tốt nhất cho quý khách hàng và đối tác.<br />
          ■ Về Phía Khách hàng: Hình tượng con người thể hiện sự quan tâm và chăm sóc đội ngũ nhân viên y tế đối với bệnh nhân.<br />
          ■ Ngoài ra hình tượng con người được cách điệu từ chữ i, chữ i là chữ viết tắt đại diện của từ “ internet” để khẳng định tính năng và sự hoạt động của giải pháp công nghệ trong môi trường internet, cũng như sự tiện lợi của ứng dụng có thể sự dụng bất cứ nơi đâu và bất kỳ thiết bị nào có kết nối internet.<br />
          </p>
        </div>
      </div>
    </div>
  )
}

export default IntroduceCulture