export const ConsultingServicesMenu = [{
    id: 1,
    text: "Tư Vấn Về Đầu Tư",
    slug: "tu-van-ve-dau-tu",
    img : require("../images/product_solution/banner---home-giai-phap-y-te.png"),
    banner :require("../images/banner/banner-tin-tuc.jpg"),
    sub: [{
        id_lv1: 1,
        textItem_lv1: "Tư vấn thủ tục đầu tư , vận hành, Bệnh viện, Phòng khám",
        slug_lv1: "tu-van-thu-tuc-dau-tu-van-hanh-benh-vien-phong-kham",
    },
    {
        id_lv1: 2,
        textItem_lv1: "Liên doanh liên kết - Phát triển chuỗi Bệnh viện Đa Khoa",
        slug_lv1: "lien-doanh-lien-ket-phat-trien-chuoi-benh-vien-da-khoa", 
    },
    {
        id_lv1: 3,
        textItem_lv1: "Liên doanh liên kết - Phát triển chuỗi Phòng khám Đa Khoa",
        slug_lv1: "lien-doanh-lien-ket-phat-trien-chuoi-phong-kham-da-khoa", 
    },
]
},
{
    id: 2,
    text: "Tư vấn về dịch vụ",
    slug: "tu-van-ve-dich-vu",
    img : require("../images/product_solution/banner---home-tich-hop-he-thong.jpg"),
    banner :require("../images/banner/banner-tin-tuc.jpg"),
    sub: [{
        id_lv1: 1,
        textItem_lv1: "Dịch vụ tư vấn, tích hợp Phần mềm",
        slug_lv1: "dich-vu-tu-van-tich-hop-phan-men", 
        },
        {
        id_lv1: 2,
        textItem_lv1: "Dịch vụ tư vấn tích hợp Dữ liệu Doanh nghiệp",
        slug_lv1: "dich-vu-tu-van-tich-hop-du-lieu-doanh-nghiep", 
        },
        {
        id_lv1: 3,
        textItem_lv1: "Dịch vụ tư vấn Hạ tầng & Hệ thống CNTT",
        slug_lv1: "dich-vu-tu-van-ha-tang-he-thong-cntt", 
        },
        {
        id_lv1: 4,
        textItem_lv1: "Tư vấn gói bảo trì - Bảo hành",
        slug_lv1: "tu-van-goi-bao-tri-bao-hanh", 
        }
    ]
}
]

export const CSCategory = [
    {
        id: 1,
        textItem: "Tư vấn thủ tục đầu tư , vận hành, Bệnh viện, Phòng khám",
        slug: "tu-van-thu-tuc-dau-tu-van-hanh-benh-vien-phong-kham",
        icon : require("../images/ConslutingService/tu-van-dau-tu-phong-kham.jpg"),
        text : ` `,
        ConsultingServicesID : 1
    },
    {
        id: 2,
        textItem: "Liên doanh liên kết - Phát triển chuỗi Bệnh viện Đa Khoa",
        slug: "lien-doanh-lien-ket-phat-trien-chuoi-benh-vien-da-khoa",
        icon : require("../images/ConslutingService/cong-ty-lien-doanh-la-gi-1-1.jpg"),
        text : ` `,
        ConsultingServicesID : 1
    },
    {
        id: 3,
        textItem: "Liên doanh liên kết - Phát triển chuỗi Phòng khám Đa Khoa",
        slug: "lien-doanh-lien-ket-phat-trien-chuoi-phong-kham-da-khoa",
        icon : require("../images/ConslutingService/thành-lập-công-ty-liên-doanh-tại-bình-dương.jpg"),
        text : ` `,
        ConsultingServicesID : 1
    }, 
    {
        id: 4,
        textItem: "Dịch vụ tư vấn, tích hợp Phần mềm",
        slug: "dich-vu-tu-van-tich-hop-phan-men",
        icon : require("../images/ConslutingService/image-tich-hop-he-thong-2.jpeg"),
        text : ` `,
        ConsultingServicesID : 2
    },
    {
        id: 5,
        textItem: "Dịch vụ tư vấn tích hợp Dữ liệu Doanh nghiệp",
        slug: "dich-vu-tu-van-tich-hop-du-lieu-doanh-nghiep",
        icon : require("../images/ConslutingService/asset-v1_FUNiX+DAP303x_01-A_VN+2022_T5+type@asset+block@Data-Engineer_Blog-scaled.jpeg"),
        text : ` `,
        ConsultingServicesID : 2
    },
    {
        id: 6,
        textItem: "Dịch vụ tư vấn Hạ tầng & Hệ thống CNTT",
        slug: "dich-vu-tu-van-ha-tang-he-thong-cntt",
        icon : require("../images/ConslutingService/he-thong-tin.png"),
        text : ` `,
        ConsultingServicesID : 2
    },
    {
        id: 7,
        textItem: "Tư vấn gói bảo trì - Bảo hành",
        slug: "tu-van-goi-bao-tri-bao-hanh",
        icon : require("../images/ConslutingService/suachuabaotrimaymocdungquydinh.jpg"),
        text : ` `,
        ConsultingServicesID : 2
    },
]