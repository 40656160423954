import React from 'react'
import imgToChuc from "../../images/sodotochuc.png"
function LeaderOrganize() {
  return (
    <div>
        <div className='introduce__content-img'>
            <img src={imgToChuc} alt="sodotochuc" />
        </div>
    </div>
  )
}

export default LeaderOrganize