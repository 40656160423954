export const typicalInProgressMenu = [
    {
        id : 1,
        nameHospitol:"BỆNH VIỆN ĐA KHOA QUẢNG NGÃI – CS 2",
        path:"benh-vien-da-khoa-quang-ngai-cs2",
        img :require("../images/Typical_project/duan-dangtrienkhai/bvdk-qn.png"),
        des : `
        <p>
        Địa chỉ: Lê Hữu Trác, Phường Nghĩa Lộ, TP.Quảng Ngãi, Tỉnh Quảng Ngãi <br/><br/>
        Tổng mức đầu tư: 1.100 tỷ đồng. <br/><br/>
        Quy mô: 500 giường, có thể mở rộng lên 1000 giường.<br/><br/>
        Dự án cao 12 tầng với diện tích sàn 11.295 m2. Hiện dự án đang thi công giai đoạn
        </p>
        `
    },
    {
        id : 2,
        nameHospitol:"BỆNH VIỆN ĐA KHOA NAM CẦN THƠ",
        path:"benh-vien-da-khoa-nam-can-tho",
        img:require("../images/Typical_project/duan-dangtrienkhai/bvdk-ct.jpg"),
        des : `
        <p>
        Đường song hành Quốc lộ 1A, Khu vực 5, Khu Dân cư Lô số 6, thuộc Khu đô thị Nam Cần Thơ, phường Hưng Thạnh, quận Cái Răng, thành phố Cần Thơ<br/><br/>
        Tổng mức đầu tư: 800 tỷ đồng.<br/><br/>
        Quy mô: 300 giường, có thể mở rộng lên 500 giường.<br/><br/>
        Dự án: 1 tầng hầm và 10 tầng nổi với diện tích sàn 28.500 m2
        </p>
        `
        
    },
    {
        id : 3,
        nameHospitol:"BỆNH VIỆN ĐA KHOA SẢN NHI – TỈNH BÀ RỊA VŨNG TÀU",
        path:"benh-vien-da-khoa-san-nhi-brvt",
        img :require("../images/Typical_project/duan-dangtrienkhai/bvdk-vt.png"),
        des : `
        <p>
        Địa chỉ: Quốc lộ 51, phường Phước Trung, thành phố Bà Rịa, tỉnh BR-VT<br/><br/>
        Tổng mức đầu tư: 900 tỷ đồng. <br/><br/>
        Quy mô: 250 giường, có thể mở rộng lên 500 giường.<br/><br/>
        Dự án cao 8 tầng với diện tích sàn 15.334 m2
        </p>
        `
    },
    {
        id : 4,
        nameHospitol:"BỆNH VIỆN ĐA KHOA BẾN TRE CS2",
        path:"benh-vien-da-khoa-ben-tre-cs2",
        img :require("../images/Typical_project/duan-dangtrienkhai/bvdk-bt2.png"),
        des : `
        <p>
        Địa chỉ: Quốc lộ 60, Xã Sơn Đông, Thành Phố Bến Tre<br/><br/>
        Tổng mức đầu tư: 2,250 tỷ đồng. <br/><br/>
        Quy mô: 450 giường, có thể mở rộng lên 1000 giường.<br/><br/>
        Dự án: 1 tầng hầm và 11 tầng nổi với diện tích sàn 10.486,8 m2
        </p>
        `
    },
    {
        id : 5,
        nameHospitol:"BỆNH VIỆN ĐA KHOA PHÚ YÊN CS2",
        path:"benh-vien-da-khoa-phu-yen-cs2",
        img :require("../images/Typical_project/duan-dangtrienkhai/bvdk-py2.png"),
        des : `
        <p>
        Địa chỉ: Số 7 Lê Duẫn, Thành Phố Tuy Hoà, Tỉnh Phú Yên <br/><br/>
        Tổng mức đầu tư: 450 tỷ đồng. <br/><br/>
        Quy mô: 400 giường, có thể mở rộng lên 700 giường.<br/><br/>
        Dự án: 1 tầng hầm và 9 tầng nổi với diện tích sàn 3,220 m2
        </p>
        `
    },
    {
        id : 6,
        nameHospitol:"BỆNH VIỆN ĐA KHOA QUẢNG BÌNH CS2",
        path:"benh-vien-da-khoa-quang-binh-cs2",
        img :require("../images/Typical_project/duan-dangtrienkhai/bvdk-qb2.png"),
        des : `
        <p>
        Địa chỉ: Bắc Ly, Thành Phố Đồng Hới, Tỉnh Quảng Bình <br/><br/>
        Tổng mức đầu tư: 480 tỷ đồng. <br/><br/>
        Quy mô: 450 giường, có thể mở rộng lên 1000 giường.<br/><br/>
        Dự án: 1 tầng hầm và 11 tầng nổi với diện tích sàn 48,880 m2
        </p>
        `
    },
    {
        id : 7,
        nameHospitol:"BỆNH VIỆN DÂN TỘC CỔ TRUYỀN PHÚ YÊN CS2",
        path:"benh-vien-da-khoa-quang-binh-cs2",
        img :require("../images/Typical_project/duan-dangtrienkhai/bvdtct-py2.png"),
        des : `
        <p>
        Địa chỉ: Số 9, Thành Phố Tuy Hoà, Tỉnh Phú Yên <br/><br/>
        Tổng mức đầu tư: 480 tỷ đồng. <br/><br/>
        Quy mô: 400 giường, có thể mở rộng lên 800 giường.<br/><br/>
        Dự án: 1 tầng hầm và 9 tầng nổi với diện tích sàn 22,000 m2
        </p>
        `
    },
    {
        id : 8,
        nameHospitol:"BỆNH VIỆN ĐA KHOA  KHU VỰC HÓC MÔN",
        path:"benh-vien-da-khoa-hoc-mon",
        img:require("../images/Typical_project/duan-dangtrienkhai/bvdk-hm.png"),
        des : `
        <p>
        Địa chỉ: 65 / 2b Đường Ba triệu, Thị trấn Hóc Môn, Huyện Hóc Môn, Thành phố Hồ Chí Minh<br/><br/>
        Tổng mức đầu tư: 1895 tỷ đồng.<br/><br/>
        Quy mô: 1000 giường, có thể mở rộng lên 500 giường.<br/><br/>
        Dự án: 2 tầng hầm và 12 tầng nổi với diện tích sàn 44.399 m2
        </p>
        `
        
    },
    {
        id : 9,
        nameHospitol:"BỆNH VIỆN ĐA KHOA  CÀ MAU",
        path:"benh-vien-da-khoa-ca-mau",
        img :require("../images/Typical_project/duan-dangtrienkhai/bvdk-cm.png"),
        des : `
        <p>
        Địa chỉ: Phường 6, Thành phố Cà Mau, Tỉnh Cà Mau<br/><br/>
        Tổng mức đầu tư: 3300 tỷ đồng. <br/><br/>
        Quy mô: 1200 giường, có thể mở rộng lên 500 giường.<br/><br/>
        Dự án: 2 lock, 1 tầng hầm và 12 tầng nổi với diện tích sàn 120,000 m2
        </p>
        `
    },
    {
        id : 10,
        nameHospitol:"BỆNH VIỆN ĐA KHOA  KHU VỰC CỦ CHI",
        path:"benh-vien-da-khoa-cu-chi",
        img :require("../images/Typical_project/duan-dangtrienkhai/bvdk-cc.png"),
        des : `
        <p>
        Địa chỉ: Tại ấp Bàu Tre 2, xã Tân An Hội, huyện Củ Chi, Thành Phố Hồ Chí Minh.<br/><br/>
        Tổng mức đầu tư: 1,854 tỷ đồng. <br/><br/>
        Quy mô: 1000 giường, có thể mở rộng lên 1000 giường.<br/><br/>
        Dự án: 2 tầng hầm và 12 tầng nổi với diện tích sàn 11,554 m
        </p>
        `
    },
    {
        id : 11,
        nameHospitol:"BỆNH VIỆN QUỐC TẾ CARMEL",
        path:"benh-vien-quoc-te-carmel",
        img :require("../images/Typical_project/duan-dangtrienkhai/bvqt-carmel.png"),
        des : `
        <p>
        Địa chỉ: Lô C47, Khu độ thị,An Phú, Anh Khánh, Thành Phố Thủ Đức, Thành Phố Hồ Chí Minh.<br/><br/>
        Tổng mức đầu tư: 1,254 tỷ đồng.  <br/><br/>
        Quy mô: 250 giường, có thể mở rộng lên 700 giường.<br/><br/>
        Dự án: 1 tầng hầm và 10 tầng nổi với diện tích sàn 12.093,3m2
        </p>
        `
    },
    {
        id : 12,
        nameHospitol:"BỆNH VIỆN ĐẠI HỌC Y DƯỢC TP.HCM – CS2",
        path:"benh-vien-y-duoc-tphcm-cs2",
        img :require("../images/Typical_project/duan-dangtrienkhai/bvyd-hcm2.png"),
        des : `
        <p>
        Địa chỉ: 201 Nguyễn Chí Thanh, P.12, Q.5, TP. Hồ Chí Minh<br/><br/>
        Quy mô 250 giường bệnh nội trú, 2000 lượt bệnh nhân ngoại trú/ngày<br/><br/>
        Đào tạo liên tục 3000 sinh viên đại học và sau đại học<br/><br/>
        4 tầng hầm, 16 tầng nổi, tổng diện tích sàn xây dựng 65.000m2<br/><br/>
        Tổng mức đầu tư: 1.560 tỷ đồng
        </p>
        `
    },
]