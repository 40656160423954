export const PSCategory = [
    {
        id: 1,
        textItem: "Giải pháp phần mềm quản lý tổng thể Bệnh viện i.Hospital",
        slug: "giai-phap-phan-mem-quan-ly-tong-the-benh-vien-i-hospital",
        icon : require("../images/product_solution/icon/icon-ihospital.png"),
        img : require("../images/product_solution/pic.jpg"),
        text : ` Với những tính năng ưu việt, giải pháp iHospital đã được Hiệp hội Phần mềm và Dịch vụ CNTT Việt Nam trao tặng giải thưởng Sao Khuê 2018. 
        Hiện nay, iHospital đã được ứng dụng thành công tại 6 Sở Y tế, hơn 200 bệnh viện và cơ sở khám chữa bệnh ở Việt Nam`,
        ProductSolutionID : 1
    },
    {
        id: 2,
        textItem: "Giải pháp quản lý chuỗi Bệnh viện, chuỗi Phòng khám",
        slug: "giai-phap-quan-ly-chuoi-benh-vien-chuoi-phong-kham", 
        icon: require("../images/product_solution/icon/icon-chuoi-ihospital.jpg"),
        img : require("../images/product_solution/baner-chuoi-ihospital.png"),
        text : `1. Quản trị thông tin khách hàng, thông tin Nhân viên. 2. Phát hành thẻ thành viên, hỗ trợ thanh toán qua ngân hàng  3. Sử dụng qua ứng dụng Mobile và ứng dụng Web 4. Liên kết giữa các phòng khám và hội sở, với các sở ban ngành Y tế, Bảo Hiểm, Ngân hàng 5. Quản lý chuỗi nhà thuốc 6. Quản lý chuỗi cung ứng, nhà cung cấp`,
        ProductSolutionID : 1
    },
    {
        id: 3,
        textItem: "Giải pháp ứng dụng công nghệ RFID trong quản lý thông tin bệnh nhân",
        slug: "giai-phap-ung-dung-cong-nghe-rfid-trong-quan-ly-thong-tin-benh-nhan", 
        icon: require("../images/product_solution/icon/icon-rfid.png"),
        img : require("../images/product_solution/banner-home-rfid-slide7.png"),
        text : `Từ trước đến nay, tại bệnh viện đã xảy ra rất nhiều tình trạng như phát nhầm thuốc, vào nhầm phòng, dùng nhầm đồ đạc trang thiết bị*y tế*, thất lạc dụng cụ`,
        
        ProductSolutionID : 1
    },
    {
        id: 4,
        textItem: "Giải pháp Xếp hàng thông minh (AI.QMS)",
        slug: "giai-phap-xep-hang-thong-min-iqms", 
        icon: require("../images/product_solution/icon/icon-smart-kiot.png"),
        img : require("../images/product_solution/giaiphapyte-iqms.png"),
        text : `Bất kể bạn đang quản lý một hệ thống phòng giao dịch ngân hàng, dịch vụ hành chính công, bệnh viện hay chuỗi cửa hàng dịch vụ, Hệ thống xếp hàng tự động iQMS của Healthcare Solution sẽ giúp mang lại trải nghiệm xếp hàng tốt nhất, giảm thiểu thời gian chờ đợi của khách hàng và nâng cao năng suất làm việc của giao dịch viên`,

        ProductSolutionID : 1
    },
    {
        id: 5,
        textItem: "Giải pháp Y tế thông minh",
        slug: "giai-phap-y-te-thong-minh", 
        icon: require("../images/product_solution/icon/smart-hospital-icon.png"),
        img : require("../images/product_solution/banner---home-giai-phap-y-te.png"),
        text : `Hầu hết các quốc gia đều phát triển các khái niệm riêng khi đặt câu hỏi “bệnh viện thông minh là gì”. Trong quá trình của cuộc cách mạng công nghệ mới này, người ta đang kết hợp một lượng lớn dữ liệu, dịch vụ điện toán đám mây, phương pháp học máy và giải pháp trí tuệ nhân tạo`,
        
        ProductSolutionID : 1
    },
    {
        id: 6,
        textItem: "Nhà tư vấn chuyên nghiệp mô hình PPP",
        slug: "nha-tu-van-chuyen-nghiep-mo-hinh-ppp", 
        icon: require("../images/product_solution/icon/publicprivate.jpg"),
        img : require("../images/product_solution/tuvan-ppp.png"),
        text : `Hợp tác đầu tư bệnh viện theo mô hình PPP là chủ trương đúng đắn của Chính phủ và Bộ y tế với chính sách động viên các nguồn lực phi nhà nước nhằm giải quyết tình trạng quá tải bệnh viện và nâng cao khả năng tiếp cận dịch vụ của bệnh nhân`,
        ProductSolutionID : 1
    },
    {
        id: 7,
        textItem: "Bộ giải pháp Collaboration",
        slug: "bo-giai-phap-collaboration", 
        icon: require("../images/product_solution/icon/icon-collabration.jpg"),
        img : require("../images/product_solution/banner-giaiphap-communication.jpg"),
        text : `Truyền thông Hợp nhất kết hợp tất cả các hình thức truyền thông doanh nghiệp thành một hệ thống hợp nhất nhằm mang lại nhiều hình thức cộng tác mới đầy mạnh mẽ.`,
        ProductSolutionID : 2
        },
        {
        id: 8,
        textItem: "Bộ giải pháp phụ trợ hạ tầng trung tâm dữ liệu",
        slug: "bo-giai-phap-phu-tro-ha-tang-trung-tam-du-lieu", 
        icon: require("../images/product_solution/icon/icon-giaiphap-datacenter.png"),
        img : require("../images/product_solution/datacenter-banner.png"),
        text : `Truyền thông Hợp nhất kết hợp tất cả các hình thức truyền thông doanh nghiệp thành một hệ thống hợp nhất nhằm mang lại nhiều hình thức cộng tác mới đầy mạnh mẽ`,
        ProductSolutionID : 2
        },
        {
        id: 9,
        textItem: "Công nghệ lưu trữ và máy chủ",
        slug: "cong-nghe-luu-tru-va-may-chu", 
        icon: require("../images/product_solution/icon/server.png"),
        img : require("../images/product_solution/banner-server.jpg"),
        text : `Máy chủ là một thành phần không thể thiếu trong hệ thống công nghệ thông tin, có thể coi đây chính là bộ não trung tâm của cả hệ thống, được chia làm nhiều dòng khác nhau tùy thuộc vào nhu cầu sử dụng`,
        ProductSolutionID : 2
        },
        {
        id: 10,
        textItem: "Giải pháp ảo hóa",
        slug: "giai-phap-ao-hoa", 
        icon: require("../images/product_solution/icon/icon-vdi.png"),
        img : require("../images/product_solution/banner-virtual-server.jpg"),
        text : `Giải pháp Ảo hóa (Virtual Destkop Infrastructure - VDI) là giải pháp sử dụng tài nguyên điện toán từ các máy chủ kết hợp với công nghệ ảo hóa để tạo ra hệ thống các máy trạm, lúc này là các máy ảo.`,
        ProductSolutionID : 2
        },
        {
        id: 11,
        textItem: "Giải pháp bảo mật",
        slug: "giai-phap-bao-mat", 
        icon: require("../images/product_solution/icon/lock-512.png"),
        img : require("../images/product_solution/security-banner.jpg"),
        text : `HSV luôn mong muốn cùng với khách hàng xây dựng nền tảng bảo mật vững chắc cho hệ thống CNTT, đảm bảo quy trình kinh doanh hoạt động hiệu quả, giải quyết những bài toán phát triển mô hình kinh doanh mới, nâng cao vị thế cạnh tranh của các doanh nghiệp.`,
        ProductSolutionID : 2
        },
        {
        id: 12,
        textItem: "Giải pháp hạ tầng mạng",
        slug: "giai-phap-ha-tang-mang", 
        icon: require("../images/product_solution/icon/icon-network.png"),
        img : require("../images/product_solution/networking-banner.jpg"),
        text : `Sự phát triển của doanh nghiệp gắn liền với sự phát triển của công nghệ thông tin. Khi hệ thống, quy mô khách hàng mở rộng, hạ tầng công nghệ thông tin cũng phải đáp ứng sự mở rộng đó.`,
        ProductSolutionID : 2
        },
        {
        id: 13,
        textItem: "Dịch vụ bảo trì",
        slug: "dich-vu-bao-tri", 
        icon: require("../images/product_solution/icon/bao-tri-icon.jpg"),
        img : require("../images/product_solution/banner-dichvu-baotri.png"),
        text : `Đội ngũ nhân viên IT của các doanh nghiệp thường phải đối mặt với những thách thức của việc duy trì và xử lý sự cố liên quan đến công nghệ mới, phức tạp và phải thường xuyên đối mặt với những yêu cầu thay đổi để đảm bảo tính sẳn dùng cho hệ thống kinh doanh. Và đồng thời, các doanh nghiệp còn phải đấu tranh để giữ cho các kỹ sư mạng đã được đào tạo và hy vọng sẽ làm giảm tiêu hao nhân lực ở mức tối thiểu...`,
        ProductSolutionID : 3
        },
        {
        id: 14,
        textItem: "Dịch vụ Kỹ sư tại chỗ (Onsite IT engineers)",
        slug: "dich-vu-ky-su-tai-cho-onsite-it-engineers", 
        icon: require("../images/product_solution/icon/icon-servicedesk.jpg"),
        img : require("../images/product_solution/banner-servicedesk.jpg"),
        text : `**Các doanh nghiệp phải đối mặt với những thách thức của việc thuê và duy trì kỹ sư lành nghề để vận hành**, hỗ trợ những việc liên quan đến CNTT phục vụ công việc kinh doanh của mình, đồng thời hi vọng có thể giảm thiểu việc tiêu hao nhân lực trong khi doanh nghiệp vẫn chưa sẵn sàng thuê ngoài việc quản lý hoàn toàn hệ thống của mình...`,
        ProductSolutionID : 3
        },
        {
        id: 15,
        textItem: "Dịch vụ quản lý bảo mật",
        slug: "dich-vu-quan-ly-bao-mat", 
        icon: require("../images/product_solution/icon/icon-dichvu-security.jpg"),
        img : require("../images/product_solution/security-banner.jpg"),
        text : `Với ra sự ra đời ngày càng nhiều các mối đe dọa an ninh phức tạp, các doanh nghiệp phải đối mặt với những thách thức bảo đảm an toàn cơ sở hạ tầng thông tin của họ, bao gồm: Sự cần thiết cho sự bảo vệ trước các cuộc tấn công ngày càng tinh vi: Doanh nghiệp phải luôn luôn làm chủ được các công nghệ mới, với các giải pháp bảo mật có thể phát hiện và đáp ứng các cuộc tấn công ngày càng tinh vi.`,
        ProductSolutionID : 3
        },
        {
        id: 16,
        textItem: "Dịch vụ quản lý mạng",
        slug: "dich-vu-quan-ly-mang", 
        icon: require("../images/product_solution/icon/quan-ly-mang.jpg"),
        img : require("../images/product_solution/network-infrastructure-banner.jpg"),
        text : `Dịch vụ Quản lý Mạng cung cấp một bộ hoàn chỉnh các giải pháp giám sát cơ sở hạ tầng CNTT của bạn dựa trên bộ khung cung cấp dịch vụ, bộ khung này tuân thủ một tập các quy trình nhằm để giám sát hiệu suất và phát hiện nhanh lỗi mạng.`,
        ProductSolutionID : 3
        },
        {
        id: 17,
        textItem: "Dịch vụ quản lý máy chủ và lưu trữ dữ liệu",
        slug: "dich-vu-quan-ly-may-chu-va-luu-tru-du-lieu", 
        icon: require("../images/product_solution/icon/icon-dichvu-quanlymaychu.png"),
        img : require("../images/product_solution/banner-flyer-file-server.jpg"),
        text : `Các doanh nghiệp có nhu cầu về lưu trữ thông tin một cách thông minh, các giải pháp sao lưu, phục hồi và lưu trữ để doanh nghiệp có thể quản lý hạ tầng thông tin tốt hơn, trong lúc vẫn kiểm soát được chi phí ở mức thấp. Khi hệ thống thông tin của doanh nghiệp phát triển, việc tăng máy chủ và hệ điều hành là vấn đề không thể tránh khỏi, các địa điểm để đặt các máy chủ có thể tại văn phòng doanh nghiệp`,
        ProductSolutionID : 3
        },
        {
        id: 18,
        textItem: "Trung tâm Dịch vụ khách hàng - ServiceDesk",
        slug: "trung-tam-dich-vu-khach-hang-servicedesk", 
        icon: require("../images/product_solution/icon/sservice-desk1.jpg"),
        img : require("../images/product_solution/banner-servicedesk.jpg"),
        text : `ServiceDesk là một bộ máy bao gồm các kênh liên lạc dễ nhớ dành cho khách hàng, phần mềm quản lý dịch vụ khách hàng, các nhân viên chuyên trách phụ trách toàn bộ quy trình vận hành ServiceDesk và cuối cùng là đội ngũ kỹ sư lành nghề của HSV được tổ chức phân công trực hỗ trợ khách hàng theo các lãnh vực chuyên môn với mức độ ưu tiên và độ sẵn sàng cao nhất.`,
        ProductSolutionID : 3
        },
        {
        id: 19,
        textItem: "Phần mềm quản lý dịch vụ",
        slug: "phan-mem-quan-ly-dich-vu", 
        icon: require("../images/product_solution/quanly-dichvu.jpg"),
        img : require("../images/product_solution/quanly-dichvu.jpg"),
        text : `Phần mềm giúp quản lý các dịch vụ Bảo hành, Khách sạn, Thẩm mỹ viện,Công chứng, Du lịch`,
        ProductSolutionID : 4
        },
        {
        id: 20,
        textItem: "Phần mềm quản lý doanh nghiệp",
        slug: "phan-mem-quan-ly-doanh-nghiep", 
        icon: require("../images/product_solution/phanmem-quanly-doanhnghiep.jpg"),
        img : require("../images/product_solution/phanmem-quanly-doanhnghiep.jpg"),
        text : `Quản lý nhân sự, Tiến độ làm việc, Chi tiết dự án, Lương thưởng nhân viên, Thu chi, Khách hàng…`,
        ProductSolutionID : 4
        },
        {
        id: 21,
        textItem: "Phần mềm quản lý xây dựng",
        slug: "phan-mem-quan-ly-xay-dung", 
        icon: require("../images/product_solution/quanly-xaydung.jpg"),
        img : require("../images/product_solution/quanly-xaydung.jpg"),
        text : `Phần mềm quản lý Tiến độ làm việc, Quản lý đầu tư, Xây dựng, Quản lý các dự án của công ty`,
        ProductSolutionID : 4
        },
        {
        id: 22,
        textItem: "Phần mềm quản lý giáo dục",
        slug: "phan-mem-quan-ly-giao-duc", 
        icon: require("../images/product_solution/quanly-giaoduc.jpg"),
        img : require("../images/product_solution/quanly-giaoduc.jpg"),
        text : `Phần mềm giúp quản lý học viên, tối ưu nguồn lực về giảng viên, cơ sở vật chất và nhân viên`,
        ProductSolutionID : 4
        },
        {
        id: 23,
        textItem: "Dịch vụ Cloud VNN",
        slug: "dich-vu-cloud-vnn", 
        icon: require("../images/product_solution/icon/icon-cloud-vnn.jpg"),
        img : require("../images/product_solution/banner-cloud-vnn.jpg"),
        text : `Dịch vụ Cloud VNN - cung cấp hạ tầng CNTT trên nền tảng Điện toán đám mây, nhằm cung cấp hạ tầng ảo hóa cho tất cả các khách hàng là cá nhân hay tổ chức có nhu cầu thuê một hoặc một vùng tài nguyên dành riêng phục vụ cho hoạt động sản xuất kinh doanh của mình; cung cấp một môi trường tính toán ảo cho phép khách hàng có thể sử dụng các máy chủ ảo chạy trên nhiều loại hệ điều hành khác nhau.`,
        ProductSolutionID : 5
        },
        {
        id: 24,
        textItem: "Dịch vụ Sao lưu dữ liệu trên đám mây",
        slug: "dich-vu-sao-luu-du-lieu-tren-dam-may", 
        icon: require("../images/product_solution/icon/icon-network.png"),
        img : require("../images/product_solution/banner-cloud-services-slider.jpg"),
        text : `Các doanh nghiệp phải có khả năng bảo vệ dữ liệu quan trọng của mình và cố gắng cải thiện khả năng phục hồi để chuẩn bị khi thảm họa không mong đợi và những sự gián đoạn lớn xảy ra`,
        ProductSolutionID : 5
        },
        {
        id: 25,
        textItem: "Dịch vụ vCLOUD",
        slug: "dich-vu-vcloud", 
        icon: require("../images/product_solution/icon/icon-service-cloud.png"),
        img : require("../images/product_solution/banner-service-cloud.jpg"),
        text : `Dịch vụ vCLOUD là dịch vụ cung cấp hạ tầng CNTT trên nền tảng Điện toán đám mây do HSV phát triển. Dịch vụ vCLOUD cung cấp hạ tầng ảo hóa cho tất cả các khách hàng là cá nhân hay tổ chức có nhu cầu thuê một hoặc một vùng tài nguyên dành riêng phục vụ cho hoạt động sản xuất kinh doanh của mình; cung cấp một môi trường tính toán ảo cho phép khách hàng có thể sử dụng các máy chủ ảo chạy trên nhiều loại hệ điều hành khác nhau.`,
        ProductSolutionID : 5
        },
        {
        id: 26,
        textItem: "Dịch vụ VCDN",
        slug: "dich-vu-vcdn", 
        icon: require("../images/product_solution/icon/CDN-1.png"),
        img : require("../images/product_solution/70-vdms-cdn-fweb-banner2017-2.jpg"),
        text : `Dịch vụ truyền tải nội dung số CDN- Content Delivery Network or Content Distribution Network. Là dịch vụ truyền tải nội dung chuyên nghiệp, hoàn chỉnh đầu tiên tại thị trường Việt Nam.`,
        ProductSolutionID : 5
        },
        {
        id: 27,
        textItem: "EasyLink Platform",
        slug: "easylink-platform", 
        icon: require("../images/product_solution/icon/icon-iot.png"),
        img : require("../images/product_solution/easylink-platform_2.png"),
        text : `Giải pháp EasyLink Platform cung cấp một nền tảng IoT mạnh mẽ và mềm dẻo, hỗ trợ cho việc phát triển các ứng dụng IoT nhanh chóng và thuận tiện`,
        ProductSolutionID : 6
        },
        {
        id: 28,
        textItem: "Giải pháp nông nghiệp thông minh",
        slug: "giai-phap-nong-nghiep-thong-minh", 
        icon: require("../images/product_solution/icon/icon-agri.png"),
        img : require("../images/product_solution/banner-iot-nongnghiepthongminh.png"),
        text : `Giải pháp quản lý nông nghiệp thông minh của HSV gồm các tính năng đo lường và giám sát về độ ẩm, nhiệt độ, hàm lượng phân bón, lượng nước tưới; tự động lập lịch tưới tiêu, bón phân,…. giúp nông dân thế hệ mới canh tác ở mọi quy mô và lĩnh vực đều có thể áp dụng khoa học công nghệ nhằm tăng năng suất mùa vụ và thu lợi nhiều hơn trên nông trại của mình`,
        ProductSolutionID : 6
        },
        {
        id: 29,
        textItem: "Giải pháp quản lý sản xuất thông minh",
        slug: "giai-phap-quan-ly-san-xuat-thong-minh", 
        icon: require("../images/product_solution/icon/revitalizing-the-shop-floor.jpg"),
        img : require("../images/product_solution/smart-factory.png"),
        text : `Giải pháp toàn diện quản lý sản xuất thông minh bao gồm quản lý kho, nhà xưởng; quản lý dây chuyền và hệ thống giám sát qua video`,
        ProductSolutionID : 6
        },
        {
        id: 30,
        textItem: "Hệ thống giao thông thông minh",
        slug: "he-thong-giao-thong-thong-minh", 
        icon: require("../images/product_solution/icon/giaothong-icon.jpg"),
        img : require("../images/product_solution/giaothongthongminh-banner.jpg"),
        text : `Giải pháp quản lý giao thông thông minh được thiết kế và tư vấn bởi đội ngũ kỹ sư của HSV, giúp tăng mức độ an toàn, hiệu quả, cắt giảm thời gian phương tiện lưu thông trên đường. Đây là giải pháp tích hợp hợp nhất giữa công nghệ thông tin và truyền thông, cho phép các nhà quản lý giám sát, vận hành và điều khiển hệ thống giao thông một cách toàn diện nhất.`,
        ProductSolutionID : 6
        },
        {
        id: 31,
        textItem: "Hệ thống quản lý giám sát Data Center",
        slug: "he-thong-quan-ly-giam-sat-data-center", 
        icon: require("../images/product_solution/icon/datacenter.jpg"),
        img : require("../images/product_solution/monitoring-datacenter.jpg"),
        text : `Hệ thống giám sát tập trung quản lý tất cả các thiết bị trong Datacenter và cảnh báo cho người vận hành nếu có bất thường xảy ra. Đối với Datacenter thì việc giám sát hoạt động và tình trạng thiết bị đòi hỏi phải tức thời, liên tục ngay khi không có mặt của nhân viên vận hành.`,
        ProductSolutionID : 6
        },
        {
        id: 32,
        textItem: "i-Parking",
        slug: "i-parking", 
        icon: require("../images/product_solution/icon/parking-icon.jpg"),
        img : require("../images/product_solution/smart-parking.jpg"),
        text : `i - Parking là Hệ thống quản lý bãi đỗ xe thông minh được thiết kế và xây dựng trên nền tảng công nghệ 4.0, giúp quản lý đồng bộ nhiều bãi xe cùng lúc, điều hành tập trung, dễ dàng tìm kiếm bãi đỗ trống và thanh toán linh hoạt. Giải pháp còn giúp chủ phương tiện dễ dàng tìm điểm đậu xe trong thành phố và các tiện ích quanh bãi đậu xe thông qua ứng dụng trên Android/IOS.`,
        ProductSolutionID : 6
        },
        {
        id: 33,
        textItem: "Y tế thông minh",
        slug: "y-te-thong-minh",
        icon: require("../images/Logo/logoESM.png"),
        img : require("../images/home1.jpg"),
        text : ``,
        ProductSolutionID : 7
        },           
        {
        id: 34,
        textItem: "Phần mềm Quản lý tổng thể Bệnh viện",
        slug: "pham-mem-quan-ly-tong-the-benh-vien", 
        icon: require("../images/product_solution/icon/iconHospital.png"),
        img : require("../images/product_solution/phan-mem-quan-ly-benh-vien.jpg"),
        text : ``,
        ProductSolutionID : 7
        },
        {
        id: 35,
        textItem: "Phần mềm Quản lý Phòng khám",
        slug: "phan-men-quan-ly-phong-kham", 
        icon: require("../images/product_solution/icon/Logo_Final_i Clinic.jpg"),
        img : require("../images/product_solution/phan-mem-quan-ly-phong-kham-mien-phi-banner.jpg"),
        text : ``,
        ProductSolutionID : 7
        },
        {
        id: 36,
        textItem: "Phần mềm Quản lý Nhà thuốc",
        slug: "phan-mem-quan-ly-nha-thuoc", 
        icon: require("../images/product_solution/icon/Logo PHARMACY-01.jpg"),
        img : require("../images/product_solution/phan-mem-quan-ly-nha-thuoc-thumb.jpg"),
        text : ``,
        ProductSolutionID : 7
        },
        {
        id: 37,
        textItem: "Phần mềm Quản Trị Nguồn Lực",
        slug: "phan-mem-quan-tri-nguon-luc", 
        icon: require("../images/product_solution/icon/iconERP.png"),
        img : require("../images/product_solution/phan-mem-quan-tri-nguon-luc-doanh-nghiep.jpg"),
        text : ``,
        ProductSolutionID : 7
        },
        {
        id: 38,
        textItem: "Phần mềm Xếp Hàng Tự Động & Kiosk Thông minh",
        slug: "phan-mem-xep-hang-tu-dong-kiosk-thong-minh",
        icon: require("../images/Logo/Logo HSV_01.png"),
        img : require("../images/product_solution/giaiphapyte-iqms.png"),
        text : ``,
        ProductSolutionID : 7
        },

]