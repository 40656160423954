import React from 'react'

function IntroduceStatement() {
    return (
        <div className='IntroduceStatement'>
            <p>
                Những kết quả mà HSV đã gặt hái được trong thời gian vừa qua là sự tiếp nối những thành công của HSV mà khởi đầu của nó - khởi đầu của sự thành công - bắt đầu từ tháng 12/2016"
                <br></br>
                HSV ngày nay không chỉ là công ty chuyên sâu trong lĩnh vực Y tế mà còn mở rộng ra doanh nghiệp với những lĩnh vực kinh doanh đa dạng trong ngành công nghệ thông tin.
                <br></br>
                HSV luôn đặt lợi ích khách hàng lên hàng đầu và chúng tôi tin rằng để làm được điều đó chúng tôi phải có những con người có kiến thức, kỹ năng và ý thức tốt để duy trì được sự tin tưởng của khách hàng. Mối quan hệ chiến lược với các nhà sản xuất hàng đầu trên thế giới giúp chúng tôi cập nhật các công nghệ, giải pháp tiên tiến nhất để có thể chuyền giao cho khách hàng một các tốt nhất.
                <br></br>
                Ngoài trách nhiệm mang lại sự lợi nhuận cho nhà đầu tư, chúng tôi còn chia sẻ lợi ích với cộng đồng và mang lại cuộc sống tốt đẹp cho nhân viên. Chúng tôi cam kết mang lại sự thành đạt, niềm tự hào cho những ai đồng hành với chúng tôi, những người đã, đang và sẽ đặt trọn niềm tin vào chúng tôi.
            </p>
        </div>
    )
}

export default IntroduceStatement