export const introducMenu = [
    {
        text:"Tổng quan",
        slug:"/gioi-thieu"
    },
    {
        text:"Thông điệp",
        slug:"thong-diep"
    },
    {
        text:"Logo và các giá trị nền tảng",
        slug:"logo-gia-tri-nen-tang"
    },
    {
        text:"Tầm nhìn - Sứ mệnh - Giá trị & cốt lõi",
        slug:"tam-nhin-su-menh-gia-tri-cot-loi"
    },
   
    {
        text:"Định hướng phát triển",
        slug:"dinh-huong-phat-trien"
    },
    {
        text:"Văn hóa và môi trường làm việc",
        slug:"van-hoa-va-moi-truong-lam-viec"
    },
    {
        text:"Chính sách nhân sự",
        slug:"chinh-sach-nhan-su"
    },

]