import React from 'react';
import "./sidebarLeft.scss";
import { MdEmail } from "react-icons/md";
import { AiTwotonePhone } from "react-icons/ai";
import { BsMoonStarsFill } from "react-icons/bs"
import { MdLightMode } from "react-icons/md";
import { useState } from 'react';
import { TbLanguage } from "react-icons/tb";

function SidebarLeft() {
  const [darkMode, setDarkMode] = useState(false);
  const [english, setEnglish] = useState(false);


  return (
    <div className='contact_container'>
      <ul className='contact_container-list'>
        <li>
          <a href="mailto:Info@hsv.com.vn">
            <MdEmail />
            <p>Email</p>
          </a>
        </li>
        <li>
          <a href="tel:+84917632112">
            <AiTwotonePhone />
            <p>Gọi ngay</p>
          </a>
        </li>
        <li onClick={() => setDarkMode(!darkMode)}>
          {
            darkMode
              ? <a href="#" style={{ pointerEvents: "none" }}>
                <BsMoonStarsFill />
                <p>Tối</p>
              </a>
              : <a href="#">
                <MdLightMode />
                <p>Sáng</p>
              </a>
          }
        </li>
        <li onClick={() => setEnglish(!english)}>
          <a href="#" style={{ pointerEvents: "none" }}>
            <TbLanguage />
            <p>{english ? "English" : "Tiếng việt"}</p>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default SidebarLeft;