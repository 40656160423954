import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { ProductSolutionMenu } from "../../localData/productSolutionMenu";
import { useNavigate } from 'react-router-dom';
import { Pagination } from "swiper";

function ListProjectSolution() {
  const navigate = useNavigate();
  return (
    <div className='TypicalProjectSlider'>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }
        }
        modules={[Pagination]}
        className="mySwiper"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 40,
          }
        }}
      >
        {ProductSolutionMenu.map((value, index) => {
          return (
            <SwiperSlide key={index} onClick={() => navigate(`/sanpham-giaiphap/${value.slug}`)}>
              <div className="home_ourservices-content">
                <ul>
                  <li>
                    <div>
                      <img src={value.img} alt="services" />
                    </div>
                    <p style={{ fontWeight: "600", color: "rgb(77, 183, 72)" }}>{value.text}</p>
                  </li>
                </ul>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default ListProjectSolution;
