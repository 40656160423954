export const leaders = [{
        id: 0,
        name: "Lê Tây",
        gender: 1,
        position: ["Chủ tịch HĐQT", "Tổng Giám Đốc"],
        education: "",
        description: `Ông Lê Tây đã hoạt động trong lĩnh vực CNTT từ năm 1997 và hơn 20 năm kinh nghiệm trong lĩnh vực này.

        <br/>Ông đã tham gia vào các Công ty lớn tại Việt Nam, Ông từng lãnh đạo nhiều dự án triển khai hệ thống hạ tầng viễn thông quy mô lớn và phức tạp như Dự án DC – Call Center cho tập đoàn FPT, CMC, Sao Bắc Đẩu, Dự án Hà Nội, Đà Nẵng, TP Hồ Chí Minh, Telecom – Hutchinson…, tham gia nhiều dự án CNTT tại Nhật Bản, Singapore, Hồng Kông, Đài Loan.
        
        <br/>Cùng với năng lực Quản lý, khảo sát và lập dự án đầu tư ngoài ra ông có nhiều kinh nghiệm triển khai và tích hợp các dự án lớn về CNTT cho các Cơ quan Chính phủ, Ngân hàng, Tập đoàn và các Công ty đầu tư FDI tại Khu Công Nghệ cao, Khu chế xuất, Khu Công Nghiệp
        
        <br/>Cùng với sự phát triển ngành Y tế của Thế giới nói chung và Việt Nam nói riêng, Ông cũng đã từng tư vấn và triển khai các dự hệ thống ứng dụng CNTT vào trong công tác quản trị Bệnh viện – Chuỗi phòng khám và là một chuyên gia trong lĩnh vực Xây dựng – Vận hành – Tích hợp CNTT đặc thù riêng cho Ngành Y Tế.
        
        <br/>Từ 2010 - 2015 đến nay với vai trò là Giám đốc CNTT của Công ty Cotec HealthCare, Ông là người tham gia thực hiện các bước thủ tục đầu tư từ giai đoạn thiết kế hạ tầng đến gia đoạn vận hành đến ứng dụng CNTT cho toàn bộ dự án và đảm bảo dự án hoạt động một cách xuyên suốt và hiệu quả.
        
        <br/>Các dự án Ông <b>Lê Tây</b> đã tham gia tư vấn, thiết kế và vận hành như sau:
        
        <br/>Bệnh viện Đa khoa Đồng Nai – GĐ2, 700 giường, TMĐT 1.500 tỷ đồng;
        
        <br/>Bệnh viện Đa khoa Bình Định - Phần mở rộng, 600 giường, TMĐT là 1.200 tỷ đồng;
        
        <br/>Bệnh viện Phụ Sản Quốc tế Đức Giang, 200 giường, TMĐT 730 tỷ đồng;
        
        <br/>Bệnh viện Hữu nghị Đa khoa Nghệ An GĐ2, 600 giường, TMĐT 1.300 tỷ đồng.
        
        <br/>Bệnh viện Đa Khoa Quãng Ngãi GĐ2, 600 giường, TMĐT 1.200 tỷ đồng.
        
        <br/>Bệnh viện Đa Khoa Bến Tre Cơ sở II, 450 giường, TMĐT 2.250 tỷ đồng.
        
        <br/>Từ năm 2016 Ông Lê Tây đồng thời là nhà sáng lập - Chủ tịch Hội Đồng Quản Trị kiêm Tổng Giám Đốc Công ty Cổ Phần Healthcare Solution Việt Nam (HSV), sở hữu thương hiệu trong lĩnh vực CNTT. Cùng với sự của nền công nghệ và nhu cầu ứng dụng CNTT mạnh mẽ của xã hội, HSV luôn có những bước nhảy vững chắc từ những “Giải pháp Công nghệ Thông tin Toàn diện” đến “Dịch vụ Khách hàng” chất lượng là minh chứng cho sự phát triển và khẳng định mình trong CNTT.
        
        <br/>Tầm nhìn: <b>“Chuyển hóa tri thức CNTT thành giá trị gia tăng cho khách hàng bằng chất xám và sự tận tâm phục vụ”</b>.
        
        <br/>Sứ mệnh:
        
        <ul>
            <li style="list-style: disc;">Đáp ứng yêu cầu của khách hàng với tinh thần phục vụ tận tụy và sự hiểu biết sâu sắc về yêu cầu đặt ra.</li>
            <li style="list-style: disc;">Không ngừng nâng cao năng lực công nghệ và cải tiến quy trình chất lượng nhằm cung cấp những sản phẩm và dịch vụ có chất lượng tốt nhất.</li>
            <li style="list-style: disc;">Đoàn kết nội bộ, nỗ lực phấn đấu vì một sự nghiệp chung.</li>
            <li style="list-style: disc;">Chân thành, chủ động trong việc xây dựng quan hệ hợp tác với các công ty đối tác để cùng phát triển.</li>
            <li style="list-style: disc;">Tái đầu tư xã hội thông qua các hoạt động từ thiện.</li>
        </ul>
        
        
        Tâp hợp đội ngũ đông đảo các chuyên gia tư vấn có trình độ chuyên môn cao với nhiều năm kinh nghiệm trong các lĩnh vực CNTT-TT khác nhau, đã từng đảm đương những vị trí lãnh đạo quan trọng trong các công ty tin học và truyền thông hàng đầu Việt Nam. Đội ngũ chuyên gia và cộng tác viên của HSV là những người đã từng trực tiếp tham gia thiết kế, triển khai, khai thác, vận hành các hệ thống CNTT-TT lớn cho nhiều khách hàng là các cơ quan Chính phủ, Ngân hàng, Tập đoàn, Tổng công ty và các doanh nghiệp.
        
        <br/>Giữ đúng định hướng từ ngày đầu thành lập – HSV nghiên cứu và phát triển chuyên sâu vào mảng tích hợp hệ thống, thực hiện các giải pháp bảo mật, an toàn thông tin trên mọi nền tảng công nghệ.
        
        <br/>Trong từng giai đoạn phát triển của mình, HSV có những sản phẩm mang tính chiến lược và thời sự để áp dụng cho các doanh nghiệp trong và ngoài nước mà điển hình đề cập đến là các giải pháp và ứng dụng trên Mã nguồn mở.
        
        <br/<b>Một số chiến lược chính giai đoạn 2016-2025</b>
        
        <ul>
            <li>Nhà tư vấn, triển khai giải pháp phần mềm và hạ tầng điện nhẹ, hệ thống Server cho khối Bệnh viện, Doanh nghiệp.</li>
            <li>Nhà tư vấn, thiết kế và triển khai hạ tầng phục vụ ERP cho các ngành kinh tế lớn ở Việt Nam.</li>
            <li>Nhà nghiên cứu và triển khai các giải pháp trên nền tảng công nghiệp 4.0</li>
            <li>Sản phẩm – Dịch vụ do HSV cung cấp</li>
            <li>Triển khai và nâng cấp hệ thống hoạch định nguồn lực doanh nghiệp (ERP) ứng dụng công nghệ tiên tiến của các hãng phần mềm SAP, Orancle, Microsoft, Phần mềm tích hợp Quản lý Chuỗi Bệnh Viện – Phòng khám.</li>
            <li>Cổng thông tin điện tử - Portal; Hệ thống quản lý nhân sự; quản lý văn bản và điều hành.</li>
            <li>Dịch vụ cung cấp các sản phẩm công nghệ thông tin phần cứng và phần mềm.</li>
            <li>Dịch vụ cơ sở hạ tầng CNTT – Tư vấn thiết kế và triển khai hạ tầng mạng, kết nối truyền dẫn cho máy chủ các dịch vụ ứng dụng.</li>
            <li>Dịch vụ bảo mật và an toàn thông tin; Dịch vụ tối ưu hóa hệ thống thông tin lõi.</li>
            <li>Dịch vụ bảo mật và an toàn thông tin; Dịch vụ tối ưu hóa hệ thống thông tin lõi.</li>
            <li>Call Center, Contract Center, Data Center.</li>
            <li>Dịch vụ bảo hành bảo trì; Dịch vụ đào tạo an ninh mạng chuyên sâu cho từng lĩnh vực.</li>
            <li>Các dịch vụ khác.</li>
        </ul>`,
        summary: `Ông đã tham gia vào các Công ty lớn tại Việt Nam, Ông từng lãnh đạo nhiều dự án triển khai 
        hệ thống hạ tầng viễn thông quy mô lớn và phức tạp như Dự án DC – Call Center cho tập đoàn FPT, CMC, Sao Bắc Đẩu, Dự án Hà Nội, Đà Nẵng, TP Hồ Chí Minh, Telecom – Hutchinson…, tham gia nhiều dự án CNTT tại Nhật Bản, Singapore, Hồng Kông, Đài Loan.`,
        category: 1,
        img: require('../images/leader/mr-tay.jpg'),
        slug: "le-tay"
    },
    {
        id: 1,
        name: "Phạm Văn Ngoan",
        gender: 1,
        position: ["Phó chủ tịch HĐQT"],
        education: "Tiến Sĩ",
        description: `
        Ông <b>Phan Văn Ngoan tốt</b> nghiệp Đại học Luật TP. Hồ Chí Minh năm 1999, Đại học kinh tế TP. Hồ Chí Minh 2007, Thạc sĩ Kinh tế năm 2015 và Tiến sĩ kinh tế vào tháng 4/2019. Ngoài ra, Ông còn tham gia đào tạo khóa quản lý nhân sự cấp cao cho các dự án y tế tại Cộng Hòa Liên Bang Đức năm 2013.

        <br/>Ông <b>Phan Văn Ngoan</b> có 20 năm kinh nghiệm chuyên sâu trong lĩnh tư vấn Luật về đầu tư và điều hành cấp cao tại các tập đoàn kinh tế của Việt Nam, đã có thời gian làm việc từ 1999 – 2019.
        
        <br/>Từ năm 1999 đến 2002, là Luật sư tại Văn phòng LS Trương Thị Hòa.
        
        <br/>Từ năm 2003 đến 2005, làm việc tại Tập đoàn Kinh Đô với vai trò là Trưởng bộ phận pháp lý của Tập đoàn, Ông Phan Văn Ngoan đã trực tiếp thực hiện thủ tục pháp lý của các dự án như: Cộng Hoà Garden, Nhà máy bánh kẹo Kinh Đô, Regal Club….
        
        <br/>Từ năm 2006 đến 7/2015, làm việc tại Cotec Group với vai trò là Phó Tổng Giám đốc điều hành của Tập đoàn, Ông Phan Văn Ngoan đã trực tiếp thực hiện các dự án như: Khu dân cư Phú Xuân Nhà Bè, phát triển dự án Blue Saphire Resort Vũng Tàu. Chủ trì pháp lý các hợp đồng M&A như: chuyển nhượng Nhà máy xi măng Cotec cho tập đoàn Holcim – Thụy Sĩ với gía trị chuyển nhượng 45 triệu USD, chuyển nhượng Nhà máy xi măng Bửu Long cho tập đoàn SCG – Thái Lan với trị giá 5 triệu USD, chuyển nhượng cổ phần 1 triệu cổ phần CLG đã niêm yết trên sàn giao dịch chứng khoán TP. Hồ Chí Minh cho tập đoàn Tamahome Nhật Bản với trị giá 2 triệu USD.
        
        <br/><br/>Từ 7/2015 đến 7/2017, công tác tại Nguyễn Kim Group với vai trò là Phó Giám đốc cấp cao của Tập đoàn, Ông Phan Văn Ngoan quản lý hơn 25 dự án phát triển Trung tâm thương mại, siêu thị trên cả nước.
        
        <br/><br/>Ông chịu trách nhiệm toàn diện về việc xây dựng đề án phát triển chuỗi trung tâm thương mại – bán lẻ ở thị trường Việt Nam liên kết với đối tác danh tiếng nước ngoài để thực hiện kinh doanh lĩnh vực bán lẻ, tiêu dùng: Aeon, Lotte, Central, BigC, Auchan, Emart, Parson, 7 eleven, Shop and Go…. và nhà đầu tư trong nước gồm Vincom và Coopmart.
        
        <br/><br/>Về kinh nghiệm triển khai Dự án Bệnh viện, Ông đã nghiên cứu pháp lý và triển khai thành công 02 dự án là: Bệnh viện Phụ sản Đức Giang – Hà Nội và Bệnh viện Đa khoa Đồng Nai – Giai đoạn 2. Công ty CP Bệnh viện Đa khoa Đồng Nai thực hiện theo mô hình hợp tác công – tư (Mô hình PPP). Dự án được Chính phủ và chính quyền và nhà đầu tư đánh giá cao mô hình PPP là thành công xét ở mọi khía cạnh. Đây là những dự án điển hình của mô hình PPP ở Việt Nam thời điểm 2012 – 2015. Với vai trò là CEO Công ty CP Bệnh viện Đa Khoa Đồng Nai, Ông là người thực hiện dự án hợp tác công tư đầu tiên trong lĩnh vực y tế. Ông chịu trách nhiệm toàn diện về các vấn đề pháp lý thực hiện dự án, tổ chức thực hiện công tác lựa chọn nhà thầu từ giai đoạn thiết kế, quản lý dự án, giám sát, thi công xây dựng đến mua sắm vật tư, thiết bị hoàn thiện, nội thất, lắp đặt thiết bị y tế…
        
        
        <br/><br/>Ông <b>**Phan Văn Ngoan** </b>chủ trì xây dựng hệ thống quy trình vận hành, cơ chế hợp tác với bệnh viện công lập về việc điều phối nguồn nhân lực y bác sĩ, tuyển dụng và đào tạo nhân sự chuyên môn, cơ chế điều phối bệnh nhân, lĩnh vực bảo hiểm y tế, chia sẽ cơ sở dữ liệu, sử dụng nguồn lực của các bên một cách minh bạch, công khai trên tinh thần hợp tác các bên cùng có lợi. Đến nay, dự án này đã hoạt động trên 03 năm và dần dần đi vào ổn định, tăng trưởng tốt.
        
        Năm 2018, Ông là nhóm trưởng nhóm tư vấn đầu tư cho nhà đầu tư – Công ty Cổ Phần Vạn Phát Hưng để tham gia giai đoạn tuyển chọn nhà thầu đầu tư Dự án Đại học Y Dược cơ sở 2 – với mô hình đối tác công – tư (mô hình PPP) với quy mô 200 giường bệnh, khu chống lây nhiễm và khu đào tạo, tổng mức đầu tư 1.560 tỷ đồng
        `,
        category: 1,
        img: require('../images/leader/mr-ngoan.jpg'),
        slug: 'pham-van-ngoan',

    },
    {
        id: 2,
        name: "Nguyễn Thị Hồng Hạnh",
        gender: 0,
        position: ["Thành Viên HĐQT", "Phó TGĐ Kinh Doanh"],
        education: "",
        description: `Bà Hạnh là một trong những sáng lập viên của Công Ty Healthcare Solution Viet Nam, Bà là một trong những người xây dựng các tầm nhìn, tổ chức hoạt động và chiến lược kinh doanh cho công ty từ những ngày đầu thành lập. Bà đã từng giữ chức vụ Tổng Giám Đốc Công Ty MDC từ năm 2005 - 2016 - 2019.

        <br/>Phương châm của Bà Hạnh: Năng động và đề cao tính chuyên nghiệp là phương châm trong kinh doanh; quyết đóan và biết lắng nghe là phương châm trong công việc điều hành; coi trọng chữ tín và hợp tác cùng có lợi (win-win) là phương châm trong các quan hệ công việc và cuộc sống.
        
        <br/>Bà đã có nhiều đóng góp trong việc phát triển thị trường kinh doanh tại khu vực phía Bắc cũng như phát triển hoạt động kinh doanh của Tổng Công ty. Là người trực tiếp chỉ đạo đội ngũ kinh doanh và có kinh nghiệm trong điều hành kinh doanh, Bà Nguyễn Thị Hồng Hạnh được kì vọng sẽ tiếp nối sứ mệnh điều hành Healthcare Solution Viet Nam chinh phục những mục tiêu kinh doanh mới, tăng thị phần ở những thị trường trọng điểm, hướng đến mục tiêu phát triển công ty hùng mạnh và giữ vững ngôi vị dẫn đầu trong lĩnh vực đổi mới sáng tạo.
        
        <br/>Từ đầu năm 2016 - 2019, Bà tham gia vào Ban Lãnh Đạo của công ty với chức danh Phó Tổng Giám Đốc khối khách hàng chiến lược. Bà chịu trách nhiệm phát triển kinh doanh các giải pháp hướng đến xây dựng Thành Phố Thông Minh, an toàn an ninh Quốc phòng, theo chủ trương, chính sách của chính phủ, các khối, ban ngành, và các tỉnh, thành phố; một số giải pháp điển hình như: giải pháp cho, Y Tế Thông Minh, Môi Trường Thông Minh, Giao Thông Thông Minh, Hệ Thống An Ninh Quốc Phòng …`,
        category: 1,
        img: require('../images/leader/mrs-hanh.jpg'),
        slug: 'nguyen-thi-hong-hanh',
        summary: "Từ đầu năm 2016 - 2019, Bà tham gia vào Ban Lãnh Đạo của công ty với chức danh Phó Tổng Giám Đốc khối khách hàng chiến lược. Bà chịu trách nhiệm phát triển kinh doanh các giải pháp hướng đến xây dựng Thành Phố Thông Minh, an toàn an ninh Quốc phòng, theo chủ trương, chính sách của chính phủ, các khối, ban ngành, và các tỉnh, thành phố; một số giải pháp điển hình như: giải pháp cho, Y Tế Thông Minh, Môi Trường Thông Minh, Giao Thông Thông Minh, Hệ Thống An Ninh Quốc Phòng …"

    },

    {
        id: 3,
        name: "Lê Tây",
        gender: 1,
        position: ["Chủ tịch HĐQT", "Tổng Giám Đốc"],
        education: "",
        description: `Ông Lê Tây đã hoạt động trong lĩnh vực CNTT từ năm 1997 và hơn 20 năm kinh nghiệm trong lĩnh vực này.

        <br/>Ông đã tham gia vào các Công ty lớn tại Việt Nam, Ông từng lãnh đạo nhiều dự án triển khai hệ thống hạ tầng viễn thông quy mô lớn và phức tạp như Dự án DC – Call Center cho tập đoàn FPT, CMC, Sao Bắc Đẩu, Dự án Hà Nội, Đà Nẵng, TP Hồ Chí Minh, Telecom – Hutchinson…, tham gia nhiều dự án CNTT tại Nhật Bản, Singapore, Hồng Kông, Đài Loan.
        
        <br/>Cùng với năng lực Quản lý, khảo sát và lập dự án đầu tư ngoài ra ông có nhiều kinh nghiệm triển khai và tích hợp các dự án lớn về CNTT cho các Cơ quan Chính phủ, Ngân hàng, Tập đoàn và các Công ty đầu tư FDI tại Khu Công Nghệ cao, Khu chế xuất, Khu Công Nghiệp
        
        <br/>Cùng với sự phát triển ngành Y tế của Thế giới nói chung và Việt Nam nói riêng, Ông cũng đã từng tư vấn và triển khai các dự hệ thống ứng dụng CNTT vào trong công tác quản trị Bệnh viện – Chuỗi phòng khám và là một chuyên gia trong lĩnh vực Xây dựng – Vận hành – Tích hợp CNTT đặc thù riêng cho Ngành Y Tế.
        
        <br/>Từ 2010 - 2015 đến nay với vai trò là Giám đốc CNTT của Công ty Cotec HealthCare, Ông là người tham gia thực hiện các bước thủ tục đầu tư từ giai đoạn thiết kế hạ tầng đến gia đoạn vận hành đến ứng dụng CNTT cho toàn bộ dự án và đảm bảo dự án hoạt động một cách xuyên suốt và hiệu quả.
        
        <br/>Các dự án Ông <b>Lê Tây</b> đã tham gia tư vấn, thiết kế và vận hành như sau:
        
        <br/>Bệnh viện Đa khoa Đồng Nai – GĐ2, 700 giường, TMĐT 1.500 tỷ đồng;
        
        <br/>Bệnh viện Đa khoa Bình Định - Phần mở rộng, 600 giường, TMĐT là 1.200 tỷ đồng;
        
        <br/>Bệnh viện Phụ Sản Quốc tế Đức Giang, 200 giường, TMĐT 730 tỷ đồng;
        
        <br/>Bệnh viện Hữu nghị Đa khoa Nghệ An GĐ2, 600 giường, TMĐT 1.300 tỷ đồng.
        
        <br/>Bệnh viện Đa Khoa Quãng Ngãi GĐ2, 600 giường, TMĐT 1.200 tỷ đồng.
        
        <br/>Bệnh viện Đa Khoa Bến Tre Cơ sở II, 450 giường, TMĐT 2.250 tỷ đồng.
        
        <br/>Từ năm 2016 Ông Lê Tây đồng thời là nhà sáng lập - Chủ tịch Hội Đồng Quản Trị kiêm Tổng Giám Đốc Công ty Cổ Phần Healthcare Solution Việt Nam (HSV), sở hữu thương hiệu trong lĩnh vực CNTT. Cùng với sự của nền công nghệ và nhu cầu ứng dụng CNTT mạnh mẽ của xã hội, HSV luôn có những bước nhảy vững chắc từ những “Giải pháp Công nghệ Thông tin Toàn diện” đến “Dịch vụ Khách hàng” chất lượng là minh chứng cho sự phát triển và khẳng định mình trong CNTT.
        
        <br/>Tầm nhìn: <b>“Chuyển hóa tri thức CNTT thành giá trị gia tăng cho khách hàng bằng chất xám và sự tận tâm phục vụ”</b>.
        
        <br/>Sứ mệnh:
        
        <ul>
            <li style="list-style: disc;">Đáp ứng yêu cầu của khách hàng với tinh thần phục vụ tận tụy và sự hiểu biết sâu sắc về yêu cầu đặt ra.</li>
            <li style="list-style: disc;">Không ngừng nâng cao năng lực công nghệ và cải tiến quy trình chất lượng nhằm cung cấp những sản phẩm và dịch vụ có chất lượng tốt nhất.</li>
            <li style="list-style: disc;">Đoàn kết nội bộ, nỗ lực phấn đấu vì một sự nghiệp chung.</li>
            <li style="list-style: disc;">Chân thành, chủ động trong việc xây dựng quan hệ hợp tác với các công ty đối tác để cùng phát triển.</li>
            <li style="list-style: disc;">Tái đầu tư xã hội thông qua các hoạt động từ thiện.</li>
        </ul>
        
        
        Tâp hợp đội ngũ đông đảo các chuyên gia tư vấn có trình độ chuyên môn cao với nhiều năm kinh nghiệm trong các lĩnh vực CNTT-TT khác nhau, đã từng đảm đương những vị trí lãnh đạo quan trọng trong các công ty tin học và truyền thông hàng đầu Việt Nam. Đội ngũ chuyên gia và cộng tác viên của HSV là những người đã từng trực tiếp tham gia thiết kế, triển khai, khai thác, vận hành các hệ thống CNTT-TT lớn cho nhiều khách hàng là các cơ quan Chính phủ, Ngân hàng, Tập đoàn, Tổng công ty và các doanh nghiệp.
        
        <br/>Giữ đúng định hướng từ ngày đầu thành lập – HSV nghiên cứu và phát triển chuyên sâu vào mảng tích hợp hệ thống, thực hiện các giải pháp bảo mật, an toàn thông tin trên mọi nền tảng công nghệ.
        
        <br/>Trong từng giai đoạn phát triển của mình, HSV có những sản phẩm mang tính chiến lược và thời sự để áp dụng cho các doanh nghiệp trong và ngoài nước mà điển hình đề cập đến là các giải pháp và ứng dụng trên Mã nguồn mở.
        
        <br/<b>Một số chiến lược chính giai đoạn 2016-2025</b>
        
        <ul>
            <li>Nhà tư vấn, triển khai giải pháp phần mềm và hạ tầng điện nhẹ, hệ thống Server cho khối Bệnh viện, Doanh nghiệp.</li>
            <li>Nhà tư vấn, thiết kế và triển khai hạ tầng phục vụ ERP cho các ngành kinh tế lớn ở Việt Nam.</li>
            <li>Nhà nghiên cứu và triển khai các giải pháp trên nền tảng công nghiệp 4.0</li>
            <li>Sản phẩm – Dịch vụ do HSV cung cấp</li>
            <li>Triển khai và nâng cấp hệ thống hoạch định nguồn lực doanh nghiệp (ERP) ứng dụng công nghệ tiên tiến của các hãng phần mềm SAP, Orancle, Microsoft, Phần mềm tích hợp Quản lý Chuỗi Bệnh Viện – Phòng khám.</li>
            <li>Cổng thông tin điện tử - Portal; Hệ thống quản lý nhân sự; quản lý văn bản và điều hành.</li>
            <li>Dịch vụ cung cấp các sản phẩm công nghệ thông tin phần cứng và phần mềm.</li>
            <li>Dịch vụ cơ sở hạ tầng CNTT – Tư vấn thiết kế và triển khai hạ tầng mạng, kết nối truyền dẫn cho máy chủ các dịch vụ ứng dụng.</li>
            <li>Dịch vụ bảo mật và an toàn thông tin; Dịch vụ tối ưu hóa hệ thống thông tin lõi.</li>
            <li>Dịch vụ bảo mật và an toàn thông tin; Dịch vụ tối ưu hóa hệ thống thông tin lõi.</li>
            <li>Call Center, Contract Center, Data Center.</li>
            <li>Dịch vụ bảo hành bảo trì; Dịch vụ đào tạo an ninh mạng chuyên sâu cho từng lĩnh vực.</li>
            <li>Các dịch vụ khác.</li>
        </ul>`,
        category: 3,
        img: require('../images/leader/mr-tay.jpg'),
        slug: 'le-tay',
        summary: `Ông đã tham gia vào các Công ty lớn tại Việt Nam, Ông từng lãnh đạo nhiều dự án triển khai 
        hệ thống hạ tầng viễn thông quy mô lớn và phức tạp như Dự án DC – Call Center cho tập đoàn FPT, CMC, Sao Bắc Đẩu, Dự án Hà Nội, Đà Nẵng, TP Hồ Chí Minh, Telecom – Hutchinson…, tham gia nhiều dự án CNTT tại Nhật Bản, Singapore, Hồng Kông, Đài Loan.`,

    },
    {
        id: 4,
        name: "Phạm Văn Ngoan",
        gender: 1,
        position: ["Phó chủ tịch HĐQT"],
        education: "Tiến Sĩ",
        description: `
        Ông <b>Phan Văn Ngoan tốt</b> nghiệp Đại học Luật TP. Hồ Chí Minh năm 1999, Đại học kinh tế TP. Hồ Chí Minh 2007, Thạc sĩ Kinh tế năm 2015 và Tiến sĩ kinh tế vào tháng 4/2019. Ngoài ra, Ông còn tham gia đào tạo khóa quản lý nhân sự cấp cao cho các dự án y tế tại Cộng Hòa Liên Bang Đức năm 2013.

        <br/>Ông <b>Phan Văn Ngoan</b> có 20 năm kinh nghiệm chuyên sâu trong lĩnh tư vấn Luật về đầu tư và điều hành cấp cao tại các tập đoàn kinh tế của Việt Nam, đã có thời gian làm việc từ 1999 – 2019.
        
        <br/>Từ năm 1999 đến 2002, là Luật sư tại Văn phòng LS Trương Thị Hòa.
        
        <br/>Từ năm 2003 đến 2005, làm việc tại Tập đoàn Kinh Đô với vai trò là Trưởng bộ phận pháp lý của Tập đoàn, Ông Phan Văn Ngoan đã trực tiếp thực hiện thủ tục pháp lý của các dự án như: Cộng Hoà Garden, Nhà máy bánh kẹo Kinh Đô, Regal Club….
        
        <br/>Từ năm 2006 đến 7/2015, làm việc tại Cotec Group với vai trò là Phó Tổng Giám đốc điều hành của Tập đoàn, Ông Phan Văn Ngoan đã trực tiếp thực hiện các dự án như: Khu dân cư Phú Xuân Nhà Bè, phát triển dự án Blue Saphire Resort Vũng Tàu. Chủ trì pháp lý các hợp đồng M&A như: chuyển nhượng Nhà máy xi măng Cotec cho tập đoàn Holcim – Thụy Sĩ với gía trị chuyển nhượng 45 triệu USD, chuyển nhượng Nhà máy xi măng Bửu Long cho tập đoàn SCG – Thái Lan với trị giá 5 triệu USD, chuyển nhượng cổ phần 1 triệu cổ phần CLG đã niêm yết trên sàn giao dịch chứng khoán TP. Hồ Chí Minh cho tập đoàn Tamahome Nhật Bản với trị giá 2 triệu USD.
        
        <br/><br/>Từ 7/2015 đến 7/2017, công tác tại Nguyễn Kim Group với vai trò là Phó Giám đốc cấp cao của Tập đoàn, Ông Phan Văn Ngoan quản lý hơn 25 dự án phát triển Trung tâm thương mại, siêu thị trên cả nước.
        
        <br/><br/>Ông chịu trách nhiệm toàn diện về việc xây dựng đề án phát triển chuỗi trung tâm thương mại – bán lẻ ở thị trường Việt Nam liên kết với đối tác danh tiếng nước ngoài để thực hiện kinh doanh lĩnh vực bán lẻ, tiêu dùng: Aeon, Lotte, Central, BigC, Auchan, Emart, Parson, 7 eleven, Shop and Go…. và nhà đầu tư trong nước gồm Vincom và Coopmart.
        
        <br/><br/>Về kinh nghiệm triển khai Dự án Bệnh viện, Ông đã nghiên cứu pháp lý và triển khai thành công 02 dự án là: Bệnh viện Phụ sản Đức Giang – Hà Nội và Bệnh viện Đa khoa Đồng Nai – Giai đoạn 2. Công ty CP Bệnh viện Đa khoa Đồng Nai thực hiện theo mô hình hợp tác công – tư (Mô hình PPP). Dự án được Chính phủ và chính quyền và nhà đầu tư đánh giá cao mô hình PPP là thành công xét ở mọi khía cạnh. Đây là những dự án điển hình của mô hình PPP ở Việt Nam thời điểm 2012 – 2015. Với vai trò là CEO Công ty CP Bệnh viện Đa Khoa Đồng Nai, Ông là người thực hiện dự án hợp tác công tư đầu tiên trong lĩnh vực y tế. Ông chịu trách nhiệm toàn diện về các vấn đề pháp lý thực hiện dự án, tổ chức thực hiện công tác lựa chọn nhà thầu từ giai đoạn thiết kế, quản lý dự án, giám sát, thi công xây dựng đến mua sắm vật tư, thiết bị hoàn thiện, nội thất, lắp đặt thiết bị y tế…
        
        
        <br/><br/>Ông <b>**Phan Văn Ngoan** </b>chủ trì xây dựng hệ thống quy trình vận hành, cơ chế hợp tác với bệnh viện công lập về việc điều phối nguồn nhân lực y bác sĩ, tuyển dụng và đào tạo nhân sự chuyên môn, cơ chế điều phối bệnh nhân, lĩnh vực bảo hiểm y tế, chia sẽ cơ sở dữ liệu, sử dụng nguồn lực của các bên một cách minh bạch, công khai trên tinh thần hợp tác các bên cùng có lợi. Đến nay, dự án này đã hoạt động trên 03 năm và dần dần đi vào ổn định, tăng trưởng tốt.
        
        Năm 2018, Ông là nhóm trưởng nhóm tư vấn đầu tư cho nhà đầu tư – Công ty Cổ Phần Vạn Phát Hưng để tham gia giai đoạn tuyển chọn nhà thầu đầu tư Dự án Đại học Y Dược cơ sở 2 – với mô hình đối tác công – tư (mô hình PPP) với quy mô 200 giường bệnh, khu chống lây nhiễm và khu đào tạo, tổng mức đầu tư 1.560 tỷ đồng
        `,
        category: 3,
        img: require('../images/leader/mr-ngoan.jpg'),
        slug: 'pham-van-ngoan',

    },
    {
        id: 5,
        name: "Lê Tây",
        gender: 1,
        position: ["Chủ tịch HĐQT", "Tổng Giám Đốc"],
        education: "",
        description: `Ông Lê Tây đã hoạt động trong lĩnh vực CNTT từ năm 1997 và hơn 20 năm kinh nghiệm trong lĩnh vực này.

        <br/>Ông đã tham gia vào các Công ty lớn tại Việt Nam, Ông từng lãnh đạo nhiều dự án triển khai hệ thống hạ tầng viễn thông quy mô lớn và phức tạp như Dự án DC – Call Center cho tập đoàn FPT, CMC, Sao Bắc Đẩu, Dự án Hà Nội, Đà Nẵng, TP Hồ Chí Minh, Telecom – Hutchinson…, tham gia nhiều dự án CNTT tại Nhật Bản, Singapore, Hồng Kông, Đài Loan.
        
        <br/>Cùng với năng lực Quản lý, khảo sát và lập dự án đầu tư ngoài ra ông có nhiều kinh nghiệm triển khai và tích hợp các dự án lớn về CNTT cho các Cơ quan Chính phủ, Ngân hàng, Tập đoàn và các Công ty đầu tư FDI tại Khu Công Nghệ cao, Khu chế xuất, Khu Công Nghiệp
        
        <br/>Cùng với sự phát triển ngành Y tế của Thế giới nói chung và Việt Nam nói riêng, Ông cũng đã từng tư vấn và triển khai các dự hệ thống ứng dụng CNTT vào trong công tác quản trị Bệnh viện – Chuỗi phòng khám và là một chuyên gia trong lĩnh vực Xây dựng – Vận hành – Tích hợp CNTT đặc thù riêng cho Ngành Y Tế.
        
        <br/>Từ 2010 - 2015 đến nay với vai trò là Giám đốc CNTT của Công ty Cotec HealthCare, Ông là người tham gia thực hiện các bước thủ tục đầu tư từ giai đoạn thiết kế hạ tầng đến gia đoạn vận hành đến ứng dụng CNTT cho toàn bộ dự án và đảm bảo dự án hoạt động một cách xuyên suốt và hiệu quả.
        
        <br/>Các dự án Ông <b>Lê Tây</b> đã tham gia tư vấn, thiết kế và vận hành như sau:
        
        <br/>Bệnh viện Đa khoa Đồng Nai – GĐ2, 700 giường, TMĐT 1.500 tỷ đồng;
        
        <br/>Bệnh viện Đa khoa Bình Định - Phần mở rộng, 600 giường, TMĐT là 1.200 tỷ đồng;
        
        <br/>Bệnh viện Phụ Sản Quốc tế Đức Giang, 200 giường, TMĐT 730 tỷ đồng;
        
        <br/>Bệnh viện Hữu nghị Đa khoa Nghệ An GĐ2, 600 giường, TMĐT 1.300 tỷ đồng.
        
        <br/>Bệnh viện Đa Khoa Quãng Ngãi GĐ2, 600 giường, TMĐT 1.200 tỷ đồng.
        
        <br/>Bệnh viện Đa Khoa Bến Tre Cơ sở II, 450 giường, TMĐT 2.250 tỷ đồng.
        
        <br/>Từ năm 2016 Ông Lê Tây đồng thời là nhà sáng lập - Chủ tịch Hội Đồng Quản Trị kiêm Tổng Giám Đốc Công ty Cổ Phần Healthcare Solution Việt Nam (HSV), sở hữu thương hiệu trong lĩnh vực CNTT. Cùng với sự của nền công nghệ và nhu cầu ứng dụng CNTT mạnh mẽ của xã hội, HSV luôn có những bước nhảy vững chắc từ những “Giải pháp Công nghệ Thông tin Toàn diện” đến “Dịch vụ Khách hàng” chất lượng là minh chứng cho sự phát triển và khẳng định mình trong CNTT.
        
        <br/>Tầm nhìn: <b>“Chuyển hóa tri thức CNTT thành giá trị gia tăng cho khách hàng bằng chất xám và sự tận tâm phục vụ”</b>.
        
        <br/>Sứ mệnh:
        
        <ul>
            <li style="list-style: disc;">Đáp ứng yêu cầu của khách hàng với tinh thần phục vụ tận tụy và sự hiểu biết sâu sắc về yêu cầu đặt ra.</li>
            <li style="list-style: disc;">Không ngừng nâng cao năng lực công nghệ và cải tiến quy trình chất lượng nhằm cung cấp những sản phẩm và dịch vụ có chất lượng tốt nhất.</li>
            <li style="list-style: disc;">Đoàn kết nội bộ, nỗ lực phấn đấu vì một sự nghiệp chung.</li>
            <li style="list-style: disc;">Chân thành, chủ động trong việc xây dựng quan hệ hợp tác với các công ty đối tác để cùng phát triển.</li>
            <li style="list-style: disc;">Tái đầu tư xã hội thông qua các hoạt động từ thiện.</li>
        </ul>
        
        
        Tâp hợp đội ngũ đông đảo các chuyên gia tư vấn có trình độ chuyên môn cao với nhiều năm kinh nghiệm trong các lĩnh vực CNTT-TT khác nhau, đã từng đảm đương những vị trí lãnh đạo quan trọng trong các công ty tin học và truyền thông hàng đầu Việt Nam. Đội ngũ chuyên gia và cộng tác viên của HSV là những người đã từng trực tiếp tham gia thiết kế, triển khai, khai thác, vận hành các hệ thống CNTT-TT lớn cho nhiều khách hàng là các cơ quan Chính phủ, Ngân hàng, Tập đoàn, Tổng công ty và các doanh nghiệp.
        
        <br/>Giữ đúng định hướng từ ngày đầu thành lập – HSV nghiên cứu và phát triển chuyên sâu vào mảng tích hợp hệ thống, thực hiện các giải pháp bảo mật, an toàn thông tin trên mọi nền tảng công nghệ.
        
        <br/>Trong từng giai đoạn phát triển của mình, HSV có những sản phẩm mang tính chiến lược và thời sự để áp dụng cho các doanh nghiệp trong và ngoài nước mà điển hình đề cập đến là các giải pháp và ứng dụng trên Mã nguồn mở.
        
        <br/<b>Một số chiến lược chính giai đoạn 2016-2025</b>
        
        <ul>
            <li>Nhà tư vấn, triển khai giải pháp phần mềm và hạ tầng điện nhẹ, hệ thống Server cho khối Bệnh viện, Doanh nghiệp.</li>
            <li>Nhà tư vấn, thiết kế và triển khai hạ tầng phục vụ ERP cho các ngành kinh tế lớn ở Việt Nam.</li>
            <li>Nhà nghiên cứu và triển khai các giải pháp trên nền tảng công nghiệp 4.0</li>
            <li>Sản phẩm – Dịch vụ do HSV cung cấp</li>
            <li>Triển khai và nâng cấp hệ thống hoạch định nguồn lực doanh nghiệp (ERP) ứng dụng công nghệ tiên tiến của các hãng phần mềm SAP, Orancle, Microsoft, Phần mềm tích hợp Quản lý Chuỗi Bệnh Viện – Phòng khám.</li>
            <li>Cổng thông tin điện tử - Portal; Hệ thống quản lý nhân sự; quản lý văn bản và điều hành.</li>
            <li>Dịch vụ cung cấp các sản phẩm công nghệ thông tin phần cứng và phần mềm.</li>
            <li>Dịch vụ cơ sở hạ tầng CNTT – Tư vấn thiết kế và triển khai hạ tầng mạng, kết nối truyền dẫn cho máy chủ các dịch vụ ứng dụng.</li>
            <li>Dịch vụ bảo mật và an toàn thông tin; Dịch vụ tối ưu hóa hệ thống thông tin lõi.</li>
            <li>Dịch vụ bảo mật và an toàn thông tin; Dịch vụ tối ưu hóa hệ thống thông tin lõi.</li>
            <li>Call Center, Contract Center, Data Center.</li>
            <li>Dịch vụ bảo hành bảo trì; Dịch vụ đào tạo an ninh mạng chuyên sâu cho từng lĩnh vực.</li>
            <li>Các dịch vụ khác.</li>
        </ul>`,
        category: 2,
        img: require('../images/leader/mr-tay.jpg'),
        slug: 'le-tay',
        summary: `Ông đã tham gia vào các Công ty lớn tại Việt Nam, Ông từng lãnh đạo nhiều dự án triển khai 
        hệ thống hạ tầng viễn thông quy mô lớn và phức tạp như Dự án DC – Call Center cho tập đoàn FPT, CMC, Sao Bắc Đẩu, Dự án Hà Nội, Đà Nẵng, TP Hồ Chí Minh, Telecom – Hutchinson…, tham gia nhiều dự án CNTT tại Nhật Bản, Singapore, Hồng Kông, Đài Loan.`,

    },
    {
        id: 6,
        name: "Phạm Văn Ngoan",
        gender: 1,
        position: ["Phó chủ tịch HĐQT"],
        education: "Tiến Sĩ",
        description: `
        Ông <b>Phan Văn Ngoan tốt</b> nghiệp Đại học Luật TP. Hồ Chí Minh năm 1999, Đại học kinh tế TP. Hồ Chí Minh 2007, Thạc sĩ Kinh tế năm 2015 và Tiến sĩ kinh tế vào tháng 4/2019. Ngoài ra, Ông còn tham gia đào tạo khóa quản lý nhân sự cấp cao cho các dự án y tế tại Cộng Hòa Liên Bang Đức năm 2013.

        <br/>Ông <b>Phan Văn Ngoan</b> có 20 năm kinh nghiệm chuyên sâu trong lĩnh tư vấn Luật về đầu tư và điều hành cấp cao tại các tập đoàn kinh tế của Việt Nam, đã có thời gian làm việc từ 1999 – 2019.
        
        <br/>Từ năm 1999 đến 2002, là Luật sư tại Văn phòng LS Trương Thị Hòa.
        
        <br/>Từ năm 2003 đến 2005, làm việc tại Tập đoàn Kinh Đô với vai trò là Trưởng bộ phận pháp lý của Tập đoàn, Ông Phan Văn Ngoan đã trực tiếp thực hiện thủ tục pháp lý của các dự án như: Cộng Hoà Garden, Nhà máy bánh kẹo Kinh Đô, Regal Club….
        
        <br/>Từ năm 2006 đến 7/2015, làm việc tại Cotec Group với vai trò là Phó Tổng Giám đốc điều hành của Tập đoàn, Ông Phan Văn Ngoan đã trực tiếp thực hiện các dự án như: Khu dân cư Phú Xuân Nhà Bè, phát triển dự án Blue Saphire Resort Vũng Tàu. Chủ trì pháp lý các hợp đồng M&A như: chuyển nhượng Nhà máy xi măng Cotec cho tập đoàn Holcim – Thụy Sĩ với gía trị chuyển nhượng 45 triệu USD, chuyển nhượng Nhà máy xi măng Bửu Long cho tập đoàn SCG – Thái Lan với trị giá 5 triệu USD, chuyển nhượng cổ phần 1 triệu cổ phần CLG đã niêm yết trên sàn giao dịch chứng khoán TP. Hồ Chí Minh cho tập đoàn Tamahome Nhật Bản với trị giá 2 triệu USD.
        
        <br/><br/>Từ 7/2015 đến 7/2017, công tác tại Nguyễn Kim Group với vai trò là Phó Giám đốc cấp cao của Tập đoàn, Ông Phan Văn Ngoan quản lý hơn 25 dự án phát triển Trung tâm thương mại, siêu thị trên cả nước.
        
        <br/><br/>Ông chịu trách nhiệm toàn diện về việc xây dựng đề án phát triển chuỗi trung tâm thương mại – bán lẻ ở thị trường Việt Nam liên kết với đối tác danh tiếng nước ngoài để thực hiện kinh doanh lĩnh vực bán lẻ, tiêu dùng: Aeon, Lotte, Central, BigC, Auchan, Emart, Parson, 7 eleven, Shop and Go…. và nhà đầu tư trong nước gồm Vincom và Coopmart.
        
        <br/><br/>Về kinh nghiệm triển khai Dự án Bệnh viện, Ông đã nghiên cứu pháp lý và triển khai thành công 02 dự án là: Bệnh viện Phụ sản Đức Giang – Hà Nội và Bệnh viện Đa khoa Đồng Nai – Giai đoạn 2. Công ty CP Bệnh viện Đa khoa Đồng Nai thực hiện theo mô hình hợp tác công – tư (Mô hình PPP). Dự án được Chính phủ và chính quyền và nhà đầu tư đánh giá cao mô hình PPP là thành công xét ở mọi khía cạnh. Đây là những dự án điển hình của mô hình PPP ở Việt Nam thời điểm 2012 – 2015. Với vai trò là CEO Công ty CP Bệnh viện Đa Khoa Đồng Nai, Ông là người thực hiện dự án hợp tác công tư đầu tiên trong lĩnh vực y tế. Ông chịu trách nhiệm toàn diện về các vấn đề pháp lý thực hiện dự án, tổ chức thực hiện công tác lựa chọn nhà thầu từ giai đoạn thiết kế, quản lý dự án, giám sát, thi công xây dựng đến mua sắm vật tư, thiết bị hoàn thiện, nội thất, lắp đặt thiết bị y tế…
        
        
        <br/><br/>Ông <b>**Phan Văn Ngoan** </b>chủ trì xây dựng hệ thống quy trình vận hành, cơ chế hợp tác với bệnh viện công lập về việc điều phối nguồn nhân lực y bác sĩ, tuyển dụng và đào tạo nhân sự chuyên môn, cơ chế điều phối bệnh nhân, lĩnh vực bảo hiểm y tế, chia sẽ cơ sở dữ liệu, sử dụng nguồn lực của các bên một cách minh bạch, công khai trên tinh thần hợp tác các bên cùng có lợi. Đến nay, dự án này đã hoạt động trên 03 năm và dần dần đi vào ổn định, tăng trưởng tốt.
        
        Năm 2018, Ông là nhóm trưởng nhóm tư vấn đầu tư cho nhà đầu tư – Công ty Cổ Phần Vạn Phát Hưng để tham gia giai đoạn tuyển chọn nhà thầu đầu tư Dự án Đại học Y Dược cơ sở 2 – với mô hình đối tác công – tư (mô hình PPP) với quy mô 200 giường bệnh, khu chống lây nhiễm và khu đào tạo, tổng mức đầu tư 1.560 tỷ đồng
        `,
        category: 2,
        img: require('../images/leader/mr-ngoan.jpg'),
        slug: 'pham-van-ngoan',

    },
    {
        id: 7,
        name: "Võ Duy Thông",
        gender: 1,
        position: ["Phó Tổng giám đốc"],
        education: "Phó Giáo Sư , Tiến Sĩ",
        description: `
        `,
        category: 2,
        img: require('../images/leader/mr-cuong.png'),
        slug: 'vo-duy-thong',

    },
    {
        id: 8,
        name: "Ngô Đức Đễ",
        gender: 1,
        position: ["Trưởng khoa"],
        education: "Bác Sĩ Chuyên Khoa",
        description: `
        `,
        category: 2,
        img: require('../images/leader/mr-de.png'),
        slug: 'ngo-duc-de',

    },

]


export const leaderCategorys = [{
        id: 1,
        categoryName: "Hội đồng quản trị",
        slug: "hoi-dong-quan-tri"
    },
    {
        id: 2,
        categoryName: "Ban quản trị",
        slug: "ban-quan-tri"
    },
    {
        id: 3,
        categoryName: "Ban Tổng giám đốc",
        slug: "ban-tong-giam-doc"
    },
    {
        id: 4,
        categoryName: "Sơ đồ tổ chức",
        slug: "so-do-to-chuc",
    }
]