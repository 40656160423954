export const typicalProject = [
    {
        title:"Bệnh viện Phụ Sản Hà Nội - Cơ Sở Đức Giang",
        img:require("../images/Typical_project/bvpshn.jpg"),
        slug :"benh-vien-phu-san-ha-noi-co-so-duc-giang"

    },
    {
        title:"Bệnh viện Hữu Nghị Đa Khoa Nghệ An",
        img:require("../images/Typical_project/bvdk-na.jpg"),
        slug :"benh-vien-huu-nghi-da-khoa-nghe-an"
    },
    {
        title:"Bênh viện đa khoa Đồng Nai khối B",
        img:require("../images/Typical_project/bvdk-dn.jpg"),
        slug :"benh-vien-da-khoa-quoc-te-cong-truong-123"

    },
    {
        title:"Bệnh Viện Đa Khoa Bình Định - Phần mở rộng",
        img:require("../images/Typical_project/duan-bvdkbd.jpg"),
        slug :"benh-vien-da-khoa-binh-dinh"
    },
   
]