import React from 'react'
import img from "../../images/home1.jpg"

function Banner() {
  return (
    <div className='banner '>
      <div className='banner-item background-img'
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.5),
        rgba(0,0,0,0.2)),url(${img})`,
        backgroundSize : '100%'
        }}>
        <div className=''>
          <p data-aos="fade-up" style={{textAlign:"center"}}
            data-aos-once="true"
            data-aos-delay={200}>Giải pháp công nghệ - Chăm sóc sức khỏe</p>
          <h2 data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay={400}>Healthcare Solution VIETNAM</h2>
        </div>

      </div>
    </div>
  )
}

export default Banner