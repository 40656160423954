import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import Router from './router/Router';
import Header from './components/header/header';
import Footer from './components/footer/footer';
// import Loading from './components/loading/loading';
import { useEffect, useState } from 'react';
import ScrollTop from './components/scrollTop/scrollTop';
import SidebarLeft from './components/sidebarLeft/sidebarLeft';

function App() {
  
  // const [loading, setLoading] = useState(true);
  const [scrollValue, setScrollValue] = useState("");
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1500)
  // }, [])

  const handleScroll = (event) => {
    const scrollTop = event.target.documentElement.scrollTop;
    setScrollValue(scrollTop);
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // return window.removeEventListener("scroll",handleScroll);
  }, [scrollValue])

  return (
    <div className="App">
      {/* {loading 
      ?  <div className='loadding-fullcreen'>
          {loading ? <Loading /> : ""}
        </div> 
      : ""
      } */}
      <ScrollTop scrollValue={scrollValue} />
      <SidebarLeft/>
      <Header
        scrollValue={scrollValue}
      />

      <main className='main'>
        <Router />
      </main>
      <Footer />
    </div>
  );
}

export default App;
