import React from 'react'

function IntroducePolicies() {
  return (
    <div className='IntroducePolicies'>
      <h5>CHÍNH SÁCH LƯƠNG, THƯỞNG VÀ CÁC CHẾ ĐỘ ĐÃI NGỘ</h5>
      <p style={{ color: 'black', fontWeight: '600', margin: "10px 0" }}>
        1.Chính sách tuyển dụng
      </p>
      <p>Tại HSV, chúng tôi luôn mở rộng, thu hút mọi nguồn nhân lực nhằm tìm ra những ứng viên phù hợp nhất đối với công ty. Vì vậy, đối với HSV, việc xây dựng và áp dụng quy trình tuyển dụng chặt chẽ nhằm đảm bảo tính công bằng,
        minh bạch đối với tất cả ứng viên là điều chúng tôi luôn quan tâm và đặt lên hàng đầu.</p>
      <p style={{ color: 'black', fontWeight: '600', margin: "10px 0" }}>
        2. Chính sách lương, thưởng
      </p>
      <p>
        Thu nhập cạnh tranh, hấp dẫn và ổn định dựa trên năng lực cũng như hiệu quả công việc của nhân viên nhằm tạo sự gắn kết lâu dài với công ty. <br />

        Thưởng & các phúc lợi:<br />

        - Thưởng năm: Căn cứ vào kết quả kinh doanh và hiệu quả làm việc của nhân viên công ty có những phần thưởng xứng đáng.<br />

        - Thưởng đột xuất: Công ty có chế độ khen thưởng đột xuất cho những cá nhân, tập thể có thành tích xuất sắc trong công tác
        (có sáng kiến cải tiến kỹ thuật, hoàn thành xuất sắc nhiệm vụ được giao….).<br />

        - Thưởng và tặng quà các dịp quan trọng trong năm: Tết Dương Lịch; Giỗ Tổ Hùng Vương; Quốc Tế Phụ Nữ, Quốc Khánh…<br />

        - Nghỉ mát/ du lịch hàng năm: trong nước hoặc ngoài nước.<br />

        - Tổ chức sinh hoạt tập thể ( hội trại ) ngày thành lập công ty.<br />

        - Mua bảo hiểm tai nạn 24/24 và bảo hiểm sức khỏe cao cấp.<br />

        - Các trợ cấp khác như điện thoại, cơm trưa….<br />

        - Chế độ bảo hiểm: công ty tham gia đầy đủ chế độ bảo hiểm xã hội,
        bảo hiểm y tế, bảo hiểm thất nghiệp theo quy định của Bộ luật lao động.<br />
      </p>

      <p style={{ color: 'black', fontWeight: '600', margin: "10px 0" }}>
        3. Chính sách đào tạo
      </p>
      <p>HSV chú trọng việc tạo điều kiện để nhân viên hoà nhập môi trường công ty và
        hoàn thiện bản thân thông qua việc tham gia các lớp hướng dẫn hội nhập,
        các khoá kỹ năng mềm, tham gia các lớp chuyên môn và thi chứng chỉ quốc tế.</p>
    </div>
  )
}

export default IntroducePolicies