import React from 'react'
import TopBanner from '../../components/topBanner/topBanner'
import TitleCenter from '../../components/title/TitleCenter'
import { ConsultingServicesMenu , CSCategory } from '../../localData/ConsultingServices'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

function ConsultingServices() {
    const { categoryID } = useParams();
    const [data, setData] = useState();
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    useEffect(() => {
        for (var item of ConsultingServicesMenu) {
            if (item.slug === categoryID) {
                setData(item);
            }
        }
    }, [categoryID]);

    return (
        <div className='DetailPSCategory'>
            <TopBanner
                titleCenter="Đầu Tư & Dịch Vụ"
                imgBanner={data ? data.banner :""}
                pageName="Đầu Tư & Dịch Vụ"
                text={data ? data.text : ""}
            />
            <div className='layout_container'>
                <TitleCenter text={data ? data.text : ""} />
                <div className='PScategory'>
                    <ul className='PScategory-list row'>
                        {CSCategory.map((value,index) => {
                            if (value.ConsultingServicesID === data?.id) {
                                return (
                                    <li key={index} className="PScategory-item col-sm-6 col-md-4 col-lg-4">
                                        <img src={value.icon} alt="" />
                                        <h4 >{value.textItem}</h4>
                                        <p>{value.text}</p>
                                    </li>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ConsultingServices