import React from 'react'
import { AiFillPlayCircle } from "react-icons/ai"
function Buttun2({ text }) {
  return (
    <div className='button_2'>
      <AiFillPlayCircle />
      <p>{text}</p>
      <span></span>
    </div>
  )
}

export default Buttun2