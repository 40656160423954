import {React,useEffect} from 'react'
import TopBanner from '../../components/topBanner/topBanner';
import imgBaner from "../../images/banner/banner---gioi-thieu.png";
import Title_center from '../../components/title/TitleCenter';
import { useState } from 'react'
import { customers } from '../../localData/typicalCustomer';

function ListCustomer() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

  return (
    <div className='news_controller'>
        <TopBanner
                titleCenter="Khách Hàng - Đối Tác"
                imgBanner={imgBaner}
                pageName="Khách Hàng - Đối Tác"
            />
            <div className='layout_container'>
                <Title_center
                    text="Khách Hàng Tiêu Biểu"
                />
                <div className="customers_container">
                    <div className="layout_container">
                        <ul>
                            {customers.map((value, index) =>{
                                if(value.categoryId ==1)
                                return(
                                    <li key={index} title={value.link}>
                                        <a href={`${value.link}`} target="_blank" rel="noopener noreferrer">
                                            <img src={value.img} alt="" />
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                 </div>
                <Title_center
                    text="Đối Tác Chiến Lược"
                />
                <div className="customers_container">
                    <div className="layout_container">
                        <ul>
                            {customers.map((value, index) =>{
                                if(value.categoryId ==2)
                                return(
                                    <li key={index} title={value.link}>
                                        <a href={`${value.link}`} target="_blank" rel="noopener noreferrer">
                                            <img src={value.img} alt="" />
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                 </div>
            </div>
    </div>
  )
}

export default ListCustomer