import {React,useEffect} from 'react'
import TopBanner from '../../components/topBanner/topBanner';
import imgBaner from "../../images/banner/banner---gioi-thieu.png";
import TitleCenter from '../../components/title/TitleCenter';
import PScategory from './PScategory';

function ProductSolution() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='ProductSolution'>
        <TopBanner
            titleCenter="Sản Phẩm và Giải Pháp"
            imgBanner={imgBaner}
            pageName="Sản Phẩm và Giải Pháp"
        />
        <div className='layout_container'>
        
        <TitleCenter
            text="Sản Phẩm và Giải Pháp"
        />
            <h4 style={{fontSize :"2rem",textAlign :"center"}}>Chúng tôi cung cấp các giải pháp công nghệ với chất lượng tối ưu nhằm <br />
                thỏa mãn tối đa nhu cầu của khách hàng</h4>
        <PScategory/>
        </div>        
    </div>
  )
}

export default ProductSolution