export const listMenu = [{
        id: 1,
        text: "Trang chủ",
        slug: "",
    },
    {
        id: 2,
        text: "Giới thiệu",
        // slug: "gioi-thieu",
        sub: [{
                id_lv1: 1,
                textItem_lv1: "Tổng quan",
                slug_lv1: "/gioi-thieu"
            },
            {
                id_lv1: 2,
                textItem_lv1: "Ban lãnh đạo",
                slug_lv1: "/gioi-thieu/ban-lanh-dao/1"
            },

        ]
    },
    {
        id: 3,
        text: "Sản phẩm - Giải pháp",
        // slug: "sanpham-giaiphap",
        sub: [
            {
                id_lv1: 7,
                textItem_lv1: "Sản phẩm công ty",
                slug_lv1: "/sanpham-giaiphap/san-pham-cong-ty"
            },
            {
                id_lv1: 1,
                textItem_lv1: "Giải pháp y tế",
                slug_lv1: "/sanpham-giaiphap/giai-phap-y-te"
            },
            {
                id_lv1: 2,
                textItem_lv1: "Tích hợp hệ thống",
                slug_lv1: "/sanpham-giaiphap/tich-hop-he-thong"
            },
            {
                id_lv1: 3,
                textItem_lv1: "Dịch vụ và hạ tầng CNTT",
                slug_lv1: "/sanpham-giaiphap/dich-vu-va-ha-tang-cntt"
            },
            {
                id_lv1: 4,
                textItem_lv1: "Giải pháp quản lí doanh nghiệp",
                slug_lv1: "/sanpham-giaiphap/giai-phap-doanh-nghiep-4-0"
            },
            {
                id_lv1: 5,
                textItem_lv1: "Dịch vụ Cloud",
                slug_lv1: "/sanpham-giaiphap/dich-vu-cloud"
            },
            {
                id_lv1: 6,
                textItem_lv1: "Giải pháp IoT",
                slug_lv1: "/sanpham-giaiphap/giai-phap-iot"
            },
        ]
    },
    {
        id: 9,
        text: "Đầu Tư & Dịch Vụ",
        sub: [{
            id_lv1: 1,
            textItem_lv1: "Tư vấn về đầu tư ",
            slug_lv1: "/dautu-dichvu/tu-van-ve-dau-tu"
        },
        {
            id_lv1: 2,
            textItem_lv1: "Tư vấn về dịch vụ",
            slug_lv1: "/dautu-dichvu/tu-van-ve-dich-vu"
        }
    ]
    },
    {
        id: 4,
        text: "Khách hàng - Đối tác",
        sub: [{
            id_lv1: 1,
            textItem_lv1: "Khách hàng tiêu biểu",
            slug_lv1: "/khachhang-doitac/khach-hang-tieu-bieu"
        },
        {
            id_lv1: 2,
            textItem_lv1: "Đối tác chiến lược",
            slug_lv1: "/khachhang-doitac/doi-tac-chien-luoc"
        },
    ]
    },
    {
        id: 7,
        text: "Dự án tiêu biểu",
        slug: "du-an-tieu-bieu",
    },
    {
        id: 8,
        text: "Liên hệ",
        slug: "lien-he",
    }
]

