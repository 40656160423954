import React from 'react'
import TopBanner from '../../components/topBanner/topBanner'
import imgBanner from "../../images/banner/banner-tin-tuc.jpg"
import TitleCenter from '../../components/title/TitleCenter'
import { ImHome } from "react-icons/im";
import { ImOffice } from "react-icons/im";
import { ImPhone } from "react-icons/im";
import { HiEnvelope } from "react-icons/hi2";
import { useEffect } from 'react';

function Contact() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <div className='contact-container'>
      <TopBanner
        titleCenter="HEALTHCARE SOLUTION VIET NAM"
        imgBanner={imgBanner}
        pageName="Trang Chủ"
        text="Liên Hệ"
      />
      <div className='layout_container'>
        <TitleCenter text="Liên Hệ" />
        <div className="row">
          <div className='col-md-6'>
            <div className="contact">
              <p><span className='contact-title'>Trụ sở Hồ Chí Minh</span></p>
              <p><span className='contact-icon'><ImHome /></span><span>64 Đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, TP. Hồ Chí Minh</span></p>
              <p><span className='contact-icon'><ImOffice /></span><span>(84-28) 38371002</span></p>
              <p><span className='contact-icon'><ImPhone /></span><span>(84)907.632.112</span></p>
              <p><span className='contact-icon'><HiEnvelope /></span><span>info@hsv.com.vn</span></p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.8673882854823!2d106.69150277872122!3d10.74470236207624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752fa08dcace2f%3A0x2541e06018f85edf!2zNjQgxJDGsOG7nW5nIHPhu5EgMiwgVMOibiBIxrBuZywgUXXhuq1uIDcsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCwgVmlldG5hbQ!5e0!3m2!1sen!2sus!4v1684990612566!5m2!1sen!2sus"
                title="Google Maps - Hồ Chí Minh"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="contact">
              <p><span className='contact-title'>Văn Phòng Đại Diện</span></p>
              <p><span className='contact-icon'><ImHome /></span><span>232/7 Võ Văn Kiệt, Phường Cầu Ông Lãnh, Quận 1, TP. Hồ Chí Minh</span></p>
              <p><span className='contact-icon'><ImOffice /></span><span>(84-28) 39300614</span></p>
              <p><span className='contact-icon'><ImPhone /></span><span>(84) 917.632.112</span></p>
              <p><span className='contact-icon'><HiEnvelope /></span><span>info@hsv.com.vn</span></p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className="contact-map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.6245406824255!2d106.69240167872307!3d10.763392262013491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f87c1cf62ed%3A0x10008462ce750002!2zMjMyIFbDtSBWxINuIEtp4buHdCwgUGjGsOG7nW5nIEPhuqd1IMOUbmcgTMOjbmgsIFF14bqtbiAxLCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmggNzEwMTEsIFZpZXRuYW0!5e0!3m2!1sen!2sus!4v1684991116502!5m2!1sen!2sus"
                title="Google Maps - Hồ Chí Minh"
                width="600" height="450" 
                style={{ border: 0 }} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact