export const ProductSolutionMenu = [
    {
        id: 7,
        text: "Sản Phẩm Công Ty",
        slug: "san-pham-cong-ty",
        img : require("../images/960x0.jpg"),
        banner :require("../images/banner/banner---san-pham-giai-phap---giai-phap-y-te.jpg"),
        sub: [{
            id_lv1: 1,
            textItem_lv1: "Y tế thông minh",
            slug_lv1: "y-te-thong-minh",
        },
        {
            id_lv1: 2,
            textItem_lv1: "Phần mềm Quản lý tổng thể Bệnh viện",
            slug_lv1: "pham-mem-quan-ly-tong-the-benh-vien", 
        },
        {
            id_lv1: 3,
            textItem_lv1: "Phần mềm Quản lý Phòng khám",
            slug_lv1: "phan-men-quan-ly-phong-kham", 
        },
        {
            id_lv1: 4,
            textItem_lv1: "Phần mềm Quản lý Nhà thuốc",
            slug_lv1: "phan-mem-quan-ly-nha-thuoc", 
        },
        {
            id_lv1: 5,
            textItem_lv1: "Phần mềm Quản Trị Nguồn Lực",
            slug_lv1: "phan-mem-quan-tri-nguon-luc", 
        },
        {
            id_lv1: 6,
            textItem_lv1: "Phần mềm Xếp Hàng Tự Động & Kiosk Thông minh",
            slug_lv1: "phan-mem-xep-hang-tu-dong-kiosk-thong-minh",
        },
    ]
    },
    {
    id: 1,
    text: "Giải Pháp Y Tế",
    slug: "giai-phap-y-te",
    img : require("../images/product_solution/banner---home-giai-phap-y-te.png"),
    banner :require("../images/banner/banner---san-pham-giai-phap---giai-phap-y-te.jpg"),
    sub: [{
        id_lv1: 1,
        textItem_lv1: "Giải pháp phần mềm quản lý tổng thể Bệnh viện i.Hospital",
        slug_lv1: "giai-phap-phan-mem-quan-ly-tong-the-benh-vien-i-hospital",
    },
    {
        id_lv1: 2,
        textItem_lv1: "Giải pháp quản lý chuỗi Bệnh viện, chuỗi Phòng khám",
        slug_lv1: "giai-phap-quan-ly-chuoi-benh-vien-chuoi-phong-kham", 
    },
    {
        id_lv1: 3,
        textItem_lv1: "Giải pháp ứng dụng công nghệ RFID trong quản lý thông tin bệnh nhân",
        slug_lv1: "giai-phap-ung-dung-cong-nghe-rfid-trong-quan-ly-thong-tin-benh-nhan", 
    },
    {
        id_lv1: 4,
        textItem_lv1: "Giải pháp Xếp hàng thông minh (AI.QMS)",
        slug_lv1: "giai-phap-xep-hang-thong-min-iqms", 
    },
    {
        id_lv1: 5,
        textItem_lv1: "Giải pháp Y tế thông minh",
        slug_lv1: "giai-phap-y-te-thong-minh", 
    },
    {
        id_lv1: 6,
        textItem_lv1: "Nhà tư vấn chuyên nghiệp mô hình PPP",
        slug_lv1: "nha-tu-van-chuyen-nghiep-mo-hinh-ppp",
    },
]
},
{
    id: 2,
    text: "Tích hợp hệ thống",
    slug: "tich-hop-he-thong",
    img : require("../images/product_solution/banner---home-tich-hop-he-thong.jpg"),
    banner :require("../images/banner/banner---san-pham-giai-phap---tich-hop-he-thong.jpg"),
    sub: [{
        id_lv1: 1,
        textItem_lv1: "Bộ giải pháp Collaboration",
        slug_lv1: "bo-giai-phap-collaboration", 
        },
        {
        id_lv1: 2,
        textItem_lv1: "Bộ giải pháp phụ trợ hạ tầng trung tâm dữ liệu",
        slug_lv1: "bo-giai-phap-phu-tro-ha-tang-trung-tam-du-lieu", 
        },
        {
        id_lv1: 3,
        textItem_lv1: "Công nghệ lưu trữ và máy chủ",
        slug_lv1: "cong-nghe-luu-tru-va-may-chu", 
        },
        {
        id_lv1: 4,
        textItem_lv1: "Giải pháp ảo hóa",
        slug_lv1: "giai-phap-ao-hoa", 
        },
        {
        id_lv1: 5,
        textItem_lv1: "Giải pháp bảo mật",
        slug_lv1: "giai-phap-bao-mat", 
        },
        {
        id_lv1: 6,
        textItem_lv1: "Giải pháp hạ tầng mạng",
        slug_lv1: "giai-phap-ha-tang-mang", 
        },
    ]
},
{
    id: 3,
    text: "Dịch vụ và hạ tầng CNTT",
    slug: "dich-vu-va-ha-tang-cntt",
    img : require("../images/product_solution/banner---home-ha-tang-cntt.jpg"),
    banner :require("../images/banner/banner---san-pham-giai-phap---dich-vu-va-ha-tang-cntt.jpg"),
    sub: [{
        id_lv1: 1,
        textItem_lv1: "Dịch vụ bảo trì",
        slug_lv1: "dich-vu-bao-tri", 
        },
        {
        id_lv1: 2,
        textItem_lv1: "Dịch vụ Kỹ sư tại chỗ (Onsite IT engineers)",
        slug_lv1: "dich-vu-ky-su-tai-cho-onsite-it-engineers", 
        },
        {
        id_lv1: 3,
        textItem_lv1: "Dịch vụ quản lý bảo mật",
        slug_lv1: "dich-vu-quan-ly-bao-mat", 
        },
        {
        id_lv1: 4,
        textItem_lv1: "Dịch vụ quản lý mạng",
        slug_lv1: "dich-vu-quan-ly-mang", 
        },
        {
        id_lv1: 5,
        textItem_lv1: "Dịch vụ quản lý máy chủ và lưu trữ dữ liệu",
        slug_lv1: "dich-vu-quan-ly-may-chu-va-luu-tru-du-lieu", 
        },
        {
        id_lv1: 6,
        textItem_lv1: "Trung tâm Dịch vụ khách hàng - ServiceDesk",
        slug_lv1: "trung-tam-dich-vu-khach-hang-servicedesk", 
        },
        ]
},
{
    id: 4,
    text: "Giải pháp doanh nghiệp 4.0",
    slug: "giai-phap-doanh-nghiep-4-0",
    img : require("../images/product_solution/banner---home-giai-phap-doanh-nghiep-4-0.jpg"),
    banner :require("../images/banner/banner---san-pham-giai-phap---giai-phap-doanh-nghiep-4-0.jpg"),
    sub: [{
        id_lv1: 1,
        textItem_lv1: "Phần mềm quản lý dịch vụ",
        slug_lv1: "phan-mem-quan-ly-dich-vu",       
        },
        {
        id_lv1: 2,
        textItem_lv1: "Phần mềm quản lý doanh nghiệp",
        slug_lv1: "phan-mem-quan-ly-doanh-nghiep", 
        },
        {
        id_lv1: 3,
        textItem_lv1: "Phần mềm quản lý xây dựng",
        slug_lv1: "phan-mem-quan-ly-xay-dung", 
        },
        {
        id_lv1: 4,
        textItem_lv1: "Phần mềm quản lý giáo dục",
        slug_lv1: "phan-mem-quan-ly-giao-duc", 
        }
    ]
},
{
    id: 5,
    text: "Dịch vụ Cloud",
    slug: "dich-vu-cloud",
    img : require("../images/product_solution/banner---home-giai-phap-cloud.jpg"),
    banner :require("../images/banner/banner---san-pham-giai-phap---dich-vu-cloud.jpg"),
    sub: [{
        id_lv1: 1,
        textItem_lv1: "Dịch vụ Cloud VNN",
        slug_lv1: "dich-vu-cloud-vnn",
        },
        {
        id_lv1: 2,
        textItem_lv1: "Dịch vụ Sao lưu dữ liệu trên đám mây",
        slug_lv1: "dich-vu-sao-luu-du-lieu-tren-dam-may",
        },
        {
        id_lv1: 3,
        textItem_lv1: "Dịch vụ vCLOUD",
        slug_lv1: "dich-vu-vcloud",
        },
        {
        id_lv1: 4,
        textItem_lv1: "Dịch vụ VCDN",
        slug_lv1: "dich-vu-vcdn", 
        }
    ]    
},
{
    id: 6,
    text: "Giải pháp IOT",
    slug: "giai-phap-iot",
    img : require("../images/product_solution/banner---home-giai-phap-iot.jpg"),
    banner :require("../images/banner/banner---san-pham-giai-phap---giai-phap-iot.jpg"),
    sub: [{
        id_lv1: 1,
        textItem_lv1: "EasyLink Platform",
        slug_lv1: "easylink-platform",
        },
        {
        id_lv1: 2,
        textItem_lv1: "Giải pháp nông nghiệp thông minh",
        slug_lv1: "giai-phap-nong-nghiep-thong-minh",
        },
        {
        id_lv1: 3,
        textItem_lv1: "Giải pháp quản lý sản xuất thông minh",
        slug_lv1: "giai-phap-quan-ly-san-xuat-thong-minh",
        },
        {
        id_lv1: 4,
        textItem_lv1: "Hệ thống giao thông thông minh",
        slug_lv1: "he-thong-giao-thong-thong-minh",
        },
        {
        id_lv1: 5,
        textItem_lv1: "Hệ thống quản lý giám sát Data Center",
        slug_lv1: "he-thong-quan-ly-giam-sat-data-center",
        },
        {
        id_lv1: 6,
        textItem_lv1: "i-Parking",
        slug_lv1: "i-parking",
        }    
        ]
    },
]
