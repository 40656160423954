import { convertSlug } from "../utils/utils"


export const listMenu = [{
        id: 1,
        text: "Trang chủ",
        slug: "",
    },
    {
        id: 2,
        text: "Giới thiệu",
        slug: "gioi-thieu",
    },
    {
        id: 3,
        text: "Sản phẩm - Giải pháp",
        slug: "sanpham-giaiphap",
        
    },
    {
        id: 9,
        text: "Đầu tư & Dịch Vụ",
        slug: "sanpham-giaiphap",
        
    },
    {
        id: 4,
        text: "Khác hàng - Đối tác",
        slug: "khachhang-doitac",
    },
    {
        id: 5,
        text: "Dự án tiêu biểu",
        slug: "du-an-tieu-bieu",
    },
    {
        id: 8,
        text: "Liên Hệ",
        slug: "lien-he",
    }
]