import React from 'react'
import { ProductSolutionMenu } from '../../localData/productSolutionMenu'
import { useNavigate } from 'react-router-dom';
function PScategory() {
    const navigate = useNavigate();

    return (
        <div className='PScategory'>
            <div className="container">
                <div className="row">
                    {ProductSolutionMenu.map((value) => 
                        <div className='col-xs-12 col-md-3 col-lg-4'>
                            <div className='PScategory-list'>
                                <div className="PScategory-itemlist" >
                                    <div className="item-img"  onClick={() => navigate(`/sanpham-giaiphap/${value.slug}`)}>
                                        <img src={value.img} alt="" />
                                    </div>
                                    <h4>{value.text}</h4>
                                 </div>
                            </div>    
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PScategory