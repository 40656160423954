import React from 'react'
import TopBanner from '../../components/topBanner/topBanner'
import imgBanner from "../../images/banner/banner-tin-tuc.jpg"
import TitleCenter from '../../components/title/TitleCenter'
import {recruitment} from '../../localData/recruitmentMenu'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

function RecruimentContent() {
    const { slug } = useParams();
    const [data, setData] = useState([]);
    
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await recruitment.getProducts();
                const filteredData = response.find(item => item.slug === slug);
                if (filteredData) {
                    setData(filteredData);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [slug]);
    const formattedHTML = { __html:  data.description};

    return (
        <div className='recruimentContent content_pages'>
            <TopBanner
                titleCenter="Tuyển Dụng"
                imgBanner={imgBanner}
                pageName="Tuyển Dụng"
                text={data.location}
            />
            <div className='layout_container'>
                <TitleCenter text={ data.location} />
            <div className="recruiment-content">
                <p style={{ fontSize: "1.6rem" }} dangerouslySetInnerHTML={formattedHTML}></p>
            </div>
            </div>
        </div>
    );
}

export default RecruimentContent