import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { typicalProject } from '../../localData/typicalProject';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper";


function TypicalProjectSlider() {
    const navigate = useNavigate();

    return (
        <div className='TypicalProjectSlider'>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                breakpoints={{
                    576: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                }}
            >
                {typicalProject.map((value) => (
                    <SwiperSlide key={value.title}>
                        <div className='TypicalProjectSlider-content' key={value.title}>
                            <img src={value.img} alt="" />
                            <p>{value.title}</p>
                            <div>
                                <p onClick={() => navigate(`/du-an-tieu-bieu/${value.slug}`)}>Xem chi tiết</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default TypicalProjectSlider
