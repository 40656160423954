import React from 'react'
import { typicalProjectMenu } from '../../localData/typicalProjectMenu';
import TitleCenter from '../../components/title/TitleCenter'
import TopBanner from '../../components/topBanner/topBanner'
import imgBanner from "../../images/banner/banner-tin-tuc.jpg"
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

function DetailTypicalProject() {
    const { slug, id } = useParams();
    const [data, setData] = useState();
    const [activeCategory, setActiveCategori] = useState("Tổng quát");
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    useEffect(() => {
        for (var item of typicalProjectMenu) {
            if (item.slug === slug && item.path === id) {
                setData(item)
            }
        }
    }, [id, slug])
    const formattedHTMLInterview = { __html: data ? data.introviews : "" };
    const formattedHTMLHSVRole = { __html: data ? data.roleHSV : "" };
    const category = ["Tổng quát", "Vai trò HSV"]

    return (
        <div className='DetailTypicalProject content_pages'>
            <TopBanner
                titleCenter="Dự án tiêu biểu"
                imgBanner={imgBanner}
                pageName="Dự án tiêu biểu"
                text={data ? data.nameHospitol : ""}
            />
            <div className='layout_container'>
                <TitleCenter
                    text={data ? data.nameHospitol : ""}
                />
                <div className="DetailTypicalProject-content">
                    <div style={{ textAlign: "center" }} className="typicalProject-image">
                        <img src={data?.img} alt="" />
                    </div>
                    <ul className="category">
                        {
                            category.map((value, index) => (
                                <li key={index} className={activeCategory === value ? "category-active" : ""} onClick={() => setActiveCategori(value)}>
                                    {value}
                                </li>
                            ))
                        }
                    </ul>
                    <div className="tab-content">
                        <div role="tabpanel" className={activeCategory === "Tổng quát" ? "" : "none"}>
                            <p style={{ fontSize: "1.6rem" }} dangerouslySetInnerHTML={formattedHTMLInterview}></p>
                        </div>
                        <div role="tabpanel" className={activeCategory === "Vai trò HSV" ? "" : "none"}>
                            <p style={{ fontSize: "1.6rem" }} dangerouslySetInnerHTML={formattedHTMLHSVRole}></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailTypicalProject