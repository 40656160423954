const imgnew1 = require("../images/news/active/02507112022.jpg");
const imgnew2 = require("../images/news/active/02607112022.jpg");
const imgnew3 = require("../images/news/active/02707112022.jpg");
const imgnew4 = require("../images/news/active/Anh_1.jpg");
const imgnew5 = require("../images/news/active/Anh_2.jpg");
const imgnew6 = require("../images/news/active/Anh_3.jpg");
const imgnew7 = require("../images/news/active/Anh_4.jpg");
const imgnew8 = require("../images/news/active/Anh_5.jpg");
const imgnew9 = require("../images/news/active/Anh_6.jpg");
const imgnew10 = require("../images/news/active/Anh_7.jpg");
const imgnew11 = require("../images/news/active/2-1668175726-34-width740height493.jpg");
const imgnew12 = require("../images/news/active/3-1668175734-882-width740height493.jpg");
const imgnew13 = require("../images/news/active/1667464047-51ca4feafdddbe414a3934d3de5236d0-width850height444.jpeg");
const imgnew14 = require("../images/news/active/1667464047-764ecb3d71ab1188beb7171669b94d69-width850height756.jpeg");
const imgnew15 = require("../images/news/active/1667527429-0d72880832f70189791c6efebfac8639-width850height513.jpeg");
const imgnew16 = require("../images/news/active/1667527429-324e5b1107e590d1037e5d2031b24085-width850height499.jpeg");
const imgnew17 = require("../images/news/active/dashboard-copy--1--1667576628-391-width1430height909.jpg");
const imgnew18 = require("../images/news/active/zoho_gibu22-edit-1667576628-865-width1280height747.jpg");
const imgnew19 = require("../images/news/active/2_Cnet.jpg");
const imgnew20 = require("../images/news/active/Twitter_firing_email_2.jpeg");
const imgnew21 = require("../images/news/active/Twitter_firing_email.jpeg");
const imgnew22 = require("../images/news/active/TTTTTTTT.jpg");
const imgnew23 = require("../images/news/active/TTTTTTTTTTTTTTTT.jpg");
const imgnew24 = require("../images/news/events/1667288777-018c296bb240d75418c7f420521b7404-width850height558.png");
const imgnew25 = require("../images/news/events/1667288778-aded72a960d33d45016a8d51bf0557a0-width850height445.jpg");
const imgnew26 = require("../images/news/events/image2-1667358253-205-width2000height2000.jpg");
const imgnew27 = require("../images/news/events/image1-1667358253-168-width2000height2000.jpg");
const imgnew28 = require("../images/news/events/image3-1667358253-553-width2000height2000.jpg");
const imgnew29 = require("../images/news/events/image4-1667358253-810-width1280height853.jpg");
const imgnew30 = require("../images/news/events/1667402850-a44b9c0efb1ced4470623d85ee080f5b-width700height393.jpg");
const imgnew31 = require("../images/news/events/thaiholdings_tower.jpeg");
const imgnew32 = require("../images/news/events/thi_gia_cp_thd.jpg");
const imgnew33 = require("../images/news/events/-6758-1663337799.jpg");
const imgnew34 = require("../images/news/events/-7620-1663337799.jpg");
const imgnew35 = require("../images/news/events/1667117255-216fe46ac86f49235d554097958c707e-width645height428.jpg");
const imgnew36 = require("../images/news/events/1666318039-lan-16663142184791198203492-width684height484.jpeg");
const imgnew37 = require("../images/news/events/1666318039-tuan-1666314703521211139817-width640height421.jpeg");
const imgnew38 = require("../images/news/events/vna_potal_tong_bi_thu_nguyen_phu_trong_den_bac_kinh_bat_dau_chuyen_tham_chinh_thuc_trung_quoc_6411863_2.jpg");
const imgnew39 = require("../images/news/events/vna_potal_tong_bi_thu_nguyen_phu_trong_den_bac_kinh_bat_dau_chuyen_tham_chinh_thuc_trung_quoc_6411871.jpg");
    
export const newsMenu = [
    {
        id: 1,
        title : `Website chứa 11 triệu sách lậu bị tịch thu tên miền`,
        path:"website-chua-11-trieu-sach-lau-bi-tich-thu-ten-mien",
        title_content : `Các tên miền của Z-Library, nền tảng chia sẻ hơn 11 triệu đầu sách không bản quyền đã bị tịch thu vào ngày 4/11.`,
        img : require("../images/news/active/02507112022.jpg"),
        description : `<p>
        Các tên miền của Z-Library, nền tảng chia sẻ hơn 11 triệu đầu sách không bản quyền đã bị tịch thu vào ngày 4/11.
        <br/><br/>
        Z-Library là một trong những nền tảng chia sẻ sách vi phạm bản quyền lớn. Ảnh: Z-Library.
        <br/><br/>
        <img src=${imgnew1} alt"">
        <br/><br/>
        Theo thông báo, tên miền3l\*.netđã bị thu giữ bởi Cục Điều tra Liên bang (FBI) và Văn phòng Công tố Mỹ tại Quận phía Đông New York vào ngày 4/11. Các địa chỉz-**b.orgvàb-**.org*cũng hiện thông báo tương tự khi người dùng truy cập.
        <br/><br/>
        Thông tin trênWhoischo thấy chính phủ Mỹ đã chuyển máy chủ các tên miền sang địa chỉns1.seizedservers.comvàns2.seizedservers.com. Đây là 2 máy chủ thường được các cơ quan pháp luật Mỹ sử dụng trong việc tịch thu tên miền.
        <br/><br/>
        Sau đó, máy chủ các tên miền được chuyển sang địa chỉ thuộc Njalla, nhà cung cấp dịch vụ máy chủ ẩn danh. Chưa rõ lý do và cách thức đểZ-Librarychuyển địa chỉ máy chủ sang dịch vụ mới.
        <br/><br/>
        DùZ-Librarykhông thể truy cập bằng các trình duyệt thông thường,Bleeping Computercho biết người dùng vẫn có thể truy cập trang web bằng địa chỉ trên Tor Onion, trình duyệt dùng để vào các trang web đen (dark web). Khi sử dụng Tor, trang web hiện cảnh báo sự cố máy chủ.
        <br/><br/>
        Tuy chưa công bố lý do cụ thể, nhiều khả năng các tên miền củaZ-Librarybị Mỹ tịch thu bởi trang web này chia sẻ sách không bản quyền.
        <br/><br/>
        Thông báo thu hồi tên miền xuất hiện khi người dùng truy cập địa chỉ của Z-Library.
        <br/><br/>
        <img src=${imgnew2} alt"">
        <br/><br/>
        Đây không phải lần đầu các tên miền củaZ-Librarybị tịch thu. Trước đó, đơn khiếu nại vi phạm bản quyền dẫn đến kiện tụng khiến dịch vụ cung cấp địa chỉ truy cậpZ-Libraryphải thu hồi tên miền vào năm 2015.
        <br/><br/>
        Những đợt chặn tên miền củaZ-Librarydo vi phạm Đạo luật Bản quyền Thiên niên kỷ Kỹ thuật số (DMCA) cũng diễn ra tại Mỹ và Pháp vào năm 2021.
        <br/><br/>
        Thời gian gần đây, Đại diện Thương mại Mỹ (USTR) đã mở cuộc điều traZ-Library, khiến những mạng xã hội vào cuộc để ngăn chặn người dùng chia sẻ website này. Trong số đó, TikTok đã chặn tìm kiếm các hashtag liên quan đếnZ-Librarytừ cuối tháng 10 do các khiếu nại về vi phạm bản quyền.
        <br/><br/>
        Thông báo về sự cố máy chủ khi truy cập Z-Library bằng Tor. Ảnh: Bleeping Computer.
        <br/><br/>
        <img src=${imgnew3} alt"">
        <br/><br/>
        Z-Librarytồn tại từ năm 2009, là nền tảng chia sẻ tài liệu học thuật miễn phí lấy từLibrary Genesis. Sau đó, người dùng đăng tải nhiều nội dung khác, bao gồm sách không bản quyền.Z-Librarylà nền tảng phi lợi nhuận, được góp tiền để vận hành.
        <br/><br/>
        Cơ sở hạ tầng củaZ-Librarygồm các máy chủ phân tán trên toàn cầu, với tổng dung lượng 220 TB. Trang web nằm trong 10.000 website được truy cập nhiều trên Internet, với hơn 11 triệu đầu sách và 84 triệu tài liệu học thuật.
        <br/><br/>
        Hiện tại, chưa rõ dữ liệu củaZ-Libraryđược xử lý như thế nào. Có khả năng nền tảng này sẽ trở lại dưới tên miền khác.
        <br/><br/>
        Nguồn : zingnews.vn
        </p>`,
        categoryID : 1
    },

    {
        id: 2,
        title : `Sự nghiệp rộng mở của các nhà vô địch Zalo AI Challenge`,
        path:"su-nghiep-rong-mo-cua-cac-nha-vo-dich-zalo-ai-challenge",
        title_content : `Với đề bài mang tính thực tiễn cao, bộ dữ liệu huấn luyện đồ sộ, môi trường thi đấu kịch tính, Zalo AI Challenge trở thành “bệ phóng” của nhiều sinh viên, kỹ sư trẻ người Việt.`,
        img : require("../images/news/active/thumb.jpg"),
        description : `<p>
        Với đề bài mang tính thực tiễn cao, bộ dữ liệu huấn luyện đồ sộ, môi trường thi đấu kịch tính, Zalo AI Challenge trở thành “bệ phóng” của nhiều sinh viên, kỹ sư trẻ người Việt.
        <br/><br/>
        Zalo AI Challenge 2021 chứng kiến chiến thắng của nhiều cái tên trẻ còn đang ngồi trên ghế nhà trường. Hiện tại, mỗi người có những thành công bước đầu trong hành trình nghiên cứu và phát triển AI thực tiễn.
        <br/><br/><span style="font-size :1.75rem; ">Bước đệm nghiên cứu sâu và đi vào thực tiễn</span><br/><br/>
        Năm 2021, vượt qua nhiều đội thi, 3 nghiên cứu sinh bậc tiến sĩ tại Mỹ thuộc đội 3PU đoạt quán quân bảng Legal Text Retrieval. Ít ai biết rằng các thành viên 3PU từng chật vật trên đường đua bảng xếp hạng. Mấu chốt giải quyết đề bài - mảng xử lý ngôn ngữ tự nhiên (nature language processing - NLP) - không phải là thế mạnh của cả 3 người, nhiều lần cả đội đã nghĩ đến việc bỏ cuộc.
        <br/><br/>
        3 thành viên đội 3PU học hỏi được nhiều kinh nghiệm sau chiến thắng.
        <br/><br/>
        <img src=${imgnew4} alt="">
        <br/><br/>
        Tiếp nhận nền giáo dục tiên tiến nhưng các thành viên chỉ mới dừng lại trên nghiên cứu, việc áp dụng vào giải quyết bài toán thực tế là cơ hội hiếm có để cọ xát. Nhận thấy cơ hội hấp dẫn từ cuộc thi, các thành viên cùng cố gắng và lật ngược tình thế vươn lên top 1 vào ngày cuối cùng của giải đấu.
        <br/><br/>
        Sau Zalo AI Challenge 2021, đội 3PU chia sẻ đã khám phá ra thế mạnh ở mảng xử lý ngôn ngữ tự nhiên, cũng như hiểu hơn về ứng dụng của công nghệ này trong doanh nghiệp. Nhóm cũng kỳ vọng đề tài nghiên cứu sâu hơn về lĩnh vực NLP sẽ hoàn thành trong thời gian tới.
        <br/><br/>
        “Tham gia Zalo AI cho mình cơ hội trải nghiệm lĩnh vực mới mà trước đó chưa có dịp tìm hiểu sâu và ứng dụng thực tế. Thêm vào đó, quản lý quỹ thời gian eo hẹp khi làm việc nhóm là điều giá trị mình học được sau cuộc thi”, Nguyễn Ngọc Cường - nghiên cứu sinh tại Đại học Quốc tế Florida (Mỹ) - chia sẻ.
        <br/><br/>
        Giải bài toán khó tại Zalo AI Challenge 2021 giúp Nguyễn Tiến Hồng làm dày kinh nghiệm phát triển giải pháp AI thực tiễn.
        <br/><br/>
        <img src=${imgnew5} alt="">
        <br/><br/>
        Còn đối với Nguyễn Tiến Hồng, việc tham gia đấu trường Zalo AI Challenge giúp anh tiếp cận đề bài AI thực tiễn mang tính thử thách cao. “Zalo AI Challenge là cuộc thi có độ khó về các bài toán nóng, quy tụ cộng đồng khoa học không chỉ ở Việt Nam mà còn những người Việt trên toàn thế giới giải quyết bài toán dành riêng cho người Việt”, Hồng chia sẻ.
        <br/><br/>
        Hồng hiện theo học chương trình tiến sĩ Electrical and Computer Engineering tại Đại học Nam California (Mỹ). Anh tâm sự có định hướng trở về quê hương cống hiến sau khi hoàn thành chương trình học. Hồng được tiếp cận các công nghệ hàng đầu thế giới, nhưng chủ yếu phục vụ nghiên cứu sâu, việc đưa những kiến thức học thuật ở trường ra ngoài thực tế là điều không đơn giản.
        <br/><br/>
        Vì thế, Hồng muốn ứng dụng kiến thức đã học ở Mỹ vào việc giải quyết các bài toán gắn liền với người Việt thông qua cuộc thi Zalo AI Challenge, như một bước chuẩn bị vững chắc trước khi trở về nước.
        <br/><br/><span style="font-size :1.75rem; ">Tích lũy kinh nghiệm, tìm thấy cách làm mới</span><br/><br/>
        Khác hai bảng đấu còn lại, quán quân đề 5K Compliance lại là một thí sinh thi cá nhân. Thành tích này được coi là màn “phục thù” chính mình của Nguyễn Nhật Hoàng.
        <br/><br/>
        Từng tham gia đấu trường Zalo AI Challenge 2020, Hoàng chia sẻ khi ấy chưa có nhiều kinh nghiệm, chiến thuật và bí ý tưởng nên đã dừng thi khi mới được 3/4 chặng đường. Trở lại mạnh mẽ với sự chuẩn bị tốt hơn năm 2021, chàng sinh viên năm cuối Đại học Bách khoa Hà Nội quyết tâm tham gia đến cùng và đạt kết quả xứng đáng.
        <br/><br/>
        Danh hiệu quán quân AI Challenge 2021 được xem là màn “phục thù” chính mình của Nguyễn Nhật Hoàng.
        <br/><br/>
        <img src=${imgnew6} alt="">
        <br/><br/>
        Nhật Hoàng chia sẻ về quá trình giải bài toán: “Cuộc thi được Zalo đầu tư lớn, ban cố vấn uy tín. Những thắc mắc của mình được ban cố vấn trả lời nhiệt tình, sát sao, giúp mình có thêm kiến thức và vượt qua bài toán khó, có tính thực tiễn cao. Các giải pháp AI cũng được đánh giá chuẩn xác”.
        <br/><br/>
        Quán quân đề 5K Compliance cho biết cuộc thi giúp anh thay đổi nhiều về tư duy. Hoàng nhận ra các bài toán khác nhau nhưng có điểm chung về kinh nghiệm xử lý dữ liệu, xây dựng mô hình, thay đổi các tham số và huấn luyện mô hình. Áp dụng các kỹ thuật này vào đồ án sáng chế xe đẩy hàng thông minh, Nhật Hoàng nhận về số điểm 9,6 và tốt nghiệp loại giỏi.
        <br/><br/>
        Chàng trai trẻ ấn tượng với phần cố vấn của các chuyên gia tổ chức Zalo AI Challenge.
        <br/><br/>
        <img src=${imgnew7} alt="">
        <br/><br/>
        Sau khi tốt nghiệp, Nhật Hoàng được tuyển vào vị trí Data Scientist tại một start-up ở Hà Nội và làm việc đến nay. Chàng trai sinh năm 1999 chia sẻ: “Chính vì cọ xát thực tế và được chứng thực năng lực từ Zalo AI Challenge, mình có khá nhiều cơ hội trong quá trình xin việc. Đây là cuộc thi có tiếng trong cộng đồng AI nên danh hiệu quán quân được nhà tuyển dụng chú ý, đánh giá cao”.
        <br/><br/>
        Zalo AI Challenge 2021 mở ra nhiều cơ hội cho Nguyễn Ngọc Cường.
        <br/><br/>
        <img src=${imgnew8} alt="">
        <br/><br/>
        Cuộc thi năm 2021 cũng mở ra cơ hội cho chàng trai thành viên đội 3PU Nguyễn Ngọc Cường. Không những tìm thấy ý tưởng mới cho các công trình nghiên cứu, sau khi công khai giải pháp hoàn chỉnh từ cuộc thi, Cường xây dựng được một GitHub Repository (kho lưu trữ dữ liệu và mã nguồn trên GitHub) với 125 sao.
        <br/><br/>
        Điều này thể hiện sự quan tâm lớn của giới lập trình dành cho giải pháp AI này, góp phần giúp chàng nghiên cứu sinh xây dựng CV ấn tượng hơn. Đây cũng là yếu tố quan trọng hỗ trợ Cường chứng minh năng lực, ứng tuyển thành công vào vị trí thực tập tại các công ty ở Mỹ. Nhờ đó, Cường có thể áp dụng kiến thức đã học, kinh nghiệm từ cuộc thi vào thực tiễn nhiều hơn.
        <br/><br/><span style="font-size :1.75rem; ">Chiến thắng bản thân, mở ra nhiều cơ hội</span><br/><br/>
        Danh hiệu quán quân Zalo AI Challenge 2021 ở bảng Hum to song cũng là tấm “vé ưu tiên” của các thành viên đội Wano trong mắt nhà tuyển dụng.
        <br/><br/>
        Thời điểm tham gia cuộc thi, cả 3 đều là sinh viên mới tốt nghiệp. Ngoài khó khăn làm việc nhóm online trong mùa dịch, các thành viên còn không có thiết bị đủ mạnh. Khi đội đưa dữ liệu lên huấn luyện mô hình, khoảng 6 tiếng sau máy bị ngắt, không thể tiếp tục thi đấu.
        <br/><br/>
        Vượt qua trở ngại, Wano đoạt giải quán quân ở bảng Hum to song.
        <br/><br/>
        <img src=${imgnew9} alt="">
        <br/><br/>
        Trong lúc ngỡ như bế tắc, các thành viên quyết định đổi chiến thuật. Thay vì tập trung vào mô hình lớn, Wano đầu tư vào dữ liệu và thử nhiều mô hình nhỏ liên quan, từ đó tìm ra giải pháp hoàn chỉnh phù hợp nhất với phần cứng của đội và bài toán chương trình. Đại diện đội Wano cho biết chiến thắng Zalo AI Challenge là kỳ tích, cả 3 đã vượt qua giới hạn của bản thân để đạt thành tích tốt.
        <br/><br/>
        Một năm sau chiến thắng, các thành viên Wano đều có công việc ổn định tại những công ty lớn về AI. Đặc biệt, sau cuộc thi, Nguyễn Văn Thiều còn tự tin ứng tuyển vào Zalo và trở thành kỹ sư AI tại đây.
        <br/><br/>
        Thiều kể về kỷ niệm đáng nhớ trong quá trình phỏng vấn vào Zalo: “Khi mình vừa giới thiệu bản thân có nền tảng vững chắc về AI thì người phỏng vấn - cũng là sếp mình bây giờ - nói rằng hôm nay sẽ không phỏng vấn gì về AI đâu. Điều này khiến mình vui sướng vì chứng tỏ anh đánh giá cao giải pháp AI mà mình nộp tại cuộc thi và giải quán quân đã chứng minh được năng lực của mình”.
        <br/><br/>
        Sau một năm chiến thắng, đội Wano đang làm việc tại các công ty lớn về AI.
        <br/><br/>
        <img src=${imgnew10} alt="">
        <br/><br/>
        Đây cũng là cơ hội để chàng kỹ sư trẻ rút ngắn thời gian hiện thực hóa giấc mơ áp dụng sáng kiến AI vào thực tế. Hiện Nguyễn Văn Thiều tham gia cùng kỹ sư Zalo AI giải quyết các bài toán phục vụ cuộc sống người Việt, trong đó có trợ lý giọng nói Kiki được sử dụng phổ biến trên xe hơi và ứng dụng nghe nhạc Zing MP3.
        <br/><br/>
        Không có kỷ niệm khó quên như Thiều nhưng Lâm Bá Thịnh cũng có CV được “làm đẹp” sau chiến thắng Zalo AI Challenge 2021. “Khi phỏng vấn, mình chia sẻ về quá trình tham gia cuộc thi. Nhà tuyển dụng bày tỏ rằng khá hài lòng với cách làm việc và giải pháp AI của team, từ đó đánh giá cao năng lực của mình”, Thịnh bộc bạch.
        <br/><br/>
        Với đề thi mang tính thách thức và có yếu tố thực tiễn cao, mỗi năm, Zalo AI Challenge mang đến cơ hội ý nghĩa cho nhiều tài năng trẻ trong cộng đồng AI Việt, tiếp thêm động lực để những giấc mơ làm sản phẩm phục vụ hàng triệu người Việt thành hình, góp phần thu hẹp khoảng cách giữa AI và cuộc sống con người.
        <br/><br/>
        Nguồn : zingnews.vn
        </p>`,
        categoryID : 1
    },
    {
        id: 3,
        title : `Tham vọng lớn của người Nhật về chip bán dẫn`,
        path:"tham-vong-lon-cua-nguoi-nhat-ve-chip-ban-dan",
        title_content : `Nhật Bản đang đầu tư gần nửa tỷ USD để tăng cường phát triển và sản xuất chất bán dẫn trong một nỗ lực “cơ hội cuối cùng” để giữ vị trí là ông lớn trên thị trường công nghệ toàn cầu.`,
        img : require("../images/news/active/3-1668175734-882-width740height493.jpg"),
        description : `<p>
        <br/><br/><span style="font-size : 2rem;font-weight :700" >Nhật Bản đang đầu tư gần nửa tỷ USD để tăng cường phát triển và sản xuất chất bán dẫn trong một nỗ lực “cơ hội cuối cùng” để giữ vị trí là ông lớn trên thị trường công nghệ toàn cầu. </span><br/><br/>
        Theo Bộ Kinh tế Thương mại và Công nghiệpNhật Bản, một công ty mới được thành lập có tên Rapidus (hay “nhanh chóng” theo tiếng Latinh) sẽ nghiên cứu phát triển chất bán dẫn thế hệ tiếp theo, hoặc “hậu 5G”. Những con chip tiên tiến này sẽ cung cấp các tiện ích thông minh và thành phố thông minh với các cảm biến và đường truyền tốc độ cao. Các thành phần phải cực kỳ mỏng, chỉ bằng một phần nhỏ bề rộng sợi tóc.
        <br/><br/>
        <img src=${imgnew11} alt="">
        <br/><br/>
        Bộ này cho biết trong một tuyên bố rằng nỗ lực trị giá 70 tỷ yên (490 triệu USD) sẽ liên quan đến việc hợp tác chặt chẽ với đồng minh lớn của Nhật Bản là Mỹ để tập hợp “những gì tốt nhất và sáng giá nhất” từ cả hai quốc gia.
        <br/><br/>
        Nhật Bản từ lâu đã tự hào là một cường quốc công nghệ, bao gồm cả sản xuất chip. Tuy nhiên, hôm 11/11, Bộ này thừa nhận rằng Nhật Bản đã tụt hậu 10 năm so với các đối thủ toàn cầu, bao gồm Mỹ, Hàn Quốc, Đài Loan và một số quốc gia châu Âu. Họ nói rằng bây giờ là “cơ hội cuối cùng của Nhật Bản” để tiếp tục là một người dẫn đầu trong lĩnh vực này.
        <br/><br/>
        Các vụ phong tỏa gần đây ởTrung Quốcliên quan đến đại dịch Covid-19 đã làm rõ hơn sự phụ thuộc của Nhật Bản vào các quốc gia khác về chip máy tính và các thành phần quan trọng khác. Các nhà phân tích cho rằng tương lai không chắc chắn của quan hệ Mỹ-Trung có thể khiến nguồn cung từ Trung Quốc gặp rủi ro, làm tăng thêm những lo ngại ngày càng tăng cho Nhật Bản.
        <br/><br/>
        <img src=${imgnew12} alt="">
        <br/><br/>
        Nhưng sự thay đổi sẽ cần thời gian. Bộ Kinh tế Thương mại và Công nghiệp cho biết quốc gia này đang đặt mục tiêu sản xuất hàng loạt chip vào cuối năm 2020 hoặc trong vài năm tới. Những công ty tham gia vào Rapidus bao gồm nhà sản xuất ô tô Toyota Motor, các nhà sản xuất điện tử Sony và NEC, cùng với SoftBank Corp, Nippon Telegraph và Telephone, Denso và Kioxia. Mỗi công ty đang đầu tư 1 tỷ yên (7 triệu USD) ngoài trợ cấp của chính phủ Nhật Bản. Một công ty khác (thứ 8) là MUFG Bank đang đầu tư 300 triệu yên (2 triệu đô la) vào Rapidus.
        <br/><br/>
        Các chip thế hệ tiếp theo được cho là sẽ rất quan trọng đối với trí tuệ nhân tạo và hệ thống lái xe tự động. Chính phủ Nhật Bản cho biết, đầu tư vào công nghệ như vậy sẽ dẫn đến nhiều việc làm và tăng trưởng kinh tế.
        <br/><br/>
        Nguồn: <a href="https://www.nguoiduatin.vn/tham-vong-lon-cua-nguoi-nhat-ve-chip-ban-dan-a579982.html" style="color:black;text-decoration: none;" target="_blank">https://www.nguoiduatin.vn/tham-vong-lon-cua-nguoi-nhat-ve-chip-ban-dan-a579982.html</a>
        </p>`,
        categoryID : 1
    },
    {
        id: 4,
        title : `Đừng cập nhật Windows 11 lúc này`,
        path:"dung-cap-nhat-windows-11-luc-nay",
        title_content : `Các kỹ sư của Microsoft đã vô tình đưa chế độ gỡ rối hiệu năng GPU vào trong bản cập nhật Windows 11 22H2, khiến cho quá trình chơi game gặp lỗi khó chịu.`,
        img : require("../images/news/active/pfy3fbdws7aqqjkjrgb3dh_970_80-1-.jpg"),
        description : `<p>
        Các kỹ sư của Microsoft đã vô tình đưa chế độ gỡ rối hiệu năng GPU vào trong bản cập nhật Windows 11 22H2, khiến cho quá trình chơi game gặp lỗi khó chịu.
        <br/><br/>
        Bản cập nhật mới khiến máy chạy Windows 11 đơ, giật khi chơi game. Ảnh: Microsoft.
        <br/><br/>
        Theo thông báo chính thức của Microsoft hôm 11/11, công ty xác nhận bản cập nhật Windows 11 22H2 phát sinh lỗi khi người dùng chơi game. Một số ứng dụng và tựa game có thể ngẫu nhiên kích hoạt chế độGPU performance debugging- một tính năng không dành cho người dùng cuối. Điều này làm giảm hiệu suất máy, gây ra hiện tượng đơ, giật.
        <br/><br/>
        Trước đó, khi cài đặt bản cập nhật mới, nhiều người nhận ra máy tính của họ gặp vấn đề trong lúc chơi game. Ban đầu, nguyên nhân bị nghi ngờ là card đồ họa của Nvidia. Tuy nhiên, sau khi cài lại trình điều khiển (driver), sự cố vẫn không thể giải quyết.
        <br/><br/>
        Theo Microsoft, những khách hàng gặp vấn đề có thể khắc phục bằng cách cập nhật phiên bản mới nhất của các trò chơi và ứng dụng bị ảnh hưởng. "Nếu không chắc chắn về cách cập nhật các trò chơi và ứng dụng mình đã cài đặt, bạn cần tham khảo ý kiến ​​nhà phát triển. Tuy nhiên hầu hết chúng sẽ cập nhật tự động", công ty thông tin thêm.
        <br/><br/>
        Song song đó, Microsoft đã ngừng tự động cập nhật các máy bị ảnh hưởng lên Windows 11 22H2 trong thời gian chờ đợi khắc phục lỗi. Người dùng cũng có thể tạm thời chọn quay lại phiên bản cũ nếu đã lỡ cài đặt.
        <br/><br/>
        TheoBleeping Computer, kể từ khi phát hành Windows 11 22H2 hôm 4/10, Microsoft 4 lần tung ra bản cập nhật nhằm trì hoãn tính năng tự động nâng cấp lên phiên bản mới do lỗi liên quan đến Xbox Game, màn hình xanh, in ấn và Windows Hello.
        <br/><br/>
        Ngoài ra, họ cũng xác nhận Windows 11 22H2 có thể tác động đáng kể đến hiệu suất sao chép file dung lượng lớn qua SMB, hủy cấp phép trên một số hệ thống và ảnh hưởng đến thiết bị đầu cuối được cấu hình qua Windows 11.
        <br/><br/>
        Đây không phải là lần đầu tiên các game thủ gặp sự cố với phiên bản hệ điều hành mới của Microsoft. Trước đó, nhiều chủ nhân máy tính chạy chip AMD nhận thấy hiệu năng thiết bị giảm sút nghiêm trọng khi cài đặt Windows 11. May mắn là vấn đề được khắc phục nhanh chóng bằng driver với từ AMD và bản vá phần mềm của Microsoft.
        <br/><br/>
        Nguồn : zingnews.vn
        </p>`,
        categoryID : 1
    },
    {
        id: 5,
        title : `Xóa ngay 4 ứng dụng này vì chúng là "bom quảng cáo" trên smartphone`,
        path:"xoa-ngay-4-ung-dung-nay-vi-chung-la-bom-quang-cao-tren-smartphone",
        title_content : `Các nhà nghiên cứu bảo mật tại Malwarebytes đã phát hiện ra 4 ứng dụng độc hại trên Google Play, được thiết kế để chuyển hướng người dùng đến các trang web độc hại và hiển thị quảng cáo.`,
        img : require("../images/news/active/1667464047-51ca4feafdddbe414a3934d3de5236d0-width850height444.jpeg"),
        description : `<p>
        <p style="font-size : 2rem;font-weight :700" >Các nhà nghiên cứu bảo mật tại Malwarebytes đã phát hiện ra 4 ứng dụng độc hại trên Google Play, được thiết kế để chuyển hướng người dùng đến các trang web độc hại và hiển thị quảng cáo.</p>
        Theo các nhà nghiên cứu, 4 ứng dụng độc hại là một phần của chiến dịch đánh cắp thông tin và phát tán phần mềm quảng cáo. Tất cả đều được phát triển bởi Mobile apps Group và đã được tải xuống hơn 1 triệu lần.
        <br/><br/>
        <img src=${imgnew13} alt="">
        <br/><br/>
        4 ứng dụng độc hại trên Google Play. Ảnh: Malwarebytes
        <p>
            Danh sách 4 ứng dụng độc hại bạn nên gỡ bỏ khỏi điện thoại ngay lập tức:
            <li style="list-style: none;">- Bluetooth App Sender (com.bluetooth.share.app), hơn 50.000 lượt tải xuống.</li>        
            <li style="list-style: none;">- Bluetooth Auto Connect (com.bluetooth.autoconnect.anybtdevices), hơn 1 triệu lượt tải xuống.</li>      
            <li style="list-style: none;">- Driver: Bluetooth, Wi-Fi, USB (com.driver.finder.bluetooth.wifi.usb), hơn 10.000 lượt tải xuống.</li>       
            <li style="list-style: none;">- Mobile transfer: smart switch (com.mobile.faster.transfer.smart.switch), hơn 1.000 lượt tải xuống.</li>
        </p>
        Với sự phát triển của công nghệ và các công cụ, không có gì ngạc nhiên khi ngày càng có nhiều ứng dụng độc hại đã nghĩ ra cách mới để qua mặt các biện pháp bảo mật của Google. Một trong những chiến thuật phổ biến thường được các ứng dụng độc hại áp dụng là “nằm vùng” một thời gian trước khi bắt đầu tấn công.
        <br/><br/>
        Khi người dùng cài đặt ứng dụng, chúng sẽ chưa hoạt động ngay lập tức mà chờ khoảng 4 ngày trước khi chuyển hướng người dùng đến trang web lừa đảo đầu tiên trong trình duyệt Chrome, và sau đó mở nhiều tab hơn cứ sau mỗi 2 tiếng.
        <br/><br/>
        Theo Malwarebytes, các trang web độc hại được thiết kế để tạo ra doanh thu thông qua quảng cáo trả tiền cho mỗi lần nhấp chuột, đồng thời còn khuyến khích người dùng cài đặt thêm các ứng dụng dọn dẹp điện thoại. Tuy nhiên, thực chất đây đều là những phần mềm quảng cáo.
        <br/><br/>
        <img src=${imgnew14} alt="">
        <br/><br/>
        Phần mềm độc hại dụ người dùng cài đặt thêm ứng dụng dọn dẹp điện thoại. Ảnh: Malwarebytes
        <br/><br/>
        4 ứng dụng này là một phần của hoạt động phần mềm độc hại rộng lớn hơn có tên HiddenAds, đã hoạt động ít nhất từ tháng 6/2019. Về cơ bản, các ứng dụng độc hại sẽ giả mạo là trình quét mã QR, ghi chú, camera, công cụ chuyển đổi tiền tệ/đơn vị, từ điển... nhằm lừa người dùng tải xuống và cài đặt.
        <br/><br/>
        Theo nhà nghiên cứu SangRyol Ryu (McAfee), những phần mềm độc hại dạng này có thể gây tốn lưu lượng và tiêu thụ nhiều điện năng, đồng thời tạo ra lợi nhuận cho các tác nhân đe dọa mà người dùng không hề hay biết.
        <br/><br/>
        Trước đó, nhà nghiên cứu Guardio Labs cũng phát hiện một chiến dịch quảng cáo độc hại có tên là Dormant Colors, tận dụng các tiện ích mở rộng giả mạo trên Google Chrome và Microsoft Edge để kiểm soát các truy vấn tìm kiếm của người dùng.
        <br/><br/>
        Nguồn: <a href="https://www.nguoiduatin.vn/xoa-ngay-4-ung-dung-nay-vi-chung-la-34-bom-quang-cao-34-tren-smartpho..." style="color:black;text-decoration: none;" target="_blank">https://www.nguoiduatin.vn/xoa-ngay-4-ung-dung-nay-vi-chung-la-34-bom-quang-cao-34-tren-smartpho...</a>
        </p>`,
        categoryID : 1
    },
    {
        id: 6,
        title : `Ứng dụng cho phép bạn tìm kiếm mọi thứ bạn từng thấy hoặc nghe trong quá khứ`,
        path:"ung-dung-cho-phep-ban-tim-kiem-moi-thu-ban-tung-thay-hoac-nghe-trong-qua-khu",
        title_content : `Rewind là ứng dụng cho phép bạn tìm lại mọi thứ đã đọc trên Internet, đã nhắn trong cuộc trò chuyện hoặc thậm chí đã nói trong các cuộc họp.`,
        img : require("../images/news/active/1667527429-0d72880832f70189791c6efebfac8639-width850height513.jpeg"),
        description : `<p>
        <span style="font-size : 2rem;font-weight :700" >Rewind là ứng dụng cho phép bạn tìm lại mọi thứ đã đọc trên Internet, đã nhắn trong cuộc trò chuyện hoặc thậm chí đã nói trong các cuộc họp.</span>
        <br/><br/>Chắc hẳn rằng ai trong số chúng ta cũng từng đã gặp trường hợp nghĩ về một thứ gì đó, nhưng lại không nhớ mình đã đọc hoặc đã viết ở đâu.
        <br/><br/>
        Bạn đã bao giờ ước mình có thể quay ngược thời gian để nghe lại một câu kết luận của sếp trong cuộc họp chưa? Bạn có bao giờ lướt News Feed (bảng tin) Facebook để tìm thứ gì đó vừa thấy vào ngày hôm qua chưa? Ứng dụng Rewind được hứa hẹn sẽ giúp giải quyết tất cả những vấn đề này.
        <br/><br/>
        <img src=${imgnew15} alt="">
        <br/><br/>
        Rewind sẽ giúp bạn tìm kiếm thông minh hơn. Ảnh: Rewind
        <br/><br/>
        Rewind là một ứng dụng mới (hiện đang trong giai đoạn thử nghiệm) của doanh nhân Dan Siroker, người đồng sáng lập và cựu giám đốc điều hành của Optimizely và Brett Bejcek. Cặp đôi đã thành lập một công ty khởi nghiệp được hỗ trợ bởi công ty đầu tư blue-chip Silicon Valley, Andreessen Horowitz (a16z).
        <br/><br/>
        <span style="font-size : 2rem;font-weight :700" >Rewind hoạt động như thế nào?</span>
        <br/><br/>
        Rewind là ứng dụng cho phép bạn tìm lại mọi thứ bạn nhìn thấy, nói và nghe thấy trong quá khứ.
        <br/><br/>
        Các nhà phát triển đã sử dụng một số thuật toán mạnh mẽ để nén dữ liệu thô lên đến 3.750 lần mà không làm giảm chất lượng. Ví dụ 10,5 GB dữ liệu thô thành 2,8 MB. Điều này có nghĩa là bạn không phải đồng bộ hóa với bất kỳ máy chủ đám mây nào hoặc cho phép bất kỳ ai khác xem dữ liệu riêng tư của bạn, mọi thứ sẽ được lưu trữ trên máy tính của bạn.
        <br/><br/>
        Rewind tận dụng các API gốc của macOS nhiều nhất có thể, được thiết kế tương thích vớiAppleSilicon.
        <br/><br/>
        Bên cạnh đó, Rewind còn tận dụng công nghệ từ Scribe, một liên doanh khác của Siroker, sử dụng công nghệ nhận dạng giọng nói tự động state-of-the-art để phiên âm nội dung trong các cuộc họp.
        <br/><br/>
        Rewind hiện vẫn đang trong giai đoạn thử nghiệm, tuy nhiên, bạn có thể đăng ký trải nghiệm sớmtại đây.
        <br/><br/>
        Trong đoạn video demo, Dan đã tìm kiếm cụm từ “TPS reports” và Rewind ngay lập tức trả về các kết quả có liên quan, giúp việc tìm kiếm tài liệu, từ khóa, câu trả lời… trong quá khứ trở nên đơn giản hơn.
        <br/><br/>
        <img src=${imgnew16} alt="">
        <br/><br/>
        Tìm kiếm nhanh các nội dung trong quá khứ bằng Rewind. Ảnh chụp màn hình
        <br/><br/>
        Nguồn:<a href="https://www.24h.com.vn/cong-nghe-thong-tin/ung-dung-cho-phep-ban-tim-kiem-moi-thu-ban-tung-thay-hoac-nghe-trong-qua-khu-c55a1411502.html" style="color:black;text-decoration:none" target="_blank">https://www.24h.com.vn/cong-nghe-thong-tin/ung-dung-cho-phep-ban-tim-kiem-moi-thu-ban-tung-thay-hoac-nghe-trong-qua-khu-c55a1411502.html</a>
        </span>`,
        categoryID : 1
    },
    {
        id: 7,
        title : `Kéo - thả để tạo ra phần mềm dành cho người không rành lập trình`,
        path:"keo-tha-de-tao-ra-phan-mem-danh-cho-nguoi-khong-ranh-lap-trinh",
        title_content : `Zoho Creator là giải pháp tất cả-trong-một (all-in-one), hỗ trợ doanh nghiệp xây dựng các ứng dụng dù người dùng không biết nhiều kiến thức về kỹ thuật lập trình.`,
        img : require("../images/news/active/low-code-no-code1_0-1667576946-528-width740height555_anh_cat_3_2.jpg"),
        description : `<p>
        <span style="font-size : 2rem;font-weight :700" >Zoho Creator là giải pháp tất cả-trong-một (all-in-one), hỗ trợ doanh nghiệp xây dựng các ứng dụng dù người dùng không biết nhiều kiến thức về kỹ thuật lập trình.</span>
        <br/><br/>
        Low-code, no-code (LCNC) là phương pháp phát triển ứng dụng, phần mềm thông qua một giao diện trực quan, đơn giản với các thao tác kéo - thả trong một nền tảng phát triển đã được định sẵn, thay vì phát triển bằng những dòng code sử dụng các ngôn ngữ lập trình. Kết quả cho ra tương đương với các phương pháp lập trình truyền thống.
        <br/><br/>
        Đến nay, nền tảng LCNC đã phát triển mạnh đến mức “bắt chước” được các khả năng phát triển ứng dụng phần mềm thực, bao gồm phát triển ứng dụng với giao diện dành cho thiết bị di động và trang web mà không ảnh hưởng đến tính bảo mật hay tính linh hoạt do các ứng dụng đám mây cung cấp.
        <br/><br/>
        Một trong các giải pháp low-code hiện nay là Zoho Creator với nhiều tính năng, nằm trong hệ sinh thái Zoho. Đây là giải pháp tất cả-trong-một (all-in-one) hỗ trợ doanh nghiệp xây dựng các ứng dụng dù người dùng không biết nhiều kiến thức về kỹ thuật lập trình.
        <br/><br/>
        <img src=${imgnew17} alt="">
        <br/><br/>
        Zoho Creator nhanh chóng giải quyết những bài toán của doanh nghiệp, giúp tiết kiệm thời gian và nâng cao hiệu quả công việcGiao diện Zoho Creator.
        <br/><br/>
        Để hiểu rõ hơn về giải pháp Zoho Creator đầy sáng tạo và hứa hẹn tạo ra một tương lai mới trong lĩnh vực lập trình, chúng tôi đã có cuộc trao đổi với ông Gibu Mathew - Phó Chủ tịch kiêm Tổng Giám đốc Zoho Corporation khu vực châu Á - Thái Bình Dương (APAC).
        <br/><br/>
        Ông Gibu cho biết, điểm nổi bật của nền tảng Zoho Creator là người dùng trong doanh nghiệp có thể dễ dàng xây dựng và phát triển phần mềm, ứng dụng để đáp ứng nhu cầu của họ. Ví dụ, Zoho đang thực hiện thanh toán cho nhà cung cấp và gửi quy trình phê duyệt thông qua ứng dụng được phát triển bởi Zoho Creator.
        <br/><br/>
        "Nhân viên làm việc đa quốc gia của chúng tôi đều có thể đăng ký các nhà cung ứng mới, gửi hoá đơn liên quan và chỉ định cá nhân phê duyệt liên quan trong công ty trên một nền tảng “tự phục vụ” duy nhất. Các nhóm phát triển nội dung đa quốc gia làm việc trên một giao diện dashboard chung, dùng để gửi bài, duyệt bài hay quản lý vô số nội dung được tạo ra từ những văn phòng khác nhau", ông Gibu chia sẻ.
        <br/><br/>
        Theo ông, trên thực tế, các khách hàng sử dụng Zoho Creator đã phát triển hơn 6 triệu ứng dụng kể từ khi phần mềm này ra mắt, bao gồm những ứng dụng được tạo ra bởi nhân viên nội bộ và người dùng bên ngoài như các đối tác kinh doanh.
        <br/><br/>
        Một vài tính năng chủ chốt của Zoho Creator bao gồm khả năng phát triển một ứng dụng đơn giản với đầy đủ chức năng, hỗ trợ nhiều người cùng thao tác, nhập liệu, xem và chỉnh sửa dữ liệu, và hoàn thành ứng dụng trong vòng chưa đầy 60 phút.
        <br/><br/>
        Người dùng Zoho Creator có thể truy cập công nghệ dành cho khách hàng và tích hợp với nhiều thiết bị nhưsmartphone, GPS, camera trên các thiết bị để tăng cường khả năng xây dựng những giải pháp phần mềm của Zoho Creator. Điều này giúp người dùng có thể dễ dàng thực hiện thao tác kéo - thả trong trình tạo ứng dụng mà không cần hiểu rõ sự phức tạp trong cách thức hoạt động của những thao tác này.
        <br/><br/>
        <img src=${imgnew18} alt="">
        <br/><br/>
        Ông Gibu Mathew - Phó Chủ tịch kiêm Tổng Giám đốc Zoho Corporation khu vực APAC.
        <br/><br/>
        "Người dùng Zoho Creator có thể xây dựng, quản lý, tích hợp và phân tích các ứng dụng kinh doanh, quản lý đầu cuối các ứng dụng này trong cùng một nền tảng. Zoho Creator cho trải nghiệm toàn diện và tăng tốc độ triển khai nhanh hơn gấp 10 lần so với các giải pháp khác trên thị trường. Bằng cách sử dụng công cụ phát triển phần mềm dựa trên nền tảng low-code này, đội ngũ IT và nhân viên các phòng ban khác có thể làm việc song song để sáng tạo và triển khai các ứng dụng có khả năng tùy chỉnh cao", ông nói.
        <br/><br/>
        Tuy nhiên, theo ông Gibu, nền tảng LCNC không hẳn có thể giải quyết được hết mọi vấn đề của doanh nghiệp về công nghệ. Đối với các nhu cầu phức tạp hơn, doanh nghiệp vẫn cần đến các nhà phát triển để hỗ trợ họ thực hiện. Dù vậy điểm mạnh của giải pháp LCNC là tính đơn giản, dễ sử dụng, và ngày càng nhiều nhân viên văn phòng có thể tự tạo và phát triển các giải pháp ứng dụng, phần mềm.
        <br/><br/>
        Hiện, Zoho Creator đang có phiên bản miễn phí cho người dùng tạo và quản lý từng ứng dụng riêng lẻ. Nếu có nhiều hơn một ứng dụng, hoặc có nhu cầu sử dụng phiên bản đầy đủ tính năng, người dùng chỉ cần trảsẽ phải trả 25 USD/tháng mà không cần mua thêm các sản phẩm khác trong hệ sinh thái của Zoho.
        <br/><br/>
        Nguồn:<a href="https://www.24h.com.vn/cong-nghe-thong-tin/keo-tha-de-tao-ra-phan-mem-danh-cho-nguoi-khong-ranh-lap-trinh-c55a1411771.html" style="color:black;text-decoration:none" target="_blank">https://www.24h.com.vn/cong-nghe-thong-tin/keo-tha-de-tao-ra-phan-mem-danh-cho-nguoi-khong-ranh-lap-trinh-c55a1411771.html</a>
        </p>`,
        categoryID : 1
    },
    {
        id: 8,
        title : `Cuộc thanh lọc tại Twitter đã bắt đầu`,
        path:"cuoc-thanh-loc-tai-twitter-da-bat-dau",
        title_content : `Vào 9h sáng ngày 4/11 (23h theo giờ Việt Nam), mọi nhân viên Twitter đều nhận một trong 2 email, giữ việc hoặc mất việc.`,
        img : require("../images/news/active/2_Cnet.jpg"),
        description : `    <p>
        Vào 9h sáng ngày 4/11 (23h theo giờ Việt Nam), mọi nhân viên Twitter đều nhận một trong 2 email, giữ việc hoặc mất việc.
        <br/><br/>
        Chỉ trong vòng một tuần kể từ khi tiếp quản Twitter, Elon Musk đã sa thải một nửa số nhân viên của công ty, bao gồm toàn bộ dàn lãnh đạo trước đây. Ảnh: Cnet.
        <br/><br/>
        <img src=${imgnew19} alt="">
        <br/><br/>
        Trong một tuần kể từ khi Elon Musk tiếp quản Twitter, nhân viên tại nền tảng mạng xã hội này cũng không biết gì hơn những người bên ngoài theo dõi vụ việc về “số phận” công việc của họ, cho đến cách đây vài tiếng.
        <br/><br/>
        Email nội bộ “hướng dẫn” quy trình sa thải, gửi cho nhân viên Twitter vào ngày 3/11, nói rằng đúng 9h sáng ngày 4/11 theo giờ Mỹ (23h theo giờ Việt Nam), mỗi người sẽ nhận được email với tiêu đề "Vị trí của bạn tại Twitter" cho biết được giữ việc hay nghỉ việc.
        <br/><br/>
        "Hôm nay là ngày làm việc cuối cùng của bạn", một số nhân viên mô tả lại nội dung email sa thải mình vừa nhận được.
        <br/><br/>
        Nội dung email được cho là gửi đến nhân viên Twitter, thông báo việc chấm dứt làm việc. Ảnh: Insider.
        <br/><br/>
        <img src=${imgnew20} alt="">
        <br/><br/>
        Nội dung email được cho là gửi đến nhân viên Twitter, thông báo việc chấm dứt làm việc. Ảnh: Insider.
        <br/><br/>
        <img src=${imgnew21} alt="">
        <br/><br/>
        Tuy nhiên, có vẻ như để tránh bị kiện vì sa thải không thông báo, các nhân viên được thông báo họ vẫn sẽ được giữ lại công ty và nhận lương, thưởng cho đến ngày 2/2/2021. Trong thời gian này, họ sẽ không phải làm việc và không được truy cập các hệ thống nội bộ của Twitter.
        <br/><br/>
        Người đứng đầu bộ phận an ninh của Twitter ngày 5/11 thông báo công ty đã sa thải 50% nhân viên, đồng thời cho biết khả năng kiểm duyệt nội dung của nền tảng này vẫn được duy trì.
        <br/><br/>
        <span style="font-size:1.75rem">Email phán quyết cuối cùng</span>
        <br/><br/>“Có vẻ như nhóm PR, truyền thông của Twitter, vốn có khoảng 80-100 người, giảm xuống chỉ còn 2 người”, Kurt Wagner, phóng viênBloombergtheo dõi vụ việc, cập nhật một giờ sau “deadline” nêu trong email nội bộ, 23h ngày 4/11.
        <br/><br/>
        Rumman Chowdhury phát hiện mình thuộc diện bị cho nghỉ việc sau khi mất tài khoản. Ảnh: HN.
        <br/><br/>
        <img src=${imgnew22} alt="">
        <br/><br/>
        Rumman Chowdhury phát hiện mình bị đuổi việc trước email “phán quyết” sau khi không đăng nhập được tài khoản Google dưới tên miền @twitter.com, và gọi động thái lần này của sếp Twitter là “cú búng tay của Thanos”. Đây cũng là cách nhiều nhân viên phát hiện ra mình bị cho nghỉ việc, trước khi được công ty thông báo chính thức.
        <br/><br/>
        Đến nay vẫn chưa rõ tổng số nhân viên Twitter bị cho nghỉ việc là bao nhiêu người. Nhiều người đã đăng bài tạm biệt đồng nghiệp và nơi làm việc cũ trong #LoveWhereYouWorked trên Twitter, cùng ảnh chụp tại văn phòng San Francisco hoặc các sự kiện của Twitter.
        <br/><br/>
        Trước đó vài tiếng, Wagner cho biết trong số các nhân viên Twitter đã nhận được email có một số người đang mang thai và không biết phúc lợi của họ sau khi bị cho nghỉ việc như thế nào. Một số phúc lợi của nhân viên Twitter đã bị cắt bỏ dưới thời sếp mới, chẳng hạn như chính sách làm việc từ xa hoặc một ngày nghỉ thêm hàng tháng ngoài cuối tuần.
        <br/><br/>
        “Những người bị sa thải chưa từng nghe bất cứ giải thích gì từ Elon Musk, bị sa thải sau một tuần làm việc mệt mỏi và một số vụ sa thải có thể đã phá vỡ các quy định về việc làm”, theo Orosz.
        <br/><br/>
        Twitter cũng đang bị kiện tại tòa án liên bang San Francisco vì sa thải hàng loạt nhân viên mà không thông báo trước.
        <br/><br/>
        <span style="font-size : 1.75rem">Số phận bất định</span>
        <br/><br/>Từ khi sếp mới tiếp quản, nhiều nguồn tin từ các nhân viên Twitter cho biết công việc của họ tại nền tảng mạng xã hội trở nên căng thẳng. Nhân viên phải đáp ứng các yêu cầu khó hiểu của sếp mới, chẳng hạn như in số code đã viết trong một tháng qua để đánh giá lại, hoặc làm thêm nhiều giờ để đáp ứng các deadline phát triển tính năng mới gấp gáp.
        <br/><br/>
        Nguồn tin củaThe Vergecho biết sau khi sếp mới Twitter chốt thu phí tick xanh, đội phát triển sản phẩm bị buộc hoàn thiện tính năng trước ngày 7/11 hoặc sẽ bị đuổi việc. “Có quá nhiều việc phải làm trong suốt cuối tuần đến mức một số người ngủ trên ghế dài hoặc mang sẵn túi ngủ”, Gergely Orosz, cây viết của trangThe Pragmatic Engineerđã nói chuyện với các kỹ sư Twitter, chia sẻ.
        <br/><br/>
        Một lập trình viên iOS của Twitter, Leah Culver, đã thu về gần 40.000 lượt thích và 3.000 lượt chia sẻ cho bức ảnh cô cầm một tập code đã in ra theo lệnh sếp mới. Nhưng chỉ vài giờ sau, Musk "quay xe", yêu cầu nhân viên dừng in và hủy số tài liệu đã in ra.
        <br/><br/>
        Leah Culver, một kỹ sư tại Twitter, gây bất ngờ khi đăng tải ảnh mình cầm một tập code đã in. Ảnh: Leah Culver.
        <br/><br/>
        <img src=${imgnew23} alt="">
        <br/><br/>
        Các kỹ sư tại Twitter cũng cho biết không có tài liệu nào được sử dụng để “đánh giá lại” như ý định ban đầu của sếp mới.
        <br/><br/>
        Đến trước ngày 3/11, tuy vẫn “nhận lệnh” đều đặn từ sếp mới, các nhân viên Twitter cũng không được biết thông tin chính thức nào từ ban lãnh đạo. “Tôi đã nói chuyện với một số kỹ sư phần mềm tại Twitter, họ đều nói với tôi rằng nhân viên cũng đang theo dõi tin tức để biết điều gì sắp xảy ra”, Orosz cho biết.
        <br/><br/>
        Trong các nhóm nội bộ, nhân viên Twitter đồn đoán về quy mô của đợt sa thải lần này sau khi Musk yêu cầu cấp quản lý thực hiện một đợt đánh giá và phân cấp hiệu suất nhân viên. Có một ảnh chụp màn hình danh sách với 3.738 cái tên, trong số khoảng 7.500 nhân viên.
        <br/><br/>
        Nguồn: Zingnews.vn
        </p>`,
        categoryID : 1
    },
    {
        id: 9,
        title : `Sở Y tế Cần Thơ dùng "quân xanh" để thông thầu, gây thiệt hại 33 tỉ đồng`,
        path:"so-y-te-can-tho-dung-quan-xanh-de-thong-thau-gay-thiet-hai-33-ti-dong",
        title_content : `Sau khi được lãnh đạo Sở Y tế Cần Thơ “bật đèn xanh”, nữ đại gia chỉ đạo nhân viên sử dụng “quân xanh” để thông thầu, trúng một loạt dự án trên địa bàn.`,
        img : require("../images/news/events/1667288777-018c296bb240d75418c7f420521b7404-width850height558.png"),
        description : `<p>
        <span style="font-size: 2rem;">Sau khi được lãnh đạo Sở Y tế Cần Thơ “bật đèn xanh”, nữ đại gia chỉ đạo nhân viên sử dụng “quân xanh” để thông thầu, trúng một loạt dự án trên địa bàn.</span>
        <br/><br/>
        Cơ quan CSĐT Bộ Công an mới đây hoàn tất kết luận điều tra, đề nghị truy tố 20 bị can vụ vi phạm quy định về đấu thầu gây hậu quả nghiêm trọng, xảy ra tại Sở Y tế Cần Thơ và một số đơn vị liên quan.
        <br/><br/>
        Ba trong số này gồm Bùi Thị Lệ Phi, Cao Minh Chu (hai cựu giám đốc Sở Y tế Cần Thơ) và Hoàng Thị Thúy Nga (chủ tịch Hội đồng sáng lập NSJ Group).
        <br/><br/>
        Các bị can bị cáo buộc sai phạm khi thực hiện bốn gói thầu mua sắm thiết bị y tế (có tổng trị giá gần 90 tỉ đồng) do Sở Y tế làm chủ đầu tư, gây thiệt hại cho nhà nước hơn 33 tỉ đồng.
        <br/><br/>
        <img src=${imgnew24} alt="">
        <br/><br/>
        Hai bị can Bùi Thị Lệ Phi (phải) và Cao Minh Chu. Ảnh: CACC
        <br/><br/>
        <span style="font-size: 2rem;">Lập “quân xanh” để thông thầu</span>
        <br/><br/>
        Bản kết luận điều tra xác định, bà Hoàng Thị Thúy Nga đứng ra thành lập bảy công ty (NSJ Group, Công ty NSJ, Công ty Bình An…) và trực tiếp điều hành mọi hoạt động. Mục đích nhằm ký các hợp đồng mua bán nội bộ để nâng khống giá thiết bị dự thầu.
        <br/><br/>
        Năm 2017, tại buổi làm việc với Bệnh viện Tim, chủ tịch TP Cần Thơ khi ấy là Võ Thành Thống giao Sở Y tế tham mưu đề xuất dự án mua sắm hệ thống DSA 2 bình diện (gói thầu số 01). Biết việc này, nhân viên dưới quyền báo cáo với bà Nga để tìm cách quan hệ với chủ đầu tư - tức Sở Y tế Cần Thơ.
        <br/><br/>
        Tháng 6-2017, từ sự giới thiệu của chủ tịch Võ Thành Thống, bà Nga đến gặp giám đốc Sở Y tế Bùi Thị Lệ Phi và phó giám đốc Cao Minh Chu. Trong cuộc gặp, hai bên trao đổi và thống nhất giá bán hệ thống DSA 2 bình diện là 38 tỉ đồng. Phía bà Nga cũng cung cấp tài liệu về báo giá, cấu hình… để Sở Y tế lập hồ sơ trình các cấp thẩm định, phê duyệt.
        <br/><br/>
        Căn cứ vào các tài liệu trên, Bệnh viện Tim sau đó đề nghị mua sắm hệ thống DSA 2 bình diện giá 38 tỉ đồng, Sở Y tế thống nhất danh mục mua sắm, UBND TP báo cáo và được HĐND TP thống nhất chủ trương đầu tư dự án.
        <br/><br/>
        Quá trình thực hiện, bà Nga tìm cách giới thiệu công ty thân quen để Sở Y tế lựa chọn làm đơn vị tư vấn đấu thầu. Tiếp đó, nhân viên của bà Nga và nhân viên công ty tư vấn thường xuyên trao đổi với nhau nhằm lập hồ sơ mời thầu có lợi cho công ty của bà Nga.
        <br/><br/>
        Đặc biệt, bà Nga còn chỉ đạo cấp dưới chuẩn bị ba bộ hồ sơ dự thầu, một là Công ty NSJ đáp ứng đầy đủ tiêu chí dự thầu, hai bộ còn lại là hai công ty có vai trò là “quân xanh” – không đáp ứng đủ tiêu chí. Dĩ nhiên, Công ty NSJ trúng thầu như đã sắp xếp.
        <br/><br/>
        Tháng 6-2018, Sở Y tế và Công ty NSJ ký hợp đồng kinh tế thực hiện gói thầu 01 với giá trị gần 38 tỉ đồng, đến tháng 12-2018 thì nghiệm thu. Kết quả định giá tài sản cho thấy hệ thống DSA 2 bình diện chỉ có giá hơn 29,5 tỉ đồng, tức gây thiệt hại hơn 8 tỉ đồng.
        <br/><br/>
        <img src=${imgnew25} alt="">
        <br/><br/>
        Hoàng Thị Thúy Nga (trái) cùng một số bị can trong vụ án. Ảnh: CACC
        <br/><br/>
        <span style="font-size: 2rem;">Mua sẵn hàng hóa trước khi “chốt thầu”</span>
        <br/><br/>
        Một gói thầu khác có sai phạm là hệ thống CT218 lát cắt tại Bệnh viện Nhi. Vẫn với thủ đoạn tương tự, bà Nga thống nhất với lãnh đạo Sở Y tế, rồi chỉ đạo nhân viên dưới quyền phối hợp với đơn vị tư vấn để Công ty NSJ trúng thầu.
        <br/><br/>
        Đáng chú ý, do có sự thông đồng và biết trước Công ty NSJ sẽ trúng, ngay từ thời điểm chưa “chốt thầu”, bà Nga đã cho người đi ký hợp đồng mua sắm hệ thống CT218 lát cắt kèm theo điều khoản giao hàng trực tiếp tại Bệnh viện Nhi, nhằm bảo đảm thời gian thực hiện hợp đồng cũng như không cho công ty khác có cơ hội tham gia.
        <br/><br/>
        Tiếp đó, để nâng giá dự thầu, các công ty do bà Nga thành lập tự ký kết hợp đồng mua đi bán lại hệ thống CT218 lát cắt.
        <br/><br/>
        Tháng 12-2018, Sở Y tế và Công ty NSJ ký hợp đồng kinh tế thực hiện gói thầu trên với giá 25,8 tỉ đồng, đến tháng 4-2019 thì nghiệm thu. Kết quả định giá xác định hệ thống CT218 lát cắt chỉ có giá 13,4 tỉ đồng, gây thiệt hại 12,4 tỉ đồng.
        <br/><br/>
        Với hai gói thầu còn lại tại Bệnh viện Nhi, bà Nga tiếp tục kịch bản cũ để công ty của mình trúng thầu. Tuy nhiên, do Công ty NSJ đã liên tiếp trúng hai gói thầu trước đó nên bà Nga lựa chọn Công ty Bình An tham gia thầu, nhằm tránh sự chú ý của dư luận và cơ quan chức năng.
        <br/><br/>
        Tháng 8-2019, Sở Y tế và Công ty Bình Anh ký hợp đồng kinh tế thực hiện gói thầu số 10 với giá 12,2 tỉ đồng, đến tháng 10-2019 thì nghiệm thu. Kết quả định giá xác định danh mục các trang thiết bị của gói thầu này chỉ có giá 5,1 tỉ đồng, tức thiệt hại 7,1 tỉ đồng.
        <br/><br/>
        Đến tháng 10-2019, Sở Y tế và Công ty Bình Anh tiếp tục ký hợp đồng kinh tế thực hiện gói thầu số 05 với giá gần 13,9 tỉ đồng, đến tháng 12-2019 thì nghiệm thu. Kết quả định giá xác định danh mục các trang thiết bị của gói thầu này chỉ có giá hơn 8,8 tỉ đồng, tức thiệt hại hơn 5 tỉ đồng.
        </p>`,
        categoryID : 2
    },
    {
        id: 10,
        title : `ICAR Việt Nam tổ chức kỷ niệm 5 năm thành lập tại Phú Quốc`,
        path:"icar-viet-nam-to-chuc-ky-niem-5-nam-thanh-lap-tai-phu-quoc",
        title_content : `Sự kiện lớn nhất trong ngành nội thất ô tô. ICAR Việt Nam tổ chức kỷ niệm 5 năm thành lập tại Vinpearl Phú quốc với sự góp mặt của hơn 200 CBNV và các đại lý thân thiết.`,
        img : require("../images/news/events/image2-1667358253-205-width2000height2000.jpg"),
        description : `<p>
        <span style="font-size: 2rem;">Sự kiện lớn nhất trong ngành nội thất ô tô. ICAR Việt Nam tổ chức kỷ niệm 5 năm thành lập tại Vinpearl Phú quốc với sự góp mặt của hơn 200 CBNV và các đại lý thân thiết.</span>
        <br/><br/>
        <img src=${imgnew26} alt="">
        <br/><br/>
        ICAR Việt Nam tổ chức kỷ niệm 5 năm thành lập tại Vinpearl Phú Quốc. Trong 5 năm thành lập, ICAR đã ghi dấu bằng những bước đột phá đầy ấn tượng và đã tạo ra một luồng gió mới trong ngành nội thất ô tô. Những sản phẩm của ICAR luôn được khách hàng đánh giá cao, đặc biệt là những tính năng hoàn toàn mới, đáp ứng xu hướng hiện đại.
        <br/><br/>
        Thành công của ICAR Việt Nam luôn gắn liền với lực lượng R&D, ngay từ những ngày đầu thành lập tầm nhìn của ICAR Việt Nam được ban lãnh đạo định hình rất rõ ràng, đó chính là việc làm chủ sản phẩm và phát triển sản phẩm phù hợp với nhu cầu của khách hàng. Đến nay, trung tâm R&D của ICAR Việt Nam đã quy tụ nhiều tài năng trẻ, là động lực và sức mạnh cho những thành công tiếp theo của kế hoạch 5 năm lần thứ hai.
        <br/><br/>
        <span style="font-size: 2rem;">Những dấu ấn của ICAR trong 5 năm đầu tiên</span>
        <br/><br/>
        ICAR Việt Nam đã phát triển từ một công ty nhỏ đến nay số lượng nhân sự công ty đã lên đến 200 CBNV. Đến nay ICAR Việt Nam có hơn 1000 đại lý phân phối chính hãng trên toàn quốc trải dài khắp 63 tỉnh thành. ICAR Việt Nam đã xây dựng các trung tâm bảo hành và chăm sóc khách hàng chính hãng tại Hà Nội, HCM, Đà Nẵng, Vinh. Và tiếp đến sẽ xây dựng thêm nhiều trung tâm bảo hành trên toàn quốc để phục vụ các khách hành nhanh chóng nhất.
        <br/><br/>
        ICAR Việt Nam là đơn vị có chế độ bảo hành dài lâu nhất và tốt nhất trên thị trường xe hơi. Với dòng sản phẩm android box có thời gian bảo hành lên đến 6 năm, gấp 2,5 lần so với thị trường. Màn hình DVD Android ô tô có thời gian bảo hành lên đến 5 năm, cảm biến áp suất lốp đa số 3 năm và camera 360 là 2 năm. Cùng với đó là chính sách hỗ trợ người dùng tối đa, mang đến những trải nghiệm tốt nhất như lỗi 1 đổi 1 trong vòng 1 năm và dùng thử 30 ngày.
        <br/><br/>
        Sau 5 năm phát triển, ICAR Việt Nam đã là cái tên quen thuộc đối với khách hàng Việt. Luôn được sự tin tưởng của tất cả các nhà phân phối và khách hàng trên toàn quốc.
        <br/><br/>
        Quay lại sự kiện kỷ niệm 5 năm thành lập và làm hội nghị khách hàng tại Phú Quốc. Với lịch trình 3 ngày 2 đêm từ ngày 3/11/2022 đến ngày 5/11/2022 đây được coi là sự kiện lớn nhất của ICAR.
        <br/><br/>
        Nhân sự kiện kỷ niệm 5 năm này, tối ngày 4/11/2022 - Gala night, ICAR Việt Nam cũng giới thiệu với các quý khách hàng, đại lý một sản phẩm mới. Hứa hẹn sẽ mang đến sự bùng nổ trong ngành nội thất ô tô.
        <br/><br/>
        <img src=${imgnew27} alt="">
        <br/><br/>
        Sự kiện Gala night tối ngày 4/11/2022 kỷ niệm 5 năm thành lập và ra mắt sản phẩm mới được livestream trực tiếp trên fanpage của ICAR Việt Nam. Quý vị và bạn đọc có thể theo dõi tại:  <a style="color: black; font-weight: 700;text-decoration: none;" href="https://www.facebook.com/icarvietnamvn">https://www.facebook.com/icarvietnamvn</a>
        <br/><br/>
        <img src=${imgnew28} alt="">
        <br/><br/>
        <span style="font-size: 2rem;">Review về đảo Phú Quốc - điểm đến của ICAR Việt Nam</span>
        <br/><br/>
        Đảo Phú Quốc từ lâu đã nổi tiếng là hòn đảo xinh đẹp với phong cảnh thiên nhiên yên bình, hoang sơ, khí hậu thuận lợi cho hoạt động vui chơi, nghỉ dưỡng, Phú Quốc còn là điểm đến lý tưởng cho nhiều hoạt động khám phá thiên nhiên kỳ thú. Đảo Phú Quốc cũng là một trong những điểm du lịch nổi tiếng thu hút nhiều du khách quốc tế và trong nước nhất tại Việt Nam.
        <br/><br/>
        <img src=${imgnew29} alt="">
        <br/><br/>
        Có thể thấy, đây được coi là sự kiện lớn nhất trong ngành nội thất ô tô. Với sự đầu tư lớn từ ICAR Việt Nam chắc chắn sẽ thu hút được rất nhiều sự quan tâm của giới nội thất. Và đặc biệt, giới xe hơi đang rất ngóng chờ sản phẩm mới của ICAR Việt Nam sắp được ra mắt trên thị trường ô tô.
        <br/><br/>
        Sự kiện kỷ niệm 5 năm thành lập - hội nghị khách hàng - ra mắt sản phẩm mới của ICAR Việt Nam sẽ được tổ chức trong 3 ngày từ 3/11/2022 đến 5/11/2022 tại Vinpearl Phú Quốc. Hãy cùng theo dõi sự kiện chi tiết trên fanpage ICAR Việt Nam nhé.
        </p>`,
        categoryID : 2
    },    
    {
        id: 11,
        title : `Tân Phó cục trưởng Cục Cảnh sát phòng chống tội phạm về ma túy là ai?`,
        path:"tan-pho-cuc-truong-cuc-canh-sat-phong-chong-toi-pham-ve-ma-tuy-la-ai",
        title_content : `Ngày 2/11, tại Hà Nội, Cục Cảnh sát điều tra tội phạm về ma túy (C04) tổ chức lễ công bố quyết định của Bộ trưởng Bộ Công an về công tác cán bộ. Buổi lễ do Thiếu tướng Nguyễn Văn Viện, Cục trưởng C04 chủ trì.`,
        img : require("../images/news/events/1667402850-a44b9c0efb1ced4470623d85ee080f5b-width700height393.jpg"),
        description : `<p>
        Ngày 2/11, tại Hà Nội, Cục Cảnh sát điều tra tội phạm về ma túy (C04) tổ chức lễ công bố quyết định của Bộ trưởng Bộ Công an về công tác cán bộ. Buổi lễ do Thiếu tướng Nguyễn Văn Viện, Cục trưởng C04 chủ trì.
        <br/><br/>
        Tại buổi lễ, thừa ủy quyền của lãnh đạo Bộ Công an, Thiếu tướng Nguyễn Văn Viện đã trao quyết định của bộ trưởng Bộ Công an về việc điều động Đại tá Hoàng Quốc Việt giữ chức vụ Phó Cục trưởng C04 từ ngày 1/11.
        <br/><br/>
        <img src=${imgnew30} alt="">
        <br/><br/>
        Đại tá Hoàng Quốc Việt tại buổi lễ
        <br/><br/>
        Phát biểu nhận nhiệm vụ, Đại tá Hoàng Quốc Việt cho biết, đây là niềm vinh dự nhưng cũng là trọng trách lớn. Đại tá Việt hứa sẽ cùng tập thể Cục C04 đoàn kết, gắn bó, hoàn thành xuất sắc nhiệm vụ được giao.
        <br/><br/>
        Hiện Cục Cảnh sát điều tra tội phạm về ma túy có 1 Cục trưởng và 7 Phó cục trưởng.
        <br/><br/>
        Được biết, Đại tá Hoàng Quốc Việt sinh năm 1975 ở Nam Định. Ông Việt từng trải qua nhiều vị trí công tác khác nhau.
        <br/><br/>
        Đại tá Việt có nhiều năm làm cán bộ điều tra tại Công an Sơn La, Phó trưởng Công an huyện Mộc Châu, lãnh đạo một số phòng rồi đến Phó giám đốc Công an tỉnh Sơn La.
        <br/><br/>
        Ông Việt từng trực tiếp chỉ đạo, tham gia đấu tranh phá thành công nhiều chuyên án lớn về ma túy, hình sự trên địa bàn tỉnh Sơn La.
        <br/><br/>
        Nguồn:<a style="color: black;text-decoration: none;" target="_blank" href="https://www.baogiaothong.vn/tan-pho-cuc-truong-cuc-canh-sat-phong-chong-toi-pham-ve-ma-tuy-la-ai..">https://www.baogiaothong.vn/tan-pho-cuc-truong-cuc-canh-sat-phong-chong-toi-pham-ve-ma-tuy-la-ai...</a>
        </p>`,
        categoryID : 2
    },
    {
        id: 12,
        title : `Giá cổ phiếu Thaiholdings rơi hơn 85% từ đầu năm`,
        path:"gia-co-phieu-thaiholdings-roi-hon-85-tu-dau-nam",
        title_content : `Thị giá cổ phiếu THD của Thaiholdings trên thị trường chứng khoán đang rơi theo đà giảm của lợi nhuận, hiện đã xuống vùng thấp nhất kể từ năm 2020.`,
        img : require("../images/news/events/thaiholdings_tower.jpeg"),
        description : `<p>
        Thị giá cổ phiếu THD của Thaiholdings trên thị trường chứng khoán đang rơi theo đà giảm của lợi nhuận, hiện đã xuống vùng thấp nhất kể từ năm 2020.
        <br/><br/>
        Thị giá cổ phiếu THD đã giảm hơn 85% từ đầu năm. Ảnh: THD.
        <br/><br/>
        <img src=${imgnew31} alt="">
        <br/><br/>
        Công ty CP Thaiholdings (THD) vừa công bố báo cáo tài chính quý III với kết quả doanh thu và lợi nhuận giảm mạnh so với cùng kỳ năm 2021.
        <br/><br/>
        Cụ thể, tính trong ba tháng gần nhất, doanh nghiệp này ghi nhận gần766 tỷ đồngdoanh thu thuần, thấp hơn 74% so với cùng kỳ năm trước. Đây là nguyên nhân chính khiến lãi gộp công ty thu về chỉ tương đương gần 1/5 so với cùng kỳ, đạt hơn32 tỷ đồng.
        <br/><br/>
        Sau khi hợp nhất doanh thu với hoạt động tài chính cùng một số hoạt động khác và trừ các chi phí phát sinh, Thaiholdings thu về khoản lãi trước thuế62,5 tỷ đồng, cũng thấp hơn 43% so với năm 2021. Lợi nhuận sau khi trừ thuế đạt hơn39 tỷ đồng, nhưng cũng chỉ tương đương 1.2 so với quý III/2021.
        <br/><br/>
        Đáng chú ý, đây đã là quý suy giảm lợi nhuận thứ ba liên tiếp của Thaiholdings, đồng thời là mức thấp nhất mà doanh nghiệp này ghi nhận được kể từ giữa năm 2020 đến nay.
        <br/><br/>
        Thaiholdings cho biết nguyên nhân khiến lợi nhuận hợp nhất giảm mạnh trong quý vừa qua là do mức nền so sánh trong quý III/2021 ở mức cao. Bên cạnh đó, năm nay công ty cũng không còn phát sinh hoạt động chuyển nhượng dự án nên lợi nhuận khác của công ty đã giảm mạnh so với cùng kỳ.
        <br/><br/>
        Trong quý III năm trước, khoản lợi nhuận khác mang về cho Thaiholdings tới hơn180 tỷ đồng, trong khi quý III năm nay chỉ là gần 5,4 tỷ.
        <br/><br/>
        Tính chung 9 tháng từ đầu năm, doanh nghiệp có trụ sở tại số 210 Trần Quang Khải, quận Hoàn Kiếm, Hà Nội đã ghi nhận3.424 tỷ đồngdoanh thu thuần và báo lãi sau thuế 256 tỷ, giảm lần lượt 43% và 46% so với năm 2021.
        <br/><br/>
        Trên bảng cân đối kế toán, tính đến cuối tháng 9 năm nay, tổng tài sản của Thaiholdings vào khoảng8.500 tỷ đồng, giảm gần 20% so với cuối năm 2021. Nguyên nhân chủ yếu do phần tài sản cố định hữu hình đã giảm mạnh từ mức hơn1.000 tỷ đồngđầu năm xuống còn25 tỷ đồnghiện tại (do không còn hợp nhất báo cáo kết quả kinh doanh của một số công ty con).
        <br/><br/>
        Ngoài ra, phần tài sản dài hạn khác của Thaiholdings cũng giảm từ hơn5.200 tỷ đồngxuống 3.100 tỷ do không còn hạch toán gần1.960 tỷ đồngphần chi phí nhận chuyển nhượng quyền sử dụng đất tại Công ty Tôn Đản (chủ sở hữu tòa nhà Thaiholdings Tower).
        <br/><br/>
        Thị giá cổ phiếu THD trượt dài theo đà suy giảm lợi nhuận của công ty. Nguồn: Tradingview.
        <br/><br/>
        <img src=${imgnew32} alt="">
        <br/><br/>
        Đáng chú ý, đi cùng đà suy giảm lợi nhuận kể trên, thị giá cổ phiếu THD cũng đang trượt dài trên thị trường chứng khoán. Từ vùng giá trên 260.000 đồng/cổ phiếu hồi đầu năm, hiện THD chỉ còn được giao dịch với giá 38.500 đồng/đơn vị (cuối ngày 31/10). Như vậy, thị giá cổ phiếu này đã giảm ròng hơn 85% từ đầu năm.
        <br/><br/>
        Xét trên biểu đồ hàng ngày, thị giá THD cũng có diễn biến cùng chiều với lợi nhuận Thaiholdings thu về hàng quý khi đều đạt đỉnh vào quý IV/2021 và giảm mạnh từ đó đến nay. Hiện thị giá cổ phiếu này cũng đã giảm về mức thấp nhất 2 năm, trùng với đà rơi của lợi nhuận.
        <br/><br/>
        Thaiholdings hiện cũng ghi nhận một khoản bất động sản đầu tư trị giá gần105 tỷ đồng, là phần nguyên giá của 68 lô đất tại Khu đô thị Xuân Thành, phường Ninh Khánh, TP Ninh Bình được công ty nắm giữ với mục đích chờ tăng giá để bán. Trong đó, 63 lô đất này đang được dùng để thế chấp, cầm cố đảm bảo cho các khoản vay của công ty.
        <br/><br/>
        Thaiholdings cho biết giá trị hợp lý của các bất động sản này chưa được đánh giá và xác định một cách thức tại ngày 30/9. Tuy nhiên, dựa trên tình hình cho thuê và giá thị trường, ban lãnh đạo tin rằng giá trị hợp lý của các bất động sản này lớn hơn giá trị ghi nhận trên sổ.
        <br/><br/>
        Nguồn: Zingnews.vn
        </p>`,
        categoryID : 2
    },
    {
        id: 13,
        title : `Chủ tịch nước: Việt Nam coi khoa học công nghệ là quốc sách hàng đầu`,
        path:"chu-tich-nuoc-viet-nam-coi-khoa-hoc-cong-nghe-la-quoc-sach-hang-dau",
        title_content : `Việt Nam coi khoa học công nghệ là quốc sách hàng đầu, động lực then chốt cho phát triển công nghiệp hiện đại, tăng trưởng cao, bền vững, Chủ tịch nước khẳng định.`,
        img : require("../images/news/events/-6758-1663337799.jpg"),
        description : `<p>
        Việt Nam coi khoa học công nghệ là quốc sách hàng đầu, động lực then chốt cho phát triển công nghiệp hiện đại, tăng trưởng cao, bền vững, Chủ tịch nước khẳng định.
        <br/><br/>
        Chiều 16/9, Chủ tịch nướcNguyễn Xuân Phúctiếp một số nhà khoa học đoạt giải Nobel dự Hội nghị khoa học quốc tế tại tỉnh Bình Định. Cùng tham dự có Bộ trưởng Khoa học và Công nghệ Huỳnh Thành Đạt; lãnh đạo bộ ngành và tỉnh Bình Định.
        <br/><br/>
        Chủ tịch nước đánh giá cao sự phối hợp tổ chức thành công hội thảoKhoa học, đạo đức và phát triển con ngườicủa Chương trìnhGặp gỡ Việt Namvới Liên minh Nghị viện thế giới (IPU), Bộ Khoa học và Công nghệ, tỉnh Bình Định, Viện nghiên cứu phát triển Pháp (IRD) tại Việt Nam. Đây là sự kiện đặc biệt nằm trong chuỗi hoạt động của Bộ Khoa học và Công nghệ, hưởng ứng năm Quốc tế khoa học cơ bản vì phát triển bền vững do Liên Hợp Quốc thông qua tháng 12/2021 và UNESCO công bố tháng 7/2022.
        <br/><br/>
        Lắng nghe phát biểu của GS Trần Thanh Vân và 4 giáo sư nước ngoài, Chủ tịch nước yêu cầu Bộ Khoa học và Công nghệ tiếp thu, để tham khảo cho việc xây dựng chính sách, điều hành.
        <br/><br/>
        <img src=${imgnew33} alt="">
        <br/><br/>
        Chủ tịch nước Nguyễn Xuân Phúc tiếp các nhà khoa học đoạt giải Nobel, chiều 16/9. Ảnh:Hoàng Phong
        <br/><br/>
        Lãnh đạo Nhà nước khẳng định Việt Nam chú trọng phát triển con người toàn diện, phát huy sức sáng tạo to lớn của người dân, bảo đảm an ninh xã hội, chăm lo sức khỏe, sự an toàn và môi trường sống, không để ai bị bỏ lại phía sau. Việt Nam cũng tập trung xây dựng hệ giá trị văn hóa và chuẩn mực con người văn hóa mới trong cách mạng công nghiệp 4.0.
        <br/><br/>
        "Chúng tôi luôn ý thức về trách nhiệm đạo đức trong mỗi quyết sách, phải thực sự vì phát triển bền vững, vì lợi ích của người dân khi ứng dụng khoa học công nghệ hay phân bổ các nguồn lực, tài nguyên; không vì tăng trưởng cao mà hạ thấp lợi ích người dân, phá hủy môi trường", Chủ tịch nước chia sẻ.
        <br/><br/>
        Việt Nam phấn đấu năm 2045 là nước phát triển và luôn nỗ lực, vững tin vào con đường này. Chủ tịch nước bày tỏ mong muốn nhận được sự hỗ trợ, hợp tác của cộng đồng quốc tế, đặc biệt là các nhà khoa học.
        <br/><br/>
        GS Michel Spiro, Chủ tịch Hội đồng Năm quốc tế khoa học cơ bản cho phát triển bền vững, Chủ tịch Liên Hiệp các Hội Vật lý quốc tế, nhấn mạnh khoa học cơ bản là "những hạt giống, mầm mống phát triển cho tương lai không chỉ 10-20 mà 50 năm nữa".
        <br/><br/>
        Việc giáo dục không chỉ gói gọn trong khoa học công nghệ mà còn trong các lĩnh vực, ngành nghề khác hướng tới phát triển bền vững nói chung. Phát triển bền vững là quá trình nhiều thách thức, mục tiêu để xây dựng thế giới tốt đẹp hơn, cải thiện chất lượng cuộc sống, đạo đức cho người dân... GS Michel Spiro cho rằng Việt Nam đang đứng trước bước ngoặt quan trọng, có thể trở thành hình mẫu trong phát triển bền vững.
        <br/><br/>
        <img src=${imgnew34} alt="">
        <br/><br/>
        Chủ tịch nước Nguyễn Xuân Phúc, Bộ trưởng Khoa học và Công nghệ Huỳnh Thành Đạt chụp ảnh cùng các nhà khoa học, chiều 16/9. Ảnh:Hoàng Phong
        <br/><br/>
        GS Munasinghe Mohan, nguyên Phó chủ tịch Ủy ban Liên chính phủ (Tổ chức được trao giải Nobel Hòa bình 2007) đến từ Srilanka cho biết ông là người bạn lâu năm và rất ngưỡng mộ Việt Nam. "Việt Nam là điểm đến ưa thích của tôi và vợ. Chúng tôi đến cách đây 20 năm, cảm phục sự đón tiếp ấm áp, hiếu khách cũng như nể phục bản lĩnh kiên cường của người Việt Nam", ông chia sẻ.
        <br/><br/>
        Ông đánh giá Việt Nam có đầy đủ điều kiện tài nguyên, môi trường, con người để trở thành hình mẫu, tiên phong trong phát triển bền vững. "Tôi tin thế kỷ 21 thuộc về châu Á, khi trọng tâm kinh tế thế giới cũng đang chuyển dịch dần về khu vực châu Á", ông nói và khẳng định các nhà khoa học sẵn sàng đồng hành với lãnh đạo Việt Nam hướng tới tăng trưởng xanh, bền vững.
        <br/><br/>
        Nguồn: vnexpress.net
        </p>`,
        categoryID : 2
    },
    {
        id: 14,
        title : `Sau lãnh đạo Sở, đến lượt Phó Giám đốc Trung tâm y tế huyện xin nghỉ việc`,
        path:"sau-lanh-dao-so-den-luot-pho-giam-doc-trung-tam-y-te-huyen-xin-nghi-viec",
        title_content : `Ngày 30/10, bác sĩ Hà Ngọc Hải, Phó Giám đốc phụ trách Trung tâm Y tế (TTYT) huyện Đak Pơ cho biết, ông đã có đơn gửi Sở Y tế tỉnh Gia Lai xin nghỉ việc. Bác sĩ Hải sinh năm 1963, còn thời gian công tác đến hết năm 2024. Lý do nêu trong đơn của bác sĩ Hải là áp lực công việc và sức khỏe không đảm bảo.`,
        img : require("../images/news/events/1667117255-216fe46ac86f49235d554097958c707e-width645height428.jpg"),
        description : `<p>
        Ngày 30/10, bác sĩ Hà Ngọc Hải, Phó Giám đốc phụ trách Trung tâm Y tế (TTYT) huyện Đak Pơ cho biết, ông đã có đơn gửi Sở Y tế tỉnh Gia Lai xin nghỉ việc. Bác sĩ Hải sinh năm 1963, còn thời gian công tác đến hết năm 2024. Lý do nêu trong đơn của bác sĩ Hải là áp lực công việc và sức khỏe không đảm bảo.
        <br/><br/>
        <img src = ${imgnew35} alt="">
        <br/><br/>
        Phó Giám đốc phụ trách Trung tâm Y tế huyện Đak Pơ xin nghỉ việc
        <br/><br/>
        Trước đó trong 5 năm liền, bác sĩ Hà Ngọc Hải được xếp loại hoàn thành xuất sắc nhiệm vụ.
        <br/><br/>
        Năm 2013, khi đang là Phó Giám đốc TTYT huyện Đức Cơ, bác sĩ Hải được điều động về đảm nhận công tác tại huyện Đak Pơ. Đến năm 2020, ông được Sở Y tế phân công giữ chức Phó Giám đốc phụ trách TTYT huyện Đak Pơ.
        <br/><br/>
        Tại Gia Lai, trước đó vài ngày, ông Nguyễn Đình Tuấn (SN 1963), Phó Giám đốc phụ trách Sở Y tế Gia Lai cũng có đơn gửi cấp thẩm quyền xin nghỉ hưu dù còn 2 năm công tác.
        <br/><br/>
        Lý do xin nghỉ vì bác sĩ Tuấn sức khỏe không còn đảm bảo; muốn dừng công tác để chăm sóc mẹ già.
        <br/><br/>
        Nguồn: <a href="https://tienphong.vn/sau-lanh-dao-so-den-luot-pho-giam-doc-trung-tam-y-te-huyen-xin-nghi-viec-post1482286.tpo" style="color :black;text-decoration: none;" target="_blank"> https://tienphong.vn/sau-lanh-dao-so-den-luot-pho-giam-doc-trung-tam-y-te-huyen-xin-nghi-viec-post1482286.tpo</a>
        </p>`,
        categoryID : 2
    },
    {
        id: 15,
        title : `Quốc hội phê chuẩn bổ nhiệm Bộ trưởng Bộ Y tế, Bộ GTVT`,
        path:"quoc-hoi-phe-chuan-bo-nhiem-bo-truong-bo-y-te-bo-gtvt",
        title_content : `Ngoài phê chuẩn bổ nhiệm Bộ trưởng Bộ Y tế và Bộ trưởng Bộ Giao thông vận tải, Quốc hội sẽ bầu Tổng Kiểm toán Nhà nước.`,
        img : require("../images/news/events/1666318039-lan-16663142184791198203492-width684height484.jpeg"),
        description : `    <p>
        <span style="font-size : 2rem">Ngoài phê chuẩn bổ nhiệm Bộ trưởng Bộ Y tế và Bộ trưởng Bộ Giao thông vận tải, Quốc hội sẽ bầu Tổng Kiểm toán Nhà nước.</span>
        <br/><br/>Ngày 21-10, tiếp tục chương trình kỳ họp thứ 4 Quốc hội khoá XV, Quốc hội sẽ tiến hành những bước cuối cùng trong quy trình miễn nhiệm Tổng Kiểm toán nhà nước (KTNN) nhiệm kỳ 2021 - 2026 đối với ông Trần Sỹ Thanh; phê chuẩn đề nghị miễn nhiệm Bộ trưởng Bộ Giao thông vận tải (GTVT) nhiệm kỳ 2021 - 2026 đối với ông Nguyễn Văn Thể.
        <br/><br/>
        Quốc hội sẽ bỏ phiếu kín và biểu quyết thông qua Nghị quyết miễn nhiệm Tổng KTNN nhiệm kỳ 2021-2026; biểu quyết thông qua Nghị quyết phê chuẩn việc miễn nhiệm Bộ trưởng Bộ GTVT nhiệm kỳ 2021-2026.
        <br/><br/>
        Tại cuộc họp báo thông tin về chương trình kỳ họp thứ 4, Tổng Thư ký, Chủ nhiệm Văn phòng Quốc hội Bùi Văn Cường cho biết việc trình miễn nhiệm Bộ trưởng Bộ GTVT là theo nguyện vọng cá nhân và theo sự phân công, sắp xếp, bố trí của cơ quan có thẩm quyền về công tác nhân sự.
        <br/><br/>
        <img src=${imgnew36} alt="">
        <br/><br/>
        Bà Đào Hồng Lan hiện giữ chức Quyền Bộ trưởng Bộ Y tế
        <br/><br/>
        Cũng liên quan đến công tác nhân sự, trong ngày 21-10, Ủy ban Thường vụ Quốc hội trình Quốc hội về dự kiến nhân sự để Quốc hội bầu Tổng KTNN nhiệm kỳ 2021 - 2026. Thủ tướng Chính phủ trình Quốc hội về việc đề nghị phê chuẩn bổ nhiệm nhân sự Bộ trưởng Bộ Y tế và Bộ trưởng Bộ GTVT nhiệm kỳ 2021 - 2026. Sau đó, các đại biểu Quốc hội sẽ thảo luận tại đoàn về nội dung này.
        <br/><br/>
        Trong chiều 21-10, Quốc hội họp riêng về công tác nhân sự, trong đó sẽ báo cáo kết quả thảo luận tại đoàn. Sau đó, Quốc hội bầu Tổng KTNN, phê chuẩn bổ nhiệm nhân sự Bộ trưởng Bộ Y tế và Bộ trưởng Bộ GTVT nhiệm kỳ 2021 - 2026 bằng hình thức bỏ phiếu kín. Từ kết quả kiểm phiếu, Quốc hội sẽ biểu quyết thông qua các Nghị quyết về nhân sự.
        <br/><br/>
        <img src=${imgnew37} alt="">
        <br/><br/>
        Ông Ngô Văn Tuấn hiện là Phó Tổng KTNN phụ trách KTNN
        <br/><br/>
        Đối với Bộ Y tế, hiện bà Đào Hồng Lan đang giữ chức Quyền Bộ trưởng. Cụ thể vào ngày 15-7, Thủ tướng Phạm Minh Chính đã trao quyết định của Bộ Chính trị điều động bà Đào Hồng Lan thôi giữ chức Bí thư Tỉnh ủy Bắc Ninh để giữ chức Bí thư Ban cán sự đảng Bộ Y tế; giao quyền Bộ trưởng Y tế đối với bà Đào Hồng Lan, Ủy viên Trung ương Đảng, Bí thư Ban cán sự đảng Bộ Y tế.
        <br/><br/>
        Về phía KTNN, cuối tháng 7-2022, Bộ Chính trị quyết định điều động, phân công ông Ngô Văn Tuấn, Ủy viên Trung ương Đảng, thôi tham gia Ban chấp hành, Ban Thường vụ và thôi giữ chức Bí thư Tỉnh ủy Hòa Bình nhiệm kỳ 2020 - 2025, để chỉ định giữ chức Bí thư Ban cán sự Đảng, Phó tổng KTNN phụ trách KTNN.
        <br/><br/>
        Nguồn: <a hred="https://nld.com.vn/thoi-su/quoc-hoi-phe-chuan-bo-nhiem-bo-truong-bo-y-te-bo-gtvt-20221020152215496.htm" alt="" style="color:black;text-decoration: none" target="_blank">https://nld.com.vn/thoi-su/quoc-hoi-phe-chuan-bo-nhiem-bo-truong-bo-y-te-bo-gtvt-20221020152215496.htm</a>
        </p>`,
        categoryID : 2
    },
    {
        id: 16,
        title : `Tổng bí thư đến Bắc Kinh, bắt đầu thăm chính thức Trung Quốc`,
        path:"tong-bi-thu-den-bac-kinh-bat-dau-tham-chinh-thuc-trung-quoc",
        title_content : `Tổng bí thư Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Việt Nam đã đến thủ đô Bắc Kinh vào lúc 13h20 ngày 30/10 (giờ địa phương), bắt đầu chuyến thăm chính thức Trung Quốc.`,
        img : require("../images/news/events/vna_potal_tong_bi_thu_nguyen_phu_trong_den_bac_kinh_bat_dau_chuyen_tham_chinh_thuc_trung_quoc_6411863_2.jpg"),
        description : `    <p>
        Tổng bí thư Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Việt Nam đã đến thủ đô Bắc Kinh vào lúc 13h20 ngày 30/10 (giờ địa phương), bắt đầu chuyến thăm chính thức Trung Quốc.
        <br/><br/>
        Ông Lưu Kiến Siêu (bên phải), Ủy viên Trung ương Đảng, Trưởng Ban Liên lạc Đối ngoại Trung ương Đảng Cộng sản Trung Quốc đón Tổng bí thư Nguyễn Phú Trọng tại sân bay Quốc tế Bắc Kinh. Ảnh: TTXVN.
        <br/><br/>
        <img src=${imgnew38} alt"">
        <br/><br/>
        Theo đặc phái viênTTXVN, vào lúc 13h20 (giờ địa phương - tức 12h20 giờ Hà Nội) ngày 30/10, Tổng bí thư Ban Chấp hành Trung ương Đảng Cộng sản Việt Nam Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Việt Nam đã đến thủ đô Bắc Kinh, bắt đầu chuyến thăm chính thức nước Cộng hòa Nhân dân Trung Hoa theo lời mời của Tổng bí thư Ban Chấp hành Trung ương Đảng Cộng sản Trung Quốc, Chủ tịch nước Cộng hòa Nhân dân Trung Hoa Tập Cận Bình.
        <br/><br/>
        Đón Tổng bí thư Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Việt Nam tại Sân bay Bắc Kinh có ông Lưu Kiến Siêu, Trưởng Ban Liên lạc Đối ngoại Trung ương Đảng Cộng sản Trung Quốc; Đại sứ Việt Nam tại Trung Quốc Phạm Sao Mai, cùng một số cán bộ Đại sứ quán tại Trung Quốc.
        <br/><br/>
        Nghi thức tặng hoa chào mừng tại lễ đón Tổng bí thư Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Đảng Cộng sản Việt Nam ở sân bay quốc tế Bắc Kinh. Ảnh: TTXVN.
        <br/><br/>
        <img src=${imgnew39} alt"">
        <br/><br/>
        Sân bay Bắc Kinh rực rỡ quốc kỳ Việt Nam và quốc kỳ Trung Quốc. Hai hàng tiêu binh dọc hai bên thảm đỏ. Đồng chí Lưu Kiến Siêu cùng các quan chức Trung Quốc nồng nhiệt chào đón Tổng bí thư Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Việt Nam.
        <br/><br/>
        Chuyến thăm chính thức Cộng hòa Nhân dân Trung Hoa của Tổng bí thư Nguyễn Phú Trọng nhằm đề cao đường lối đối ngoại, trong đó khẳng định chủ trương nhất quán của Việt Nam coi trọng quan hệ với Trung Quốc là ưu tiên hàng đầu trong chính sách đối ngoại, mong muốn phát triển lâu dài, ổn định, ngày càng hiệu quả, thực chất theo tinh thần nhận thức chung của lãnh đạo cấp cao hai đảng, hai nước; đồng thời làm rõ những quan tâm, lập trường đúng đắn, các lợi ích chính đáng của Việt Nam.
        <br/><br/>
        Cùng với đó, chuyến thăm cũng thể hiện sự ủng hộ đối với những tư tưởng và đường lối phát triển của Đại hội XX Đảng Cộng sản Trung Quốc có lợi cho hòa bình, hợp tác, phát triển; vị trí “hạt nhân lãnh đạo” của Tổng bí thư Tập Cận Bình được Đảng Cộng sản Trung Quốc xác lập; đồng thời thúc đẩy chính sách của Trung Quốc về hữu nghị, hợp tác với Việt Nam; ủng hộ Việt Nam phát triển, có vị thế quốc tế ngày càng cao, thực hiện thắng lợi Nghị quyết Đại hội XIII của Đảng..
        <br/><br/>
        Nguồn : zingnews.vn
        </p>`,
        categoryID : 2
    },
]