import { useState, useEffect } from 'react'
import logoImg from "../../images/Logo/LogoHeader.png"
// import "./header.scss"
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { listMenu } from '../../localData/menu';
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu } from 'react-icons/ai'
import { FaArrowAltCircleRight } from 'react-icons/fa';

function Header({ scrollValue }) {
    const [activeMenu, setActiveMenu] = useState("Trang chủ");
    const [activeSub, setActiveSub] = useState("");
    const navigate = useNavigate();
    const [activeMenuMobile, setActiveMenuMobile] = useState(false)

    useEffect(() => {
        const currentPath = window.location.pathname;
        for (const item of listMenu) {
            if (currentPath === `/${item.slug}`) {
                setActiveMenu(item.text);
                break;
            }
            if (item.sub) {
                for (const subItem of item.sub) {
                    if (currentPath === subItem.slug_lv1) {
                        setActiveMenu(item.text);
                        setActiveSub(subItem.textItem_lv1);
                        break;
                    }
                }
            }
        }
    }, []);

    useEffect(() => {
        document.title = `HSV - ${activeMenu}`;
    }, [activeMenu]);

    const handleActiveMenu = (menuItem, slug) => {
        setActiveMenu(menuItem)
        if (slug !== undefined) {
            navigate(`/${slug}`)
            setActiveMenuMobile(false)
        }
    }

    const handleActiveMenuSub = (menuItem, slug) => {
        setActiveSub(menuItem)
        navigate(`${slug}`)
        setActiveMenuMobile(false)
    }
    const handleLogoOnclick = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        navigate(`/`)
    }

    const handleActiveMobileOnclick = () => {
        setActiveMenuMobile(true)
    }

    return (
        <div className={scrollValue > 100 ? "header header-active" : "header"}>
            <div className="layout_container">
                <div className="row">
                    <div className="header__menu">
                        <div className="col-xs-12 col-xl-3">
                            <div className="header__logo">
                                <img src={logoImg} alt="logo" style={{ cursor: "pointer" }} onClick={() => handleLogoOnclick()}></img>
                            </div>
                        </div>
                        <div className='col-xs-12 col-xl-9'>
                            <div className="header__menu-top">
                                <ul className="header__menu-listtop header__menu-icon">
                                    <li className='header__menu-item header__menu-icon-mobile' onClick={() => handleActiveMobileOnclick()}><AiOutlineMenu /></li>
                                    <li className='header__menu-item' onClick={() => window.open("https://www.hsv.com.vn")}><BsTwitter /></li>
                                    <li className='header__menu-item' onClick={() => window.open("https://www.facebook.com/profile.php?id=100063723542675")}><FaFacebookF /></li>
                                    <li className='header__menu-item' onClick={() => window.open("https://www.linkedin.com/login?fromSignIn=true&trk=guest_homepage-basic_nav-header-signin")}><FaLinkedinIn /></li>
                                    <li className='header__menu-item header__menu-item-new' onClick={() => navigate(`/tuyen-dung`)}>Tuyển Dụng</li>
                                    <li className='header__menu-item header__menu-item-new' onClick={() => navigate(`/tin-tuc`)}>Tin Tức</li>
                                </ul>

                                <ul className={activeMenuMobile ? "header__menu-list header__menu-text  header__menu-text-active" : "header__menu-list header__menu-text"}>
                                    {listMenu.map((value, index) => (
                                        <li className='header__menu-item' key={index} onClick={() => handleActiveMenu(value.text, value.slug)} >
                                            <p className={activeMenu === value.text
                                                ? "header__menu-active"
                                                : ""}>{value.text}</p>
                                            <span style={{ width: `${activeMenu === value.text ? "90%" : ""}` }}></span>
                                            <ul className={value.sub ? "header__menu-list-c2" : "none"}>
                                                {value.sub
                                                    ? value.sub.map((valueSub, index) => (
                                                        <li className='header__menu-item' onClick={() => handleActiveMenuSub(valueSub.textItem_lv1, valueSub.slug_lv1)} key={index}>
                                                            <p className={activeSub === valueSub.textItem_lv1 ? "header__menu-active" : ""}>{valueSub.textItem_lv1}</p>
                                                        </li>
                                                    ))
                                                    : ""
                                                }
                                            </ul>
                                        </li>
                                    ))}
                                    <li onClick={() => setActiveMenuMobile(false)} className='header__menu-item header__menu-item-rightIcon'><FaArrowAltCircleRight /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header