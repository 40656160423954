import React from 'react'

function IntroduceFoundationalValue() {
    return (
        <div className='IntroduceFoundationalValue'>
            <p style={{color:'black',fontWeight:'600',marginBottom:"20px"}}>HSV Xác định xây dựng một doanh nghiệp văn hóa vững mạnh , một môi trường lý tưởng cho nhân viên, là một
                hành trình chứ không phải một đích đến
            </p>
            <p style={{color:'black',fontWeight:'600',marginBottom:"20px"}}>Văn hóa công ty</p>
            <p>
                Văn hoá là gốc rễ của sự phát triển, tại HSV xây dựng một nền văn hóa riêng: lấy hành động làm động lực, lấy thực tiễn làm mục tiêu.
                <br></br>
                Đầu tư và phát triển con người: HSV đã đầu tư vào việc đào tạo kỹ năng, kiến thức, công nghệ mới cho CBNV, xây dựng qũy đào tạo cho nhân viên kỹ thuật được tham dự những khóa đào tạo công nghệ ở nước ngoài, quy hoạch đội ngũ quản lý một cách quy mô và bài bản.
                <br></br>
                Đề cao sáng tạo: HSV luôn coi trọng và tiếp nhận từ mọi thành viên công ty những ý tưởng sáng tạo, những đề xuất cải tiến công việc. HSV, đừng ngại ngần khi đưa ra những ý tưởng của bản thân.
                <br></br>
                Lắng nghe và chọn lọc: là phương châm của Ban Giám đốc công ty. Lấy thực tiễn làm tiêu chuẩn để kiểm nghiệm và đánh giá kết quả công việc của mỗi người như khẩu hiệu hành động của công ty: Kết quả mô tả giá trị.
                <br></br>
                Môi trường làm việc
                <br></br>
                HSV luôn tạo điều kiện để mỗi cán bộ nhân viên công ty phát huy tối đa năng lực, sáng tạo và sự gắn bó với công ty. Chính vì vậy, ngoài việc xây dựng môi trường làm việc thân thiện, hòa đồng, hợp tác và phát triển bền vững chúng tôi cũng xây dựng những chính sách, chế độ nhiều ưu đãi dành cho CBNV như các chế độ bảo hiểm, phúc lợi, các trợ cấp, khen thưởng…
                <br></br>
                Ngoài ra, HSV luôn chú trọng đầu tư xây dựng cơ sở hạ tầng khang trang, hiện đại, giúp cán bộ nhân viên có được không gian làm việc thoải mái, nhiều tiện nghi

            </p>
        </div>
    )
}

export default IntroduceFoundationalValue