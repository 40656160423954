import React from 'react'
import { useParams } from 'react-router-dom'
import { leaders } from '../../localData/leaderShip'
import { useNavigate } from 'react-router-dom';

function LeaderMember() {
    const navigate = useNavigate();

    let param = useParams()

    return (
        <div className='member'>
            <ul className="memberlist row">
                {
                    leaders.map((value, index) => (
                        param.id == value.category ?
                            <div className='col-sm-6 col-md-3 col-lg-3'>
                                <li className='' key={index} onClick={() => navigate(`/leader/${value.category}/${value.slug}`)}>
                                    <div>
                                        <img src={value.img} alt="Ban lanh dao" />
                                    </div>
                                    <h4 style={{ marginBottom: "5px" }}>{value.education}</h4>
                                    <h4>{value.gender == 0 ? "Bà" : "Ông"}: {value.name}</h4>
                                    <ul>
                                        {
                                            value.position.map((value, index) => (
                                                <li key={index}>{value}</li>
                                            ))
                                        }
                                    </ul>
                                </li>
                            </div>
                            : ""
                    ))
                }
            </ul>
        </div>
    )
}

export default LeaderMember