import { Routes, Route } from "react-router-dom";
import Home from "../pages/home/home";
import Introduce from "../pages/introduce/introduce";
import IntroduceOverview from "../pages/introduce/introduceOverview";
import IntroduceStatement from "../pages/introduce/introduceStatement";
import IntroduceFoundationalValue from "../pages/introduce/introduceFoundationalValue"
import IntroducePolicies from "../pages/introduce/introducePolicies";
import IntroduceCulture from "../pages/introduce/introduceCulture";
import LeaderShip from "../pages/leaderShip/Leaders";
import LeaderMember from "../pages/leaderShip/LeaderMember";
import MemberContent from "../pages/leaderShip/MemberContent";
import "../pages/leaderShip/leaders.scss"
import Customers from "../pages/customers/Customers";
import TypicalProject from "../pages/typicalProject/TypicalProject";
import DetailTypicalProject from "../pages/typicalProject/DetailTypicalProject";
import News from "../pages/News/news";
import DetailNews from "../pages/News/DetailNews";
import ProductSolution from "../pages/productSolution/ProductSolution";
import DetailPSCategory from "../pages/productSolution/DetailPSCategory";
import DetailPSContent from "../pages/productSolution/DetailPSContent";
import Contact from "../pages/contact/contact";
import Recruitment from "../pages/recruitment/recruiment";
import RecruimentContent from "../pages/recruitment/recruimentContent";
import ListCustomer from "../pages/customers/ListCustomer";
import IntroduceVision from "../pages/introduce/introduceVision";
import IntroducrOriented from "../pages/introduce/introducrOriented";
import LeaderOrganize from "../pages/leaderShip/LeaderOrganize";
import ConsultingServices from "../pages/ConsultingServices/ConsultingServices";
import PageNotFound from "../components/notfound/PageNotFound";
function Router() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/gioi-thieu/" element={<Introduce />} >
        <Route path="/gioi-thieu/" element={<IntroduceOverview />} />
        <Route path="/gioi-thieu/thong-diep" element={<IntroduceStatement />} />
        <Route path="/gioi-thieu/logo-gia-tri-nen-tang" element={<IntroduceCulture />} />
        <Route path="/gioi-thieu/van-hoa-va-moi-truong-lam-viec" element={<IntroduceFoundationalValue />} />
        <Route path="/gioi-thieu/chinh-sach-nhan-su" element={<IntroducePolicies />} />
        <Route path="/gioi-thieu/tam-nhin-su-menh-gia-tri-cot-loi" element={<IntroduceVision />} />
        <Route path="/gioi-thieu/dinh-huong-phat-trien" element={<IntroducrOriented />} />
      </Route>
        <Route path="/gioi-thieu/ban-lanh-dao" element={<LeaderShip />}  >
        <Route path=":id" element={<LeaderMember />} />
        <Route path="4" element={<LeaderOrganize />} />
      </Route>
      <Route path="/leader/:categoriId/:slug" element={<MemberContent />} />
      <Route path="/khachhang-doitac/:slug" element={<Customers/>}/>
      <Route path="/khachhang-doitac/" element={<ListCustomer/>}/>
      
      <Route path="/du-an-tieu-bieu" element={<TypicalProject/>}/>
      <Route path="/du-an-tieu-bieu/:id" element={<DetailTypicalProject/>}/>

      <Route path="/tin-tuc/" element={<News />}  />
      <Route path="/tin-tuc/:slug" element={<DetailNews/>}/>

      <Route path="/sanpham-giaiphap/" element={<ProductSolution />}  />
      <Route path="/sanpham-giaiphap/:categoryID" element={<DetailPSCategory />}  />
      <Route path="/sanpham-giaiphap/:categoryID/:slug" element={<DetailPSContent />}  />

      <Route path="/dautu-dichvu/:categoryID" element={<ConsultingServices />}  />


      <Route path="/lien-he/" element={<Contact />}  />

      <Route path="/tuyen-dung/" element={<Recruitment />}  />
      <Route path="/tuyen-dung/:slug" element={<RecruimentContent />}  />

      <Route path="*" element={<PageNotFound/>} /> {/* Đường dẫn cuối cùng xử lý trang không tìm thấy */}
    </Routes>
    
  )
}

export default Router