const imgdn = require("../images/Typical_project/ppp-bvdkdn-tuvan.png");
const imgbd = require("../images/Typical_project/bvdk-binhdinh.png");
const imghn = require("../images/Typical_project/ppp-bvpsdg-tuvan.png");
const imgna = require("../images/Typical_project/duan-bvdkna.jpg");


export const typicalProjectMenu = [
    {
        id : 1,
        nameHospitol:"Bênh viện đa khoa Đồng Nai khối B",
        path:"benh-vien-da-khoa-quoc-te-cong-truong-123",
        img :require("../images/Typical_project/bvdk-dn.jpg"),
        introviews:`<p>
        <img src=${imgdn} alt=""><br/>
        COTECGROUP, Bệnh viện Đa khoa Đồng Nai và COTECLAND cùng nhau góp vốn thành lập “Công ty Cổ phần Bệnh viện Đa khoa Đồng Nai” để đầu tư Dự án “Bệnh viện Đa khoa Đồng Nai – giai đoạn 2” theo phương thức xã hội hóa với quy mô 700 giường bệnh cung cấp các dịch vụ y tế cao cấp theo tiêu chuẩn khách sạn 4-5 sao.
        <br/><br/>
        Công ty Cổ phần Bệnh viện Đa khoa Đồng Nai có vốn điều lệ 270 tỷ đồng, được góp vốn thành lập bởi 3 cổ đông:
        <br/><br/>
        Bệnh viện Đa khoa Đồng Nai góp 40% Vốn điều lệ bằng lợi thế dự án, giá trị thương hiệu và chi phí đầu tư cơ sở hạ tầng ban đầu;
        <br/><br/>
        Công ty Cổ phần Kỹ thuật Xây dựng và Vật liệu Xây dựng (COTECGROUP) góp 42% Vốn điều lệ bằng tiền mặt;
        <br/><br/>
        Công ty Cổ phần Đầu tư và Phát triển Nhà đất Cotec (COTECLAND) góp 18% Vốn điều lệ bằng tiền mặt.
        </p>`,
        roleHSV:`<p>
        <ul>
            <li style="list-style-type: decimal">Tư vấn Xây dựng, hoạt động và điều hành bệnh viện theo mô hình kết hợp PPP .</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống Phần mềm quản lý tổng thể bệnh viện.</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống máy chủ HPE Proliant</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai Hệ thống lưu trữ HPE SAN Storage, Hệ thống HPE Backup.</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống PACS kết nối hệ thống HIS</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống Networking bao gồm:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống cáp truyền dẫn trục chính</li>
                    <li style="list-style: none;">- Hệ thống Cisco Core Switch</li>
                    <li style="list-style: none;">- Chuyển mạch tầng Cisco Distribution Switch</li>
                    <li style="list-style: none;">- Chuyển mạh Cisco Access Layer</li>
                    <li style="list-style: none;">- Hệ thống Cisco Wifi</li>
                </ul>
        <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống điện nhẹ bao gồm:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống Camera,</li>
                    <li style="list-style: none;">- Hệ thống PA (Hệ thống âm thanh công cộng)</li>
                    <li style="list-style: none;">- Hệ thống thiết bị gọi Y tá</li>
                    <li style="list-style: none;">- Hệ thống xếp hàng thông minh</li>
                    <li style="list-style: none;">- Bãi giữ xe thông minh</li>
                </ul>
        <li style="list-style-type: decimal">Tư vấn và triên khai hệ thống Datacenter:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống sàn nâng</li>
                    <li style="list-style: none;">- Hệ thống chống sét lan truyền trên đường nguồn và tín hiệu</li>
                    <li style="list-style: none;">- Hệ thống khí khí FM 200</li>
                    <li style="list-style: none;">- Hệ thống Nguồn UPS</li>
                    <li style="list-style: none;">- Hệ thống điều hòa không khí</li>
                </ul>
        </ul>
        </p>`,
        des : `
        <p>
        COTECGROUP, Bệnh viện Đa khoa Đồng Nai và COTECLAND cùng nhau góp vốn thành lập “Công ty Cổ phần Bệnh viện Đa khoa Đồng Nai” để đầu tư Dự án “Bệnh viện Đa khoa Đồng Nai – giai đoạn 2” theo phương thức xã hội hóa với quy mô 700 giường bệnh cung cấp các dịch vụ y tế cao cấp theo tiêu chuẩn khách sạn 4-5 sao.
        <br/><br/>
        Địa Chỉ : 02 Đồng Khởi, Phường Tam Hòa, Thành Phố Biên Hòa, Tỉnh Đồng Nai
        <br/><br/>
        Đường Dây Nóng : 0967901717.
        </p>
        `
    },
    {
        id : 2,
        nameHospitol:"Bệnh Viện Đa Khoa Bình Định - Phần mở rộng",
        path:"benh-vien-da-khoa-binh-dinh",
        img:require("../images/Typical_project/duan-bvdkbd.jpg"),
        introviews:`<p>
        <img style="width:100%" src=${imgbd} alt=""><br/>
        Tên dự án: <b>BỆNH VIỆN ĐA KHOA BÌNH ĐỊNH – PHẦN MỞ RỘNG</b>
        <br/><br/>
        Chủ đầu tư: Công ty Cổ phần bệnh viện Đa khoa Bình Định.
        <br/><br/>
        Địa chỉ: 39A Phạm Ngọc Thạch, P. Trần Phú, TP. Quy Nhơn, tỉnh Bình Định
        <br/><br/>
        Thông tin dự án:
        <br/><br/>
        Tổng mức đầu tư: 1.300 tỷ đồng.
        <br/><br/>
        Quy mô: 600 giường, có thể mở rộng lên 724 giường.
        <br/><br/>
        Dự án cao 12 tầng với diện tích sàn 53.240 m2.
        <br/><br/>
        Dự kiến Bệnh viện vận hành chính thức vào tháng 03/2018.
        </p>`,
        roleHSV:`<p>
        <ul>
            <li style="list-style-type: decimal">Tư vấn Xây dựng, hoạt động và điều hành bệnh viện theo mô hình kết hợp PPP .</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống Phần mềm quản lý tổng thể bệnh viện.</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống máy chủ HPE Proliant</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai Hệ thống lưu trữ HPE SAN Storage, Hệ thống HPE Backup.</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống PACS kết nối hệ thống HIS</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống Networking bao gồm:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống cáp truyền dẫn trục chính</li>
                    <li style="list-style: none;">- Hệ thống Cisco Core Switch</li>
                    <li style="list-style: none;">- Chuyển mạch tầng Cisco Distribution Switch</li>
                    <li style="list-style: none;">- Chuyển mạh Cisco Access Layer</li>
                    <li style="list-style: none;">- Hệ thống Cisco Wifi</li>
                </ul>
        <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống điện nhẹ bao gồm:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống Camera,</li>
                    <li style="list-style: none;">- Hệ thống PA (Hệ thống âm thanh công cộng)</li>
                    <li style="list-style: none;">- Hệ thống thiết bị gọi Y tá</li>
                    <li style="list-style: none;">- Hệ thống xếp hàng thông minh</li>
                    <li style="list-style: none;">- Bãi giữ xe thông minh</li>
                </ul>
        <li style="list-style-type: decimal">Tư vấn và triên khai hệ thống Datacenter:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống sàn nâng</li>
                    <li style="list-style: none;">- Hệ thống chống sét lan truyền trên đường nguồn và tín hiệu</li>
                    <li style="list-style: none;">- Hệ thống khí khí FM 200</li>
                    <li style="list-style: none;">- Hệ thống Nguồn UPS</li>
                    <li style="list-style: none;">- Hệ thống điều hòa không khí</li>
                </ul>
        </ul>
        </p>`,
        des : `
        <p>
        Chủ đầu tư: Công ty Cổ phần bệnh viện Đa khoa Bình Định.
        <br/><br/>
        Địa Chỉ : 39A Phạm Ngọc Thạch, P. Trần Phú, TP. Quy Nhơn, tỉnh Bình Định
        <br/><br/>
        Đường Dây Nóng : 1900969639
        </p>
        `
        
    },
    {
        id : 3,
        nameHospitol:"Bệnh viện Phụ Sản Hà Nội - Cơ Sở Đức Giang",
        path:"benh-vien-phu-san-ha-noi-co-so-duc-giang",
        img :require("../images/Typical_project/bvpshn.jpg"),
        introviews:`<p>
        <img src=${imghn} alt=""><br/>
        Tên dự án: <b>BỆNH VIỆN PHỤ SẢN HÀ NỘI – CƠ SỞ ĐỨC GIANG</b>
        <br/><br/>
        Chủ đầu tư: Công ty Cổ phần Hằng Hà
        <br/><br/>
        Địa chỉ: 52 Trường Lâm, Phường Đức Giang, Quận Long Biên, TP. Hà Nội
        <br/><br/>
        Thông tin dự án:
        <li style="list-style: none;">Tổng mức đầu tư: 567 tỷ đồng. Quy mô: 168 giường.</li>
        <li style="list-style: none;">Dự án cao 8 tầng, diện tích sàn 23.249 m2. Hiện dự án đã hoàn thành, đang thực hiện thủ tục xin cấp phép hoạt động.</li>
        </p>`,
        roleHSV:`<p>
        <ul>
            <li style="list-style-type: decimal">Tư vấn Xây dựng, hoạt động và điều hành bệnh viện theo mô hình kết hợp PPP .</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống Phần mềm quản lý tổng thể bệnh viện.</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống máy chủ HPE Proliant</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai Hệ thống lưu trữ HPE SAN Storage, Hệ thống HPE Backup.</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống PACS kết nối hệ thống HIS</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống Networking bao gồm:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống cáp truyền dẫn trục chính</li>
                    <li style="list-style: none;">- Hệ thống Cisco Core Switch</li>
                    <li style="list-style: none;">- Chuyển mạch tầng Cisco Distribution Switch</li>
                    <li style="list-style: none;">- Chuyển mạh Cisco Access Layer</li>
                    <li style="list-style: none;">- Hệ thống Cisco Wifi</li>
                </ul>
        <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống điện nhẹ bao gồm:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống Camera,</li>
                    <li style="list-style: none;">- Hệ thống PA (Hệ thống âm thanh công cộng)</li>
                    <li style="list-style: none;">- Hệ thống thiết bị gọi Y tá</li>
                    <li style="list-style: none;">- Hệ thống xếp hàng thông minh</li>
                    <li style="list-style: none;">- Bãi giữ xe thông minh</li>
                </ul>
        <li style="list-style-type: decimal">Tư vấn và triên khai hệ thống Datacenter:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống sàn nâng</li>
                    <li style="list-style: none;">- Hệ thống chống sét lan truyền trên đường nguồn và tín hiệu</li>
                    <li style="list-style: none;">- Hệ thống khí khí FM 200</li>
                    <li style="list-style: none;">- Hệ thống Nguồn UPS</li>
                    <li style="list-style: none;">- Hệ thống điều hòa không khí</li>
                </ul>
        </ul>
        </p>`,
        des : `
        <p>
        Chủ đầu tư: Công ty Cổ phần Hằng Hà
        <br/><br/>
        Địa chỉ: 52 Trường Lâm, Phường Đức Giang, Quận Long Biên, TP. Hà Nội
        <br/><br/>
        Đường Dây Nóng : 024 3834 3181
        </p>
        `
    },
    {
        id : 4,
        nameHospitol:"Bệnh viện Hữu Nghị Đa Khoa Nghệ An",
        path:"benh-vien-huu-nghi-da-khoa-nghe-an",
        img :require("../images/Typical_project/bvdk-na.jpg"),
        introviews:`<p>
        <img src=${imgna} alt=""><br/>
        Tên dự án: <b>BỆNH VIỆN HỮU NGHỊ ĐA KHOA NGHỆ AN – GIAI ĐOẠN 2</b>
        <br/><br/>
        Chủ đầu tư: Công ty Cổ phần bệnh viện Hữu nghị Đa khoa Nghệ An.
        <br/><br/>
        Địa chỉ: Đại lộ LêNin, xã Nghi Phú, TP. Vinh, tỉnh Nghệ An
        <br/><br/>
        <b>Thông tin dự án</b>:
        <li style="list-style: none;">Tổng mức đầu tư: 1.300 tỷ đồng.</li><br/>
        <li style="list-style: none;">Quy mô: 600 giường, có thể mở rộng lên 800 giường.</li>
        <li style="list-style: none;">Dự án cao 8 tầng với diện tích sàn 48.295 m2. Hiện dự án đang thi công giai đoạn hoàn thiện và lắp đặt thiết bị.</li><br/>
        </p>`,
        roleHSV:`<p>
        <ul>
            <li style="list-style-type: decimal">Tư vấn Xây dựng, hoạt động và điều hành bệnh viện theo mô hình kết hợp PPP .</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống Phần mềm quản lý tổng thể bệnh viện.</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống máy chủ HPE Proliant</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai Hệ thống lưu trữ HPE SAN Storage, Hệ thống HPE Backup.</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống PACS kết nối hệ thống HIS</li>
            <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống Networking bao gồm:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống cáp truyền dẫn trục chính</li>
                    <li style="list-style: none;">- Hệ thống Cisco Core Switch</li>
                    <li style="list-style: none;">- Chuyển mạch tầng Cisco Distribution Switch</li>
                    <li style="list-style: none;">- Chuyển mạh Cisco Access Layer</li>
                    <li style="list-style: none;">- Hệ thống Cisco Wifi</li>
                </ul>
        <li style="list-style-type: decimal">Tư vấn và triển khai hệ thống điện nhẹ bao gồm:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống Camera,</li>
                    <li style="list-style: none;">- Hệ thống PA (Hệ thống âm thanh công cộng)</li>
                    <li style="list-style: none;">- Hệ thống thiết bị gọi Y tá</li>
                    <li style="list-style: none;">- Hệ thống xếp hàng thông minh</li>
                    <li style="list-style: none;">- Bãi giữ xe thông minh</li>
                </ul>
        <li style="list-style-type: decimal">Tư vấn và triên khai hệ thống Datacenter:</li>
                <ul>
                    <li style="list-style: none;">- Hệ thống sàn nâng</li>
                    <li style="list-style: none;">- Hệ thống chống sét lan truyền trên đường nguồn và tín hiệu</li>
                    <li style="list-style: none;">- Hệ thống khí khí FM 200</li>
                    <li style="list-style: none;">- Hệ thống Nguồn UPS</li>
                    <li style="list-style: none;">- Hệ thống điều hòa không khí</li>
                </ul>
        </ul>
        </p>`,
        des : `
        <p>
        Chủ đầu tư: Công ty Cổ phần bệnh viện Hữu nghị Đa khoa Nghệ An.
        <br/><br/>
        Địa chỉ: Km5, Đại lộ Lê nin, Nghi Phú, TP Vinh, Nghệ An
        <br/><br/>
        Đường Dây Nóng : 0886.234.222
        </p>
        `
    },
]