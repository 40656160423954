import React, { useState, useEffect } from 'react';
import TopBanner from '../../components/topBanner/topBanner'
import imgBanner from "../../images/banner/banner-tin-tuc.jpg"
import TitleCenter from '../../components/title/TitleCenter'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {recruitment} from '../../localData/recruitmentMenu'
import { useNavigate } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';  


export default function BasicDemo() {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    useEffect(() => {
        recruitment.getProducts().then(data => setProducts(data));
    }, []);

    const detailTemplate = (rowData) => {
        return (
            <button className='btn btn-outline-primary'  onClick={() => navigate(`/tuyen-dung/${rowData.slug}`)}>Xem Chi Tiết</button>
        );
    };
    return (
        <div className="Recruitment">
            <TopBanner
                titleCenter="Tuyển Dụng"
                imgBanner={imgBanner}
                pageName="Tuyển Dụng"
            />
            <div className='layout_container'>
                <TitleCenter
                    text="Tuyển Dụng"
                />
                <div className="card">
                    <DataTable value={products} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="id" header="#"></Column>
                        <Column field="location" header="Vị Trí Tuyển Dụng"></Column>
                        <Column field="dateup" header="Ngày Tuyển"></Column>
                        <Column field="dateout" header="Hạn Nộp Hồ Sơ"></Column>
                        <Column body={detailTemplate} header="Chi Tiết"></Column>
                    </DataTable>
                </div>
            </div>
        </div>
        
    );
}

