export const recruitment = {
    getProductsData() {
        return [
            {
                id: 1,
                location: `Nhân viên phòng hành chánh`,
                slug: "nhan-vien-ban-thoi-gian-lam-viec-phong-hanh-chanh",
                dateup: `29-05-2023`,
                dateout: `15-07-2023`,
                description: `
                <p>
                    Ngày đăng: 29-05-2023
                    <br/><br/>
                    Ngày hết hạn nộp hồ sơ: 15-07-2023
                    <br/><br/>
                    <b>Mô tả công việc:</b>
                    <br/><br/>
                    (Mức lương: 8 - 10 triệu VNĐ)
                    <br/><br/>
                    Thống kê các báo cáo định kỳ ( ngày, tháng, quí ) của phòng hành chính
                    <br/><br/>
                    Chịu trách nhiệm giám sát và quản lý về tình hình công việc hành chính
                    <br/><br/>
                    Báo cáo tình hình hoạt động định kỳ lên quản lý cấp trên
                    <br/><br/>
                    Việc xây dựng cơ cấu tổ chức phòng hành chính
                    <br/><br/>
                    Chức vụ: Nhân Viên/Chuyên Viên
                    <br/><br/>
                    Hình thức làm việc: Toàn thời gian
                    <br/><br/>
                    <b>Quyền lợi được hưởng:</b>
                    <br/><br/>
                    Môi trường làm việc dân chủ, hiện đại, chuyên nghiệp, có cơ hội thăng tiến và ổn định lâu dài.
                    <br/><br/>
                    Được đóng BHXH, BHYT, BH thất nghiệp theo quy định của nhà nước.
                    <br/><br/>
                    Được hưởng chế độ chăm sóc sức khỏe toàn diện.
                    <br/><br/>
                    Được hưởng các quyền lợi khác theo chính sách của công ty
                    <br/><br/>
                    Yêu cầu bằng cấp (tối thiểu): Cao Đẳng
                    <br/><br/>
                    Yêu cầu công việc:
                    <br/><br/>
                    Kỹ năng giao tiếp và xử lý công việc tốt
                    <br/><br/>
                    Có kinh nghiệm trong lĩnh vực bán hàng kinh doanh
                    <br/><br/>
                    Yêu cầu giới tính: Nam/Nữ
                    <br/><br/>
                    - Địa Chỉ nộp hồ sơ: VPĐD: Số 64 Đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, TP HCM
                </p>
                `
            },
            {
                id: 2,
                location: `Nhân viên Sale`,
                slug: 'nhan-vien-sale',
                dateup: `29-05-2023`,
                dateout: `15-07-2023`,
                description: `
                <p>
                    Ngày đăng: 29-05-2023
                    <br/><br/>
                    Ngày hết hạn nộp hồ sơ: 15-07-2023
                    <br/><br/>
                    <b>MÔ TẢ CÔNG VIỆC</b>
                    <br/><br/>
                    *** Số lượng:
                    <br/>
                    Hồ Chí Minh: 4 Nhân viên
                    <br/><br/>
                    <b>***MÔ TẢ CÔNG VIỆC</b>
                    <ul>
                    <li>Giới thiệu và tư vấn sản phẩm thiết bị công nghệ cho khách hàng.</li>
                    <li>Lập báo giá, dự toán theo yêu cầu của khách hàng.</li>
                    <li>Duy trì quan hệ với khách hàng và chăm sóc khách hàng để có thể đi đến việc ký kết hợp đồng hoặc nhận được đơn đặt hàng, hỗ trợ việc thanh lý hợp đồng và thanh toán.</li>
                    <li>Ưu tiên: Có 2 năm kinh nghiệm bán hàng kỹ thuật, tư vấn giải pháp hạ tầng và công nghệ : Phần Mềm quản lý Bệnh Viện, Phòng Khám, Thiết bị CNTT, Thiết bị Server, PC, Máy In, Hạ tầng Networking, (Hệ thống camera giám sát (CCTV), Xếp hàng tự động (QMS), âm thanh (PA), Hệ thống Accesscontrol, Hệ thống Chuyền mẫu bệnh phẩm, Hệ thống tổng đài điện thoại, Hệ thống gọi y tá, Hệ thống quản lý toà nhà( BMS), Hệ thống truyền dẫn thiết bị</li>
                    <li>Báo cáo công việc trực tiếp cho Giám Đốc Kinh Doanh</li>
                    </ul>
                    <br/>
                    <b>*** Mức Lương và Các Chế Độ Phúc Lợi:</b>
                    <ul>
                    <li>Mức thu nhập tối thiểu 12 triệu/tháng hoặc thỏa thuận</li>
                    <li>Thưởng lương tháng 13</li>
                    <li>Thưởng kết quả kinh doanh theo Quý, Năm</li>
                    <li>Tham gia bảo hiểm xã hội đầy đủ</li>
                    <li>Khám sức khỏe hàng năm</li>
                    <li>Phúc lợi gắn kết hàng năm</li>
                    <li>Thưởng tiền vào dịp lễ (30/4 – 1/5, 2/9 và Tết dương lịch)</li>
                    </ul>
                    <br/>
                    <b>Các quyền lợi khác được hưởng như:</b>
                    <ul>
                    <li>Chế độ nghỉ hè, nghỉ mát,</li>
                    <li>Được làm việc trong trong môi trường hiện đại, chuyên nghiệp</li>
                    </ul>
                `
            },
            {
                id: 3,
                location: `Kế toán tổng hợp`,
                slug: "ke-toan-tong-hop",
                dateup: `22-08-2023`,
                dateout: `31/09/2023`,
                description: `
                <p>
                    Ngày đăng: 22/08/2023
                    <br/><br/>
                    Ngày hết hạn nộp hồ sơ: 31/09/2023
                    <br/><br/>
                    Vị Trí : <br/>
                    <li>1️⃣ Kế toán tổng hợp</li>
                    <li>Mức lương:13-17 Triệu</li>
                    ✍ Mô tả công việc
                    <li>Kiểm tra, đối chiếu số liệu của các phòng ban, bộ phận, các định khoản phát sinh và cân đối số liệu kế toán chi tiết và tổng hợp</li>
                    <li>Thống kê và tổng hợp số liệu kế toán:  · Ghi chép sổ sách, các thu/chi dòng tiền trong nội bộ công ty  · Tập hợp, lưu trữ, bảo quản chứng từ, bảo mật số liệu kế toán  · Theo dõi công nợ phải thu, phải trả; định kỳ lập biên bản xác minh công nợ theo quy định  · Lập tờ khai thuế GTGT, TNCN và tình hình sử dụng hóa đơn  · Tham gia công tác kiểm kê định kỳ (hoặc đột xuất)</li>
                    <li>Lập BCTC và báo cáo quyết toán thuế TNDN, TNCN và các báo cáo giải trình liên quan khác.</li>
                    <li>Tham mưu cho cấp quản lý, tham gia tổ chức, xây dựng và vận hành bộ máy kế toán trong doanh nghiệp.</li>
                    <li>Giải trình số liệu và làm việc với Cơ quan hành chính Nhà nước khi có yêu cầu</li>
                    <li>Các công việc liên quan khác do cấp quản lý phân công</li>
                    ✍ Trình độ 
                    <br/>
                    Tốt nghiệp Đại Học 
                    <br/>
                    Kinh nghiệm:3-5 năm
                    <br/>
                    Quyền lợi được hưởng :
                    <li>Lương cơ bản + Phụ cấp + Hoa hồng khác</li>
                    <li>Môi trường làm việc chuyên nghiệp, năng động, thân thiện.</li>
                    <li>Được hưởng đầy đủ các chế độ đãi ngộ đối với người lao động theo quy định pháp luật.</li>
                    <li>Được xét duyệt tăng lương và lương tháng 13 theo chính sách công ty và kết quả công việc.</li>
                    <li>Được tạo điều kiện phát triển nghề nghiệp và khả năng bản thân.</li>
                    ✍ Thông tin liên hệ:
                    <li>Nộp hồ sơ trực tiếp tại công ty hoặc liên hệ trực tiếp:</li>
                    <li>➡️ Anh Thành - Giám đốc nhân sự</li>
                    <li>📱 <a>0932 958 366</a></li>
                    <li>📩 thanh.nguyen@hsv.com.vn</li>
                    <li>➡️ Chị Mai Hân - Giám đốc kinh doanh</li>
                    <li>📱 <a>0907 336 888</a></li>
                    <li>📩 han.nguyen@hsv.com.vn</li>
                    <li>➡️ Ms Duyên - P.Hành chính</li>
                    <li>📱 <a>0917 632 112</a></li>
                    <li>📩 duyen.truong@hsv.com.vn</li>
                    <li>⏰ Thời gian nhận hồ sơ đến hết ngày 31/08/2023</li>
                    <li>❤️ Hân hoan chào đón ứng viên đủ tài đủ đức gia nhập Hệ sinh thái Y tế ESM</li>
                    <li>☘️☘️☘️☘️☘️</li>
                   <li> Địa Chỉ nhận hồ sơ : 64 đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, Tp HCM</li>
                    </p>
        `
            },
            {
                id: 4,
                location: `Nhân viên quản lý Nhân Sự Tiền Lương`,
                slug: "nhan-vien-quan-ly-nhan-su-tien-luong",
                dateup: `22-08-2023`,
                dateout: `31/09/2023`,
                description: `
                <p>
                    Ngày đăng: 22/08/2023
                    <br/><br/>
                    Ngày hết hạn nộp hồ sơ: 31/09/2023
                    <br/><br/>
                    Vị Trí : <br/>
                    <li>2️⃣ Nhân viên quản lý Nhân Sự Tiền Lương</li>
                    <br/>
                    ✍ Mô tả công việc
                    Cao Đẳng hoặc Đại Học 
                    <li>Chịu trách nhiệm tính lương và kiểm tra mức độ phù hợp về tăng ca của Cán bộ nhân viên</li>
                    <li>Xây dựng, điều chỉnh bảng lương phù hợp trong từng thời kì</li>
                    <li>Quản lý, soạn thảo Hợp đồng lao động (HĐLĐ)</li>
                    <li>Xây dựng, điều chỉnh các nội dung liên quan đến HĐLĐ, chính sách phúc lợi theo từng thời kì</li>
                    <li>Quản lý, cấp phát đồng phục cho nhân viên</li>
                    <li>Đăng ký Mã số thuế cá nhân, giảm trừ gia cảnh cho người lao động</li>
                    <li>Quản lý, sắp xếp hồ sơ cứng nhân viên và trên phần mềm, review KPI đơn vị theo...</li>
                    <br/><br/>
                    ✍ Trình độ 
                    Tốt nghiệp Cao đẳng hoặc Đại học
                    <br/>
                    
                    <br/><br/>
                    Quyền lợi được hưởng :
                    <li>Lương 9 - 12 triệu</li>
                    <li>Môi trường làm việc chuyên nghiệp, năng động, thân thiện.</li>
                    <li>Được hưởng đầy đủ các chế độ đãi ngộ đối với người lao động theo quy định pháp luật.</li>
                    <li>Được xét duyệt tăng lương và lương tháng 13 theo chính sách công ty và kết quả công việc.</li>
                    <li>Được tạo điều kiện phát triển nghề nghiệp và khả năng bản thân.</li>
                    <br/>
                    ✍ Thông tin liên hệ:
                    <li>Nộp hồ sơ trực tiếp tại công ty hoặc liên hệ trực tiếp:</li>
                    <li>➡️ Anh Thành - Giám đốc nhân sự</li>
                    <li>📱 <a>0932 958 366</a></li>
                    <li>📩 thanh.nguyen@hsv.com.vn</li>
                    <li>➡️ Chị Mai Hân - Giám đốc kinh doanh</li>
                    <li>📱 <a>0907 336 888</a></li>
                    <li>📩 han.nguyen@hsv.com.vn</li>
                    <li>➡️ Ms Duyên - P.Hành chính</li>
                    <li>📱 <a>0917 632 112</a></li>
                    <li>📩 duyen.truong@hsv.com.vn</li>
                    <li>⏰ Thời gian nhận hồ sơ đến hết ngày 31/08/2023</li>
                    <li>❤️ Hân hoan chào đón ứng viên đủ tài đủ đức gia nhập Hệ sinh thái Y tế ESM</li>
                    <li>☘️☘️☘️☘️☘️</li>
                   <li> Địa Chỉ nhận hồ sơ : 64 đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, Tp HCM</li>
                    </p>
        `
            },
            {
                id: 5,
                location: `Nhân viên chăm sóc khách hàng`,
                slug: "nhan-vien-cham-soc-khach-hang",
                dateup: `01-07-2023`,
                dateout: `31/08/2023`,
                description: `
                <p>
                    Ngày đăng: 01-07-2023
                    <br/><br/>
                    Ngày hết hạn nộp hồ sơ: 31/08/2023
                    <br/><br/>
                    Vị Trí : <br/>
                    <li>1️⃣ Nhân viên chăm sóc khách hàng</li>
                    <br/>
                    ✍ Mô tả công việc
                    Trao đổi cụ thể khi phỏng vấn
                    <br/><br/>
                    ✍ Trình độ 
                    Tốt nghiệp CĐ, ĐH (ưu tiên ứng viên có kinh nghiệm sales, Mkt & CSKH tại các cơ sở y tế hoặc trình dược viên kênh ETC)  
                    <br/><br/>
                    Quyền lợi được hưởng :
                    <li>Lương cơ bản + Phụ cấp + Hoa hồng khác</li>
                    <li>Môi trường làm việc chuyên nghiệp, năng động, thân thiện.</li>
                    <li>Được hưởng đầy đủ các chế độ đãi ngộ đối với người lao động theo quy định pháp luật.</li>
                    <li>Được xét duyệt tăng lương và lương tháng 13 theo chính sách công ty và kết quả công việc.</li>
                    <li>Được tạo điều kiện phát triển nghề nghiệp và khả năng bản thân.</li>
                    <br/>
                    ✍ Thông tin liên hệ:
                    <li>Nộp hồ sơ trực tiếp tại công ty hoặc liên hệ trực tiếp:</li>
                    <li>➡️ Anh Thành - Giám đốc nhân sự</li>
                    <li>📱 <a>0932 958 366</a></li>
                    <li>📩 thanh.nguyen@hsv.com.vn</li>
                    <li>➡️ Chị Mai Hân - Giám đốc kinh doanh</li>
                    <li>📱 <a>0907 336 888</a></li>
                    <li>📩 han.nguyen@hsv.com.vn</li>
                    <li>➡️ Ms Duyên - P.Hành chính</li>
                    <li>📱 <a>0917 632 112</a></li>
                    <li>📩 duyen.truong@hsv.com.vn</li>
                    <li>⏰ Thời gian nhận hồ sơ đến hết ngày 31/08/2023</li>
                    <li>❤️ Hân hoan chào đón ứng viên đủ tài đủ đức gia nhập Hệ sinh thái Y tế ESM</li>
                    <li>☘️☘️☘️☘️☘️</li>
                   <li> Địa Chỉ nhận hồ sơ : 64 đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, Tp HCM</li>
                    </p>
        `
            },
            {
                id: 6,
                location: `Thư ký HĐQT`,
                slug: "thu-ky-hdqt",
                dateup: `01-07-2023`,
                dateout: `31/08/2023`,
                description: `
                <p>
                    Ngày đăng: 01-07-2023
                    <br/><br/>
                    Ngày hết hạn nộp hồ sơ: 31/08/2023
                    <br/><br/>
                    Vị Trí : <br/>
                    <li>1️⃣ Thư ký HĐQT</li>
                    <br/>
                    ✍ Mô tả công việc
                    Trao đổi cụ thể khi phỏng vấn
                    <br/><br/>
                    ✍ Trình độ 
                    Tốt nghiệp CĐ, ĐH (ưu tiên ứng viên có kinh nghiệm sales, Mkt & CSKH tại các cơ sở y tế hoặc trình dược viên kênh ETC)  
                    <br/><br/>
                    Quyền lợi được hưởng :
                    <li>Lương cơ bản + Phụ cấp + Hoa hồng khác</li>
                    <li>Môi trường làm việc chuyên nghiệp, năng động, thân thiện.</li>
                    <li>Được hưởng đầy đủ các chế độ đãi ngộ đối với người lao động theo quy định pháp luật.</li>
                    <li>Được xét duyệt tăng lương và lương tháng 13 theo chính sách công ty và kết quả công việc.</li>
                    <li>Được tạo điều kiện phát triển nghề nghiệp và khả năng bản thân.</li>
                    <br/>
                    ✍ Thông tin liên hệ:
                    <li>Nộp hồ sơ trực tiếp tại công ty hoặc liên hệ trực tiếp:</li>
                    <li>➡️ Anh Thành - Giám đốc nhân sự</li>
                    <li>📱 <a>0932 958 366</a></li>
                    <li>📩 thanh.nguyen@hsv.com.vn</li>
                    <li>➡️ Chị Mai Hân - Giám đốc kinh doanh</li>
                    <li>📱 <a>0907 336 888</a></li>
                    <li>📩 han.nguyen@hsv.com.vn</li>
                    <li>➡️ Ms Duyên - P.Hành chính</li>
                    <li>📱 <a>0917 632 112</a></li>
                    <li>📩 duyen.truong@hsv.com.vn</li>
                    <li>⏰ Thời gian nhận hồ sơ đến hết ngày 31/08/2023</li>
                    <li>❤️ Hân hoan chào đón ứng viên đủ tài đủ đức gia nhập Hệ sinh thái Y tế ESM</li>
                    <li>☘️☘️☘️☘️☘️</li>
                   <li> Địa Chỉ nhận hồ sơ : 64 đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, Tp HCM</li>
                    </p>
        `
            },
            {
                id: 7,
                location: `Trưởng phòng phát triển phần mềm`,
                slug: "truong-phong-phan-mem",
                dateup: `01-07-2023`,
                dateout: `31/08/2023`,
                description: `
                <p>
                    Ngày đăng: 01-07-2023
                    <br/><br/>
                    Ngày hết hạn nộp hồ sơ: 31/08/2023
                    <br/><br/>
                    Vị Trí : <br/>
                    <li>1️⃣ Trưởng phòng phát triển phần mềm</li>
                    <br/>
                    ✍ Mô tả công việc
                    Trao đổi cụ thể khi phỏng vấn
                    <br/><br/>
                    ✍ Trình độ 
                    Tốt nghiệp CĐ, ĐH (Có kinh nghiệm 2 - 5 năm trở lên theo từng vị trí ) 
                    <br/><br/>
                    Quyền lợi được hưởng :
                    <li>Lương cơ bản + Phụ cấp + Hoa hồng khác</li>
                    <li>Môi trường làm việc chuyên nghiệp, năng động, thân thiện.</li>
                    <li>Được hưởng đầy đủ các chế độ đãi ngộ đối với người lao động theo quy định pháp luật.</li>
                    <li>Được xét duyệt tăng lương và lương tháng 13 theo chính sách công ty và kết quả công việc.</li>
                    <li>Được tạo điều kiện phát triển nghề nghiệp và khả năng bản thân.</li>
                    <br/>
                    ✍ Thông tin liên hệ:
                    <li>Nộp hồ sơ trực tiếp tại công ty hoặc liên hệ trực tiếp:</li>
                    <li>➡️ Anh Thành - Giám đốc nhân sự</li>
                    <li>📱 <a>0932 958 366</a></li>
                    <li>📩 thanh.nguyen@hsv.com.vn</li>
                    <li>➡️ Chị Mai Hân - Giám đốc kinh doanh</li>
                    <li>📱 <a>0907 336 888</a></li>
                    <li>📩 han.nguyen@hsv.com.vn</li>
                    <li>➡️ Ms Duyên - P.Hành chính</li>
                    <li>📱 <a>0917 632 112</a></li>
                    <li>📩 duyen.truong@hsv.com.vn</li>
                    <li>⏰ Thời gian nhận hồ sơ đến hết ngày 31/08/2023</li>
                    <li>❤️ Hân hoan chào đón ứng viên đủ tài đủ đức gia nhập Hệ sinh thái Y tế ESM</li>
                    <li>☘️☘️☘️☘️☘️</li>
                   <li> Địa Chỉ nhận hồ sơ : 64 đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, Tp HCM</li>
                    </p>
        `
            },
            {
                id: 8,
                location: `Front End Developer`,
                slug: "front-end-developer",
                dateup: `01-07-2023`,
                dateout: `31/08/2023`,
                description: `
                <p>
                    Ngày đăng: 01-07-2023
                    <br/><br/>
                    Ngày hết hạn nộp hồ sơ: 31/08/2023
                    <br/><br/>
                    Vị Trí : <br/>
                    <li>2️⃣ Nhân viên lập trình Front End</li>
                    <br/>
                    ✍ Mô tả công việc
                    Trao đổi cụ thể khi phỏng vấn
                    <br/><br/>
                    ✍ Trình độ 
                    Tốt nghiệp CĐ, ĐH (Có kinh nghiệm 2 - 5 năm trở lên theo từng vị trí ) 
                    <br/><br/>
                    Quyền lợi được hưởng :
                    <li>Lương cơ bản + Phụ cấp + Hoa hồng khác</li>
                    <li>Môi trường làm việc chuyên nghiệp, năng động, thân thiện.</li>
                    <li>Được hưởng đầy đủ các chế độ đãi ngộ đối với người lao động theo quy định pháp luật.</li>
                    <li>Được xét duyệt tăng lương và lương tháng 13 theo chính sách công ty và kết quả công việc.</li>
                    <li>Được tạo điều kiện phát triển nghề nghiệp và khả năng bản thân.</li>
                    <br/>
                    ✍ Thông tin liên hệ:
                    <li>Nộp hồ sơ trực tiếp tại công ty hoặc liên hệ trực tiếp:</li>
                    <li>➡️ Anh Thành - Giám đốc nhân sự</li>
                    <li>📱 <a>0932 958 366</a></li>
                    <li>📩 thanh.nguyen@hsv.com.vn</li>
                    <li>➡️ Chị Mai Hân - Giám đốc kinh doanh</li>
                    <li>📱 <a>0907 336 888</a></li>
                    <li>📩 han.nguyen@hsv.com.vn</li>
                    <li>➡️ Ms Duyên - P.Hành chính</li>
                    <li>📱 <a>0917 632 112</a></li>
                    <li>📩 duyen.truong@hsv.com.vn</li>
                    <li>⏰ Thời gian nhận hồ sơ đến hết ngày 31/08/2023</li>
                    <li>❤️ Hân hoan chào đón ứng viên đủ tài đủ đức gia nhập Hệ sinh thái Y tế ESM</li>
                    <li>☘️☘️☘️☘️☘️</li>
                   <li> Địa Chỉ nhận hồ sơ : 64 đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, Tp HCM</li>
                    </p>
        `
            },
            {
                id: 9,
                location: `Back End Developer`,
                slug: "Back-end-developer",
                dateup: `01-07-2023`,
                dateout: `31/08/2023`,
                description: `
                <p>
                    Ngày đăng: 01-07-2023
                    <br/><br/>
                    Ngày hết hạn nộp hồ sơ: 31/08/2023
                    <br/><br/>
                    Vị Trí : <br/>
                    <li>2️⃣ Back End Developer</li>
                    <br/>
                    ✍ Mô tả công việc
                    Trao đổi cụ thể khi phỏng vấn
                    <br/><br/>
                    ✍ Trình độ 
                    Tốt nghiệp CĐ, ĐH (Có kinh nghiệm 2 - 5 năm trở lên theo từng vị trí ) 
                    <br/><br/>
                    Quyền lợi được hưởng :
                    <li>Lương cơ bản + Phụ cấp + Hoa hồng khác</li>
                    <li>Môi trường làm việc chuyên nghiệp, năng động, thân thiện.</li>
                    <li>Được hưởng đầy đủ các chế độ đãi ngộ đối với người lao động theo quy định pháp luật.</li>
                    <li>Được xét duyệt tăng lương và lương tháng 13 theo chính sách công ty và kết quả công việc.</li>
                    <li>Được tạo điều kiện phát triển nghề nghiệp và khả năng bản thân.</li>
                    <br/>
                    ✍ Thông tin liên hệ:
                    <li>Nộp hồ sơ trực tiếp tại công ty hoặc liên hệ trực tiếp:</li>
                    <li>➡️ Anh Thành - Giám đốc nhân sự</li>
                    <li>📱 <a>0932 958 366</a></li>
                    <li>📩 thanh.nguyen@hsv.com.vn</li>
                    <li>➡️ Chị Mai Hân - Giám đốc kinh doanh</li>
                    <li>📱 <a>0907 336 888</a></li>
                    <li>📩 han.nguyen@hsv.com.vn</li>
                    <li>➡️ Ms Duyên - P.Hành chính</li>
                    <li>📱 <a>0917 632 112</a></li>
                    <li>📩 duyen.truong@hsv.com.vn</li>
                    <li>⏰ Thời gian nhận hồ sơ đến hết ngày 31/08/2023</li>
                    <li>❤️ Hân hoan chào đón ứng viên đủ tài đủ đức gia nhập Hệ sinh thái Y tế ESM</li>
                    <li>☘️☘️☘️☘️☘️</li>
                   <li> Địa Chỉ nhận hồ sơ : 64 đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, Tp HCM</li>
                    </p>
        `
            },
            {
                id: 10,
                location: `Nhân viên Design UI/UX`,
                slug: "nhan-vien-design",
                dateup: `01-07-2023`,
                dateout: `31/08/2023`,
                description: `
                <p>
                    Ngày đăng: 01-07-2023
                    <br/><br/>
                    Ngày hết hạn nộp hồ sơ: 31/08/2023
                    <br/><br/>
                    Vị Trí : <br/>
                    <li>1️⃣ Nhân viên Design UI/UX</li>
                    <br/>
                    ✍ Mô tả công việc
                    Trao đổi cụ thể khi phỏng vấn
                    <br/><br/>
                    ✍ Trình độ 
                    Tốt nghiệp CĐ, ĐH (Có kinh nghiệm 2 - 5 năm trở lên theo từng vị trí ) 
                    <br/><br/>
                    Quyền lợi được hưởng :
                    <li>Lương cơ bản + Phụ cấp + Hoa hồng khác</li>
                    <li>Môi trường làm việc chuyên nghiệp, năng động, thân thiện.</li>
                    <li>Được hưởng đầy đủ các chế độ đãi ngộ đối với người lao động theo quy định pháp luật.</li>
                    <li>Được xét duyệt tăng lương và lương tháng 13 theo chính sách công ty và kết quả công việc.</li>
                    <li>Được tạo điều kiện phát triển nghề nghiệp và khả năng bản thân.</li>
                    <br/>
                    ✍ Thông tin liên hệ:
                    <li>Nộp hồ sơ trực tiếp tại công ty hoặc liên hệ trực tiếp:</li>
                    <li>➡️ Anh Thành - Giám đốc nhân sự</li>
                    <li>📱 <a>0932 958 366</a></li>
                    <li>📩 thanh.nguyen@hsv.com.vn</li>
                    <li>➡️ Chị Mai Hân - Giám đốc kinh doanh</li>
                    <li>📱 <a>0907 336 888</a></li>
                    <li>📩 han.nguyen@hsv.com.vn</li>
                    <li>➡️ Ms Duyên - P.Hành chính</li>
                    <li>📱 <a>0917 632 112</a></li>
                    <li>📩 duyen.truong@hsv.com.vn</li>
                    <li>⏰ Thời gian nhận hồ sơ đến hết ngày 31/08/2023</li>
                    <li>❤️ Hân hoan chào đón ứng viên đủ tài đủ đức gia nhập Hệ sinh thái Y tế ESM</li>
                    <li>☘️☘️☘️☘️☘️</li>
                   <li> Địa Chỉ nhận hồ sơ : 64 đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, Tp HCM</li>
                    </p>
        `
            }
            
        ];
    },


    getProducts() {
        return Promise.resolve(this.getProductsData());
    },
};

