export const customers = [{
        img: require("../images/Typical custom/doitac-cotechealtchare.png"),
        link: "https://cotechealthcare.com.vn/",
        categoryId:1,
    },
    {
        img: require("../images/Typical custom/bvdkdn-logo.png"),
        link: "http://dnh.org.vn/",
        categoryId:1,
    },
    {
        img: require("../images/Typical custom/bvdkbd-logo.png"),
        link: "https://benhvienbinhdinh.com.vn/",
        categoryId:1,
    },
    {
        img: require("../images/Typical custom/logo-pcc.jpg"),
        link: "https://www.phc.co.th/vi/",
        categoryId:1,
    },
    {
        img: require("../images/Typical custom/twg-logo.png"),
        link: "https://www.twgroup.com.vn/",
        categoryId:1,
    },
    {
        img: require("../images/Typical custom/bvdksnla-logo.pgp.png"),
        link: "http://www.benhvientwg.vn/",
        categoryId:1,
    },
    {
        img: require("../images/Typical custom/partner-cisco.jpg"),
        link: "https://www.cisco.com/",
        categoryId:2,
    },
    {
        img: require("../images/Typical custom/partner-hp-gold.png"),
        link: "https://www.hpe.com/asia_pac/en/home.html",
        categoryId:2,
    },
    {
        img: require("../images/Typical custom/microsoft-large-logo.jpg"),
        link:"https://www.microsoft.com/vi-vn",
        categoryId:2,
    },
    {
        img:require("../images/Typical custom/emc_corporation_logo.jpg"),
        link:"https://www.dell.com/en-us",
        categoryId:2,
    },
    {
        img:require("../images/Typical custom/doitac-oracle.jpg"),
        link:"https://www.oracle.com/",
        categoryId:2
    },
    {
        img:require("../images/Typical custom/fortinet-logo.jpg"),
        link:"https://www.fortinet.com/",
        categoryId:2
    },
    {
        img:require("../images/Typical custom/net-app.png"),
        link:"https://www.netapp.com/",
        categoryId:2
    },
    {
        img:require("../images/Typical custom/vmware_enterprise_logo_700x700_ss.png"),
        link:"https://www.vmware.com/sg.html",
        categoryId:2
    },
]

export const customersTitle = [
    {
        id:1,
        title:"Khách hàng tiêu biểu",
        slug:"khach-hang-tieu-bieu",
    },
    {
        id:2,
        title:"Đối tác chiến lược",
        slug:"doi-tac-chien-luoc",
    }
]