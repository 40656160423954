import React from 'react'
import { ImArrowUp } from "react-icons/im";


export default function ScrollTop({scrollValue}) {

  const handleScrollTop = () =>{
      window.scrollTo({
          top: 0, 
          behavior: 'smooth'
      });
  }
  return (
    <div className={scrollValue > 200 ? "scrollTop scrollTop-active" :"scrollTop"} onClick={handleScrollTop}>
    <div>
      <span >Back to top</span>
      <ImArrowUp/>
    </div>
  </div>
  )
}
