import React from 'react'

function TopBanner({ imgBanner, text, pageName, titleCenter }) {
    return (
        <div className="top_banner">
            <div className='top_banner-img banner-item'
                style={{
                    backgroundImage: `linear-gradient(to bottom,rgba(0, 0, 0, 0.6), rgba(0, 0, 0 , 0)),url(${imgBanner})`
                }}>
            </div>
            <div className='top_banner-title'>
                <div className='layout_container'>
                    <h4 className='title-zone'>
                        {titleCenter}
                    </h4>
                    <p className='title-title'>{pageName} / {text}</p>
                </div>
            </div>
        </div>
    )
}

export default TopBanner