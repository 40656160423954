import React from 'react';
import { typicalInProgressMenu } from '../../localData/typicalInProgress';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { useNavigate } from 'react-router-dom';

import "swiper/css";
import "swiper/css/pagination";

function ListTypicalInProgress() {
  const navigate = useNavigate();

  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      className="mySwiper"
      autoplay={{
        delay: 2500, // autoplay every 1 second
        disableOnInteraction: false, // continue autoplay even when user interacts with the swiper
      }}
      pagination={{
        clickable: true, // make pagination bullets clickable
      }}
      navigation={false} // disable navigation buttons
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 1,
          spaceBetween: 40,
        }
      }}
    >
      {typicalInProgressMenu.map((value, index) => {
        const formattedHTMLDes = { __html: value.des };

        return (
          <SwiperSlide key={index}>
            <div className="section3">
              <div className=''>
                <img src={value.img} alt="img section3.1" style={{ borderRadius: "10px" }} />
                <div className='section3-content'>
                  <h3>{value.nameHospitol}</h3>
                  <p style={{ marginTop: "10px", fontWeight: "100" }} dangerouslySetInnerHTML={formattedHTMLDes}></p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default ListTypicalInProgress;