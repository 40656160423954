import React from 'react'
import logoImg from "../../images/Logo/logoVision.png"

function IntroduceVision() {
    return (
        <div className='IntroduceVision'>
            <p style={{ color: '#4db748', fontWeight: '600', margin: "10px 0", fontSize: "2rem" , textAlign :"center" }}>
                Tầm Nhìn - Sứ Mệnh - Giá Trị & Cốt Lõi
            </p>
            <div className='IntroduceCulture-content' >
                <div className='IntroduceCulture-content-left'>
                <p style={{fontStyle :"italic"}}><b style={{color :"#1974bf" ,fontSize: "1.8rem",fontStyle : "normal" }}>TẦM NHÌN :</b> <br />
                Trở thành doanh nghiệp hàng đầu cung cấp   các giải pháp về CNTT - Viễn thông cho hệ sinh thái y tế  - dược tại Việt Nam. HSV là nơi hội tụ sự sáng tạo, nhân tài, của sự thành công, là niềm tự hào của nhà đầu tư, đối tác, khách hàng và người lao động
                </p>
                <p style={{fontStyle :"italic"}}><b style={{color :"#4db748" ,fontSize: "1.8rem",fontStyle : "normal" }}>SỨ MỆNH :</b> <br />
                Nghiên cứu và phát triển các giải pháp về CNTT tiên tiến nhằm ứng dụng vào cuộc sống luôn tiện lợi với hoài bão mang trí tuệ người Việt tiếp cận nền công nghệ của thế giới, thúc đẩy ngành CNTT Việt Nam phát triển vượt bậc
                </p>
                <p style={{fontStyle :"italic"}}><b style={{color :"#1f4769" ,fontSize: "1.8rem",fontStyle : "normal" }}>GIÁ TRỊ & CỐT LỖI :</b> <br />
                Xây dựng Công ty phát triển bền vững, lấy chất lượng sản phẩm, dịch vụ làm cam kết hàng đầu. Đầu tư và Phát triển đội ngũ sáng tạo, tận tâm, chính trực, dấn thân, cống hiến là kim chỉ nam để hướng đến thành công. Cân bằng lợi ích cho các bên tham gia, tạo ra giá trị gia tăng và thịnh vượng là mục tiêu dài hạn, và là đích đến của công ty
                </p>
                </div>
                <div>
                <img className='IntroduceCulture-content-img' src={logoImg} alt="" />
                </div>
            </div>
        </div>
    )
}

export default IntroduceVision