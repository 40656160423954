import { useState } from 'react';
import { introducMenu } from "../../localData/introduceMenu";


import imgBaner from "../../images/banner/banner---gioi-thieu.png";

import { useNavigate, Outlet } from "react-router-dom";
import TopBanner from '../../components/topBanner/topBanner';
import {useEffect} from 'react';


function Introduce() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const [activeMenu, setActiveMenu] = useState("Tổng quan");
  const navigate = useNavigate();


  const handelMenuOnclick = (activeMenu, slug) => {
    setActiveMenu(activeMenu);
    navigate(slug)
  }

  return (
    <div className='introduce'>
      <TopBanner
        imgBanner={imgBaner}
        pageName="Giới thiệu"
        titleCenter={activeMenu}
        text={activeMenu}
      />
      <div className='layout_container'>
        <h3 className='introduce_title'>
          {activeMenu}
        </h3>
        <ul className='introduce_menu'>
          {introducMenu.map((value, index) => (
            <li key={index} onClick={() => handelMenuOnclick(value.text, value.slug)}
              className={activeMenu === value.text ? " introduce_menu-active" : ""}
            >
              <p>{value.text}</p>
            </li>
          ))}
        </ul>
        <div className='introduce__content'>

          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Introduce