import React, { useEffect, useState } from 'react';
import { customers, customersTitle } from '../../localData/typicalCustomer';
import TopBanner from '../../components/topBanner/topBanner';
import imgBanner from "../../images/banner/banner---ban-lanh-dao.jpg";
import TitleCenter from '../../components/title/TitleCenter';
import { useParams } from 'react-router-dom';

function Customers() {
    const param = useParams();
    const [title, setTitle] = useState("");
    const [data, setData] = useState([]);
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        const selectedTitle = customersTitle.find(value => value.slug === param.slug);
        if (selectedTitle) {
            setTitle(selectedTitle.title);
            const filteredData = customers.filter(value => value.categoryId === selectedTitle.id);
            setData(filteredData);
        }
    }, [param.slug]);

    return (
        <div className="customers_container">
            <TopBanner imgBanner={imgBanner}
                titleCenter="Khách hàng - Đối tác"
                text={title}
                pageName="Khách hàng đối tác"
            />
            <div className="layout_container">
                <TitleCenter text={title} />
                <ul className='row'>
                    {data.map((value, index) => (
                        <div className='col-sm-6 col-md-3 col-lg-3' key={index} title={value.link}>
                            <li>
                                <a href={`${value.link}`} target="_blank" rel="noopener noreferrer">
                                    <img src={value.img} alt="" />
                                </a>
                            </li>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Customers;