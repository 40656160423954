import React from "react"
import { useNavigate } from 'react-router-dom';

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div className="PageNotFound">
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="main">
        <h1>404</h1>
        <p>It looks like you're lost...<br/>That's a trouble?</p>
        <button type="button" onClick={()=>navigate(`/`)}>Go back</button>
      </div>
    </div>
  )
}

export default PageNotFound