import React from 'react'
import { newsMenu } from '../../localData/newsMenu'
import { useNavigate } from 'react-router-dom';


function NewsCategory({ categoryID }) {
    const navigate = useNavigate();

    const filteredNews = newsMenu.filter((value) => value.categoryID === categoryID);
    return (
        <div className='listNews_content'>
            <div className="container">
                <div className="row">
                    {filteredNews.map((value) => (
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <div className="listNews" onClick={() => navigate(`/tin-tuc/${value.path}`)}>
                                <img src={value.img} alt="" />
                                <p>{value.title_content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default NewsCategory
