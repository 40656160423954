import React from 'react';
import { customers } from '../../localData/typicalCustomer';
import HomeTitle from './homeTitle';

function Section4() {
    let delayTime = 0;
    return (
        <section  style={{marginTop:"50px"}} className='section-fullscreen' title='hsv - dự án tiêu biểu'>
            <div className='layout_container home_ourservices' style={{ padding: "unset" }}>
                <div className='section4 p-bt-100'>
                    <HomeTitle title="Đối tác chiến lược" text="Kết nối"></HomeTitle>
                    <ul className='section4-grid row'>
                        {customers.map((value, index) => {
                            delayTime = delayTime + 30;
                            if (value.categoryId == 2) {
                                return (
                                    <div key={index} className='wow fadeInUp animated col-sm-6 col-md-3 col-lg-3'>
                                        <li className='section4-grid-card '
                                            data-aos="zoom-in"
                                            data-aos-once="true"
                                            data-aos-delay={delayTime}
                                            onClick={() => window.open(value.link)}
                                        >
                                            <img src={value.img} alt="" />
                                        </li>
                                    </div>
                                );
                            }
                        })}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Section4;