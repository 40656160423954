import {React,useEffect} from 'react'
import TopBanner from '../../components/topBanner/topBanner';
import imgBaner from "../../images/banner/banner---gioi-thieu.png";
import Title_center from '../../components/title/TitleCenter';
import NewsCategory from './NewsCategory';

function News() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return (
    <div className='news_controller'>
        <TopBanner
                titleCenter="Tin Tức"
                imgBanner={imgBaner}
                pageName="Tin Tức"
            />
            <div className='layout_container'>
                <Title_center
                    text="Tin Hoạt Động"
                />
            
                <NewsCategory
                    categoryID = {1}  
                />

                <Title_center
                    text="Tin Sự Kiện"
                />

                <NewsCategory
                    categoryID = {2}  
                />
            </div>
    </div>
  )
}

export default News