import React from 'react';
import esmLogo from "../../images/Logo/logoESM.png";
import iHospitalLogo from "../../images/product_solution/icon/iconHospital-no-bg.png";
import clinicLogo from "../../images/product_solution/icon/Logo_Final_i_Clinic-no-bg.png";
import pharmacyLogo from "../../images/product_solution/icon/iconPharmacy-no-bg.png";
import erplogo from "../../images/Logo/Logo ERP.png";
import hsvLogo from "../../images/Logo/Logo HSV_01.png";

function LogoSlider() {
  const logos = [
    { img: esmLogo, text: "Y tế thông minh", href: "https://www.hsv.com.vn/sanpham-giaiphap/san-pham-cong-ty/y-te-thong-minh" },
    { img: iHospitalLogo, text: "Phần mềm Quản lí tổng Bệnh viện", href: "https://www.hsv.com.vn/sanpham-giaiphap/san-pham-cong-ty/pham-mem-quan-ly-tong-the-benh-vien" },
    { img: clinicLogo, text: "Phần mềm Quản lý Phòng khám", href: "https://www.hsv.com.vn/sanpham-giaiphap/san-pham-cong-ty/phan-men-quan-ly-phong-kham" },
    { img: pharmacyLogo, text: "Phần mềm Quản lý Nhà thuốc", href: "https://www.hsv.com.vn/sanpham-giaiphap/san-pham-cong-ty/phan-mem-quan-ly-nha-thuoc" },
    { img: erplogo, text: "Phần mềm Quản trị Nguồn Lực", href: "https://www.hsv.com.vn/sanpham-giaiphap/san-pham-cong-ty/phan-mem-quan-tri-nguon-luc" },
    { img: hsvLogo, text: "Hệ thống bán lẻ", href: "https://hsv-esm.vn/" }
  ];

  return (
    <div className='home_clientsWords-content'>
      <div className='background-img logo-slider'>
        <ul className='layout_container'>
          {logos.map((logo, index) => (
            <li key={index} className="" onClick={() => window.open(logo.href)}>
              <img src={logo.img} alt={`logo ${index}`} />
              <p>{logo.text}</p>
            </li>
          ))}
           {logos.map((logo, index) => (
            <li key={index} className="" onClick={() => window.open(logo.href)}>
              <img src={logo.img} alt={`logo ${index}`} />
              <p>{logo.text}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default LogoSlider;