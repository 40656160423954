import React from 'react'
import imgMucTieu from "../../images/muctieu.png"
import imgMoHinh from "../../images/mohinhkinhdoan.png"

function IntroducrOriented() {
  return (
    <div>
            <div className='introduce__content-img'>
                <img src={imgMucTieu} alt="muc tieu" />
            </div>
            <span style={{margin: "10px auto" ,background: "rgb(77, 183, 72)", marginTop: "20px", marginBottom: "20px",height: "4px",display: "block"}}></span>
            <div className='introduce__content-img'>
                <img src={imgMoHinh} alt="mo hinh kinh doanh" />
            </div>
      </div>
  )
}

export default IntroducrOriented