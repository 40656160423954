import React from 'react'
import TopBanner from '../../components/topBanner/topBanner'
import imgBanner from "../../images/banner/banner-tin-tuc.jpg"
import TITLE_CENTER from '../../components/title/TitleCenter'
import TypicalProjectSlider from './TypicalProjectSlider'
import { useEffect } from 'react';

function TypicalProject() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    return (
        <div className='typicalProject_container'>
            <TopBanner
                titleCenter="Dự án tiêu biểu"
                imgBanner={imgBanner}
                pageName="Dự án tiêu biểu"
            />
            <div className='layout_container'>
                <TITLE_CENTER
                    text="Dự Án Tiêu Biểu"
                />
                <TypicalProjectSlider/>
            </div>

        </div>
    )
}

export default TypicalProject
