import React from 'react';
import { typicalProjectMenu } from '../../localData/typicalProjectMenu';
import { AiFillPlayCircle } from "react-icons/ai";
import Buttun2 from '../../components/button_2/button';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { useNavigate } from 'react-router-dom';

import "swiper/css";
import "swiper/css/pagination";

function TypicalProject() {
  const navigate = useNavigate();

  return (
    <Swiper
      className="mySwiper"
      modules={[Autoplay, Pagination]}
      autoplay={{
        delay: 2500, // autoplay every 1 second
        disableOnInteraction: false, // continue autoplay even when user interacts with the swiper
      }}
      pagination={{
        clickable: true, // make pagination bullets clickable
      }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 1,
          spaceBetween: 40,
        }
      }}
    >
      {typicalProjectMenu.map((value, index) => {
        const formattedHTMLDes = { __html: value.des };

        return (
          <SwiperSlide key={index}>
            <div className="section3">
              <div className=''>
                <div className='section3-img'>
                  <img src={value.img} alt="img section3.1" />
                  <div className='section3_detailMobile' onClick={() => navigate(`/du-an-tieu-bieu/${value.path}`)}>
                    <Buttun2 text="Xem Chi Tiết" Icon={AiFillPlayCircle} />
                  </div>
                </div>
                <div className='section3-content'>
                  <h3>{value.nameHospitol}</h3>
                  <p style={{ marginTop: "10px" }} dangerouslySetInnerHTML={formattedHTMLDes}></p>
                  <div style={{ marginTop: "20px" }} onClick={() => navigate(`/du-an-tieu-bieu/${value.path}`)}>
                    <Buttun2 text="Xem Chi Tiết" Icon={AiFillPlayCircle} />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default TypicalProject;