import React from 'react'

function TitleCenter({text}) {
    return (
        <h3 className='title_center'>
            {text}
        </h3>
    )
}

export default TitleCenter