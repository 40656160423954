import React from 'react'

function HomeTitle({text,title}) {
    return (
        <div className='home__title'>
            <p>{text}</p>
            <h3>{title}</h3>
            <span></span>
        </div>
    )
}

export default HomeTitle