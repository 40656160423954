import React from 'react'
import TopBanner from '../../components/topBanner/topBanner'
import imgBaner from "../../images/banner/banner---gioi-thieu.png";
import { leaderCategorys } from '../../localData/leaderShip';
import { useState ,useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import TitleCenter from '../../components/title/TitleCenter';
// import LeaderShip_member from './leaderShip_member';


function Leaders() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  const [activeMenu, setActiveMenu] = useState("Hội đồng quản trị")
  const navigate = useNavigate();


  const handleCategoriOnclick = (categoryName, id) => {
    navigate(`/gioi-thieu/ban-lanh-dao/${id}`);
    setActiveMenu(categoryName)
  }
  return (
    <div className='leadership_controller'>
      <TopBanner
        imgBanner={imgBaner}
        pageName="Giới thiệu"
        titleCenter="Ban lãnh đạo"
        text={activeMenu}
      />
      <div className='layout_container'>
       <TitleCenter
        text={activeMenu}
       />
        <ul className="category">
          {
            leaderCategorys.map((value, index) => (
              <li className={activeMenu == value.categoryName ? "category-active categori_border" : "categori_border"}
                key={index}
                onClick={() => handleCategoriOnclick(value.categoryName, value.id)}>
                {value.categoryName}
              </li>
            ))
          }
        </ul>
        <Outlet />
      </div>
    </div>
  )
}

export default Leaders