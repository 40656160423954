const img1 = require("../images/product_solution/ihospital-smart4.0.png");
const img2 = require("../images/product_solution/smart-kiosk-ihospital.jpg");
const img3 = require("../images/product_solution/emr-ihospital.jpg");
const img4 = require("../images/product_solution/healthcare-rfid.jpg");
const img5 = require("../images/product_solution/rfid1.png");
const img6 = require("../images/product_solution/healthcare_2014_565.jpg");
const img7 = require("../images/product_solution/giaiphapyte-iqms.png");
const img8 = require("../images/product_solution/qr-code-rfid.png");
const img9 = require("../images/product_solution/smart-hospital-emr.png");
const img10 = require("../images/product_solution/smart-hospital-video.png");
const img11 = require("../images/product_solution/ppp-xuctien1.png");
const img12 = require("../images/product_solution/ppp-xuctien2.png");
const img13 = require("../images/product_solution/ppp-xuctien3.png");
const img14 = require("../images/product_solution/ppp-xuctien4.png");
const img15 = require("../images/product_solution/ppp-duan-tuvan.png");
const img16 = require("../images/product_solution/ppp-bvdkdn-tuvan.png");
const img17 = require("../images/product_solution/ppp-bvdkdn-tuvan1.png");
const img18 = require("../images/product_solution/ppp-bvdkdn-tuvan2.png");
const img19 = require("../images/product_solution/ppp-bvdkdn-tuvan3.png");
const img20 = require("../images/product_solution/ppp-bvdkdn-tuvan4.png");
const img21 = require("../images/product_solution/ppp-bvdkdn-tuvan.png");
const img22 = require("../images/product_solution/ppp-bvpsdg-tuvan1.png");
const img23 = require("../images/product_solution/ppp-bvpsdg-tuvan2.png");
const img24 = require("../images/product_solution/ppp-tuvan-bvbd1.png");
const img25 = require("../images/product_solution/ppp-tuvan-bvbd2.png");
const img26 = require("../images/product_solution/ppp-tuvan-bvbd3.png");
const img27 = require("../images/product_solution/ppp-tuvan-bvbd4.png");
const img28 = require("../images/product_solution/ppp-tuvan-bvna1.png");
const img29 = require("../images/product_solution/ppp-tuvan-bvna2.png");
const img30 = require("../images/product_solution/ppp-tuvan-dangxuctien.png");
const img31 = require("../images/product_solution/giaiphap-voip.jpg");
const img32 = require("../images/product_solution/bo3.jpg");
const img33 = require("../images/product_solution/bo4.jpg");
const img34 = require("../images/product_solution/bo5.jpg");
const img35 = require("../images/product_solution/hpe-proliant-dl380.jpg");
const img36 = require("../images/product_solution/maychu-4.png");
const img37 = require("../images/product_solution/s00004150.png");
const img38 = require("../images/product_solution/3pair-7400.jpg");
const img39 = require("../images/product_solution/3pair-10000.jpg");
const img40 = require("../images/product_solution/aohoa1.jpg");
const img41 = require("../images/product_solution/aohoa2.jpg");
const img42 = require("../images/product_solution/tichhop-security1.png");
const img43 = require("../images/product_solution/giaiphap-antivirus.png");
const img44 = require("../images/product_solution/giaiphap-ddos.png");
const img45 = require("../images/product_solution/giaiphap-quyetlohong.png");
const img46 = require("../images/product_solution/netowork-diagram.jpg");
const img47 = require("../images/product_solution/network-diagram1.jpg");
const img48 = require("../images/product_solution/network-diagram3.jpg");
const img49 = require("../images/product_solution/network-diagram4.jpg");
const img50 = require("../images/product_solution/network-design.jpg");
const img51 = require("../images/product_solution/network-design1.jpg");
const img52 = require("../images/product_solution/dichvu-baotri.jpg");
const img53 = require("../images/product_solution/dichvu-baotri1.jpg");
const img54 = require("../images/product_solution/dichvu-baotri2.jpg");
const img55 = require("../images/product_solution/f1.jpg");
const img56 = require("../images/product_solution/g1.jpg");
const img57 = require("../images/product_solution/spa-1.png");
const img58 = require("../images/product_solution/khachsan-1-1.png");
const img59 = require("../images/product_solution/congchung-1.png");
const img60 = require("../images/product_solution/tongtheerp1.jpg");
const img61 = require("../images/product_solution/khachhang-1.jpg");
const img62 = require("../images/product_solution/content-cloud-vnn.png");
const img63 = require("../images/product_solution/cloud_vnn_architecture_overview.jpg");
const img64 = require("../images/product_solution/cloud_vnn_service_diagram.jpg");
const img65 = require("../images/product_solution/banggiadv_cloudvnn.jpg");
const img66 = require("../images/product_solution/easybackup_topology_final.jpg");
const img67 = require("../images/product_solution/cdn.jpg");
const img68 = require("../images/product_solution/tinhnang_vcdn.png");
const img69 = require("../images/product_solution/nong-nghiep-thong-minh.jpg");
const img70 = require("../images/product_solution/nongnghiep-thongminh.png");
const img71 = require("../images/product_solution/camera-kho.jpg");
const img72 = require("../images/product_solution/quanly-kho.jpg");
const img73 = require("../images/product_solution/quanly-kho1.jpg");
const img74 = require("../images/product_solution/qlsx-chuyen.jpg");
const img75 = require("../images/product_solution/qlsx-chuyen1.jpg");
const img76 = require("../images/product_solution/giaothongthongminh3.jpg");
const img77 = require("../images/product_solution/icon/icon-cn-2.png");
const img78 = require("../images/product_solution/icon/icon-cn-3.png");
const img79 = require("../images/product_solution/icon/icon-cn-4.png");
const img80 = require("../images/product_solution/icon/icon-cn-5.png");
const img81 = require("../images/product_solution/icon/icon-cn-6.png");
const img82 = require("../images/product_solution/icon/icon-cn-7.png");
const img83 = require("../images/product_solution/icon/icon-cn-8.png");
const img84 = require("../images/product_solution/icon/icon-cn-9.png");
const img85 = require("../images/product_solution/icon/icon-cn-10.png");
const img86 = require("../images/product_solution/icon/icon-cn-11.png");
const img87 = require("../images/product_solution/icon/icon-cn-12.png");
const img88 = require("../images/product_solution/icon/icon-cn-13.png");
const img89 = require("../images/product_solution/department-of-transportation-vehicle-to-vehicle-v2v-program_100349712_m.jpg");
const img90 = require("../images/product_solution/tongthe-iot-datacenter.jpg");
const img91 = require("../images/product_solution/chitiet-iot-datacenter.jpg");
const img92 = require("../images/product_solution/chitiet-iot-datacenter2.jpg");
const img93 = require("../images/product_solution/chitiet-iot-datacenter3.jpg");
const img94 = require("../images/product_solution/chitiet-iot-datacenter4.jpg");
const img95 = require("../images/product_solution/chitiet-iot-datacenter5.jpg");
const img96 = require("../images/product_solution/chitiet-iot-datacenter6.jpg");
const img97 = require("../images/product_solution/i-parking1.png");
const img98 = require("../images/product_solution/ytethongminh1.png");
const img99 = require("../images/product_solution/ytethongminh2.png");
const img100 = require("../images/product_solution/hospital.png");
const img101 = require("../images/product_solution/clinic.png");
const img102 = require("../images/product_solution/pharmacy.png");
const img103 = require("../images/product_solution/ERD.png");
const img104 = require("../images/product_solution/erd2.png");
const imgxh1 = require("../images/product_solution/hinh1.png");
const imgxh2 = require("../images/product_solution/hinh2.png");
const imgxh3 = require("../images/product_solution/hinh3.png");
const imgxh4 = require("../images/product_solution/hinh4.png");
const imgxh5 = require("../images/product_solution/hinh5.png");
const imgxh6 = require("../images/product_solution/hinh6.png");
const imgxh7 = require("../images/product_solution/hinh7.png");
const imgxh8 = require("../images/product_solution/hinh8.png");
const imgxh9 = require("../images/product_solution/hinh9.png");
export const PSContent = [{
    id: 1,
    textItem: "Đặc điểm nổi bật",
    text: `<p>
        Phương thức khám chữa bệnh thông minh thông qua ứng dụng công nghệ 4.0: Chatbot thông minh cung cấp thông tin bệnh nhân và đặt lịch khám online;
        <br/><br/>
        <img src=${img1} alt="">
        <br/><br/>
        Kiosk thông minh giúp bệnh nhân tự tiếp nhận, thanh toán, tra cứu thông tin, hướng dẫn di chuyển trong BV;
        <br/><br/>
        <img src=${img2} alt="">
        <br/><br/>
        Hàng chờ thông minh với khả năng ước lượng thời gian thực hiện và nhắc nhở khi sắp đến lượt; Viện phí thông minh tích hợp thanh toán online;
        Khám chữa bệnh thông minh thông qua ứng dụng di động và hàng loạt tiện ích hỗ trợ bác sĩ ra toa, tra cứu phác đồ điều trị cho từng bệnh, bệnh án điện tử và chữ ký số hướng đến bệnh viện không giấy
        <br/><br/>
        <img src=${img3} alt="">
    </p>`,
    PScategory : 1
},
{
    id_lv2: 2,
    textItem: "Công nghệ tiến tiến",
    text: `<p>
    <strong>Đa nền tảng</strong>: windows form, web form, mobile; vận hành trên máy tính để bàn, thiết bị di động…
    <br/><br/>
    <strong>CSDL</strong>: SQL, cho phép chia sẻ dữ liệu y tế mở
    <br/><br/>
    <strong>Kiến trúc hướng dịch vụ (SOA)</strong>: Đáp ứng số lượng lớn người dùng cùng lúc mà không làm chậm hệ thống
    <br/><br/>
    <strong>Hỗ trợ đa ngôn ngữ</strong>:
    <br/><br/>
    <strong>Cho phép tích hợp các trang thiết bị y tế và hệ thống khác như</strong>: giám định BHYT, thanh toán online ngân hàng, bệnh án điện tử tập trung, hồ sơ y tế cá nhân toàn quốc thông qua các chuẩn y khoa tại VN và thế giới như HL7, DICOM… và các QĐ 5573, 4210… của Bộ Y tế
    <br/><br/>
    <strong>Đảm bảo chất lượng</strong>: ISO 9001:2000, ISO-IEC 27001:2005
    </p>`,
    PScategory : 1
},
{
    id_lv2: 3,
    textItem: "Lợi ích của ứng dụng",
    text: `<p>
    <strong>Đăng ký khám bệnh</strong>: Thời gian hoàn thành thủ tục đăng ký khám bệnh cho một bệnh nhân với sự hỗ trợ của hệ thống quản lý tiếp nhận bệnh nhân h.MIS là 1 phút. Nếu không có sự hỗ trợ của phần mềm, qua trình này cần tới 4 phút
    <br/><br/>
    <strong>Kê toa thuốc</strong>: Thời gian bác sỹ hoàn thành kê toa thuốc cho bệnh nhân là 2 phút, với hệ thống tên thuốc, toa thuốc mẫu và bệnh án điện tử của bệnh nhân. Nếu không có sự hỗ trợ của phần mềm, quá trình này cần tới 5 phút.
    <br/><br/>
    <strong>Làm thủ tục viện phí</strong>: Thời gian làm thủ tục viện phí cho bệnh nhân ra viện nhanh hơn 1,5 lần và tính chính xác cao hơn
    <br/><br/>
    <strong>Thời gian phát thuốc, thu viện phí</strong>: giảm từ 5 phút/người còn 2 phút/người.
    <br/><br/>
    <strong>Tổng hợp báo cáo</strong>: giảm từ 1 – 2 ngày với sự khó khăn trong tìm kiếm, liên kết số liệu xuống còn 5 phút với tính liên kết cáo
    <br/><br/>
    <strong>Hiệu suất sử dụng nguồn lực của bệnh viện</strong>: Tăng lên gấp 2-3 lần so với khi chưa ứng dụng phần mềm
    <br/><br/>
    <strong>Xem kết quả xét nghiệm</strong>: Ngay sau khi xét nghiệm, các bác sĩ khoa nội trú hoặc phòng khám có thể xem kết quả trên máy tính. Trước đây, thời gian này lâu hơn vì cần đợi kết quả xét nghiệm được in ra.
    </p>`,
    PScategory : 1
},
{
    id_lv2: 4,
    textItem: "Lợi ích của giải pháp",
    text: `<p>
    Hỗ trợ thông tin chính xác và tức thời của Chuỗi Bệnh viện & Phòng Khám.
    <br/><br/>
    Kiểm soát được hiệu quả hoạt động của Chuỗi Bệnh viện & Phòng Khám.
    <br/><br/>
    Công cụ để định hướng hoạt động kinh doanh của Tập đoàn.
    <br/><br/>
    Truy cập từ xa mọi thời điểm.
    </p>`,
    PScategory : 2
},
{
    id_lv2: 5,
    textItem: "Tổng quan giải pháp",
    text: `<p>
    Giải pháp ứng dụng RFID trong bệnh viện quản lý bệnh nhân là một trong những xu hướng công nghệ ứng dụng mới nhằm giúp quản lý tốt hơn việc chăm sóc bệnh nhân, tối ưu hóa và nâng cao mức độ dịch vụ y tế cũng như các vấn đề về an ninh an toàn cho bệnh viện và bệnh nhân. Từ trước đến nay, tại bệnh viện đã xảy ra rất nhiều tình trạng như phát nhầm thuốc, vào nhầm phòng, dùng nhầm đồ đạc trang thiết bị y tế, thất lạc dụng cụ hay không kiểm soát được bệnh nhân và người nhà đang điều trị trong bệnh viện, không bám sát tức thời các sự kiện diễn ra đối với bác sỹ, bệnh nhân, đồ dùng và thiết bị gắn kèm theo…dễ dẫn đến nhiều phát sinh và phức tạp.
    <br/><br/>
    Việc ứng dụng công nghệ RFID trong quản lý bệnh viện, bệnh nhân và tài sản cũng như các vấn đề khác đã được các nước tiên tiến trên thế giới áp dụng rộng rãi và thành công. Đối với Viêt nam, công nghệ RFID hiện còn khá mới mẻ và nó chỉ thực sự phát huy tác dụng hiệu quả khi xác định được ý nghĩa và quyết tâm sử dụng vào thực tiễn. Với sự phát triển nhanh chóng của công nghệ RFID, hiện nay việc ứng dụng vào quản lý bệnh viện đã trỏ nên dễ dàng hiệu quả mà chi phí rất hợp lý.
    <br/><br/>
    <img src=${img4} alt="">
    <br/><br/>
    Ứng dụng công nghệ RFID trong quản lý bệnh nhân, nhằm thay thế mã vạch trong việc xác định đường đi của thuốc, nhận dạng bệnh nhân là một trong những xu hướng quản lý trong tương lai…\ Việc triển khai hệ thống RFID tại các bệnh viện chi phí đầu tư không cao. Một hệ thống RFID gồm có đầu đọc thẻ RFID, thẻ RFID và phần mềm RFID trung gian kết nối giữa thiết bị RFID và phần mềm ứng dụng.
    <br/><br/>
    Ngày nay các thẻ RFID đã có giá rẻ hơn rất nhiều và cũng có nhiều hình dạng khác nhau đáp ứng những nhu cầu đa dạng trong quản lý bệnh nhân bằng công nghệ RFID.
    <br/><br/>
    Thẻ RFID ứng dụng trong bệnh viện có một số mẫu phổ biến như sau:
    <br/><br/>
    <img src=${img5} alt="">
    <br/><br/>
    Trong các bệnh viện có thể gắng thẻ RFID tương ứng với mỗi bệnh nhân để quản lý. Có thể đó là vòng đeo tay RFID để đeo trực tiếp vào bệnh nhân, hoặc tem nhãn RFID để dán vào sổ bệnh nhân hoặc lên giường bệnh nhân, ngoài ra thẻ RFID có dạng thẻ cứng để làm thẻ khám chữa bệnh cho bệnh nhân.
    <br/><br/>
    RFID ngày càng ứng dụng phổ biến trong các bệnh viện trên thế giới, trong khi ở Việt Nam các bệnh viện đang sử dụng mã vạch là phổ biến và có một số bệnh viện đang xem xét phương án thay thế mã vạch bằng công nghệ RFID để quản lý bệnh nhân.
    <br/><br/>
    Ứng dụng quản lý bệnh nhân:
    <br/><br/>
    Thẻ RFID được gắn trên người bệnh nhân với hình dạng một chiếc đồng hồ. Khi đó, người bệnh nhập viện sẽ được cấp một thẻ khám bệnh có mã số tương ứng với các thông tin về bệnh nhân trên máy tính…
    <br/><br/>
    <img src=${img6} alt="">
    <br/><br/>
    Không có tính di động, các y tá phải có mặt tại phòng y tá trung tâm để quan sát những biểu hiện sống của bệnh nhân và tình trạng của thiết bị y tế trong phòng bệnh. Nhưng với máy tính di động không dây, nhân viên y tá có thể kiểm tra các biểu hiện sống của bệnh nhân và có thể thực hiện cả những điều chỉnh cần thiết cho thiết bị y tế khi đang di chuyển trên khắp cơ sở y tế.
    <br/><br/>
    Có tính di động, nhiều nhiệm vụ có thể được thực hiện nhanh hơn. Điều này cho phép các y tá thực hiện việc chăm sóc bệnh nhân tốt hơn và cải thiện đáng kể năng suất bằng cách loại bỏ thời gian đi lại thường xuyên tới phòng y tá trung tâm hoặc phòng bệnh để thực hiện những nhiệm vụ quan trọng.
    <br/><br/>
    Hệ thống RFID gồm các thẻ RFID (thiết bị gắn với đối tượng theo dõi) và các đầu đọc thẻ (reader) được kết nối với nhau bằng qua một máy tính trung tâm. Thẻ RFID chứa chip và ăng ten, có thể lưu nhiều thông tin, dữ liệu, được bảo vệ bằng vỏ hợp chất rất bền chống lại sự phá hoại của hóa chất, nhiệt độ cao…
    <br/><br/>
    Với kích thước nhỏ gọn (nhỏ nhất bằng hạt gạo), có thể gắn thẻ RFID lên quần áo, thiết kế thành đồ trang sức (nhẫn, vòng đeo tay…) cho bệnh nhân sử dụng trong suốt quá trình điều trị. Song song đó, các đầu đọc được lắp đặt ở lối ra vào BV, các phòng bệnh (bán kính hoạt động khoảng 30m) sẽ phát đi tín hiệu sóng vô tuyến qua ăng ten và nhận tín hiệu phản hồi từ thẻ.
    <br/><br/>
    RFID chứa mã nhận dạng đối tượng (gắn trên bệnh nhân).
    <br/><br/>
    Đầu đọc sẽ giải mã, chuyển tới máy tính đầy đủ các thông tin như: vị trí, họ tên bệnh nhân, tên bệnh, bác sĩ phụ trách, đơn thuốc, nhóm máu, tiền sử bệnh, dị ứng thuốc… giúp cho công tác khám và điều trị bệnh nhanh chóng, chính xác, an toàn.
    <br/><br/>
    Các tính năng và tiện ích:
    <ul>
        <li style="list-style: none;">- Liên kết y tá với thông tin cập nhật chính xác nhất về bệnh nhân, thiết bị giám sát, bơm truyền, bệnh án và lịch uống thuốc, bất kể họ đang làm việc ở đâu.</li>
        <li style="list-style: none;">- Dành thời gian để nhân viên của bạn tập trung vào việc chăm sóc bệnh nhân thay vì công việc giấy tờ.</li>
        <li style="list-style: none;">- Giảm thời gian phản hồi bệnh nhân.</li>
    </ul>
    </p>`,
    PScategory : 3
},
{
    id_lv2: 6,
    textItem: "Lợi Ích Mang Lại",
    text: `<p>
    Giảm 48% thời gian chờ đợi của khách hàng
    <br/><br/>
    Tăng độ hài lòng và chỉ số trung thành của khách hàng
    <br/><br/>
    Không gian phục vụ
    <br/><br/>
    chuyên nghiệp
    <br/><br/>
    Tăng 45% hiệu suất làm việc của giao dịch viên
    <br/><br/>
    Kiểm soát chất lượng phục vụ theo thời gian thực
    </p>`,
    PScategory : 4
},
{
    id_lv2: 7,
    textItem: "Hệ thống xếp hàng thông minh iQMS",
    text: `<p>
    <img src=${img7} alt="" />
    </p>`,
    PScategory : 5
},
{
    id_lv2: 8,
    textItem: "Tích hợp công nghệ QR Code và RFID",
    text: `<p>
    <img src=${img8} alt="" />
    </p>`,
    PScategory : 5
},
{
    id_lv2: 9,
    textItem: "Bệnh án điện tử EMR",
    text: `<p>
    <img src=${img9} alt="" />
    </p>`,
    PScategory : 5
},
{
    id_lv2: 10,
    textItem: "Hội chuẩn từ xa",
    text: `<p>
    <img src=${img10} alt="" />
    </p>`,
    PScategory : 5
},
{
    id_lv2: 11,
    textItem: "Các giai đoạn tư vấn",
    text: `<p>
    <img src=${img11} alt="">
    <img src=${img12} alt="">
    <img src=${img13} alt="">
    <img src=${img14} alt="">
    </p>`,
    PScategory : 6
},
{
    id_lv2: 12,
    textItem: "Các Dự án đã thực hiện tư vấn",
    text: `<p>
    <img src=${img15} alt="">
    <img src=${img16} alt="">
    <img src=${img17} alt="">
    <img src=${img18} alt="">
    <img src=${img19} alt="">
    <img src=${img20} alt="">
    <img src=${img21} alt="">
    <img src=${img22} alt="">
    <img src=${img23} alt="">
    <img src=${img24} alt="">
    <img src=${img25} alt="">
    <img src=${img26} alt="">
    <img src=${img27} alt="">
    <img src=${img28} alt="">
    <img src=${img29} alt="">
    </p>`,
    PScategory : 6
},
{
    id_lv2: 13,
    textItem: "Các dự án đang xúc tiến",
    text: `<p>
    <img src=${img30} alt="" />
    </p>`,
    PScategory : 6
},
{id_lv2: 14,
    textItem: "Giải pháp VOIP",
    text: `<p>
    <strong>1. Tổng quan</strong>: Hiện tại có nhiều mô hình để triển khai hệ thống truyền thông hợp nhất Unified Communication, tuy nhiên với bất kì hệ thống truyền thông hợp nhất nào đều phải dựa trên một thiết bị trung tâm xử lý cuộc gọi, Cisco Unified Communications Manager.
    <br/><br/>
    Cisco Unified CallManager là thành phần xử lý cuộc gọi trong hệ thống truyền thông hợp nhất của Cisco. Cisco Unified CallManager mở rộng những tính năng thoại cho doanh nghiệp và những khả năng chuyển thành những thiết bị đóng gói cuộc gọi như là IP Phones, những thiết bị xử lý đa phương tiện, VoIP gateways, và những ứng dụng đa phương tiện. Hơn nữa những dịch vụ như hợp nhất messaging, hội thoại đa phương tiện, trung tâm liên lạc, và những phản hồi tương tác đa phương tiện với giải pháp IP telephony thông qua những giao tiếp lập trình ứng dụng (APIs) Cisco Unified CallManager.
    <br/><br/>
    <strong>2. Các ưu điểm và lợi ích của giải pháp</strong>: IP Telephony là một trong những giải pháp nhằm đáp ứng nhu cầu điện thoại trên mạng IP đồng thời vẫn duy trì khả năng giao tiếp với các mạng truyền thống khác như PSTN, ISDN.
    <br/><br/>
    Do vậy, việc trang bị hệ thống IP Telephony chính là sự chuẩn bị hữu hiệu để đáp ứng được với sự phát triển của công nghệ trong tương lai. Hệ thống IP Telephony sẽ đem lại các lợi ích như sau:
    <ul>
        <li style="list-style: none;"><strong>Độ sẵn sàng cao</strong>:khả năng chạy dự phòng khi một phần hoặc toàn bộ thiết bị hư hỏng, hay khi ngưng hệ thống để bảo trì định kỳ.</li>
        <br/>
        <li style="list-style: none;"><strong>Tiết kiệm chi phí kết nối đường dài</strong>:công ty hay tổ chức áp dụng IP Telephony có thể giảm được cước phí điện thoại liên tỉnh hay quốc tế gọi qua mạng PSTN.</li>
        <br/>
        <li style="list-style: none;"><strong>Thông tin đảm bảo thông suốt</strong>:các thiết bị kết nối có thể phát hiện ra sự cố trên đường truyền và chuyển qua sử dụng đường truyền khác, đảm bảo thông tin giữa hai đầu luôn được duy trì một cách thông suốt.</li>
    </ul>
    Các ưu điểm nổi bật của hệ thống IP Telephony so với hệ thống PBX truyền thống:
    <ul>
        <li style="list-style: none;"><strong>Softphone</strong>:là phần mềm chạy trên PC cung cấp chức năng của một điện thoại. Dịch vụ này giúp người sử dụng có thể thiết lập cuộc gọi, trả lời điện thoại trong khi vẫn đang làm việc trên máy tính.</li>
        <br/>
        <li style="list-style: none;"><strong>Meet-me Conference</strong>:là dịch vụ hội đàm có tính chất thường xuyên, định kỳ. Các thành viên tự động truy cập vào “phòng hội đàm” ở thời điểm đã thỏa thuận trước mà không cần có người làm nhiệm vụ kết nối.</li>
        <br/>
        <li style="list-style: none;"><strong>Truy cập Web</strong>:qua IP phone để lấy thông tin dạng XML trên trang Web đó. Trang Web này có thể là trang Web chứa các thông tin nội bộ của một công ty như cập nhật giá cả, chính sách…</li>
        <br/>
        <li style="list-style: none;"><strong>Mobile user</strong>:đây là tính năng vô cùng hữu hiệu cho các nhân viên có nhu cầu đi công tác thường xuyên. Từ chi nhánh của công ty ở khắp nơi trên thế giới, thông qua mạng WAN nhân viên đó có thể đăng ký vào mạng IP Telephony của cơ quan mình để sử dụng dịch vụ điện thoại giống như đang ở tại nơi làm việc thường xuyên của mình.</li>
        <br/>
        <li style="list-style: none;"><strong>Các dịch vụ mở rộng</strong>: IP Contact center: IVR, ICD, CTI…hệ thống hỗ trợ khách hàng, tự động trả lời cho phép các doanh nghiệp ngày nay hỗ trợ khách hàng tốt hơn trong môi trường cạnh tranh.</li>
    </ul>
    <strong>3.Các thành phần cơ bản hệ thống thoại trên nền IP</strong>: 
    <br/><br/>
    <strong>3.1Thành phần tổng đài trung tâm</strong>: Trung tâm xử lý cuộc gọi Cisco Unified Communication Manager (tên gọi trước đây là Cisco CallManager hay còn gọi là Tổng đài IP) là trung tâm xử lý tất cả các cuộc gọi thoại cho mạng IP Telephony. Cisco Unified Communication Manager tăng cường khả năng của mạng thoại IP thông qua các thiết bị mạng như IP phone, thiết bị media processing, thiết bị VoIP gateway, các ứng dụng đa phương tiện. Hơn nữa, Cisco Unified Communication Manager cho phép phân phối mọi IP phone, gateway và các ứng dụng thông qua mạng IP tạo thành mạng IP Telephony vơi đủ mọi thiết kế từ đơn lẻ (single), phân bổ (distributed) hay dạng ảo hóa (virtual). Số lượng user mà Cisco Unified Communication Manager quản lý tùy thuộc vào cấu trúc phần cứng cũng như phần mềm với số lượng tối đa lên đến hàng chục ngàn điện thoại và thiết bị Voice.
    <br/><br/>
    <img src=${img31} alt="">
    <br/><br/>
    Mô hình UC của Cisco
    <br/><br/>
    <strong>3.2Hệ thống Voice Gateway</strong>: Voice gateway cung cấp khả năng giao tiếp giữa các dạng giao thức báo tín hiệu điều khiển Voice với nhau. Chẳng hạn Voice Gateway cung cấp khả năng giao tiếp giữa các hệ thống Voice sử dụng giao thức H.323 với các hệ thống PBX sử dụng giao thức khác thông qua một hệ thống chuyển đổi giao thức. Cung cấp khả năng kết nối của hệ thống IP Telephony với hệ thống PSTN, các tổng đài PBX truyền thống hoặc kết nối đến các thiết bị analog phone hoặc máy fax.
    <br/><br/>
    Trong các dòng sản phẩn Cisco Voice Gateway thường được phân chia ra thành hai dạng:
    <br/><br/>
    Analog Gateway: Dưới đây là 2 dạng thiết bị Analog Gateway thường gặp:
    <ul>
        <li style="list-style: none;">- Các thiết bị Analog station gateway cung cấp kết nối giữa mạng IP Telephony với hệ thống thoại bưu điện truyền thống (POTS).</li>
        <li style="list-style: none;">- Các thiết bị Analog Gateway kết nối đến các line trung kế, cung cấp giao diện kết nối giữa hệ thống IP Telephony với các CO Switch của mạng PSTN, hoặc kết nối đến các hệ thống PBX khác.</li>
    </ul>
    Digital gateways: Các thiết bị digital gateway cũng là các thiết bị cung cấp giao tiếp giữa hệ thống mạng IP Telephonyh với mạng PSTN hoặc các hệ thống PBX thông qua các đường trung kế digital.
    <br/><br/>
    <strong>3.3Hệ thống Gatekeeper</strong>: Gatekeeper là một thiết bị H.323 cung cấp dịch vụ kiểm soát cuộc gọi đến các điểm đầu cuối và chuyển địa chỉ giữa E.164 và danh sách điện thoại. Gatekeeper sẽ quản lý băng thông vào và ra giữa các trung tâm và giữa các zone. Gatekeeper sẽ cung cấp các dịch vụ sau:
    <ul>
        <li style="list-style: none;">Address translation: thực hiện việc chuyển địa chỉ E.164 và danh sách điện thoại</li>
        <li style="list-style: none;">Quản lý các cuộc gọi ra, vào từng vùng và từng công ty thành viên</li>
        <li style="list-style: none;">Hỗ trợ không giới hạn số lượng sites.</li>
    </ul>
    Các thiết bị đầu cuối (cho dịch vụ voice) sẽ được cấu hình để tự động tìm kiếm gatekeeper (gatekeeper discovery) thông qua sending multicast gatekeeper request (GRQ).
    <br/><br/>
    <strong>3.4Hệ thống Endpoint</strong>: Các thiết bị giao tiếp đầu cuối bao gồm điện thoại bàn, phần mềm thoại được cài trên các máy tính PC và mỗi thiết bị này đều có các kết nối Ethernet. Chi tiết các thiết bị như sau:
    <br/><br/>
    <ul>
        <li style="list-style: none;"><strong>Điện thoại IP</strong> : cho phép thực hiện trực tiếp các cuộc thoại IP.</li>
        <br/>
        <li style="list-style: none;"><strong>Điện thoại IP phone dùng phần mềm</strong>: đây chính là các ứng dụng thoại được cài vào máy tính PC người dùng và có đầy đủ tính năng của điện thoại như lưu vết các cuộc gọi, quay số ….</li>
        <br/>
        <li style="list-style: none;"><strong>Thiết bị thoại Video đầu cuối</strong>: bao gồm camera liên kết với máy tính có khả năng tương thích hoàn toàn với Cisco Unified Communication Manager. Khi máy tính PC người dùng được kết nối vật lý đến Cisco IP phone, người dùng có thể thiết lập cuộc gọi có hình ảnh.</li>
        <br/>
        <li style="list-style: none;"><strong>Điện thoại IP không dây</strong>: cho phép liên kết thoại không dây với các thiết bị khác thông qua hệ thống mạng không dây theo các chuẩn 802.11.</li>
        <br/>
        <li style="list-style: none;"><strong>Điện thoại đi động</strong>: ngoài tính năng hỗ trợ nhận và thực hiện các cuộc gọi từ mạng GSM thông thường thông qua Simcard, các dòng điện thoại này còn hỗ trợ Dual-mode (có hỗ trợ wifi), khi đó thông qua sóng wifi trong tòa nhà các điện thoại này đăng ký với Cisco Unified Communication Manager và trở thành chiếc điện thoại để bàn và mỗi nhân viên đều có thể đi bất kỳ nơi nào trong vùng wifi mà không phải bỏ lỡ cuộc gọi nào khi có người gọi điện đến số để bàn của mình.</li>
    </ul>
    <strong>4. Các mô hình triển khai VoIP</strong>: Hiện tại có nhiều mô hình để triển khai hệ thống truyền thông hợp nhất Unified Communication, tuy nhiên với bất kì hệ thống truyền thông hợp nhất nào đều phải dựa trên một thiết bị trung tâm xử lý cuộc gọi, Cisco Unified Communications Manager. Đây là hệ thống xử lý trung tâm cho toàn bộ hệ thống Unified Communcation của hãng Cisco. Dưới đây là một số mô hình triển khai hệ thống IP Telephony dựa trên nền tảng:
    <br/><br/>
    <img src=${img32} alt="">
    <br/><br/>
    <ul>
        <li style="list-style: none;"><strong>Mô hình Single Site</strong>: Mô hình triển khai hệ thống Cisco Unified Communication single site bao gồm một trung tâm xử lý cuộc gọi được đặt duy nhất tại một site. Các dịch vụ IP Telephony sẽ không cung cấp thông qua môi trường mạng WAN. Đối với mô hình triển khai này toàn bộ thiết bị phục vụ cho hệ thống IP Telephony bao gồm hệ thống tổng đài, hệ thống voice gateway, hệ thống Endpoint đều được đặt tại một site trung tâm, các dữ liệu voice sẽ chỉ tập trung tại một điểm duy nhất. Với mô hình triển khai Single Site sẽ cung cấp tính có sẵn cao, khả năng tận dụng cơ sở hạ tầng mạng có sẵn. Khi sử dụng mô hình Single Site, hệ thống có thể hoàn toàn sử dụng chuẩn Codec G.711 cho các dịch vụ thoại, do các dữ liệu thoại không phải đi qua môi trường mạng WAN, nơi có nhiều hạn chế về vấn đề băng thông. Tuy nhiên khi triển khai hệ thống IP Telephony theo mô hình Single Site, các dịch vụ VoIP chỉ tập trung tại một điểm mà không có sự phân tán ra các chi nhánh hoặc các phòng giao dịch khác.</li>
        <br/>
        <li style="list-style: none;"><strong>Mô hình Multi-Site với trung tâm xử lý cuộc gọi tập trung</strong>: Mô hình triển khai Multi-Site được thiết kế theo nguyên tắc có một trung tâm xử lý cuộc gọi được đặt tại hội sở, cung cấp các dịch vụ VoIP cho các site ở xa kết nối đến thông qua hạ tầng mạng IP WAN. Hệ thống mạng WAN sẽ đảm nhiệm việc vận chuyển các traffic VoIP giữa site trung tâm và các site ở xa. Các remote site sẽ dựa vào hệ thống Cisco Unified Communication Manager được triển khai dưới dạng Cluster để sử dụng các dịch vụ như Voice Mail, IVR (interactive voice response). Các ứng dụng này thường được đặt tại một Site trung tâm cùng với Cisco Unified Communication Manager để giảm công sức quản trị và bảo trì.</li>
        <br/>
        <li style="list-style: none;"><strong>Mô hình giao tiếp đa điểm xử lý cuộc gọi phân tán</strong>: Mô hình này chỉ được áp dụng khi một Cisco Unified Communication Manager Cluster không đủ lớn và mạnh để hỗ trợ cho toàn bộ hệ thống hoặc do điều kiện địa lý, hoặc về mặt tổ chức. Mô hình này bao gồm các Cisco Unified Communication Manager cluster kết nối lẫn nhau thông qua đường trunk H.323. Khi đó mỗi một Cisco Unified Communication Manager Cluster sẽ bao gồm các Cisco Unified Communication Manager, gateway, gatekeeper, MCU, thiết bị đầu cuối endpoint, các ứng dụng. Ngoài ra, tính năng quản lý chất lượng dịch vụ QoS phải được áp dụng lên các kết nối WAN giữa các site.</li>
    </ul>
</p>`,
    PScategory : 7
},
{
    id_lv2: 15,
    textItem: "Giải pháp truyền thông đa phương tiện",
    text: `<p>
    <strong>Giới thiệu công nghệ DMS</strong>
    <br/><br/>
    Digital Media Suite - DMS là một giải pháp truyền thông đa phương tiên được phát triển bởi Cisco, DMS làm thay đổi cách thức học tập, truyền thông và cộng tác cho các tập đoàn doanh nghiệp, ngân hàng, bệnh viện và trường học.
    <br/><br/>
    <strong>Bộ sản phẩm của DMS</strong>
    <br/><br/>
    Bộ sản phẩm DMS gồm ba phần tính năng chính đế ứng dụng cho giải pháp truyền đa phương tiện của khách hàng: Digital Sign, Cast và Show and Share.
    <br/><br/>
    <img src=${img33} alt="">
    <ul>
    <li style="list-style: none;"><strong>Digital Sign</strong>:đóng vai trò như bảng điện tử, đảm nhiệm chức năng hiển thị hình ảnh, video, flash,.. trên màn hình LCD. Ngay tên gọi Digital Sign nhằm để khuyến cáo khách hàng thay thế các bảng hiển thị LED truyền thống bằng cách hiển thị nội dung phong phú và sống động hơn trên màn LCD được quản lý bởi hệ thống DMS.</li>
    <br/>
    <li style="list-style: none;"><strong>Cast</strong>:được xem như là hệ thống IP Tivi nội bộ của tổ chức, với Cast khác hàng có thể tùy biến để cung cấp một hệ thống video theo yêu cầu (VoDs) hoặc trực tuyến (live video) nhằm mục đích quảng bá, truyền đạt thông tin và giải trí cho nhân viên, khách hàng và sinh viên.</li>
    <br/>
    <li style="list-style: none;"><strong>Show and Share</strong>:ứng dụng chia sẽ video trong mạng nội bộ và cũng có thể publish ra bên ngoài mạng internet. Show and Share cung cấp cho khách hàng, nhân viên và sinh viên truy cập video hoặc xem trực tiếp (live webcast) trên máy tính cá nhân của họ. Người dùng cũng có thể tải video lên, xem video và ghi các đoạn video trực tiếp từ máy tính cá nhân ở bất cứ nơi đâu thời gian nào</li>
    </ul>
    <strong>Thành phần thiết bị</strong> Hệ thống DMS gồm các thành phần cơ bản như:
    <ul>
    <li style="list-style: none;">Digital Media Manager (DMM) làm nhiệm vụ quản lý các thiết bị đầu cuối</li>
    <br/><li style="list-style: none;">Digital Media Player (DMP) là thiết bị đầu cuối kết nối trực tiếp vào LCD để trình chiếu nội dùng.</li>
    <br/><li style="list-style: none;">Ngoài ra trong hệ thống còn có thể trang bị các thiết bị phụ trợ khác tùy theo nhu cầu của khách hàng như là Encoder, Media Experience Engine.</li>
    </ul>
    <strong>Lợi ích của khách hàng</strong>
    <ul>
    <li style="list-style: none;">DMS sử dụng mạng làm nên tảng cung cấp dịch vụ truyền thông đa phương tiên, DMS đáp ứng cho nhiều ngành nghề khác nhau, được xem như là một công cụ sáng tạo dành cho tiếp thị, bán hàng, giáo dục và đào tạo.</li>
    <br/><li style="list-style: none;">Đẩy mạnh sự phát triển bằng việc quảng cáo các cơ hội khuyến mại sản phẩm và quảng cáo.</li>
    <br/><li style="list-style: none;">Truyền thông bằng hình thức video an toàn và nhanh chóng mọi lúc mọi nơi trên phạm vi rộng</li>
    <br/><li style="list-style: none;">Đẩy mạnh học tập và đào tạo cho nhân viên, khách hàng, đối tác và sinh viên phân tán trên nhiều khu vực địa lý đồng thời.</li>
    <br/><li style="list-style: none;">Tiết kiệm thời gian tiền bạc bằng cách truyền thông video thay vì dành hàng giờ trong những cuộc họp cá nhân.</li>
    <br/><li style="list-style: none;">Truyền thông mang tính bảo mật cho phép tác giả kiểm soát được ai có quyền truy nhập để xem nội dung của họ.</li>
    </ul>
    <strong>Vì sao chọn lựa Cisco</strong>
    <br/><br/>
    Thông qua năng lực đặc biệt tích hợp nhiều thiết bị truyền thông và các định dạng phương tiện, Cisco có thể trợ giúp các công ty cải thiện chất lượng, hiệu suất và hiệu quả truyền thông số theo cách mà các giải pháp cạnh tranh không thể hỗ trợ.
    <ul>
    <li style="list-style: none;"><strong>Giải pháp toàn diện</strong>:Không đối thủ cạnh tranh nào có thể so sánh được với Cisco về giải pháp toàn diện bao gồm webcasting và chia sẻ video, biển hiệu số và các ứng dụng IPTV trong doanh nghiệp</li>
    <br/><li style="list-style: none;"><strong>Tích hợp</strong>:Giải pháp này, không giống với các giải pháp của đối thủ cạnh tranh, được tích hợp chặt chẽ vào mạng và sử dụng tính tin cậy và bảo mật của mạng làm nền tảng. Nó tích hợp hoàn toàn với Cisco Medianet và các hệ thống video doanh nghiệp khác như TelePresence.</li>
    <br/><li style="list-style: none;"><strong>Tạo và cung cấp nội dung một cách hiệu quả</strong>:Chỉ có Cisco mới có thể cung cấp một hệ thống tích hợp nhiều thành phần công nghệ với nhau, giúp tối ưu việc tạo ra nội dung, chỉnh sửa nội dung để có được nội dung phong phú hấp dẫn và hiệu quả.</li>
    <br/><li style="list-style: none;"><strong>Độ tin cậy</strong>:Giải pháp Cisco DMS sử dụng đầu kỹ thuật số bán dẫn có độ tin cậy cao để tránh tỷ lệ lỗi cao của máy tính cá nhân.</li>
    <br/><li style="list-style: none;"><strong>Tính khả mở</strong>:Giải pháp Cisco DMS cung cấp một nền tảng biển hiệu số có thể mở rộng tới 1000 điểm cuối</li>
    </ul>
    </p>`,
    PScategory : 7
},
{
    id_lv2: 16,
    textItem: "Giải pháp hội nghị truyền hình Cisco TelePresence",
    text: `<p>
    <strong>Tổng quan</strong> Cisco TelePresence là một giải pháp tiêu chuẩn cho hội nghị truyền thông cung cấp các trải nghiệm ba chiều, cá nhân, kết nối các nhóm và cá nhân theo nhiều cách khác nhau, mở rộng cộng tác trực diện trong phạm vị doanh nghiệp, các đối tác và nhà cung cấp. Không còn bó hẹp trong mục đích tiết kiệm chi phí đi lại khi tổ chức các cuộc họp như truyền thống, Cisco TelePresence hướng đến việc xây dựng một môi trường làm việc cộng tác với khả năng tương tác đa dạng, thay đổi cách thức và mô hình vận hành của doanh nghiệp với các trải nghiệm hoàn toàn mới.
    <br/><br/>
    Với sự phát triển của hội thảo video truyền thống từ độ nét tiêu chuẩn (SD) sang chất lượng cao (HD), hệ thống danh mục các điểm cuối đa dạng và các thành phần hạ tầng hoàn chỉnh của Cisco TelePresence phù hợp cho tất cả các đối tượng khách hàng và mô hình doanh nghiệp khác nhau cho dù khách hàng chọn lựa giải pháp đầu tư mới hay mở rộng hệ thống hiện có. Thân thiện với người dùng, TCO hiệu quả, hiệu suất đầu tư cao là các ưu điểm của giải pháp Cisco TelePresence.
    <br/><br/>
    <strong>Dải sản phẩm Cisco TelePresence</strong> Cisco TelePresence có danh mục các sản phẩm bao gồm: thiết bị đầu cuối (endpoint), các thành phần cơ sở hạ tầng (infrastructure components) và khả năng liên công ty (intercompany capabilities)
    <br/><br/>
    Các thiết bị đầu cuối được thiết kế tối ưu cho các môi trường khác nhau:
    <ul>
    <li style="list-style: none;">Ba chiều (Immersive): các phòng TelePresence được thiết kế tối ưu và tùy chỉnh thích hợp tạo cảm giác giao tiếp ảo như mặt đối mặt. Bao gồm tất cả các thành phần như ánh sáng, camera, bàn, microphones…</li>
    <br/><li style="list-style: none;">Đa mục đích (Multipurpose): các trải nghiệm TelePresence tối ưu hóa cho nhiều loại phòng họp và môi trường khác nhau.</li>
    <br/><li style="list-style: none;">Cá nhân (Personal): thiết kế dành riêng cho người dùng cá nhân tại văn phòng hay tại nhà với các ứng dụng làm việc uyển chuyển.</li>
    </ul>
    <br/><br/>
    <img src=${img34} alt="">
    <br/><br/>
    Management: Cisco TelePresence Manager, Cisco TelePresence Management Suite
    <br/><br/>
    <strong>Các ứng dụng của giải pháp Cisco TelePresence</strong>
    <br/><br/>
    Cisco TelePresence có phạm vi ứng dụng rất rộng cho phép bạn làm được nhiều việc hơn với ít chi phí hơn, thay đổi tổ chức và thân thiện hơn với môi trường. Cisco TelePresence cho phép bạn hợp nhất tổ chức và thay đổi cách thức giao tiếp và trao đổi thông tin với sự sẵn sàng nguồn lực quan trọng ở bất kể địa điểm nào. Nhiều ứng dụng và quy trình kinh doanh đang được thay đổi bằng cách sử dụng Cisco TelePresence như một công cụ chiến lược, ví dụ như:
    <br/><br/>
    <ul>
    <li style="list-style: none;">Các ứng dụng tài chính bao gồm các cửa sổ giao dịch ảo và các chuyên gia văn phòng chi nhánh ngân hàng từ xa.</li>
    <br/><li style="list-style: none;">Tư vấn cá nhân với chuyên gia từ xa cho các cửa hàng bán lẻ.</li>
    <br/><li style="list-style: none;">Dịch vụ hỗ trợ khách hàng trực quan (face to face).</li>
    <br/><li style="list-style: none;">Hỗ trợ phỏng vấn và nguồn nhân lực từ xa.</li>
    </ul>
    <strong>Các lợi ích khi triển khai Cisco TelePresence</strong>
    <br/><br/>
    Với danh mục sản phẩm toàn diện và cấu trúc tích hợp, Cisco TelePresence cho khả năng tương thích hoàn toàn dựa trên các tiêu chuẩn và có tính năng thân thiện với người sử dụng. Với Cisco TelePresence, bạn có thể:
    <br/><br/>
    <ul>
    <li style="list-style: none;">Kết nối trực diện với các khách hàng, đối tác và đồng nghiệp, xây dựng lòng tin, sự thông hiểu và các mối quan hệ. Các trải nghiệm video hoàn toàn khác biệt so với thoại truyền thống.</li>
    <br/><li style="list-style: none;">Sắp xếp các giám đốc điều hành và chuyên gia theo cách thức chưa từng có trước đây, thay đổi mô hình kinh doanh của bạn và tạo ra sự khác biệt cạnh tranh</li>
    <br/><li style="list-style: none;">Tăng năng suất làm việc bằng khả năng tương tác nhiều hơn và giảm bớt sự di chuyển.</li>
    <br/><li style="list-style: none;">Đẩy nhanh thời gian thâm nhập thị trường và đưa ra quyết định nhanh hơn với đúng nguồn lực sẵn có vào đúng thời điểm.</li>
    <br/><li style="list-style: none;">Giải phóng tài nguyên CNTT với khả năng dễ sử dụng và vận hành đơn giản để tập trung vào các dự án chiến lược thay vì hoạt động bảo trì vận hành</li>
    </ul>
    </p>`,
    PScategory : 7
},
{
    id_lv2: 17,
    textItem: "Thiết kế hệ thống nguồn điện",
    text: `<p>
    TTDL sẽ dùng nguồn điện lưới của tòa nhà, với hệ thống tự động chuyển mạch (ATS) giữa điện lưới và nguồn điện máy phát tòa nhà. Tủ điện tổng TTDL sẽ cấp nguồn cho hệ thống nguồn điện không gián đoạn (UPS), và hệ thống UPS sẽ cung cấp nguồn điện ổn định cho các thiết bị CNTT. Trong trường hợp mất điện lưới, hệ thống UPS sẽ sử dụng điện lưu trong ắcquy để cấp nguồn điện liên tục cho các thiết bị CNTT và các thiết bị an ninh (quản lý truy cập, PCCC). Thời gian vận hành bằng ắcquy được tính toán để bảo đảm nguồn điện liên tục trong vòng 20 phút tại tổng tải các thiết bị CNTT .
    <br/><br/>
    Tủ điện tổng TTDL cũng sẽ cung cấp nguồn điện cho các UPS, Tủ điện CRAC cung cấp cho 2 cooling Uniflair, tủ điện ELV cung cấp cho các thiết bị dân dụng, chiếu sáng.
    <br/><br/>
    Thiết kế mặt bằng TTDL:
    <br/><br/>
    TTDL được triển khai trên diện tích 52m2 tại tòa nhà văn phòng. Thiết kế mặt bằng đáp ứng theo yêu cầu của ngân hàng như sau:
    <br/><br/>
    Trong phòng DC chứa các thiết bị 2 máy lạnh Uniflair, 1 tủ UPS, 1 tủ Battery, 07 rack server, 4 rack network. Với tổng công suất 32kW
    <br/><br/>
    Trong thiết kế theo phương án sử dụng máy lạnh thổi sàn, các tủ rack server được bố trí thành 01 hàng, Tạo thành 01 hành lang lạnh & 01 hành lang nóng. Các hệ thống lạnh thổi sàn sẽ được bố trí đối xứng nhau khí nóng được hút về phía trên và thổi khí lạnh xuống phía dưới sàn nâng tạo một áp lực dương dưới sàn nâng nhằm đưa khí lạnh đến các khu vực mong muốn bằng cách sử dụng các tấm sàn lỗ.
    </p>`,
    PScategory : 8
},
{
    id_lv2: 18,
    textItem: "Thiết kế hệ thống điều hòa không khí chính xác",
    text: `<p>
    TTDL sẽ được trang bị hệ thống điều hòa không khí (ĐHKK) chính xác nhằm kiểm soát nhiệt độ môi trường từ 20-24°C và độ ẩm tương đối từ 40-55%. Hệ thống ĐHKK được sử dụng trong thiết kế phòng Server là hệ thống thổi sàn (downflow). Các hệ thống lạnh được đặt trong cùng phòng với các tủ rack server. Tổng công suất thiết kế đáp ứng công suất điện (công suất tỏa nhiệt) của các thiết bị CNTT với tính dự phòng N+1:
    <ul>
    <li style="list-style: none;">Sử dụng 02 hệ thống lạnh, đáp ứng công suất làm mát 39kw/máy với 39W dự phòng. Nhằm bảo đảm sự hiệu quả trong vận hành, Thiết kế này đáp ứng tính dự phòng N+1.</li>
    <br/><li style="list-style: none;">Công nghệ lạnh giải nhiệt bằng khí bao gồm 01 dàn ngưng giải nhiệt đặt ngoài trời cho mỗi hệ thống. Các dàn ngưng sẽ được đặt tại khu vực phía sau của tòa nhà .</li>
    </ul>
    </p>`,
    PScategory : 8
},
{
    id_lv2: 19,
    textItem: "Thiết kế hệ thống an ninh & phòng cháy chữa cháy",
    text: `<p>Cửa ra vào TTDL được trang bị đầu đọc thẻ từ, kết hợp phím mật mã nhằm bảo đảm mức độ quản lý truy cập cao. Hệ thống phòng cháy chữa cháy (PCCC) sử dụng FM200. Các hệ thống an ninh & PCCC được trang bị nguồn ắc quy riêng, có thể lưu điện & vận hành liên tục 24giờ trong trường hợp mất điện.</p>`,
    PScategory : 8
},
{
    id_lv2: 20,
    textItem: "Thiết kế xây dựng",
    text: `<p>Toàn bộ các tuyến cáp điện sẽ được bố trí dưới sàn nâng và sẽ lắp đặt sàn nâng trong khu vực TTDL. Mặt trước sẽ được xây dựng lại vách thạch cao chống cháy 1 giờ bao gồm bông cách nhiệt. Vách thạch cao kéo dài từ sàn bê tông đến trần giả nhằm bảo đảm độ kín tuyệt đối. Cửa ra vào là loại cửa thép chống cháy 1 giờ.</p>`
    ,PScategory : 8
},
{
    id_lv2:21,
    textItem: "Thiết kế hệ thống quản trị tập trung",
    text: `<p>
    Hệ thống quản trị tập trung đáp ứng 04 chức năng chính:
    <ul>
    <li style="list-style: none;">Giám sát tập trung các camera an ninh bố trí trong TTDL.</li>
    <br/><li style="list-style: none;">Giám sát nhiệt độ & độ ẩm tại các khu vực cần thiết trong các phòng & rò rỉ nước trong TTDL.</li>
    <br/><li style="list-style: none;">Giám sát & quản lý tập trung các hệ thống UPS, CRAC, thanh PDU phân phối nguồn cho các tủ rack.</li>
    <br/><li style="list-style: none;">Quản lý & gửi các cảnh báo theo điều kiện cài đặt cho các nhân viên quản lý TTDL theo nhiều dạng thức (SMS, email,…).</li>
    </ul>
    Hệ thống quản trị bao gồm server & các thiết bị điện tử cần thiết (appliance, sensor, camera) được hãng APC trực tiếp sản xuất & tích hợp. Các hệ thống sử dụng nguồn thông qua UPS nên sẽ không bị gián đoạn khi mất điện lưới & khi máy phát điện khởi động.
    </p>`,
    PScategory : 8
},
{
    id_lv2: 22,
    textItem: "Công nghệ máy chủ",
    text: `<p>
    Máy chủ là một thành phần không thể thiếu trong hệ thống công nghệ thông tin, có thể coi đây chính là bộ não trung tâm của cả hệ thống, được chia làm nhiều dòng khác nhau tùy thuộc vào nhu cầu sử dụng. Hiện nay, HSV đang là đối tác của nhiều hãng như Cisco UCS, IBM, HP, Dell … Máy chủ được chia làm ba phân khúc chính:
    <br/><br/>
    Dòng Entry Level: dành phục vụ cho các nhu cầu thấp, chủ yếu cho các doanh nghiệp nhỏ hoặc các ứng dụng không cần cấu hình cao. Các máy chủ phân khúc này bao gồm máy chủ dạng Tower như IBM x3100, Dell PowerEdge T-series, HP ProLiant ML-Series và các máy chủ dòng Rack như IBM x3250, x3530, UCS C22. Đặc điểm của dòng máy chủ này là sử dụng một vi xử lý thế hệ Intel E3 hoặc E5-2400 series.
    <br/><br/>
    <img src=${img35} alt="">
    <br/><br/>
    Dòng Midrange Level: dành phục vụ cho các doanh nghiệp tầm nhỏ và trung, có khả năng đáp ứng cho các hệ thống đòi hỏi tốc độ xử lý cao và nhu cầu ảo hóa. Đây là dòng máy chủ thông dụng nhất ở thị trường Việt Nam gồm một số đại diện như Cisco UCS C220, C240, C460 hoặc IBM x3650, x3850, Dell PowerEdge R720, R820, R910. Đặc điểm của dòng máy chủ này là sử dụng được nhiều vi xử lý thế hệ Intel E5, hỗ trợ rất tốt cho ảo hóa và các ứng dụng nặng.
    <br/><br/>
    <img src=${img36} alt="">
    <br/><br/>
    Dòng Enterprise: dành phục vụ cho các hệ thống có yêu cầu rất cao về hiệu năng cũng như khả năng ảo hóa, hỗ trợ nhiều vi xử lý, nhiều bộ nhớ, cung cấp bang thông IO truyền dữ liệu rất cao, đại diện cho phân khúc này là các máy chủ dạng kiến trúc Blade. Ưu điểm của dòng kiến trúc này đó là sự tiết kiệm cũng như hiệu năng tối ưu về năng lực xử lý, điện năng tiêu thụ, quản lý và nâng cấp.
    <br/><br/>
    Một hệ thống máy chủ Blade gồm hai thành phần đó là Blade Chassis và Blade Server.
    <br/><br/>
    Blade Chassis là một thiết bị dang khung, cung cấp kết nối IO, Power, Management cho toàn bộ hệ thống Blade.
    <br/><br/>
    Blade server là máy chủ dạng phiến, đầy đủ các thành phần như một máy chủ thông thường nhưng được thiết kế đặc biệt với các slot tương ứng với chassis.
    </p>`,
    PScategory : 9
},
{
    id_lv2: 23,
    textItem: "Giải pháp lưu trữ",
    text: `<p>
    Về phân loại theo Phân khúc thì được chia làm 3 phân khúc chính:
    <br/><br/>
    Dòng Entry Level: dành phục vụ cho các nhu cầu thấp, chủ yếu cho các doanh nghiệp nhỏ hoặc các ứng dụng không cần IOPS cao, khả năng mở rộng tương đối thấp và rẻ tiền với một số tính năng căn bản như Thin Provisioning, Snapshot, Đại diện cho dòng này có thể kể ra như: NetApp FAS2200, IBM Storwize V3700, Dell PowerVault MD3 Series, EMC VNXe3150/3300, VNX5100 Entry Storage Arrays, HDS Hitachi Unified Storage 110 Entry Storage Array, HP 3PAR StoreServ 7200 Entry Storage Arrays.
    <br/><br/>
    <img src=${img37} alt="">
    <br/><br/>
    Dòng Midrange Level: dành phục vụ cho các doanh nghiệp tầm nhỏ và trung, có khả năng đáp ứng cho các hệ thống đòi hỏi IOPS cao và khả năng mở rộng tốt. Đặc điểm chung của dòng này đó là sử dụng các tính năng tối ưu hóa dữ liệu như Deduplicate, Compress, Easy Tier. Ngoài ra so với dòng Entry Level, dòng này bổ sung thêm các tính năng cho phép đồng bộ dữ liệu từ xa, xây dựng DR Site. Đại điện cho dòng này gồm các sản phẩm NetApp FAS/V 3200/6200, EMC - VNX5000, HP 3PAR StoreServ 7400, HDS Hitachi Unified Storage (HUS 110/130/150 & HUS VM).
    <br/><br/>
    <img src=${img38} alt="">
    <br/><br/>
    Dòng Enterprise: dành phục vụ cho các hệ thống có yêu cầu rất cao về IOPS, hiệu năng cũng như khả năng ảo hóa, cung cấp nhiều tính năng cao cấp như ảo hóa lưu trữ, snapshot, native compress, realtime deduplicate. Đại diện cho dòng này có thể kể như HP 3PAR StoreServ 10000, IBM DS8000, Hitachi Virtual Storage Platform (VSP), Hitachi Unified Storage VM (HUS VM), EMC Symmetrix VMAX 10K, VMAX 20K, and VMAX 40K.
    <br/><br/>
    <img src=${img39} alt="">
    </p>`,
    PScategory : 9
},
{
    id_lv2: 24,
    textItem: "Tổng quan về VDI",
    text: `<p>
    Giải pháp Ảo hóa (Virtual Destkop Infrastructure - VDI) là giải pháp sử dụng tài nguyên điện toán từ các máy chủ kết hợp với công nghệ ảo hóa để tạo ra hệ thống các máy trạm, lúc này là các máy ảo. Người dùng sẽ truy cập đến hệ thống và được cấp phát máy ảo để sử dụng từ xa.
    <br/><br/>
    Những hình ảnh về giao diện của các máy ảo đang hoạt động được truyền về màn hình của thiết bị đầu cuối của người dùng, cũng như các thao tác của người dùng(bàn phím, chuột..) diễn ra hoàn toàn bình thường thông qua các giao thức hiện thị từ xa(Desktop Display Protocol) được thiết lập giữa thiết bị truy cập của người dùng và máy trạm bên trong trung tâm dữ liệu.
    <br/><br/>
    <strong>Mô hình giải pháp</strong>
    <br/><br/>
    Mô hình tổng quan một giải pháp VDI được thể hiện như dưới đây
    <br/><br/>
    <img src=${img40} alt="">
    <br/><br/>
    Trong đó:
    <br/><br/>
    <strong>Hệ thống máy chủ (Physical Server)</strong>
    <br/><br/>
    Gồm các máy chủ có năng lực mạnh mẽ để đáp ứng đủ các nhu cầu điện toán (CPU, memory, network, IO…) cho hệ thống các máy trạm là các máy ảo.
    <br/><br/>
    <strong>Hệ thống phần mềm (Phần mềm ảo hóa và Quản lý hạ tầng ảo hóa)</strong>
    <br/><br/>
    Các máy chủ sẽ được ảo hóa để tạo môi trường cho hàng loạt các máy trạm hoạt động. Bên cạnh đó là chức năng quản lý, triển khai, cấp phát, thu hồi các máy trạm và thực thi các chính sách trong quá trình sử dụng của người dùng khi kết nối đến hệ thống các máy trạm này (các Connection Server trong mô hình).
    <br/><br/>
    Connection Server thực hiện các chức năng:
    <br/><br/>
    <strong>Xác thực người dùng.</strong>
    <br/><br/>
    Quản lý, cấp phát các máy trạm ảo hóa cho người dùng. V
    <br/><br/>
    Với hệ thống VDI, các máy trạm được nhóm thành các Pool, cho phép một nhóm người dùng cùng chia sẻ một nhóm máy trạm.
    <br/><br/>
    Triển khai các máy trạm mới một cách nhanh chóng, tương tác với thành phần đóng vai trò quản lý hạ tầng ảo hóa (Virtualization Management Server) để yêu cầu khởi tạo các máy trạm mới.
    <br/><br/>
    Thu hồi các máy trạm đã cấp cho người dùng.
    <br/><br/>
    Connection Server chịu trách nhiệm tương tác với người dùng nội bộ bên trong hệ thống. Đối với người dùng truy cập từ bên ngoài Internet, chức năng này sẽ được đảm nhiệm bởi các Security Server, được bổ sung các tính năng xác thực chặt chẽ cũng như đảm bảo quá trình trao đổi dữ liệu giữa người dùng và máy trạm được mã hóa an toàn.
    <br/><br/>
    <strong>Hệ thống lưu trữ (SAN Storage)</strong>
    <br/><br/>
    Cung cấp môi trường lưu trữ tập trung cho các máy trạm ảo hóa. Việc tổ chức các máy trạm nằm tập trung đòi hỏi những yêu cầu lớn về năng lực cho hệ thống lưu trữ. Hơn nữa, khả năng chia sẽ các phân vùng của hệ thống SAN là cần thiết để đảm bảo độ ổn định, vững bền và nâng cao tính sẵn sàng cho sự hoạt động của hệ thống.p
    <br/><br/>
    <strong>Thiết bị đầu cuối</strong>
    <br/><br/>
    Người dùng sử dụng các thiết bị được cài đặt phần mềm chuyên dụng để truy cập từ xa tới các máy trạm. Các thiết bị đầu cuối giờ đây chỉ là phương tiện tạo kết nối từ xa đến các máy trạm cho người dùng, vì vậy các yêu cầu về năng lực phần cứng cho thiết bị đầu cuối này sẽ đơn giản hơn rất nhiều so với các máy cá nhân được cấp cho người dùng trước đây(là các Thin Client), giúp tiết kiệm chi phí mua sắm sau này khi hệ thống được triển khai
    <br/><br/>
    Các máy tính truyền thống cũng có thể cài đặt các phần mềm kết nối đề sử dụng làm thiết bị truy cập (là các Fat Client), đảm bảo khả năng tương thích và tận dụng các thiết bị của hệ thống cũ cho hệ thống mới
    </p>`,
    PScategory : 10
},
{
    id_lv2: 25,
    textItem: "Đánh giá hệ thống VDI",
    text: `<p>
    <strong>1.Lợi ích đem lại cho hoạt động doanh nghiệp khi triển khai VDI</strong>
    <br/><br/>
    <strong>1.1 Ưu điểm với người dùng đầu cuối</strong>
    <br/><br/>
    Người dùng được hưởng lợi từ hệ thống VDI với những lợi ích sau đây :
    <br/><br/>
    Truy cập linh hoạt : Với một thiết bị có đường truyền kết nối tới trung tâm dữ liệu ( Máy tính để bàn, laptop, thin client, tablet…) là người dùng có thể tiếp cận với môi trường làm việc quen thuộc của mình
    <br/><br/>
    An toàn dữ liệu và sự ổn định: Hạ tầng phục vụ các máy trạm giờ đây là các thiết bị sử dụng trong DataCenter có sự bền vững và ổn định hơn rất nhiều so với các các máy trạm truyền thống sử dụng các thiết bị thông thường. Nhờ tính chất tập trung, người quản trị có khả năng quản lý tốt hơn tới các máy trạm cho phép giảm bớt các nguy cơ, sự cố xảy ra với hệ điều hành ( Lỗi phần mềm, virus…). Điều này giúp cho quá trình sử dụng của người dùng được thông suốt và ít bị gián đoạn.
    <br/><br/>
    <strong>1.2 Ưu điểm trong việc vận hành quản trị hệ thống</strong>
    <br/><br/>
    Là giải pháp với mô hình được Cisco thiết kế, kiểm thử và qui chuẩn thành tài liệu ( Cisco Validated Design), dưới đây là những lợi ích có thể nhìn thấy ngay mà hệ thống VDI với giải pháp do Cisco đề xuất đem lại cho người quản trị:
    <br/><br/>
    Quản lý hệ thống máy chủ tập trung tại một điểm với UCS Manager
    <br/><br/>
    Người quản trị chỉ cần đăng nhập vào một điểm để quản lý hạ tầng máy chủ, cấu hình các thông số Nework và SAN ở lớp Access thay vì phải đăng nhập nhiều lần vào chassis, server, LAN Switch, SAN Switch như trước đây.
    <br/><br/>
    Thiết bị Fabric Interconnect hỗ trợ các cổng FcoE cùng FC đem lại kết nối hợp nhất thay cho kết nối LAN và SAN tách biệt như trước đây, giúp cho việc triển khai ban đầu và vận hành trong sử dụng được đơn giản hóa.
    <br/><br/>
    Quản lý hệ thống máy trạm thuận tiện nhờ công nghệ ảo hóa
    <br/><br/>
    Các công việc mà một người quản trị phải xử lý đối với hệ thống máy trạm đều trở nên hết sức dễ dàng và tiện lợi :
    <br/><br/>
    Nhanh chóng triển khai hệ thống máy trạm mới: Thời gian dành cho việc khởi tạo các máy trạm và có thể cấp cho người dùng trở nên nhanh chóng với khoảng thời gián từ 20-30 phút, thay vì phải mua sắm thiết bị và cài đặt hệ điều hành hay phần mềm với thời gian chuẩn bị có thể tính theo ngày, tuần.
    <br/><br/>
    Cấp phát linh hoạt: Việc cấp phát máy trạm cho người dùng có thể theo nhu cầu thực tế sử dụng thay vì theo ước đoán. Cho phép sự một máy trạm phục vụ nhiều người dùng vào các thời điểm khác nhau, giúp nâng cao hiệu quả sử dụng tài nguyên
    <br/><br/>
    Dễ dàng thu hồi và tái sử dụng: Những máy trạm phục vụ cho nhu cầu tạm thời được cấp phát nhanh chóng và thu hồi lại tài nguyên đã cấp một cách dễ dàng đơn giản bằng việc xóa khỏi hệ thống hoặc lưu trữ các máy trạm ở trạng thái offline.
    <br/><br/>
    Dễ dàng chuẩn hóa và cập nhật các thay đổi lên máy trạm: Sử dụng một bản mẫu ban đầu để tạo ra các máy trạm cho người dùng sẽ đảm bảo được các máy trạm đều có những cấu hình giống nhau, và khi cần thực hiện thay đổi, người quản trị chỉ cần thực hiện thay đổi lên bản mẫu ban đầu là những thay đổi đó sẽ được đẩy tới các máy đã tạo ra từ bản mẫu này.
    <br/><br/>
    Dễ dàng khắc phục sự cố: Việc các máy trạm tập trung ngay tại Datacenter giúp cho người quản trị sẽ không còn phải xuống hỗ trợ tận nơi để xứ lý các sự cố với trạm của người dùng.
    <br/><br/>
    Nâng cao bảo mật dữ liệu : Dữ liệu trên máy trạm của người dùng nằm ngay tại Datacenter, thay vì nằm rải rác và trên hệ thống lưu trữ cục bộ, vốn tiềm ẩn rủi ro và rất khó kiểm soát cho người quản trị
    <br/><br/>
    <strong>1.3 Ưu điểm về chi phí đầu tư và chi phí duy trì</strong>
    <br/><br/>
    Chi phí đầu tư ban đầu dành cho một máy trạm VDI lớn hơn so với máy trạm PC truyền thống. Tuy vậy, tổng chi phí duy trì hệ thống tính trong khoảng thời gian 5 năm với 350 người dùng của hệ thống VDI thấp hơn, nhờ sự giảm bớt được những chi phí về:
    <br/><br/>
    Phí sửa chữa: Hệ thống VDI hoạt động trên hạ tầng các thiết bị dành cho trung tâm dữ liệu có sự ổn định và bền vững hơn rất nhiều so với các thiết bị của PC truyền thống
    <br/><br/>
    Chi phí về điện năng: Người dùng giờ đây sử dụng các thiết bị Thin Client / Zero Client để truy cập vào máy trạm, đây là những thiết bị nhỏ gọn và điện năng tiêu thụ thấp hơn nhiều so với các cây máy tính. Cộng cả điện năng tiêu thụ của các máy chủ phục vụ cho hạ tầng VDI vẫn thấp hơn nhiều khi tính tổng điện năng tiêu thụ so với các máy PC
    <br/><br/>
    Chi phí cho đội ngũ IT: Đội ngũ IT được tinh giảm và có thể quản lý được lượng lớn các máy trạm, thay vì phải duy trì đội ngũ nhân lực tại nhiều nơi.
    <br/><br/>
    Hình trên minh họa rằng việc triển khai VDI trên các máy chủ 4 và 2 CPU truyền thống đạt được mức tiết kiệm từ $1,223 và $954 trên mỗi người dùng, trong khi triển khai VDI trên Hệ thống Điện toán Hợp nhất của Cisco đạt được mức tiết kiệm tăng thêm là $304 trên mỗi người dùng để đạt được tổng mức tiết kiệm là $1,527 so với máy PC truyền thống.
    <br/><br/>
    Phần lớn tiết kiệm mà hệ thống UCS mang lại có được (khoảng 60%) là nhờ giảm được chi phí hoạt động liên quan đến quản trị, cấp nguồn và làm mát, nhờ các công nghệ mà hệ thống UCS sử dụng, đó là :
    <br/><br/>
    Khả năng quản lý tập trung: Hệ thống UCS cho phép quản lý hạ tầng máy chủ , Access Switch, SAN Switch phục vụ cho các hạ tầng máy chủ này cùng tại một điểm, là sự khác biệt so với các hệ thống khác khi 3 thành phần này là các điểm quản trị khác nhau. Điều nay giúp tiết kiệm về nhân lực cùng các chi phí bảo trì và hỗ trợ phần mềm.
    <br/><br/>
    Hợp nhất kết nối LAN và SAN: Sử dụng công nghệ FcoE và các cạc mạng ảo hóa hợp nhất, điều nay giúp cắt giảm đáng kể các chi phí về :
    <br/><br/>
    Chi phí cho thiết bị chuyển mạch, cạc NIC, HBA, cáp mạng khi không còn phải sử dụng hạ tầng LAN và SAN tách biệt
    <br/><br/>
    Điện năng tiêu thụ, nguồn, làm mát, khi số lượng thiết bị được giảm bớt
    <br/><br/>
    Chi phí về không gian
    <br/><br/>
    <strong>2. Một số nhược điểm của hệ thống VDI và những giải pháp khắc phục</strong>
    <br/><br/>
    Trong mô hình hệ thống VDI, các máy trạm được ảo hóa và tập trung tại trung tâm dữ liệu đem lại những ưu điểm và thuận tiện so với mô hình truyền thống. Tuy nhiên nó cũng có những nhược điểm cần được xem xét, tính toán để có giải pháp khắc phục. Những nhược điểm này cũng xuất phát từ chính việc tập trung hóa các máy trạm lại tại trung tâm dữ liệu khi nó làm gia tăng sự phụ thuộc vào năng lực của trung tâm dữ liệu cũng như đặt ra những đòi hỏi cần được đáp ứng
    <br/><br/>
    <strong>2.1 Sự phụ thuộc vào đường truyền kết nối tới trung tâm dữ liệu</strong>
    <br/><br/>
    Người dùng có thể truy cập tới máy trạm của mình một cách linh hoạt hơn, nhưng với điều kiện là việc kết nối tới trung tâm dữ liệu cũng cần đảm bảo cho phép người dùng có nhiều lựa chọn và đảm bảo được tính sẵn sàng cũng như yếu tố dự phòng.
    <br/><br/>
    Giải pháp cho phép người dùng vẫn có thể sử dụng ngay cả khi đường truyền kết nối đến trung tâm dữ liệu bị mất hoàn toàn, đó là sử dụng chế độ Local Mode của phần mềm Vmware View Client trên máy người dùng truy cập:
    <br/><br/>
    Các thông tin về máy trạm ảo hóa sẽ được tải về trên máy người dùng, cho phép sử dụng mà không cần kết nối trực tiếp tới trung tâm dữ liệu. Khi kết nối được phục hồi, những thay đổi sẽ được chuyển đến và đồng bộ với máy trạm tại trung tâm dữ liệu.
    <br/><br/>
    Yếu tố phụ thuộc thứ hai là tốc độ đường truyền tới trung tâm dữ liệu ảnh hưởng rất lớn đến trải nghiệm sử dụng của người dùng, đặc biệt trên các đường truyền có tốc độ giới hạn hay không được đảm bảo. Các ứng dụng nhạy cảm với sự thay đổi với hình ảnh hay các thao tác di chuyển của người dùng sẽ trở nên khó khăn nếu hình ảnh đầu ra của máy trạm truyền đi trên kết nối băng thông thấp và có độ trễ cao. Giải quyết vấn đề này thì việc tối ưu quá trình trao đổi giữ liệu trên đường truyền phục vụ người dùng từ xa đến trung tâm dữ liệu là một giải pháp cần thiết.
    <br/><br/>
    <img src=${img41} alt="">
    <br/><br/>
    <strong>2.2 Những đỏi hỏi về năng lực của hệ thống trung tâm dữ liệu</strong>
    <br/><br/>
    Tài nguyên cũng như dữ liệu của các máy trạm giờ đây nằm ngay tại hệ thống trung tâm dữ liệu đặt ra những yêu cầu mà hệ thống trung tâm dữ liệu cần đáp ứng hoặc nâng cao để có thể đảm bảo được những ưu thế đã đem lại:
    <br/><br/>
    Độ sẵn sàng và tính dự phòng: Việc tập trung lại các máy ảo cũng đem lại rủi ro về “điểm chết” (single point of failure) của hệ thống, cũng như tầm ảnh hưởng khi có sự cố sẽ rộng và lớn hơn rất nhiều, không chỉ là một vài máy trạm riêng lẻ nữa. Vì vậy các tính toán để đảm bảo tính sẵn sàng và dự phòng cần được xem xét một cách kĩ lưỡng và chặt chẽ.
    <br/><br/>
    Yêu cầu về năng lực điện toán: Việc số lượng lớn các máy trạm sử dụng ứng dụng nặng, tiêu tốn tài nguyên sẽ tạo ra lượng tải khổng lồ lên hạ tầng hệ thống tại trung tâm dữ liệu.
    <br/><br/>
    Yêu cầu cho hệ thống mạng: Các máy trạm có “khoảng cách” gần hơn tới các máy chủ dịch vụ, băng thông và tốc độ đường truyền được nâng cao nhưng nó đem lại các vấn đề về bảo mật, QoS cũng như cần có cơ chế đảm bảo các chính sách được áp dụng tới các máy ảo có khả năng linh động
    <br/><br/>
    Yêu cầu về hệ thống lưu trữ: Hạ tầng lưu trữ cần được tính toán cẩn thẩn và có năng lực đáp ứng được cho hệ thống VDI khi dữ liệu vào ra từ các máy trạm đổ dồn về hệ thống lưu trữ của trung tâm dữ liệu
    <br/><br/>
    Yêu cầu về con người: Nhân lực IT có thể được giảm bớt so với mô hình máy trạm truyền thống nhưng hạ tầng VDI liên quan chặt chẽ tới các vấn đề khác nhau trong hệ thống trung tâm dữ liệu với những yêu cầu cao hơn, vì thế đội ngũ quản trị cần được đào tạo và có kiến thức chuyên sâu để có thể vận hành hệ thống.
    </p>`,
    PScategory : 10
},
{
    id_lv2: 26,
    textItem: "Giải pháp Tường lửa",
    text: `<p><img src=${img42} alt=""></p>`,
    PScategory : 11
},
{
    id_lv2: 27,
    textItem: "Giải pháp Antivirus",
    text: `<p><img src=${img43} alt=""></p>`,
    PScategory : 11
},
{
    id_lv2: 28,
    textItem: "Giải pháp chống DDOS",
    text: `<p><img src=${img44} alt=""></p>`,
    PScategory : 11
},
{
    id_lv2: 29,
    textItem: "Giải pháp quét lỗ hổng",
    text: `<p><img src=${img45} alt=""></p>`,
    PScategory : 11
},
{
    id_lv2: 30,
    textItem: "Xu hướng phát triển CNTT",
    text: `<p>
    <strong>1. Xây dựng kết nối đa dạng</strong>
    <br/><br/>
    Yêu cầu xây dựng kết nối đảm bảo hoạt động các ứng dụng ngày càng đa dạng và phổ biến, từ các ứng dụng cơ bản như phần mềm nghiệp vụ, email, Web … trước đây, nhu cầu kết nối mạng đã phát triển cho rất nhiều các ứng dụng khác như Voice, Video, Video Conferencing, Data backup … Vì vậy, hệ thống mạng không chỉ phải đáp ứng các yêu cầu kết nối cơ bản mà còn phải đáp ứng các yêu cầu cao hơn về băng thông, chất lượng dịch vụ.
    <br/><br/>
    <strong>2. Xây dựng trung tâm dữ liệu</strong>
    <br/><br/>
    Ttrước đây trung tâm lưu trữ dữ liệu trong doanh nghiệp chưa được quan tâm và đầu tư đúng mức, chưa tương xứng với sự phát triển không ngừng và mở rộng hoạt động kinh doanh của doanh nghiệp. Chính vì sự thiếu quan tâm này kéo theo doanh nghiệp phải đối mặt nhiều thách thức không đáng có . Từ những vấn đề trên, hiện nay các doanh nghiệp vừa và lớn ở Việt Nam đã nhìn nhận vai trò quan trọng của Data center là ưu tiên hàng đầu, không thể thiếu, những lợi ích cũng như hiệu quả mà Data Center mang lại nhằm phục vụ nhu cầu kinh doanh rất to lớn , do đó việc chú trọng đầu tư vào Data Center là quyết định đúng đắn và mang tính bắt buộc, nó được ví như là “trái tim” trong môi trường IT và kinh doanh của doanh nghiệp.
    <br/><br/>
    <strong>3. Đảm bảo vấn đề về bảo mật</strong>
    <br/><br/>
    Đối với các doanh nghiệp, các tổ chức internet là một kho dữ liệu, một công cụ, một môi trường đặc biệt hữu hiệu cho các hoạt động của họ. Sự phát triển gần như không có biên giới của Internet , việc kết nối hệ thống mạng của các tổ chức vào Internet khiến cho hệ thống mạng của họ có thể bị xâm nhập từ một vị trí bất kỳ trên thế giới. Điều này dẫn tới việc đảm bảo an toàn thông tin và chống lại các truy cập bất hợp pháp từ Internet là một công việc hiển nhiên và vô cùng quan trọng.
    <br/><br/>
    Thêm nữa, ngoài vấn đề truy cập bất hợp pháp, virus cũng đang là nguyên nhân gây ra sự khó chịu cho người dùng. Trước đây khi các máy tính độc lập với nhau, virus ảnh hưởng tới máy tính cũng chỉ nằm trên máy tính bị nhiễm. Ngày nay, khi các máy tính được nối mạng với nhau, một máy tính bị nhiễm thì nguy cơ lây nhiễm trên toàn mạng là hoàn toàn có thể.
    <br/><br/>
    <strong>4. Hiệu quả đầu tư</strong>
    <br/><br/>
    Khi tính toán đến nâng cấp một hệ thống chúng ta cần phải xem xét kỹ lưỡng phần nào cần được nâng cấp, đầu tư mới và chọn lựa sản phẩm thế nào. Việc tính toán chính xác sẽ giúp được tiết kiệm được chi phí đầu tư và khả năng sinh lãi sau đầu tư (ROI). Đây là một lĩnh vực tưởng chừng không liên quan tới kỹ thuật nhưng thực ra nó có mối quan hệ mật thiết, ví dụ như nó liên quan mật thiết với Scalability (khả năng mở rộng). Nếu chúng ta đầu tư một thiết bị hợp lý, có khả năng mở rộng cao sẽ giúp cho tiết kiệm được chi phí phải mua thêm thiết bị mới trong tương lai vì chúng ta chỉ cần nâng cấp thiết bị hiện tại. Với trách nhiệm của một nhà tư vấn giải pháp, công ty chúng tôi sẽ đưa ra những thiết bị phù hợp nhất trong dự án này.
    </p>`,
    PScategory : 12
},
{
    id_lv2: 31,
    textItem: "Nguyên tắc thiết kế xây dựng hệ thống mạng",
    text: `<p>
    <strong>1. Nguyên tắc xây dựng hệ thống mạng</strong>
    <br/><br/>
    <strong>1.1. Mô hình mạng phân cấp</strong>
    <br/><br/>
    Mô hình mạng phân cấp và thiết kế theo phân hệ hóa được triển bởi hầu hết các công ty và tập đoàn lớn bởi vì:
    <br/><br/>
    Đơn giản và dễ dàng khi mở rộng thêm các tòa nhà, các kết nối WAN, các chi nhánh, văn phòng trực thuộc, các phân hệ mới như E-banking, VPN…
    <br/><br/>
    Việc nâng cấp hoặc mở rộng chỉ mang tính chất thay đổi cục bộ hoặc khu vực được kết nối tới chứ không ảnh hưởng tất các phân hệ chức năng khác.
    <br/><br/>
    Hệ thống mạng có thể mở rộng lên gấp đôi hoặc gấp 3 mà không ảnh hưởng tới cấu trúc và thiết kế ban đầu.
    <br/><br/>
    Đơn giản trong việc cách ly và khắc phục sự cố. Đây là một điều vô cùng quan trọng đối với các hệ thống mạng lớn hàng trăm hoặc hàng nghìn thiết bị định tuyến (router).
    <br/><br/>
    Mô hình thiết kế phân cấp 3 lớp sẽ được chúng ta áp dụng trong cả thiết kế mạng WAN và mạng Campus. Cấu trúc chung của một một mô hình phân cấp bao gồm 3 lớp Core, Distribution và Access.
    <br/><br/>
    <strong>1.2. Cấu trúc mạng dự phòng</strong>
    <br/><br/>
    Sẵn sàng về dịch vụ: Khách hàng luôn luôn có nhu cầu truy cập vào hệ thống dịch vụ ở mọi nơi, mọi lúc, qua rất nhiều phương tiện khác nhau như Internet, SMS, Telephone .. Việc đảm bảo độ sẵn sàng trong cung cấp dịch vụ cho khách hàng đã trở thành một yêu cầu bắt buộc với hệ thống công nghệ thông tin. Thiệt hại do gián đoạn dịch vụ không chỉ giới hạn ở số lượng khách hàng và giá trị giao dịch vào thời điểm bị gián đoạn.
    <br/><br/>
    An toàn về dữ liệu: Các dữ liệu cần phải được bảo vệ toàn vẹn và đảm bảo khả năng truy cập. Các yếu tố cần phải xem xét:
    <br/><br/>
    Đảm bảo cơ chế dự phòng
    <br/><br/>
    Dự phòng về đường truyền (internet, WAN…):
    <br/><br/>
    Dự phòng về nguồn điện: Các thiết bị quan trọng đảm bảo luôn có 2 nguồn sử dụng, 1 nguồn hoạt động chính và 1 nguồn dự phòng.
    <br/><br/>
    <img src=${img46} alt="">
    <br/><br/>
    Việc dự phòng giúp tránh tình huống toàn bộ hệ thống bị mất liên lạc do chỉ một thiết bị bị sự cố.
    <br/><br/>
    <strong>1.3 Cấu trúc mạng ảo hóa</strong>
    <br/><br/>
    Khi xây dựng Trung tâm dữ liệu, bước tiếp theo của việc tập trung hóa (Data Center Consolidation) là bước thực hiện ảo hóa Trung tâm dữ liệu (Data Center Virtualization). Giai đoạn ảo hóa này cho phép người quản trị Trung tâm dữ liệu có thể tạo ra các lớp ảo, trừu tượng giữa các ứng dụng, hệ thống máy chủ và cơ sở hạ tầng mạng.
    <br/><br/>
    Ảo hóa Trung tâm dữ liệu là việc tạo ra một thực thể logic từ các thực thể vật lý, hoặc là tạo ra nhiều thực thể logic từ một thực thể vật lý. Các thực thể này có thể là thiết bị tính toán, hệ thống lưu trữ, hệ thống mạng, hoặc các ứng dụng. Như vậy hoàn toàn có thể ảo hóa hầu hết các thành phần cấu thành nên Trung tâm dữ liệu, từ các dịch vụ mạng, hệ thống lưu trữ, hệ thống tính toán, và các thành phần kết nối, như được thể hiện trên hình dưới đây.
    <br/><br/>
    Thực hiện ảo hóa Trung tâm dữ liệu cho phép tăng cường hiệu quả sử dụng của các nguồn lực tại Trung tâm dữ liệu, khả năng cung cấp dịch vụ nhanh hơn, và giảm thiểu sự ảnh hưởng đến độ sẵn sàng của các ứng dụng tại Trung tâm dữ liệu khi thực hiện việc bảo trì, bảo dưỡng. Hơn nữa, một trong những lợi ích quan trọng của việc ảo hóa là có thể tạo ra các cơ sở hạ tầng IT độc lập, riêng rẽ, an toàn dựa trên một cơ sở hạ tầng vật lý duy nhất. Các cơ sở hạ tầng IT độc lập được ảo hóa này hỗ trợ các nhóm làm việc khác nhau, hỗ trợ các cơ sỏ hạ tầng của các đơn vị thành viên khác nhau dựa trên một nền tảng vật lý duy nhất.
    <br/><br/>
    <strong>1.4. Bảo mật hệ thống mạng</strong>
    <br/><br/>
    Đảm bảo an ninh thông tin cũng là một yêu cầu rất quan trọng đối với hệ thống mạng. Đây là một yếu tố cần phải xem xét kỹ lưỡng trong thiết kế.
    <br/><br/>
    Theo như khảo sát của Computer Security Institute (CSI), có tới 70% các tổ chức thừa nhận hệ thống mạng của họ đã từng bị thâm nhập trái phép, và tới 60% các cuộc thâm nhập được thực hiện từ bên trong tổ chức đó. Nó chứng tỏ rằng an ninh mạng không chỉ thực thi đối với các truy cập bên ngoài, nó còn phải được thực hiện bản thân từ bên trong hệ thống mạng của tổ chức đó.
    <br/><br/>
    Thêm nữa, ngoài vấn đề truy cập bất hợp pháp, virus cũng đang là nguyên nhân gây ra sự khó chịu cho người dùng. Trước đây khi các máy tính độc lập với nhau, virus ảnh hưởng tới máy tính cũng chỉ nằm trên máy tính bị nhiễm. Ngày nay, khi các máy tính được nối mạng với nhau, một máy tính bị nhiễm thì nguy cơ lây nhiễm trên toàn mạng là hoàn toàn có thể.
    <br/><br/>
    <img src=${img47} alt="">
    <br/><br/>
    <strong>1.5. Cấu trúc module hóa</strong>
    <br/><br/>
    Hệ thống mạng được phân chia theo các khối chức năng và các khu vực rõ ràng
    <br/><br/>
    <img src=${img48} alt="">
    <ul>
    <li style="list-style: none;">Hệ thống trung tâm miền và các hệ thống chi nhánh tỉnh, chi nhánh huyện</li>
    <br/><li style="list-style: none;">Các khối chức năng riêng biệt (Core , Management, Edge ..)</li>
    <br/><li style="list-style: none;">Việc module hóa hệ thống đảm bảo</li>
    <br/><li style="list-style: none;">Dễ dàng cho việc quản trị, vận hành, nâng cấp, thay đổi ..</li>
    <br/><li style="list-style: none;">Việc thay đổi, nâng cấp bên trong mỗi khối không gây ảnh hưởng đến các khối khác. Với mỗi khối chỉ quan tâm đến các khu vực còn lại trên khía cạnh giao diện vật lý giao tiếp và dịch vụ cung cấp.</li>
    </ul>
    <strong>1.6. Tối ưu hóa hệ thống mạng</strong>
    <br/><br/>
    Với một hệ thống mạng lớn, bao gồm nhiều thiết bị, việc tối ưu hóa hệ thống sẽ mang lại rất nhiều lợi ích:
    <ul>
    <li style="list-style: none;">Giảm thiểu rủi ro sự cố vận hành hệ thống</li>
    <br/><li style="list-style: none;">Tăng năng lực vận hành hệ thống</li>
    <br/><li style="list-style: none;">Giảm bớt nhu cầu đầu tư, nâng cấp thiết bị</li>
    <br/><li style="list-style: none;">Các biện pháp thực hiện tối ưu hóa hệ thống mạng của YYY Bank có thể thực hiện:</li>
    <br/><li style="list-style: none;">Quy hoạch, tối ưu hóa địa chỉ IP: Đây là bước cực kỳ quan trọng, một phân bổ địa chỉ IP được quy hoạch tốt sẽ là tiền đề để xây dựng một hệ thống mạng ổn định, năng lực cao và dễ mở rộng, nâng cấp</li>
    <br/><li style="list-style: none;">Tối ưu hóa định tuyến: Sử dụng giao thức định tuyến phù hợp, quy hoạch hệ thống mạng theo các khu vực phù hợp. Sử dụng các kỹ thuật như : Route Sumarization, Routing Filtering, Load-balancing … sẽ giúp tăng năng lực hệ thống và tận dụng tối đa được performance của từng thiết bị, đường truyền</li>
    <br/><li style="list-style: none;">Triển khai QoS: Việc triển khai QoS sẽ làm giảm băng thông WAN, Internet và nâng cao chất lượng hoạt động của các ứng dụng, đặc biệt là các ứng dụng như Voice, Video.</li>
    </ul>
    <strong>1.7. Chiến lược về hãng</strong>
    <br/><br/>
    Hệ thống của một hãng, hay nhiều nhiều hãng (Single Vendor vs Multi-Vendor)
    <br/><br/>
    <strong>2. Các tiêu chí thiết kế hệ thống mạng</strong>
    <br/><br/>
    <strong>2.1. Functionality (Chức năng)</strong>
    <br/><br/>
    Tiêu chí chức năng (functionality) là tiêu chí quan trọng hàng đầu. Một hệ thống CNTT thực sự chỉ có ý nghĩa khi nó đáp ứng đúng và đủ các yêu cầu của doanh nghiệp và các mục tiêu, chức năng đã được đề ra.
    <br/><br/>
    <strong>2.2. Performance (Năng lực xử lý)</strong>
    <br/><br/>
    Là một trong những thành phần quan trọng nhất trên mạng, Năng Lực Xử Lý (Performance) được xác định qua băng thông và tỷ lệ dữ liệu có thể truyền theo đơn vị thời gian là Giây (pps). Do tính chất phức tạp khi tính toàn Năng Lực Xử Lý, người ta sẽ tập trung vào 3 yếu tố ảnh hưởng mà liên quan tới Năng Lực Xử Lý nhất để có thể nhận biết được một mạng, đó là:
    <ul>
    <li style="list-style: none;">Responsiveness (thời gian đáp ứng)</li>
    <br/><li style="list-style: none;">Throughtput ( Băng thông)</li>
    <br/><li style="list-style: none;">Utilization (Tối ưu)</li>
    </ul>
    Việc quan tâm nhất sau khi đã đầu tư nâng cấp chính là khả năng xử lý của mạng. Thời gian đáp ứng (Responsiveness) của các ứng dụng trên mạng lúc này phải trong thời gian cho phép vào những lúc mạng đang hoạt động cao điểm nhất. Để làm được việc này Throughput (Băng thông) trên mạng phải luôn đảo bảo được độ ổn định và tối ưu được băng thông (Utilization). Tính tối ưu ở đây chính là hiệu suất hoạt động của thiết bị sao cho nó có khả năng đáp ứng được các yêu cầu được đưa đến dồn dập.
    <br/><br/>
    <strong>2.3. Scalability (Khả năng mở rộng)</strong>
    <br/><br/>
    Việc tất yếu hệ thống mạng sẽ ngày một mở rộng, do vậy bất cứ việc đầu tư mua thêm hoặc nâng cấp đều phải tính tới khả năng mở rộng. Vấn đề mở rộng có thể được chia làm hai dạng là mở rộng cứng và mở rộng mềm.
    <br/><br/>
    Mở rộng cứng ở đây có thể được hiểu khả năng nâng cấp với các thiết bị hiện tại. Các thiết bị phải có phần mở rộng như Module, Slots, Card.. Để làm được việc đó người quản trị cũng như người thiết kế phải có khả năng phân cấp hệ thống, phân vùng và xác định rõ nhiệm vụ của từng vùng khác nhau để có được cái nhìn chính xác nhất về mạng, nhằm tiện cho việc điều chỉnh, nâng cấp.
    <br/><br/>
    Mở rộng mềm là một điểm cũng khá là quan trọng. Người quản trị mạng sẽ phải có khả năng dự đoán được sự mở rộng của mạng để từ đó có thể xác định loại giao thức định tuyến sẽ được sử dụng trên mạng (nhằm tránh việc thay đổi giao thức định tuyến rất phức tạp) và có một quy hoạch về địa chỉ IP (IP Plan) phù hợp nhất với hệ thống mạng.
    <br/><br/>
    <strong>2.4. Availability (Độ ổn định)</strong>
    <br/><br/>
    Độ ổn định (Độ sẵn sàng) được tính toán như sau:
    <ul>
    <br/><li style="list-style: none;">Availability(Intrinsic) A i = MTBF / (MTBF + MTTR)</li>
    <br/><li style="list-style: none;">Một điểm quan trọng khi thiết kế mạng là tính ổn định. Người ta định nghĩa độ ổn định là thời gian tạm dừng hệ thống (MTTR: mean time to repair) phải giảm tối đa và thời gian chạy liên tục ko có lỗi (MTBF: meatime between failure) phải tăng tối đa. Chính vì vậy, có một số điểm chúng ta cần phải quan tâm khi nói về tính ổn định như sau:</li>
    <br/><li style="list-style: none;">Khả năng dự phòng của thiết bị</li>
    <br/><li style="list-style: none;">Khả năng dự phòng về đường truyền</li>
    <br/><li style="list-style: none;">Khả năng dự phòng của giao thức định tuyến</li>
    </ul>
    <strong>2.5. Manageability(Khả năng quản lý)</strong>
    <br/><br/>
    <img src=${img49} alt="">
    <br/><br/>
    Hệ thống mạng chỉ có thể được vận hành hiệu quả nếu được quản lý tốt. Khả năng quản lý cần đảm bảo các nội dung sau:
    <ul>
    <li style="list-style: none;">Quản lý lỗi (Fault Management): Khả năng phát hiện, vào thông báo khi có lỗi xảy ra trên mạng</li>
    <br/><li style="list-style: none;">Quản lý cấu hình (Configuration Management): khả năng quản lý cấu hình của thiết bị. Khả năng quản lý này bao gồm việc quản lý file cấu hình, thống kê thiết bị và quản lý phần mềm cũng như log lại các thay đổi về mặt cấu hình</li>
    <br/><li style="list-style: none;">Kiểm toán hệ thống (Accounting Management): khả năng quản lý kiểm tra, kiểm soát tổng thể hệ thống từ góc độ trung gian.</li>
    <br/><li style="list-style: none;">Quản lý hiệu năng (Performance Management): Khả năng thu thập các thông tin từ thiết bị, đường truyền, hiển thị được tình trạng sử dụng của thiết bị , đường truyền để từ đó có các quyết định phù hợp</li>
    <br/><li style="list-style: none;">Quản lý an ninh (Security Management): Khả năng quản lý, thiết lập và giám sát các chính sách an ninh trên toàn mạng</li>
    </ul>
    <strong>2.6. Cost Effectiveness(Hiệu quả đầu tư)</strong>
    <br/><br/>
    Tiết kiệm chi phí (cost Effectiveness) không đơn giản là cắt giảm chi phí một cách tối đa có thể. Tiết kiệm chi phí trong thiết kế cần phải hiểu rộng hơn là khả năng tận dụng đầu tư một cách tối ưu để mang lại hiệu quả. Thể hiện ở các nội dung sau:
    <br/><br/>
    Sử dụng hợp lý các thiết bị
    <br/><br/>
    Phát triển có lộ trình phù hợp, tránh lãng phí khấu hao thiết bị
    </p>`,
    PScategory : 12
},
{
    id_lv2: 33,
    textItem: "Thiết kế tổng thể",
    text: `<p>
    <strong>Mô hình thiết kể tổng thể như sau:</strong>
    <br/><br/>
    Mô hình Enterprise Composite Network chia hệ thống mạng thành 3 phân vùng chức năng vật lý cũng như luận điểm khác nhau gọi là 03 phân hệ Enterprise Campus, Enterprise Edge và Service Provider Edge. Tuy rằng 03 phân hệ này có chức năng riêng biệt nhau nhưng vẫn tương hỗ, liên kết với nhau tạo thành một khối thống nhất trong vận hành toàn bộ hệ thống và mỗi một phân hệ đều được cấu hình, triển khai theo mô hình phân lớp cơ bản là lớp Core, lớp Distribution, lớp Access
    <br/><br/>
    <img src=${img50} alt="">
    <br/><br/>
    Chia nhỏ thành các module như sau:
    <br/><br/>
    <img src=${img51} alt="">
    <br/><br/>
    <strong>1.Phân hệ Enterprise Campus</strong>
    <br/><br/>
    Đây là phân hệ cung cấp hệ thống mạng có hiệu năng, tính sẵng sàng và độ tin cậy cao.
    <br/><br/>
    Phân hệ Enterprise Campus được cấu tạo từ những thành phần chức năng sau đây:
    <br/><br/>
    Thành phần quản trị mạng thực hiện giám sát hoạt động của toàn bộ hệ thống, xác thực, quản lý cấu hình và hiển thị các thông báo, trạng thái hệ thống thành các tập tin, các cảnh báo v.v.v…
    <br/><br/>
    Thành phần Bulding Access: bao gồm các người dùng cuối, điện thoại IP phone và switch truy cập lớp 2 kết nối đến Building Distribution nhằm thực hiện chức năng quản lý broadcast, lọc các giao thức, truy cập mạng và chất lượng dịch vụ.
    <br/><br/>
    Thành phần Bulding Distribution: Nơi đây tập trung các wiring closet, các switch lớp 3 nhằm thực hiện chức năng định tuyến, chất lượng dịch vụ, quản lý truy cập, phân tải thông qua nhiều kết nối, nhanh chóng khắc phục lỗi.
    <br/><br/>
    Thành phần Campus Backbone có tính năng như lớp Core trong mô hình phân lớp nên cung cấp tập trung và dự phòng các kết nối tốc độ cao nhất giữa những building với nhau cũng như Server farm và Edge Distribution.
    <br/><br/>
    Thành phần Server farm cung cấp các dịch vụ ứng dụng cho người dùng bên trong như các máy chủ mail nội bộ, máy chủ ứng dụng, máy chủ in, máy chủ file, DNS. Thành phần này được kết nối trực tiếp với Core layer Switch nhằm cung cấp độ tin cậy và sẵn sàng cho các máy chủ.
    <br/><br/>
    Thành phần Edge Distribution tập trung các kết nối từ các nơi khác và chuyển các dữ liệu đến phân hệ con Campus Backbone.
    <br/><br/>
    Với những thành phần chức năng đó, phân hệ Enterprise Campus phải đáp ứng đầy đủ kiến trúc thiết kế có tính phân cấp, tối ưu hóa, module hóa, dự phòng cao, bảo mật chặt chẽ và khả năng ảo hóa.
    <br/><br/>
    <strong>1.1. Core</strong>
    <br/><br/>
    Lớp Core thực hiện những chức năng và vai trò hết sức quan trọng trong hệ thống, do vậy, đánh giá trên các tiêu chí thiết kế của hệ thống mạng, khi xây dựng lớp Core cần đảm bảo quan trọng nhất là năng lực xử lý, khả năng mở rộng, tính sẵn sàng và tiết kiệm chi phí của nhà đầu tư.
    <br/><br/>
    Chúng tôi đề xuất giải pháp thiết kế và giải pháp thiết bị cho lớp Core phải đảm bảo được tối đa nguyên tắc tối ưu hóa, dự phòng. Bên cạnh đó, một giải pháp quan trọng khi tích hợp trên thiết bị Core chính là khả năng ảo hóa để cung cấp năng lực xử lý tối ưu cho hệ thống.
    <br/><br/>
    <strong>1.2 Distribution</strong>
    <br/><br/>
    Lớp Phân Phối sẽ làm trung gian kết nối giữa Lớp Trục Chính và Lớp Truy Cập. Chức năng chính của Lớp Phân Phối là thực hiện các tính toán, phân bố kết nối vào hệ thống cho Lớp Truy Cập dựa trên các chính sách phân quyền chung được nhà quản trị đề ra. Lớp này còn cung cấp kết nối cho Lớp Truy Cập thông qua hệ thống cáp đồng UTP 4 pair hoặc cáp quang nếu cần thiết.
    <br/><br/>
    Lớp Phân Phối cũng sẽ kết nối với Lớp Trục Chính thông qua hệ thống cáp quang, nhằm bảo đảm tốc độ chuyển mạch luôn là Gigabit.
    <br/><br/>
    Với những chức năng đó, khi thiết kế module Distribution, chúng tôi thiết kế với nguyên tắc tối ưu hóa, dự phòng và bảo mật.
    <br/><br/>
    Việc thiết kế tối ưu hóa đảm bảo khả năng xử lý tốt của thiết bị, đồng thời cho khả năng mở rộng dễ dàng khi quy mô phát triển trong tương lai.
    <br/><br/>
    Thiết kế có tính dự phòng về thiết bị, đường truyền sẽ đảm bảo tiêu chí sẵn sàng, ổn định của hệ thống. Đồng thời tăng cường một số giải pháp bảo mật ở đây.
    <br/><br/>
    <strong>1.3 Building Access</strong>
    <br/><br/>
    Lớp Truy Cập sẽ có nhiệm vụ chủ yếu là cung cấp kết nối cho người dùng đầu cuối vào hệ thống ở các tốc độ 10/100/1000 Mbps.
    <br/><br/>
    Lớp Truy Cập sẽ đảm nhận những nhiệm vụ chính sau:
    <br/><br/>
    Cung cấp kết nối cho người dùng, thiết bị đầu cuối để hòa vào hệ thống chung.
    <br/><br/>
    Chuyển mạch băng thông, cung cấp kết nối với tốc độ đúng bằng tốc độ chuyển mạch đặc trưng của switch cho mỗi thiết bị đầu cuối. Thông thường tốc độ này sẽ là 100Mbps.
    <br/><br/>
    Kết nối đến các Lớp Phân Phối để tạo nên hạ tầng chuyển mạch tổng thể, phục vụ cho nhu cầu trao đổi liên lạc giữa các cụm người dùng.
    <br/><br/>
    Phân cấp người dùng thành từng nhóm theo chức năng, hoặc theo không gian làm việc thông qua việc tạo các mạng LAN ảo.
    <br/><br/>
    Module Access yêu cầu 2 tiêu chí quan trọng nhất là Năng lực xử lý tốt đồng thời vẫn luôn tiết kiệm chi phí đầu tư. Ngoài ra cần thiết hỗ trợ mở rộng với số lượng user tăng và các dịch vụ khác như voice tích hợp vào hệ thống.
    <br/><br/>
    Để đảm bảo những tiêu chí đó, nguyên tắc thiết kế của chúng tôi là Tối ưu hóa kết nối, băng thông sao cho hiệu quả nhất. Thiết bị ở đây chỉ cần lựa chọn những switch layer 2 có năng lực chuyển mạch trung bình là phù hợp.
    <br/><br/>
    <strong>1.4 Data Center</strong>
    <br/><br/>
    Hệ thống trung tâm dữ liệu có thể nói là trái tim của doanh nghiệp, cung cấp những dịch vụ và lưu trữ thông tin hoạt động của toàn hệ thống. Đánh giá trên những tiêu chí thiết kế ở mục 3 thì Data Center phải đáp ứng đầy đủ cả 6 tiêu chí. Trong đó, quan trọng nhất là năng lực xử lý, độ ổn định, sẵn sàng và khả năng quản lý. Để đáp ứng những yêu cầu đó, kiến trúc Data Center được xây dựng dựa trên một số nguyên tắc sau:
    <br/><br/>
    Kiến trúc Data Center cũng được phân cấp Core, Distribution, Access, thiết kế này cũng tương tự mô hình chuyển mạch 3 lớp, đảm bảo tối ưu được băng thông và năng lực xử lý thiết bị.
    <br/><br/>
    Khả năng dự phòng cao nhất
    <br/><br/>
    Bảo mật được thực hiện ở mức cao nhất, với chính sách bảo mật sâu, nhiều tầng.
    <br/><br/>
    Kiến trúc mạng Trung tâm dữ liệu (DCNA - Data Center Network Architecture) dựa trên nền tảng Kiến trúc mạng định hướng dịch vụ (SONA), cung cấp cho doanh nghiệp khả năng tập trung hóa, đảm bảo tính liên tục trong hoạt động kinh doanh, đồng thời cho phép hỗ trợ các kiến trúc và công nghệ đang phát triển như kiến trúc định hướng dịch vụ, công nghệ ảo hóa, công nghệ tính toán theo yêu cầu (On-demand Computing), với phương thức tiếp cận theo Kiến trúc.
    <br/><br/>
    Dưạ trên nền tảng Kiến trúc mạng định hướng dịch vụ, Kiến trúc mạng Trung tâm dữ liệu bao gồm
    <br/><br/>
    Lớp cơ sở hạ tầng mạng (Networked Infrastruture Layer)
    <br/><br/>
    Lớp dịch vụ tương tác (Interactive Services Layer)
    <br/><br/>
    Kiến trúc này hỗ trợ việc triển khai các ứng dụng kinh doanh và các ứng dụng cộng tác của doanh nghiệp, bất kể là triển khai với các kiến trúc ứng dụng truyền thống hay kiến trúc định hướng dịch vụ tiên tiến.
    <br/><br/>
    <strong>1.5 Common Service</strong>
    <br/><br/>
    Module Common Service bao gồm các server ứng dụng khác của hệ thống mạng cục bộ, tách biệt với các ứng dụng core banking để giảm tải cho cho hệ thống Firewall, network.. tại module Data Center. Vì vậy vai trò của module Application Server đối với hệ thống mạng nội bộ là rất quan trọng. Do đó, module này khi thiết kế cần đáp ứng thiết kế dự phòng, bảo mật để đảm bảo tính sẵn sàng cho các ứng dụng.
    <br/><br/>
    <strong>1.6 Network Management</strong>
    <br/><br/>
    Module quản trị bao gồm các máy chủ thực hiện chức năng quản trị tập trung cho toàn bộ hệ thống. Module Management sẽ giúp cho người quản trị có một cái nhìn chung về toàn mạng, đồng thời phát hiện nhanh chóng những lỗi từ vật lý thiết bị đến hoạt động của mạng. Với những công cụ mạnh mẽ, người quản trị có thể thao tác điều khiển tập trung các cấu hình, chính sách cho cả hệ thống.
    <br/><br/>
    Đây không phải khu vực đòi hỏi cao về năng lực xử lý, khả năng mở rộng tuy nhiên cần thiết kế với khả năng quản lý tốt. Đồng thời nguyên tắc thiết kế bảo mật phải được áp dụng cho các máy chủ quản trị ở khu vực này, giới hạn truy cập từ nội bộ mạng và từ bên ngoài.
    <br/><br/>
    Khi thiết kế hệ thống, chúng tôi cũng lựa chọn giải pháp dự phòng cho các thiết bị chuyển mạch để đảm bảo tính sẵn sàng. Chúng tôi để xuất một số giải pháp cho module quản trị:
    <br/><br/>
    Hệ thống quản trị các thiết bị mạng
    <br/><br/>
    Hệ thống xác thực
    <br/><br/>
    Hệ thống quản trị Security
    <br/><br/>
    Hệ thống giám sát, phát hiện, phân tích tấn công
    <br/><br/>
    <strong>1.7 DR Site</strong>
    <br/><br/>
    DR Site (Disaster Recovery) là trung tâm dự phòng cho Data Center. DR được xây dựng với kiến trúc mạng tương tự kiến trúc của Data Center. DR sẽ được dựng lên dự phòng cho DC trong trường hợp toàn bộ DC gặp sự cố DR sẽ chịu trách nhiệm xử lý toàn bộ traffic nghiệp vụ của hệ thống còn ở trạng thái thông thường DR sẽ hoạt động chia tải cho DC
    <br/><br/>
    Do vậy, xét về tiêu chí và nguyên tắc thiết kế, DR phải đảm bảo đầy đủ những tiêu chí thiết kế của Data Center.
    <br/><br/>
    <strong>2.Phân hệ Enterprise Edge</strong>
    <br/><br/>
    <strong>2.1 Inbound Internet Module</strong>
    <br/><br/>
    Trong thời gian từ khoảng đầu thập kỷ 90 trở lại đây, Internet trên thế giới phát triển đã đem lại một phương thức làm việc, sản xuất, kinh doanh hoàn toàn mới. Kho tài nguyên và nhu cầu truy cập, trao đổi thông tin qua Internet tăng lên đáng kể và trở thành một phần quan trọng trong công việc của các doanh nghiệp.
    <br/><br/>
    Với yêu cầu đó, cổng truy cập Internet được xây dựng thành một module độc lập với hệ thống bảo mật, định tuyến và các chính sách riêng. Tại đây cung cấp các dịch vụ như truy cập Web, E-mail, Instant Message, VPN… với lưu lượng sử dụng cao. Các tiêu chí quan trọng của module này:
    <br/><br/>
    Tính ổn định: Các đường truyển Internet thường có khả năng down do nhiều nguyên nhân khách quan hơn các kết nối khác trong mạng nội bộ, do vậy, chúng tôi thiết kế module Internet với khả năng dự phòng về đường truyền (với nhiều ISP), dự phòng về thiết bị (chạy theo cặp) để đảm bảo cao nhất độ ổn định cho các truy cập Internet.
    <br/><br/>
    Tính bảo mật: cổng truy cập Internet cũng là điểm dễ tấn công nhất trong hệ thống, do vậy, chúng tôi đề xuất những giải pháp bảo mật với Firewall, IPS để bảo mật, chống tấn công và những rủi ro từ bên ngoài Internet.
    <br/><br/>
    <strong>2.2 Outbound Internet Module</strong>
    <br/><br/>
    Sự phát triển vể cả số lượng lẫn quy mô của các dịch vụ trên Internet và tương tác của người dùng qua Internet diễn ra mạnh mẽ.
    <br/><br/>
    <strong>2.3 Partner</strong>
    <br/><br/>
    Module Partner cung cấp kết nối đến các đối tác khác.
    <br/><br/>
    <strong>2.4 WAN Module</strong>
    <br/><br/>
    Thiết kế phân cấp hệ thống WAN thành 03 mức là Core, Distribution và Access.
    <br/><br/>
    Lớp WAN CORE: Có trách nhiệm truyền dẫn toàn bộ gói tin trên mạng, đảm bảo tối ưu hoá được lưu lượng truyền trên mạng
    <br/><br/>
    Lớp WAN Distribution: Có vai trò tiếp nhận lưu lượng truyền từ lớp Access, sau đó phân tích thông tin, hoặc dựa vào các policy để đưa ra phương án truyền dẫn tốt nhất.
    <br/><br/>
    Lớp WAN Access: Là nơi tiếp nhận các thông tin ban đầu, sau đó gửi toàn bộ thông tin tới lớp Distribution
    </p>`,
    PScategory : 12
},
{
    id_lv2: 34,
    textItem: "Thách thức của doanh nghiệp",
    text: `<p>Đội ngũ nhân viên IT của các doanh nghiệp thường phải đối mặt với những thách thức của việc duy trì và xử lý sự cố liên quan đến công nghệ mới, phức tạp và phải thường xuyên đối mặt với những yêu cầu thay đổi để đảm bảo tính sẳn dùng cho hệ thống kinh doanh. Và đồng thời, các doanh nghiệp còn phải đấu tranh để giữ cho các kỹ sư mạng đã được đào tạo và hy vọng sẽ làm giảm tiêu hao nhân lực ở mức tối thiểu, trong khi các doanh nghiệp vẫn chưa sẵn sàng thuê các nhà cung cấp dịch vụ bên ngoài để quản lý toàn hệ thống của mình.</p>`
    ,PScategory : 13
},
{
    id_lv2: 35,
    textItem: "Giải pháp của chúng tôi",
    text: `<p>
    dịch vụ bảo trì bảo dưỡng “Maintenance Services” thực hiện việc bảo dưỡng phòng ngừa (Preventive Maintenance), bảo trì, khắc phục sự cố (Incident Maintenance), dịch vụ thay thế phần cứng dựa trên các tiêu chuẩn cung cấp dịch vụ và tuân thủ các quy trình để đáp ứng yêu cầu cam kết dịch vụ SLAs (Service Level Agreement).
    <br/><br/>
    <img src=${img52} alt="">
    </p>`,
    PScategory : 13
    },
{
    id_lv2: 36,
    textItem: "Đề xuất dịch vụ",
    text: `<p>
    <strong>Dịch vụ Bảo trì ngăn chặn và xử lý lỗi</strong>
    <br/><br/>
    Sơ đồ sau đây mô tả quy trình dịch vụ bảo trì để đảm bảo các sự gián đoạn được nhanh chóng được xác định và sửa chữa:
    <br/><br/>
    <strong>Dịch vụjavascript:void(0); thay thế phần cứng tạm thời</strong>
    <br/><br/>
    Sơ đồ sau đây mô tả quy trình duy trì và thay thế phần cứng tạm thời để đảm bảo các sự cố được xác định và sửa chữa một cách nhanh chóng.
    <br/><br/>
    <img src=${img53} alt="">
    <br/><br/>
    <strong>Dịch vụ thay thế phần cứng cố định</strong>
    <br/><br/>
    Sơ đồ sau đây mô tả quy trình duy trì và thay thế phần cứng cố định để đảm bảo các sự cố được xác định và sửa chữa một cách nhanh chóng.
    <br/><br/>
    <img src=${img54} alt="">
    </p>`
    ,PScategory : 13
},
{
    id_lv2: 37,
    textItem: "Thách thức của doanh nghiệp",
    text: `<p>
    <strong>Các doanh nghiệp phải đối mặt với những thách thức của việc thuê và duy trì kỹ sư lành nghề để vận hành, hỗ trợ những việc liên quan đến CNTT phục vụ công việc kinh doanh của mình, đồng thời hi vọng có thể giảm thiểu việc tiêu hao nhân lực trong khi doanh nghiệp vẫn chưa sẵn sàng thuê ngoài việc quản lý hoàn toàn hệ thống của mình.</strong>
    <br/><br/>
    Bên cạnh đó, việc kinh doanh sản xuất của doanh nghiệp có thể tăng giảm đột ngội trong một giai đoạn nào đó, do đó đòi hỏi doanh nghiệp phải có hệ thống CNTT và đội ngủ IT tăng hoặc giảm tương ứng một cách nhanh chóng, và điều này làm đau đầu rất nhiều cho các nhà quản lý.
    </p>`
    ,PScategory : 14
    },
{
    id_lv2: 38,
    textItem: "Giải pháp của chúng tôi",
    text: `<p>HSV cung cấp các kỹ sư lành nghề và IT helpdesks làm việc tại các chỗ khách hàng thực hiện các công việc theo phạm vi công việc được thỏa thuận.</p>`
    ,PScategory : 14
    },
{
    id_lv2: 39,
    textItem: "Đề xuất của chúng tôi",
    text: `<p>
    Dịch vụ “onsite IT engineer” có thể cung cấp trên các lĩnh vực sau:
    <ol>
    <li>Network devices</li>
    <li>Servers</li>
    <li>SAN/Disk Units</li>
    <li>Windows OS</li>
    <li>Linux OS</li>
    <li>AIX OS</li>
    <li>Applications</li>
    <li>Data Storage and Archival</li>
    <li>IPS, IDS, Firewall</li>
    <li>IT Helpdesk</li>
    </ol>
    </p>`
    ,PScategory : 14
},
{
    id_lv2: 40,
    textItem: "Thách thức của Doanh nghiệp",
    text: `<p>
    Với ra sự ra đời ngày càng nhiều các mối đe dọa an ninh phức tạp, các doanh nghiệp phải đối mặt với những thách thức bảo đảm an toàn cơ sở hạ tầng thông tin của họ, bao gồm:
    <ul>
    <li style="list-style: none;">Sự cần thiết cho sự bảo vệ trước các cuộc tấn công ngày càng tinh vi: với các giải pháp bảo mật có thể phát hiện và đáp ứng các cuộc tấn công.</li>
    <br/><li style="list-style: none;">Sự cần thiết phải duy trì các nguồn lực bảo mật có kỹ năng: Cài đặt và quản lý các giải pháp của một hệ thống bảo mật toàn diện</li>
    <br/><li style="list-style: none;">Sự cần thiết cho việc giám sát liên tục: Duy trì đội ngũ nhân viên bên trong để giám sát liên tục và đáp ứng kịp thời các sự kiện xảy ra là một khó khăn lớn cho doanh nghiệp</li>
    <br/><li style="list-style: none;">Sự cần thiết trong việc ngăn ngừa hệ thống sụp đổ do lỗ hổng bảo mật</li>
    </ul>
    </p>`
    ,PScategory : 15
    },
{
    id_lv2: 41,
    textItem: "Giải pháp của chúng tôi",
    text: `<p>Với một quy trình nghiêm ngặt gồm 6 giai đoạn, HSV sẽ xây dựng bức tranh toàn diện về các yêu cầu bảo mật cho doanh nghiệp, cũng như cung cấp một cách tiếp cận tùy biến để đáp ứng các nhu cầu bảo mật đó.
    <br />
    <img src=${img55} alt="" >
    </p>`
    ,PScategory : 15
    },
{
    id_lv2: 42,
    textItem: "Lợi ích cho khách hàng",
    text: `<p>
    Những lợi ích từ dịch vụ Quản lý bảo mật HSV bao gồm:
    <ul>
    <li style="list-style: none;">Giảm thời gian chết hệ thống thông qua việc nhận diện sớm những nguy cơ và lỗi</li>
    <br/><li style="list-style: none;">Bảo mật tiềm tang, để đưa ra những đáp ứng sớm.</li>
    <br/><li style="list-style: none;">Nâng cao ý thức về bảo mật và đi trước bảo bảo mật.</li>
    <br/><li style="list-style: none;">Tăng cường hiệu quả dịch vụ cũng như độ sẵn sang bằng việc giám sát 24x7x365.</li>
    <br/><li style="list-style: none;">Yên tâm về hệ thống bảo mật, những rủi ro đó sẽ được chúng tôi kiểm soát.</li>
    <br/><li style="list-style: none;">Giảm chi phí và mất mát doanh thu do hệ thống chết bằng việc phát hiện sớm lỗi và rủi ro bảo mật trước khi xảy ra.</li>
    <br/><li style="list-style: none;">Cải thiện chi phí vốn chủ sở hữu (TCO).</li>
    </ul>
    </p>`
    ,PScategory : 15
    },
{
    id_lv2: 43,
    textItem: "Đề xuất dịch vụ",
    text: `<p>
    <strong>Dịch vụ giám sát bảo mật thông tin và sự kiện (SIEM)</strong>
    <br/><br/>
    24x7 phục vụ giám sát hệ thống khách hàng suốt ngày đêm, bảo vệ tài sản thông tin quan trọng của khách hàng.
    <br/><br/>
    Ngăn ngừa, phát hiện và đáp ứng kịp thời với các lỗ hổng bảo mật và các mối đe dọa 24/7 bởi trung tâm hỗ trợ của HSV.
    <br/><br/>
    Các chuyên gia bảo mật có trình độ cao và một quy trình chắc chắn dựa trên thực tiễn sẽ bảo mật hệ thống mạng của tổ chức bạn tốt nhất.
    <br/><br/>
    <strong>Dịch vụ giám sát hiểm họa từ bên trong (ITM)</strong>
    <br/><br/>
    Phương pháp tiếp cận đa tầng để xác định đích giá trị cao, theo dõi các hoạt động của các nhóm người dùng khác nhau nhằm xác định, theo dõi và đáp ứng với các mức khác nhau của các mối đe dọa nội bộ.
    <br/><br/>
    <strong>Dịch vụ giám sát hệ thống xử lý, kiểm soát luồng thông tin và dữ liệu</strong>
    <br/><br/>
    SCADA-Kết hợp giám sát và tập trung hóa thông tin bảo mật từ tất cả nguồn lưu thông vào mạng.
    <br/><br/>
    Các thông tin này sau đó được kiểm tra các mối tương quan để phát hiện, xác định và cung cấp các cảnh báo về tấn công, cho phép chủ sở hữu hệ thống có các phản ứng nhanh chóng, kịp thời
    <br/><br/>
    <strong>Dịch vụ quản lý các thiết bị bảo mật</strong>
    <br/><br/>
    Cung cấp dịch vụ cài đặt và quản lý các thiết bị bảo mật đặt tại khách hàng như tường lửa (firewall), hệ thống phòng chống xâm nhập (IPS), các thiết bị quản lý đe dọa hợp nhất (UTM), hệ thống phát hiện xâm nhập mạng (IDS) và hệ thống quản lý bản vá(Patch Management).
    <br/><br/>
    <strong>Dịch vụ ngăn chặn hiểm họa nhanh</strong>
    <br/><br/>
    Dịch vụ này đáp ứng nhanh, kịp thời khi một sự cố an ninh xảy ra bằng cách định vị, phân tích tác động của nó và cách ly các nút mạng bị ảnh hưởng. Điều này làm giảm đáng kể các gián đoạn cho hệ thống của khách hàng.
    <br/><br/>
    <strong>Dịch vụ quản trị hệ thống CNTT</strong>
    <br/><br/>
    Chuyên viên CNTT của chúng tôi khảo sát, đánh giá hệ thống an toàn thông tin của doanh nghiệp, cung cấp cho doanh nghiệp một bức tranh toàn cảnh về hệ thống bảo mật bao gồm các chính sách bảo mật, các qui trình…qua đó giúp doanh nghiệp đánh giá đúng mức độ hiệu quả của hệ thống với các mối đe dọa từ bên trong lẫn bên ngoài.
    <br/><br/>
    Dịch vụ “Đào tạo và quản lý CNTT": các chuyên gia tư vấn an ninh mạng sẽ xem xét toàn cảnh về bảo mật của doanh nghiệp bạn, khuyến cáo những phần cần cải thiện, và giúp nhân viên của bạn có thể nhanh chóng xác định và giải quyết các lo lắng cũng như thách thức về an toàn mạng.
    <br/><br/>
    <strong>Dịch vụ giám sát sự tuân thủ và quản trị hệ thống CNTT</strong>
    <br/><br/>
    Cung cấp một chuỗi các hoạt động cài đặt, đánh giá và giám sát các hệ thống nội bộ để bảo đảm việc tuân thủ các tiêu chuẩn IT qui định, bao gồm việc kiểm soát truy cập, các hoạt động quản trị hệ thống, theo dõi quá trình đăng nhập, cũng như việc quản lý thay đổi và quản lý rủi ro. Dịch vụ "Giám sát sự tuân thủ" thu thập thông tin, đánh giá sự tương quan của các sự kiện và lập báo cáo nhằm bảo đảm tuân thủ các các chuẩn Sarbanes-Oxley, HIPAA (Health Insurance Portability and Accountability Act) và PCI Payment Card Industry)…
    </p>`
    ,PScategory : 15
},
{
    id_lv2: 44,
    textItem: "Thách thức doanh nghiệp",
    text: `<p>
    Sự phát triển CNTT nhanh hơn so với đầu tư.
    <br/><br/>
    Khó khăn trong việc thuê, đào tạo và giữ cán bộ CNTT chất lượng cao.
    <br/><br/>
    Giảm chi phí bằng cách thuê ngoài và để tập trung nguồn lực vào những dự án và định hướng chiến lực phục vụ kinh doanh chính.
    <br/><br/>
    Chi phí và khó khăn trong hỗ trợ 24/4.
    <br/><br/>
    Môi trường CNTT trở nên phức tạp về kiến trúc, vận hành và quản lý.
    <br/><br/>
    Không còn lo lắng để tập trung vào nghành kinh doanh chính nhằm nâng cao lợi nhuận, hiệu quả và sự cạnh tranh.
    </p>`
    ,PScategory : 16
    },
{
    id_lv2: 45,
    textItem: "Giải pháp của chúng tôi",
    text: `<p>Dịch vụ “Quản lý Mạng” cung cấp một bộ hoàn chỉnh các giải pháp giám sát cơ sở hạ tầng CNTT của bạn dựa trên bộ khung cung cấp dịch vụ, bộ khung này tuân thủ một tập các quy trình nhằm để giám sát hiệu suất và phát hiện nhanh lỗi mạng.
    <br />
    <img src=${img56} alt="" >
    </p>`
    ,PScategory : 16
    },
{
    id_lv2: 46,
    textItem: "Lợi ích doanh nghiệp",
    text: `<p>
    Các lợi ích từ dịch vụ Quản lý mạng của HSV đem lại cho doanh nghiệp bao gồm:
    <ul>
    <li style="list-style: none;">Nâng cao hiệu quả và tính sẵn sang hệ thống.</li>
    <br/><li style="list-style: none;">Giảm thời gian chết bằng cách nhận diện những rủi ro, vấn đề tiềm ẩn và giải quyết trước khi xảy ra.</li>
    <br/><li style="list-style: none;">Quản lý nguồn lực CNTT mềm dẻo (tài chính, kỹ năng, đào tạo, dự án cần) hiệu quả hơn.</li>
    <br/><li style="list-style: none;">Khả năng triển khai, quản lý và vận hành các giải pháp phức tạp.</li>
    <br/><li style="list-style: none;">24x7 kết nối với các chuyên gia hàng đầu.</li>
    <br/><li style="list-style: none;">Cải thiện chi phí chủ sở hữu.</li>
    <br/><li style="list-style: none;">Biết trước chi phí hàng tháng</li>
    </ul>
    </p>`
    ,PScategory : 16
    },
{
    id_lv2: 47,
    textItem: "Đề xuất dịch vụ",
    text: `<p>
    <strong>Giám sát lỗi và chất lượng của cơ sở hạ tầng mạng của khách hàng</strong>
    <br/><br/>
    Giám sát các sự cố cung cấp cơ chế chủ động cảnh báo, xác định và cô lập giúp đáp ứng nhanh hơn trong việc khắc phục sự cố. Việc theo dõi hiệu suất cung cấp thông tin về việc sử dụng tài nguyên và hiệu suất của thiết bị cả thời gian thực lẫn thông tin trước đó (historical information). Song song với việc trợ giúp hoạch định khả năng sử dụng thiết bị trong tương lai, nó cũng khoanh vùng cơ sở hạ tầng có vấn đề về hiệu suất.
    <br/><br/>
    <strong>Giám sát và quản lý các sự kiện cả ngày lẫn đêm</strong>
    <br/><br/>
    Quy trình quản lý sự cố dựa theo tiêu chuẩn ISO 20000. Việc quản lý sự cố bao gồm việc ghi “log” các sự kiện vào hệ thống quản lý “log”. Ngoài ra nó cũng bao gồm quá trình leo thang của sự kiện (đến nhóm hỗ trợ dự án cấp cao / các chuyên gia / ban lãnh đạo HSV) đến khi sự cố được giải quyết và đóng lại.
    <br/><br/>
    <strong>Vận hành và quản trị mạng</strong>
    <br/><br/>
    HSV cung cấp dịch vụ vận hành các hoạt động thường nhật của mạng, "quản lý thay đổi" và "quản lý cấu hình"… Các kỹ sư có kinh nghiệm của chúng tôi sẽ khuyến cáo, đưa ra giải pháp, xử lý, khắc phục các sự cố phức tạp bao gồm cả việc cấu hình lại hệ thống mạng nâng cao.
    <br/><br/>
    <strong>Tư vấn</strong>
    <br/><br/>
    Chúng tôi cung cấp dịch vụ tư vấn về hạ tầng CNTT để đáp ứng yêu cầu xây dựng mới của khách hàng, hoặc cải tiến cơ sở hạ tầng CNTT hiện có đề đáp ứng khả năng hồi phục nhanh, tính bảo mật và hiệu suất hoạt động…
    <br/><br/>
    <strong>Báo cáo</strong>
    <br/><br/>
    Khách hàng được cấp quyền truy cập vào web portal để xem các lỗi và tình trạng hiệu suất của mạng.
    </p>`
    ,PScategory : 16
},
{
    id_lv2: 48,
    textItem: "Thách thức doanh nghiệp",
    text: `<p>
    Dung lượng lưu trữ dữ liệu ngày càng tăng
    <br/><br/>
    Ngày càng tăng độ phức tạp trong việc quản lý các tầng khác nhau của hệ thống lưu trữ
    <br/><br/>
    Ngày càng tăng chi phí quản lý lưu trữ
    <br/><br/>
    Ngày càng tăng các loại máy chủ và hệ điều hành
    <br/><br/>
    Ngày càng tăng số lượng các địa điểm đặt máy chủ tại văn phòng doanh nghiệp hoặc đặt tại trung tâm dữ liệu của nhà cung cấp dịch vụ
    <br/><br/>
    Ngừng dịch vụ, mất dữ liệu làm mất khả năng kinh doanh.
    <br/><br/>
    Khó khăn và chi phí cho hỗ trợ 24/7.
    <br/><br/>
    Môi trường CNTT trở nên phức tạp về kiến trúc, vận hành và quản lý.
    <br/><br/>
    Không còn lo lắng để tập trung vào nghành kinh doanh chính nhằm nâng cao lợi nhuận, hiệu quả và sự cạnh tranh.
    </p>`
    ,PScategory : 17
    },
{
    id_lv2: 49,
    textItem: "Giải pháp của chúng tôi",
    text: `<p>
    Dịch vụ “Quản lý máy chủ”
    <br/><br/>
    Dịch vụ được hỗ trợ cả ngày lẫn đêm bởi Bàn Hỗ Trợ(ServiceDesk) của chúng tôi. Với các công cụ giám lỗi và hiệu năng, khách hàng có thể yên tâm rằng ứng dụng quan trọng của các bạn được quản lý một cách chuyên nghiệp trong một môi trường bảo đảm.
    <br/><br/>
    Dịch vụ “Quản lý Lưu trữ”
    <br/><br/>
    Dịch vụ của chúng tôi bao gồm tập hợp các dịch vụ quản lý và hỗ trợ hạ tầng lưu trữ của doanh nghiệp. Với các dịch vụ, các công cụ và quy trình quản lý của chúng tôi đảm bảo tính sẵn sàng và tối ưu hóa hiệu suất của hệ thống lưu trữ của doanh nghiệp.
    <br/><br/>
    Dịch vụ được hỗ trợ cả ngày lẫn đêm bởi Bàn Hỗ Trợ(ServiceDesk) của chúng tôi. Với các công cụ giám lỗi và hiệu năng, khách hàng có thể yên tâm rằng ứng dụng quan trọng của các bạn được quản lý một cách chuyên nghiệp trong một môi trường bảo đảm. Dịch vụ của chúng tôi bao gồm tập hợp các dịch vụ quản lý và hỗ trợ hạ tầng lưu trữ của doanh nghiệp. Với các dịch vụ, các công cụ và quy trình quản lý của chúng tôi đảm bảo tính sẵn sàng và tối ưu hóa hiệu suất của hệ thống lưu trữ của doanh nghiệp.
    <br/><br/>
    Giám sát - thu thập dữ liệu sự kiện, thông báo và xem xét lại với sự hỗ trợ của các nhà cung cấp, giám sát việc sử dụng dung lượng lưu trữ, việc giám sát 24x7 và quy trình leo thang (chỉ hệ thống con lưu trữ doanh nghiệp).
    <br/><br/>
    Quản lý các hoạt động và cấu hình - Xử lý xự kiện và giải quyết lỗi, lập kế hoạch và phối hợp với nhà cung cấp dịch vụ bảo trì phần cứng và phần mềm, duy trì việc cập nhật tài liệu cấu hình hệ thống
    <br/><br/>
    Cung cấp các số liệu thống kê lỗi hàng tháng
    <br/><br/>
    Cung cấp dung lượng sử dụng lưu trữ hàng tháng
    <br/><br/>
    Dịch vụ “Quản lý dữ liệu bản sao” để bảo vệ thêm
    </p>`
    ,PScategory : 17
    },
{
    id_lv2: 50,
    textItem: "Lợi ích mang lại cho doanh nghiệp",
    text: `<p>
    Giảm thời gian chết và chi phí phát sinh.
    <br/><br/>
    Tiết kiệm chi phí hỗ trợ máy chủ.
    <br/><br/>
    Tận dụng nhân lực phục vụ cho các dự án phát triển của doanh nhiệp.
    <br/><br/>
    Yên tâm về việc quản lý giám sát ngoài giờ làm việc.
    <br/><br/>
    Quản lý nguồn lực CNTT mềm dẻo (tài chính, kỹ năng, đào tạo, dự án cần) hiệu quả hơn.
    <br/><br/>
    Khả năng triển khai, quản lý và vận hành các giải pháp phức tạp.
    <br/><br/>
    24x7 kết nối với các chuyên gia hàng đầu.
    <br/><br/>
    Cải thiện chi phí chủ sở hữu.
    <br/><br/>
    Biết trước chi phí hàng tháng.
    </p>`
    ,PScategory : 17
},
{
    id_lv2: 51,
    textItem: "Giới thiệu dịch vụ",
    text: `<p>
    Nhằm mục đích đảm bảo chất lượng toàn bộ các loại hình dịch vụ hỗ trợ cung cấp cho các khách hàng của mình, công ty HSV chúng tôi đã đầu tư tổ chức một trung tâm dịch vụ khách hàng (ServiceDesk) làm đầu mối duy nhất để tiếp nhận yêu cầu hỗ trợ từ các khách hàng, phân công kỹ sư hỗ trợ các cấp, theo dõi quản lý quá trình xử lý yêu cầu hỗ trợ kỹ thuật của khách hàng.
    <br/><br/>
    <strong>ServiceDesk</strong> là một bộ máy bao gồm các kênh liên lạc dễ nhớ dành cho khách hàng, phần mềm quản lý dịch vụ khách hàng, các nhân viên chuyên trách phụ trách toàn bộ quy trình vận hành ServiceDesk và cuối cùng là đội ngũ kỹ sư lành nghề của HSV được tổ chức phân công trực hỗ trợ khách hàng theo các lãnh vực chuyên môn với mức độ ưu tiên và độ sẵn sàng cao nhất.
    </p>`
    ,PScategory : 18
    },
{
    id_lv2: 52,
    textItem: "Hoạt động của Servicedesk",
    text: `<p>
    ServiceDesk được tổ chức để luôn luôn sẵn sàng tiếp nhận các yêu cầu hỗ trợ của khách hàng với thông tin liên hệ như sau:
    <br/><br/>
    Ngay khi nhận được yêu cầu hỗ trợ từ khách hàng, ServiceDesk kiểm tra đầy đủ thông tin với người yêu cầu bao gồm:
    <ul>
    <li style="list-style: none;">Thông tin liên hệ: Tên người yêu cầu, số điện thoại liên hệ và email</li>
    <br/><li style="list-style: none;">Thông tin thiết bị: Tình trạng lỗi, số serial, tem bảo hành</li>
    <br/><li style="list-style: none;">Thông tin hợp đồng: Số đơn hàng, địa điểm đặt thiết bị</li>
    </ul>
    Sau khi có đầy đủ thông tin, ServiceDesk xác định loại hình dịch vụ được yêu cầu, tạo ngay một yêu cầu hỗ trợ (Case) trên phần mềm quản lý dịch vụ khách hàng (cũng gọi là phần mềm ServiceDesk) và chỉ định kỹ sư hỗ trợ của lĩnh vực chuyên môn liên quan cho khách hàng dựa trên bảng phân công trực có sẵn.
    <br/><br/>
    Kỹ sư trực hỗ trợ sẽ lập tức liên hệ lại với khách hàng để bắt đầu quá trình hỗ trợ cho tới khi hoàn tất hỗ trợ và được sự xác nhận hoàn tất của khách hàng qua email trong trường hợp hỗ trợ từ xa, hoặc qua phiếu dịch vụ trong trường hợp hỗ trợ tại hiện trường. Trong suốt quá trình hỗ trợ khách hàng, người kỹ sư hỗ trợ sẽ chịu trách nhiệm cập nhật thông tin xử lý vào Case trên phần mềm ServiceDesk cho tới khi hoàn tất.
    <br/><br/>
    Ngoài ra trong mô hình hoạt động của mình, người quản lý ServiceDesk cũng sẽ là kênh đầu tiên tiếp nhận và xử lý phản hồi vượt cấp của khách hàng trong một số trường hợp nếu khách hàng không hài lòng về chất lượng dịch vụ mà Sao Bắc Đẩu cung cấp.
    </p>`
    ,PScategory : 18
    },
{
    id_lv2: 53,
    textItem: "Tổ chức đội ngũ kỹ thuật",
    text: `<p>
    Đội ngũ hỗ trợ kỹ thuật của HSV Đẩu được tổ chức trong một trung tâm công nghệ, dẫn đầu bởi một chuyên gia có chứng chỉ CCIE của Cisco (TC Director). Trung tâm được chia thành ba đội cho ba vùng chính của Việt Nam là đội hỗ trợ cho khu vực miền Nam (South Team), đội hỗ trợ cho khu vực miền Trung (Central Team) và đội hỗ trợ cho khu vực miền Bắc (North Team). Mỗi đội sẽ bao gồm một trưởng phòng giữ vai trò quản lý kỹ thuật chung (Technical Manager). Bên dưới có các nhóm chuyên môn cho các lĩnh vực kỹ thuật công nghệ khác nhau. Mỗi nhóm có trưởng nhóm đóng vai trò kỹ sư hỗ trợ cấp hai (Technical level 2), các thành viên trong nhóm đóng vai trò của kỹ sư hỗ trợ cấp 1 (Technical level 1) cho khách hàng.
    <br/><br/>
    Đội ngũ hỗ trợ kỹ thuật được phân công trực sẵn sàng hỗ trợ kỹ thuật theo một danh sách trực được cập nhật hai tuần một lần, trong đó bao gồm:
    <ul>
    <li style="list-style: none;">Lĩnh vực chuyên môn: Network, System, DataCenter, Cabling.</li>
    <br/><li style="list-style: none;">Danh sách kỹ sư hỗ trợ trực cho miền Nam, kỹ sư hỗ trợ trực cho miền Trung và kỹ sư hỗ trợ trực cho miền Bắc.</li>
    <br/><li style="list-style: none;">Kỹ sư cấp 1 và cấp 2 trực trong giờ làm việc.</li>
    <br/><li style="list-style: none;">Kỹ sư cấp 1 và cấp 2 trực ngoài giờ làm việc.</li>
    </ul>
    </p>`
    ,PScategory : 18
},
{
    id_lv2: 54,
    textItem: "Phần mềm quản lý Spa",
    text: `<p>
    Trong xã hội hiện đại nhu cầu làm đẹp và chăm sóc sức khỏe ngày càng được quan tâm cũng như sử dụng thường xuyên hơn. Do đó các spa, các trung tâm chăm sóc sắc đẹp ngày càng phát triển về cả chiều rộng lẫn chiều sâu. Với vai trò là người quản lý bạn đã có giải pháp để quán lý số lượng khách hàng ngày càng tăng lên của mình? Làm sao để nắm bắt được nhu cầu khách hàng kịp thời cũng như quản lý cơ sở của mình một cách hiệu quả nhất? Phần mềm quản lý Spa “của HSV sẽ giúp bạn thực hiện nó một cách dễ dàng và hiệu quả!
    <br/><br/>
    <img src=${img57} alt="" >
    <br/><br/>
    Lợi ích của phần mềm quản lý spa
    <ul>
    Quản lý, theo dõi lượng khách hàng dễ dàng. Từ đó, đưa ra những chiến thuật kinh doanh hợp lý.
    <br/>Quản lý doanh số, lợi nhuận của từng dịch vụ hàng.
    <br/>Quản lý các chi nhánh của spa.
    <br/>Dễ dàng trao đổi và hỗ trợ xử lý công việc giữa các nhân viên và bộ phận.
    <br/>Hệ thống báo cáo thời gian thực.
    <br/>Dễ dàng tùy chỉnh phần mềm khi quy trình công việc thay đổi.
    <br/>Tất cả mọi thông tin khách hàng và các thông tin có liên quan đều được lưu lại trên phần mềm, tránh thất thoát, và người dùng có thể dễ dàng tìm lại khi cần.
    </ul>
    <h2>Chức năng của phần mềm quản lý spa</h2>
    <strong>Quản lý và chăm sóc khách hàng</strong>
    <ul>
    Nắm bắt tra cứu mọi hoạt động liên quan đến khách hàng nhanh chóng đơn giản như doanh thu, số lần đến, mức độ hài lòng, nhật ký thẻ, nhật ký sử dụng.
    <br/>Phân tích tần suất khách đến theo từng nhóm, phân loại khách…
    <br/>Quản lý, tiếp nhận khách hàng, khách mới, khách cũ.
    <br/>Quản lý nhật ký giao dịch khách hàng (Số lần khách đến, dịch vụ, thẻ đã sử dụng, lần gần nhất khách đến…)
    <br/>Quản lý chi tiết thông tin khách hàng, nhắc nhở ngày sinh nhật, ngày kỷ niệm của khách.
    <br/>Phiếu đánh giá, khảo sát khách hàng.
    <br/>Báo cáo so sánh tăng giảm tình hình sử dụng sản phẩm dịch vụ của khách hàng…
    </ul>
    <strong>Quản lý danh sách hẹn</strong>
    <ul>
    Thiết lập giường, phòng theo mô hình kinh doanh riêng.
    <br/>Lưu giữ chi tiết cuộc hẹn của khách hàng.
    <br/>Hiển thị trạng thái của cuộc hẹn.
    <br/>Tự động hiển thị thông báo nhắc nhở khi có cuộc hẹn sắp diễn ra.
    <br/>Thêm, xóa, sửa cuộc hẹn.
    <br/>Tự động liệt kê danh sách chuyên viên thích hợp để thực hiện đối với từng cuộc hẹn.
    <br/>Liệt kê danh sách cuộc hẹn đã xác nhận diễn ra trong ngày, tuần…
    </ul>
    <strong>Quản lý gói dịch vụ</strong>
    <ul>
    Quản lý gói dịch vụ theo nhiều tiêu chí: mã gói dịch vụ, tên dịch vụ, mã hóa đơn, mã khách hàng, tên khách hàng…
    <br/>Quản lý thông tin số lần đã sử dụng dịch vụ và số lần còn lại tự động được cập nhật.
    <br/>Lưu giữ thông tin về ngày, chuyên viên thực hiện đối với mõi lần sử dụng.
    <br/>Quản lý số tiền thanh toán và còn nợ của mỗi khách hàng.
    <br/>Cho phép trả lại gói dịch vụ khi chưa sử dụng hoặc đang sử dụng.
    <br/>Quản lý gia hạn gói dịch vụ.
    <br/>Quản lý và tính hoa hồng cho chuyên viên khi sử dụng gói dịch vụ.
    <br/>Lưu trữ chi tiết dịch vụ chuyên viên đã thực hiện: mã hóa đơn, tên dịch vụ, ngày thực hiện…
    <br/>Có thể thiết lập mức hoa hồng đối với từng dịch vụ.
    </ul>
    <strong>Quản lý sản phẩm</strong>
    <ul>
    Quản lý nhóm sản phẩm
    <br/>Quản lý giá từng sản phẩm, thiết lập mức hoa hồng cho chuyên viên khi bán được
    <br/>Quản lý nhóm dịch vụ
    <br/>Thiết lập được mức tiêu hao nguyên liệu đối với từng sản phẩm,dịch vụ
    <br/>Quản lý quá trình đặt hàng
    <br/>Quản lý tên sản phẩm, mã sản phẩm, đơn giá, số lượng hàng…
    </ul>
    <strong>Quản lý thẻ khách hàng</strong>
    <ul>
    Quản lý thẻ trị liệu, thẻ lần, thẻ giá trị, voucher giảm giá.
    <br/>Theo dõi công nợ thẻ, doanh thu thẻ, nhật ký sử dụng thẻ.
    <br/>Chiêt khấu cho nhân viên bán, nhân viên thực hiện thẻ.
    </ul>
    <strong>Quản lý bán hàng, thu ngân</strong>
    <ul>
    Quản lý nhiều hình thức thanh toán khác nhau ( Tiền mặt, tiền gửi, thẻ voucher, thẻ giá trị…)
    <br/>Quản lý bán hàng, hóa đơn thanh toán, hóa đơn thu ngân.
    <br/>Theo dõi khách đang thực hiện dịch vụ, công đoạn, định mức.
    <br/>Quản lý đánh giá, ý kiến khách hàng sau khi thực hiên dịch vụ.
    </ul>
    <strong>Báo cáo tổng hợp, thống kê lãi, lỗ</strong>
    <ul>
    Báo cáo tổng hợp, chi tiết các hoạt động kinh doanh của spa hay salon.
    <br/>Báo cáo thống kê chi phí, doanh thu, lãi lỗ theo từng ngày, tháng, năm…
    <br/>Báo cáo lương, bảng lương, phụ cấp…
    <br/>Báo cáo tần suất khách hàng đến, báo cáo chăm sóc khách hàng.
    <br/>Biểu đồ bán hàng, so sánh, thống kê hàng bán… và nhiều báo cáo khác.
    </ul>
    Phần mềm quản lý spa của Hitek giúp bạn quản lý được tổng thể được một trung tâm hay một chuỗi trung tâm thẩm mỹ, spa…
    <br/><br/>
    Phần mềm dễ sử dụng giúp nhân viên bán hàng nhanh chóng và chính xác. Các bộ phận kết hợp nhịp nhàng mang đến sự hài lòng cho khách hàng. Quản lý chặt chẽ thẻ thành viên, tích điểm, tiền khách hàng trả trước. Cung cấp đầy đủ thông tin và hỗ trợ các báo cáo phân tích, giúp lãnh đạo đưa ra cấc quyết định kịp thời, chính xác.
    <br/><br/>
    Có thể nói phần mềm quản lý của Hitek là giải pháp phần mềm phù hợp nhất, toàn diện nhất để đáp ứng nhu cầu quản lý điều hành của các spa. Phần mềm có rất nhiều tiện ích, đáp ứng gần như hoàn toàn các yêu cầu khắt khe nhất của khách hàng.
    </p>`
    ,PScategory : 19
    },
{
    id_lv2: 55,
    textItem: "Phần mềm quản lý phòng vé",
    text: `<p>
    Trong xã hội hiện đại nhu cầu làm đẹp và chăm sóc sức khỏe ngày càng được quan tâm cũng như sử dụng thường xuyên hơn. Do đó các spa, các trung tâm chăm sóc sắc đẹp ngày càng phát triển về cả chiều rộng lẫn chiều sâu. Với vai trò là người quản lý bạn đã có giải pháp để quán lý số lượng khách hàng ngày càng tăng lên của mình? Làm sao để nắm bắt được nhu cầu khách hàng kịp thời cũng như quản lý cơ sở của mình một cách hiệu quả nhất? Phần mềm quản lý Spa “của HSV sẽ giúp bạn thực hiện nó một cách dễ dàng và hiệu quả!
    <h2>CHỨC NĂNG PHẦN MỀM QUẢN LÝ PHÒNG VÉ</h2>
    <strong>Quản lý thông tin chung</strong>
    <ul>
    Quản lý thông tin khách hàng
    <br/>Quản lý thông tin hãng hàng không
    <br/>Quản lý thông tin chuyến bay
    <br/>Quản lý thông tin nhân viên
    </ul>
    <strong>Quản lý thông tin chuyến bay</strong>
    <ul>
    Quản lý hãng hàng không
    <br/>Quản lý chuyến bay
    <br/>Quản lý ngày, giờ, điểm đi, điểm đến…
    </ul>
    <strong>Quản lý khách hàng</strong>
    <ul>
    Quản lý danh sách khách hàng
    <br/>Quản lý thông tin khách hàng
    <br/>Tìm kiếm thông tin khách hàng theo nhiều tiêu chí
    <br/>Quản lý thông tin vé khách hàng đặt
    </ul>
    <strong>Quản lý nhân sự</strong>
    <ul>
    Quản lý thông tin nhân viên
    <br/>Tìm kiếm nhân viên theo các tiêu chí: tên, ca trực, ngày trực…
    <br/>Theo dõi số lượng book vé, doanh thu, lợi nhuận theo từng nhân viên, theo hãng hàng không… để tính lương, thưởng cho nhân viên.
    <br/>Quản lý nhân viên của từng đại lý và của cả công ty
    </ul>
    <strong>Quản lý thu chi và công nợ</strong>
    <ul>
    Quản lý phiếu thu, phiếu chi của các giao dịch thu tiền vé, thu chi công nợ và các thu chi khác.
    <br/>Quản lý công nợ khách hàng, chi tiết khách hàng còn nợ.
    <br/>Quản lý công nợ nhà cung cấp: chi tiết giao dịch với nhà cung cấp dễ dàng kiểm soát và phát hiện sai lệch kịp thời.
    <br/>Quản lý doanh thu chi tiết của toàn bộ công ty.
    <br/>Quản lý chi tiết thu chi tiền mặt.
    <br/>Tài khoản ngân hàng: chi tiết giao dịch thu chi của từng tài khoản ngân hàng.
    </ul>
    <strong>LỢI ÍCH CỦA PHẦN MỀM QUẢN LÝ PHÒNG VÉ</strong>
    <ul>
    Thông tin khách hàng được quản lý tập trung, giúp dễ dàng tìm kiếm và phân loại.
    <br/>Quản lý khách hàng, đường bay, nhân viên, marketing…
    <br/>Dễ dàng trao đổi và hỗ trợ xử lý công việc giữa các nhân viên và bộ phận.
    <br/>Hệ thống báo cáo thời gian thực.
    <br/>Dễ dàng tùy chỉnh phần mềm khi quy trình thay đổi.
    </ul>
    Phần mềm quản lý phòng vé giúp các nhà quản lý nắm được quá trình hoạt động của công ty một cách dễ dàng, phần mềm có các tính năng hỗ trợ giúp quản lý, phân quyền dữ liệu, và báo cáo theo từng danh mục, giúp kiểm soát được số lượng book vé, doanh thu, lợi nhuận của công ty. Phần mềm quản lý phòng vé của Hitek có thể tùy biến dễ dàng, đáp ứng được những yêu cầu chuyên biệt của từng doanh nghiệp. Với khả năng tùy biến cao, phần mềm dễ dàng cho việc nâng cấp và mở rộng các tính năng về sau khi doanh nghiệp muốn mở rộng quy mô hay thay đổi quy trình hoạt động kinh doanh.
    <br/><br/>
    Có thể nói phần mềm quản lý phòng vé Hitek là giải pháp phần mềm phù hợp nhất, toàn diện nhất để đáp ứng mọi nhu cầu quản lý phòng vé. Điểm nổi bật của phần mềm chúng tôi là giao diện thân thiện, đơn giản, dễ sử dụng ngay cả đối với những người chưa biết nhiều về máy tính, kế toán đều có thể sử dụng được thành thạo. Điều khoản chăm sóc khác hàng tối đa với những chính sách tư vấn hỗ trợ tận nơi (HSV cử nhân viên tới tận công ty tạo lập và hướng dẫn lập các module theo yêu cầu).
    </p>`
    ,PScategory : 19
    },
{
    id_lv2: 56,
    textItem: "Phần mềm quản lý du lịch",
    text: `<p>
    Trong xã hội hiện đại nhu cầu làm đẹp và chăm sóc sức khỏe ngày càng được quan tâm cũng như sử dụng thường xuyên hơn. Do đó các spa, các trung tâm chăm sóc sắc đẹp ngày càng phát triển về cả chiều rộng lẫn chiều sâu. Với vai trò là người quản lý bạn đã có giải pháp để quán lý số lượng khách hàng ngày càng tăng lên của mình? Làm sao để nắm bắt được nhu cầu khách hàng kịp thời cũng như quản lý cơ sở của mình một cách hiệu quả nhất? Phần mềm quản lý Spa “của HSV sẽ giúp bạn thực hiện nó một cách dễ dàng và hiệu quả!
    <h2>LỢI ÍCH PHẦN MỀM</h2>
    <strong>Tập trung vào khách hàng với các dịch vụ được cải thiện</strong>– Thông tin 360 độ về khách hàng, cho phép xem toàn bộ lịch sử giao dịch với khách hàng từ lúc bắt đầu request/booking cho tới các hoạt động marketing sau này.
    <br /><br />
    <strong>Tích hợp thông tin với quy trình một cách thông suốt</strong>– Thông tin tự động luân chuyển giữa các bộ phận một cách chính xác theo quy trình.
    <br /><br />
    <strong>Tăng nhanh việc xoay vòng nguồn vốn</strong>– Cơ chế quản lý báo giá dịch vụ linh hoạt giúp doanh nghiệp bạn luôn kiểm soát được chi phí và giá bán một cách chính xác và kịp thời.
    <br /><br />
    <strong>Công cụ mạnh mẽ giúp ra quyết định kịp thời</strong>– Hệ thống báo cáo điện từ cập nhật từng phút hoạt động kinh doanh của từng chi nhánh giúp bạn hạn chế tối đa các rủi ro.
    <br /><br />
    <h2>CHỨC NĂNG CỦA PHẦN MỀM</h2>
    <strong>Quản lý và chăm sóc khách hàng</strong>
    <ul>
    Nhập danh sách khách hàng từ file excel.
    <br/>Báo cáo khách hàng theo: nhân viên phụ trách, địa bàn, số lần sử dụng dịch vụ, giá trị hợp đồng…
    <br/>Quản lý, tra cứu thông tin về người đặt tour (cá nhân/tổ chức) và người đi tour (du khách).
    <br/>Hỗ trợ nhân viên kinh doanh lên lịch chăm sóc khách hàng.
    </ul>
    <strong>Quản lý đối tác và nhà cung cấp</strong>
    <ul>
    Phân loại, tra cứu nhà cung cấp dịch vụ: Khách sạn, nhà hàng, landtour, vận chuyển, du thuyền v.v…
    <br/>Định nghĩa bảng giá dịch vụ: giá theo mùa, theo hợp đồng, theo đối tượng khách.
    <br/>Nhập thông tin về nhà cung cấp: đầu mối liên lạc, thư viện ảnh, chính sách hủy dịch vụ, bản đồ vị trí…
    <br/>Báo cáo và in ấn: cảnh báo hết hạn hiệu lực hợp đồng, biểu đồ giá cung cấp dịch vụ.
    </ul>
    <strong>Quản lý request/booking</strong>
    <ul>
    Đặt dịch vụ (request/booking): đặt tour, dịch vụ khách sạn, du thuyền, phương tiện vận chuyển, dịch vụ hỗn hợp.
    <br/>Tạo mới request/booking từ mẫu dựng sẵn (create from template).
    <br/>Xử lý booking: lưu vết thay đổi, lên danh sách công việc (to do list), nhập danh sách du khách.
    <br/>Lịch sử trao đổi: Ngày giờ trao đổi, phương thức (email/điện thoại), nội dung trao đổi.
    <br/>Báo cáo thống kê: doanh số booking dự báo và thực tế theo tháng, theo người phụ trách, theo loại tour v.v…
    </ul>
    <strong>Quản lý bán tour và dịch vụ</strong>
    <ul>
    Quản lý bán tour: Lịch khởi hành, nhà cung cấp liên quan, lịch trình tour, số chỗ còn đặt, danh sách du khách.
    <br/>Quản lý hoạt động điều hành tour: Lên lịch điều hành, cảnh báo đến hạn, phân công và giám sát công việc điều hành tour, đánh giá/phản hồi từ khách hàng.
    <br/>Lên kế hoạch thanh toán: theo dõi thu chi liên quan đến tour/nhà cung cấp, khách hàng.
    <br/>Thiết kế và tính giá sản phẩm tour: lên ý tưởng sản phẩm, lập các phương án tính giá, đánh giá thảo luận.
    </ul>
    <strong>Tính năng nâng cao</strong>
    <ul>
    Quản lý đa chi nhánh: Phân cấp dữ liệu riêng cho từng chi nhánh.
    <br/>Báo cáo phân tích đa chiều.
    <br/>Đặt hàng trực tuyến theo thời gian thực.
    <br/>Quản lý chiến dịch marketing.
    <br/>Báo cáo tổng hợp theo chi nhánh hoặc cả công ty.
    </ul>
    Phần mềm quản lý phòng vé giúp các nhà quản lý nắm được quá trình hoạt động của công ty một cách dễ dàng, phần mềm có các tính năng hỗ trợ giúp quản lý, phân quyền dữ liệu, và báo cáo theo từng danh mục, giúp kiểm soát được số lượng book vé, doanh thu, lợi nhuận của công ty. Phần mềm quản lý phòng vé của Hitek có thể tùy biến dễ dàng, đáp ứng được những yêu cầu chuyên biệt của từng doanh nghiệp. Với khả năng tùy biến cao, phần mềm dễ dàng cho việc nâng cấp và mở rộng các tính năng về sau khi doanh nghiệp muốn mở rộng quy mô hay thay đổi quy trình hoạt động kinh doanh.
    </p>`
    ,PScategory : 19
    },
{
    id_lv2: 57,
    textItem: "Phần mềm quản lý khách sạn",
    text: `<p>
    <h2>PHẦN MỀM QUẢN LÝ KHÁCH SẠN</h2>
    <br/><br/>
    <img src=${img58} alt="">
    <br/><br/>
    Bằng kinh nghiệm sẵn có trong việc phát triển các phần mềm quản lý doanh nghiệp và kiến thức trong lĩnh vực quản lý nghiệp vụ công chứng, Công ty Cổ phần dịch vụ và công nghệ HSV cung cấp giải pháp phần mềm quản lý phòng công chứng “Hitek” với công nghệ tiên tiến nhất, giúp giải quyết khó khăn mà các phòng công chứng đang gặp phải. Phầm mềm “HSV” của HSV hướng tới số hóa dữ liệu, tối giản hóa thao tác nghiệp vụ và tự động lưu lại các thông tin về hợp đồng, tài sản, lịch sử giao dịch. Phần mềm quản lý phòng công chứng của Hitek là cơ sở đáng tin cậy giúp công chứng viên dễ dàng tra cứu thông tin và xác định tính hợp lệ của tài sản, từ đó đẩy nhanh tiến độ công chứng hồ sơ và làm hài lòng các khách hàng .
    <h2>TÍNH NĂNG CỦA PHẦN MỀM QUẢN LÝ KHÁCH SẠN</h2>
    <h3>QUẢN LÝ VÀ CHĂM SÓC KHÁCH HÀNG</h3>
    <ul>
    - Quản lý thông tin khách hàng : tên khách hàng, các thông tin liên lạc liên quan, hợp đồng, số lần sử dụng dịch vụ, các dịch vụ đã sử dụng, lịch sử và ghi chú sử dụng dịch vụ như:
    <br/>- Quản lý phòng, thông tin phòng.
    <br/>- Quản lý giá phòng, loại phòng.
    <br/>- Quản lý phòng theo lầu.
    <br/>- Quản lý phòng theo trạng thái (màu sắc: đỏ, xanh,…)
    <br/>- Thời gian nhảy tự động cho phòng đang phục vụ.
    <br/>- Thống kê về nguồn khách hàng: thông tin thời gian tạo, nguồn hình thành khác hàng, nhân viên tiếp nhận quản lý khách hàng….
    <br/>- Thông báo về ghi chú liên quan đến khách hàng: nhắc nhở ngày giờ dịch vụ, sinh nhật của khách hàng, sinh nhật khách, lịch sử sử dụng dịch vụ của khách,..
    <br/>- Lưu lại đánh giá, phản ánh của khách hàng về chất lượng dịch vụ
    <br/>- Tự động gửi tin nhắn,email chúc mừng, chăm sóc khách hàng
    <br/>- Hỗ trợ nhân viên lên lịch để chăm sóc khách hàng
     </ul>
    <h3>Quản lý đối tác và các nhà cung cấp</h3>
    <ul>
    - Quản lý đầy đủ thông tin các đối tác: Các thông tin liên hệ, bảng giá, hợp đồng, hoa hồng, thông tin các khuyến mãi liên quan,….
    <br/>- Quản lý lịch sử hợp tác
    <br/>- Lưu lại đánh giá chất lượng dịch vụ của các đối tác
    <br/>- Nhắc nhở và cảnh báo: có thời hạn sắp hết hợp đồng, có phản ánh về chất lượng dịch vụ, có khuyến mãi triển khai,….
    <br/>- Hỗ trợ tìm kiếm, thống kê với đa dạng các tiêu chí
    </ul>
    <h3>Quản lý Request/booking</h3>
    <ul>
    - Ghi nhận thông tin đặt dịch vụ và lưu trữ thông tin
    <br/>- Tạo lập và lưu trữ thông tin các dịch vụ, báo giá và quản lý tình hình khách hàng
    <br/>- Lên lịch chăm sóc
    <br/>- Cảnh báo, thông báo ngày hẹn chăm sóc, ngày báo giá tới khách qua hệ thống SMS, Email,…
    <br/>- Giao việc cho nhân viên hay bộ phận chuyên trách trực tiếp trên phần mềm
    <br/>- Cập nhật số tiền đã thanh toán và công nợ, thông tin đặt cọc,…
    <br/>- Thống kê doanh số theo thắng, theo người phụ trách…
    <br/>- Tạo lập booking trên cơ sở Request
    <br/>- Tự động chuyển các khoản thu sang kế toán để tiện cho việc theo dõi và quản lý
    </ul>
    <h3>Quản lý và điều hành các hoạt động khách sạn</h3>
    <ul>
    - Quản lý thông tin về đối tác (cá nhân, nhóm hoặc đoàn công ty) và các quá trình đám phán, ghi chú liên quan
    <br/>- Phân quyền quản lý, xem đối tác cho bộ phận liên quan có thể tiếp nhận và cùng theo dõi, xử lý
    <br/>- Tạo lập và quản lý kế hoạch sử dụng chi phí, nhà quản lý có thể dễ dàng theo dõi kế hoạch chi pí một cách chi tiết, cập nhật từng phút từng giây để nhanh chóng đưa ra chỉ đạo
    <br/>- Ghi nhận và lưu lại tất cả phản ánh của khách hàng liên quan đã sử dụng các dịch vụ
    <br/>- Hệ thống, cảnh báo, thông báo đến người dùng chuẩn bị đến ngày thực hiện, hay thông báo những điều quan trọng mà người dùng thiết lập
    <br/>- Tra cứu thông tin theo nhiều tiêu chí lựa chọn
    <br/>- Hệ thống bảng biểu, đồ thị linh hoạt giúp người dùng tạo bản in, hay thông báo, báo giá đến đối tác nhanh chóng
    </ul>
    <h3>Quản lý kho vật tư, trang thiết bị</h3>
    <ul>
    - Quản lý chi tiết hàng hóa, danh mục hàng hóa: ngày nhập, lô hàng nhập, số serial, hạn sử dụng của hàng hóa
    <br/>- Cảnh báo hàng đến hạn sử dụng, quá hạn sử dụng, hàng đến ngày xuất, cảnh báo hết hàng khi số lượng hàng chạm ngưỡng tối thiểu…
    <br/>- Quản lý kế hoạch nhập hàng, dự trữ hàng, quản lý hàng tồn kho, hàng cần sử dụng…
    <br/>- Quản lý chi tiết loại hàng hóa: hàng xuất trả, hàng từng bộ phận ….
    <br/>- Quản lý giá vốn hàng hóa, các chi phí phát sinh liên quan đến hàng hóa, để chuyển đến kế toán
    </ul>
    <h3>Quản lý tài chính kế toán</h3>
    <ul>
    - Hoạch định kế hoạch tài chính kế toán
    <br/>- Quản lý công nợ; các khoản phải thu, phải trả
    <br/>- Kế toán chi phí giá thành
    <br/>- Quản lý TSCĐ – CCDC
    <br/>- Kế toán tổng hợp
    <br/>- Báo cáo thuế/BCTC
    </ul>
    <h3>Theo dõi thanh toán và in bill</h3>
    <ul>
    - Cập nhật thanh toán của các dịch vụ một cách nhanh chóng chi tiết để quản lý.
    <br/>- Tính tiền tự động theo giờ (ngày).
    <br/>- Cho phép thanh toán nhiều lần.In bill (tiền phòng + tiền thực đơn + các lần thanh toán).
    <br/>- Thích hợp nhiều cỡ giấy, loại máy in.
    <br/>- Cho phép xem tạm tính trước khi in.
    <br/>- Có thể in 2 liên một lúc, có số liên, có lưu lại lần in tạm tính.
    <br/>- Cập nhật và quản lý các khoản chi cho từng đối tựng, từng hạng mục một cách hiệu quả
    <br/>- Thông báo, cảnh báo ngày đến hạn thanh toán, ngày chi, hay khoản thanh toán quá hạn nhanh chóng kịp thời qua hệ thống trang chủ phần mềm, SMS, Email,….
    <br/>- Có khả năng thống kê, tổng hợp thu, chi, công nợ theo đa dạng các tiêu chí : khách hàng, đối tác, người thu chi ,….
    </ul>
    <h3>Quản lý các phòng ban, nhân viên</h2>
    <ul>
    - Thống kê các phòng ban trong doanh nghiệp, số lượng thành viên trong các bộ phận phòng ban, chức năng nhiệm vụ của phòng ban.
    <br/>- Thông tin các thành viên trong phòng ban, thông tin liên hệ và các thông tin liên quan
    <br/>- Cập nhật tình hình làm việc và phân công công viêc, thông báo đến các phòng ban nhanh chóng
    <br/>- Đánh giá khả năng làm việc của từng phòng ban, nhân viên công ty
    <br/>- Đánh giá khả năng làm việc của từng phòng ban, nhân viên công ty
    </ul>
    <h3>Các tính năng nổi bật khác</h3>
    <ul>
    - Chức năng lên lịch và quản lý công việc của cá nhân, của toàn doanh nghiệp
    <br/>- Lưu trữ và tìm kiếm hồ sơ tài liệu nhanh chóng, tiện lợi qua đa dạng các tiêu chí
    <br/>- Tạo lập và quản lý thông tin người dùng, các thông tin liên quan đến nhân viên
    <br/>- Hệ thống báo cáo, biểu đồ đa dạng giúp người dùng có thể lên các báo cáo, quyết định, báo giá, giấy tờ,… nhanh chóng, kịp thời nhu cầu của người dùng
    <br/>- Khả năng phân quyền cho người dùng chi tiết đến từng hạng mục, giúp các nhà quản lý vẫn có thể quản lý theo dõi tình hình của nhân viên một cách tập trung mà vẫn đảm bảo bảo mật thông tin quan trọng
    <br/>- Khả năng tùy biến của phần mềm rất linh hoạt, không theo một khuôn mẫu nhất định nào – điều mà các phần mềm khác không có được, xây dựng theo quy trình làm việc và nhu cầu của khách hàng nên phù hợp với mọi loại doanh nghiệp và dễ dàng thay đổi đế đáp ứng nhu cầu từ phía doanh nghiệp.
    </ul>
    <h3>Quản lý chương trình khuyến mãi, marketing</h2>
    <ul>
    - Phần mềm quản lý các chương trình khuyến mãi cho khách hàng thân thiết.
    <br/>- Tích điểm cho khách hàng ( cá nhân tổ chức, công ty…) thường xuyên đặt phòng hoặc hay sử dụng dịch vụ của khách sạn.
    <br/>- Chức năng lưu trữ thông tin khách hàng tiềm năng của cửa hàng.
    </ul>
    <h2>NGOÀI RA BẠN CÒN CÓ THỂ THÊM CÁC CHỨC NĂNG QUẢN LÝ PHÂN HỆ TÙY CHỌN KHÁC</h2>
    <strong>Quản lý nhà hàng:</strong>giúp nhận đặt món theo từng bàn, phối hợp công việc với bộ phận bếp. Module cho phép khách thanh toán bằng nhiều hình thức: tiền đồng, ngoại tệ, thẻ tín dụng hay ghi vào tiền phòng…
    <br /><br />
    <strong>Quản lý Spa & thể thao:</strong>Nhiều khách sạn ở Việt Nam đã cung cấp dịch vụ Spa (chăm sóc sắc đẹp, tắm bùn, xông hơi, lột da mặt…). Doanh thu từ hoạt động này có thể thất thoát nếu không được quản lý chung trong hệ thống. Nếu doanh thu từ hoạt động này chiếm 30% tổng doanh thu, khách sạn nên nghĩ đến đầu tư phân hệ này.
    <h2>LỢI ÍCH CỦA PHẦN MỀM QUẢN LÝ KHÁCH SẠN HITEK</h2>
    <ul>
    - Tất cả mọi thông tin về khách sạn, thông tin khách hàng và các thông tin có liên quan đều được lưu lại trên phần mềm tránh thất thoát, và người dùng có thể dễ dàng tìm lại khi cần
    <br/>- Quy trình làm việc đồng bộ, tập trung giúp giảm thiểu các công việc trùng lặp, các công việc thừa, có thể sử dụng kết quả của những khâu làm việc trước đó, từ đó giúp doanh nghiệp tiết kiệm được chi phí cũng như thời gian làm việc, tăng năng suất lao động
    <br/>- Giúp doanh nghiệp cắt giảm các khâu làm việc, cũng như tăng tính kết nối thống nhất giữa các phòng ban, từ đó nâng cao chất lượng dịch vụ của doanh nghiệp
    <br/>- Các cấp lãnh đạo có thể theo dòi tình hình hoạt động của doanh nghiệp, cũng như nhận thông tin từ cấp dưới một cách nhanh chóng, đầy đủ để đưa ra chỉ đạo, quyết định xử lý kịp thời, từ đó giúp doanh nghiệp tăng hiệu quả làm việc và kinh doanh
    <br/>- Phần mềm có giao diện thân thiện, đơn giản, dễ sử dụng, có khả năng tùy biến cao, có thể phù hợp với tất cả các loại hình doanh nghiệp, có thể thay đổi linh hoạt để đáp ứng nhu cầu từ phía doanh nghiệp
    <br/>- Hệ thống cập nhật từng phút, từng giây hoạt động của doanh nghiệp giúp các nhà quản lý cập nhật nhanh tình hình và hạn chế rủi ro.
     </ul>
     Nhìn chung phần mềm HSV là giải pháp phần mềm phù hợp, toàn diện nhất để đáp ứng nhu cầu quản lý của một hoặc một chuỗi khách sạn với nhiều tính năng hữu ích mà giá cả rất cạnh tranh, hợp với túi tiền của khách hàng. Bên cạnh đó, phần mềm có khả năng tùy biến cao nên số lượng tính năng phát triển không giới hạn, đáp ứng gần như đầy đủ mọi nhu cầu của khách hàng. Phần mềm có giao diện đơn giản, rất dễ sử dụng, thêm vào đó, bộ phận kĩ thuật của chúng tôi luôn sẵn sàng để hỗ trợ người dùng 24/7.
</p>`
,PScategory : 19
},
{
    id_lv2: 58,
    textItem: "Phần mềm quản lý bảo hành",
    text: `<p>
    Trong xã hội hiện đại nhu cầu làm đẹp và chăm sóc sức khỏe ngày càng được quan tâm cũng như sử dụng thường xuyên hơn. Do đó các spa, các trung tâm chăm sóc sắc đẹp ngày càng phát triển về cả chiều rộng lẫn chiều sâu. Với vai trò là người quản lý bạn đã có giải pháp để quán lý số lượng khách hàng ngày càng tăng lên của mình? Làm sao để nắm bắt được nhu cầu khách hàng kịp thời cũng như quản lý cơ sở của mình một cách hiệu quả nhất? Phần mềm quản lý Spa “của HSV sẽ giúp bạn thực hiện nó một cách dễ dàng và hiệu quả!
    <h2>ƯU ĐIỂM CỦA PHẦN MỀM QUẢN LÝ BẢO HÀNH</h2>
    <ul>
        Cung cấp quy trình bảo hành chuyên nghiệp, hiện đại cho doanh nghiệp, giúp nhà quản lý có thể đưa ra những giải pháp thúc đẩy doanh thu, giảm thời gian và chi phí bảo hành cho doanh nghiệp.
    <br/>Quản lý song song dữ liệu từ bộ phận bán hàng và bộ phận bảo hành.Đồng bộ dữ liệu từ bộ phận bán hàng và bộ phận bảo hành giúp tìm kiếm nhanh thông tin của sản phẩm và khách hàng. Nhanh chóng ra quyết định tiếp nhận hay từ chối bảo hành. Nhờ đó, khách hàng hài lòng hơn về thái độ và dịch vụ chuyên nghiệp của doanh nghiệp.
    <br/>Thông tin được quản lý tập trung giúp dễ dàng tìm kiếm và phân loại, giúp giảm thiếu thất thoát, và dễ dàng tìm ra quy trình phù hợp nhất cho doanh nghiệp.
    <br/>Nhân viên ở các bộ phận có thể thường xuyên báo cáo quá trình, mức độ hoàn thiện công việc khi có yêu cầu của quản lý mà không phải gặp mặt trực tiếp.
    <br/>Nắm bắt, quản lý được quy trình bảo hành một cách chặt chẽ bằng cách nắm bắt quy trình bảo hành của từng sản phẩm song song với việc giám sát công việc của nhân viên bảo hành. Từ đó doanh nghiệp có thể nhắc nhở kỹ thuật đẩy nhanh tiến độ hoàn thành công việc.
    <br/>Dễ dàng trao đổi và hỗ trợ xử lý công việc giữa các nhân viên và bộ phận.
    <br/>Tính toán khối lượng công việc đã hoàn thành và khối lượng công việc cần hoàn thành, giúp nhân viên trong doanh nghiệp dễ dàng nắm bắt tình trạng công việc, từ đó đưa ra kế hoạch hoàn thành công việc một cách cụ thể, chính xác.
    <br/>Hệ thống báo cáo thời gian thực.
    <br/>Dễ dàng tùy chỉnh phần mềm khi quy trình công việc thay đổi.
    </ul>
    <h2>CHỨC NĂNG CỦA PHẦN MỀM QUẢN LÝ BẢO HÀNH</h2>
    <h3>Quản lý khách hàng, nhà cung cấp, doanh nghiệp</h3>
    <strong>Quản lý sản phầm và nhật ký bảo hành sản phẩm</strong>
    <ul>
    Quản lý đơn đặt hàng: hệ thống quản lý quá trình xuất hàng của từng đơn hàng và theo dõi tình trạng các đơn hàng.
    <br/>Quản lý thu tiền: ghi nhận các khoản thu từ khách hàng như thu công nợ, thu tiền…hệ thống tự động hạch toán các khoản thu theo một hoặc nhiều chứng từ.
    <br/>Quản lý sản phầm đã bán tại các trung tâm phân phối.
    <br/>Quản lý thông tin sản phẩm (series, imei, mẫu mã, màu sắc, ngày bán, thời gian bảo hành,… ).
    <br/>Quản lý đổi trả hàng: hỗ trợ việc đổi trả hàng ngay trên một phiếu mua hàng.
    <br/>Theo dõi nhật ký bảo hành và sử dụng dịch vụ.
    </ul>
    <strong>Khách hàng</strong>
    <ul>
    Quản lý phân nhóm khách hàng (khách buôn, khách lẻ, khu vực …).
    <br/>Quản lý thông tin chi tiết khách hàng ( Mã khách, số thẻ, tên khách, điện thoại, địa chỉ, email, số mobile, website …).
    <br/>Đối với khách hàng là công ty thì có thêm danh sách người liên hệ.
    <br/>Một khách hàng có thể vừa là khách hàng và nhà cung cấp.
    <br/>Quản lý thông tin chi tiết nhà cung cấp ( Mã nhà cung cấp, tên nhà cung cấp, điện thoại, địa chỉ, email, số mobile, website, tài khoản ngân hàng, mã số thuế …).
    <br/>Quản lý tiếp nhận sản phẩm, đặt lịch hẹn thời gian bảo hành và bàn giao sản phẩm.
    </ul>
    <strong>Quản lý request/booking</strong>
    <ul>
    Đặt dịch vụ (request/booking): đặt tour, dịch vụ khách sạn, du thuyền, phương tiện vận chuyển, dịch vụ hỗn hợp.
    <br/>Tạo mới request/booking từ mẫu dựng sẵn (create from template).
    <br/>Xử lý booking: lưu vết thay đổi, lên danh sách công việc (to do list), nhập danh sách du khách.
    <br/>Lịch sử trao đổi: Ngày giờ trao đổi, phương thức (email/điện thoại), nội dung trao đổi.
    <br/>Báo cáo thống kê: doanh số booking dự báo và thực tế theo tháng, theo người phụ trách, theo loại tour v.v…
    </ul>
    <h3>Quản lý bảo hành</h3>
    <strong>Danh mục dịch vụ</strong>
    <ul>
    Quản lý danh mục các công việc sửa chữa ( Mã công việc, tên công việc, phân loại sản phầm, đơn giá bảo hành …)
    </ul>
    <strong>Tiếp nhận sản phẩm</strong>
    <ul>
    Quản lý thông tin tiếp nhận sản phẩm, đánh giá hỏng hóc và lập báo giá cho khách hàng.
    <br/>In phiếu tiếp nhận sản phẩm, tình trạng sản phẩm và báo giá trước khi khách duyệt.
    </ul>
    <strong>Lập báo giá cho khách hàng, bảo hiểm</strong>
    <ul>
    In báo giá chuẩn cho khách hàng sau khi khách hàng hoặc bảo hiểm duyệt.
    <br/>In lệnh sửa chữa, phiếu xuất kho cho việc sửa chữa.
    <br/>In hợp đồng sửa chữa ( nếu cần ).
    </ul>
    <strong>Theo dõi quá trình bảo hành sản phẩm</strong>
    <ul>
    Theo dõi các sản phẩm đang bảo hành, thông báo và báo giá cho khách hàng khi có phát sinh trong quá trình sửa chữa.
    <br/>Tiến hành nghiệm thu, đánh giá nhân viên kỹ thuật bảo hành và bàn giao sản phẩm cho phòng bảo hành.
    <br/>In phiếu thanh toán, phiếu xuất xưởng sau khi sản phẩm bảo hành xong.
    <br/>Bàn giao sản phẩm (thanh lý hợp đồng nếu có).
    </ul>
    <h3>Quản lý bán hàng, xuất kho và dịch vụ</h3>
    <ul>
    Cung cấp quy trình bảo hành chuyên nghiệp, hiện đại cho doanh nghiệp, giúp nhà quản lý có thể đưa ra những giải pháp thúc đẩy doanh thu, giảm thời gian và chi phí bảo hành cho doanh nghiệp.
    <br/>Cập nhật số liệu chi tiết các phụ tùng sản phẩm lưu kho .
    <br/>Báo cáo số tiền đã thu của khách hàng.
    <br/>Báo cáo tình hình bảo hành sản phẩm.
    <br/>Báo cáo bán hàng (theo ngày, khách hàng, hàng hóa vật tư, kho …).
    <br/>Báo cáo số lượng được khuyễn mãi,chiết khấu khi bán.
    <br/>Báo cáo phân tích bán hàng theo mặt hàng,khách hàng,thời gian
    <br/>Báo cáo doanh thu bán hàng theo hóa đơn.
    <br/>Báo cáo doanh thu bán hàng theo ngày hoặc bất kỳ khoảng thời gian nào
    <br/>Báo cáo doanh thu bán hàng theo từng mặt hàng, nhóm hàng, kho hàng.
    <br/>Báo cáo doanh thu bán hàng theo từng khách hàng hoặc nhóm khách hàng (khách buôn, khách lẻ, khách quen …).
    <br/>Báo cáo công nợ bán hàng nếu khách hàng mua chịu hoặc trả chậm.
    </ul>
    <h3>Quản lý nhập mua, nhập kho</h3>
    <ul>
    Cập nhật số liệu khi mua hàng (ngày mua, nhà cung cấp, mặt hàng, số lượng, kho nhập, đơn giá, hình thức khuyến mãi, chiết khấu, thuế suất ..vv..).
    <br/>Báo cáo công nợ nhà cung cấp nếu mua nợ.
    <br/>Báo cáo số tiền đã trả nhà cung cấp.
    <br/>Báo cáo hàng nhập mua (theo ngày, nhà cung cấp, vật tư, kho …).
    <br/>Báo cáo số lượng được khuyễn mãi, chiết khấu khi mua.
    <br/>Báo cáo phân tích mua hàng theo mặt hàng,nhà cung cấp,thời gian … và các báo cáo theo đặc thù riêng.
    </ul>
    <h3>Quản lý kho</h3>
    <ul>
    Quản lý phân nhóm, phân loại phụ tùng hàng hóa .
    <br/>Quản lý thông tin chi tiết mặt hàng (Mã phụ tùng, mã lô, mã vạch, tên sản phẩm, đơn vị tính, giá nhập, giá bán buôn, giá bản lẻ, xuất sứ, hạn sử dụng, cảnh báo tồn tối thiểu, tồn tối đa …).
    <br/>Quản lý giá bán buôn giá bán lẻ đối với từng mặt hàng, phụ tùng, nhóm hàng cho từng loại khách hàng khác nhau (Khách buôn, khách lẻ, khách quen ..).
    </ul>
    <h3>Quản lý nhân viên</h3>
    <ul>
    Quản lý nhân viên, hồ sơ nhân viên.
    <br/>Quản lý hợp đồng nhân viên.
    <br/>Liên kết với máy chấm công, quản lý chấm công.
    <br/>Quản lý nghỉ phép.
    <br/>Quản lý tiền lương theo chính sách của công ty, kết hợp với dữ liệu máy chấm công, nghỉ phép,..
    <br/>Quản lý đánh giá nhân viên.
    </ul>
    <h3>Quản lý kế toán</h3>
    <ul>
    Quản lý thanh toán
    <br/>Quản lý phân bổ doanh thu
    <br/>Quản lý hóa đơn mua hàng
    <br/>Theo dõi công nợ khách hàng
    <br/>Quản lý đề nghị thanh toán theo các hợp đồng ký với khách hàng
    <br/>Quản lý các nghiệp vụ kế toán thông dụng
    <br/>Kết xuất các báo cáo kế toán theo yêu cầu.
    </ul>
    <h3>Quản lý hệ thống, phân quyền, bảo mật</h3>
    <strong>Bảo mật</strong>
    <ul>
    Tự động backup và phục hồi số liệu theo ngày.
    <br/>Dữ liệu luôn luôn được tự động backup dự phòng thường xuyên.
    <br/>Công ty Hitek có chính sách bảo mật tuyệt đối dữ liệu của các doanh nghiệp, khách hàng sử dụng sản phẩm của công ty.
    <br/>Chỉ Admin mới có quyền can thiệp hệ thống dữ liệu và phân quyền sử dụng cho người dùng.
    </ul>
    <strong>Phân quyền sử dụng</strong>
    <ul>
    Phân quyền chức năng sử dụng theo nhóm người sử dụng hoặc từng người sử dụng.
    <br/>Có thể phân quyền xem sửa xóa đến từng thành viên sử dụng phần mềm.
    </ul>
    <strong>Tiện ích</strong>
    <ul>
    Phần mềm có thể xuất trực tiếp ra excel, word, Pdf, mail.
    <br/>Phần mềm có thể import các danh sách hàng hóa, khách hàng từ excel vào phần mềm.
    <br/>Lưu nhật ký sử dụng phần mềm của tất cả các thành viên khi đăng nhập và sử dụng phần mềm.
    <br/>In ấn liền nhiều phiếu thu, chi hoặc hóa đơn chỉ bằng một lệnhin.
    <br/>Xuất ra các báo giá cho một hoặc nhiều khách hàng.
    <br/>Lọc dữ liệu, báo cáo tự động và nhanh chóng theo nhiều tiêu chí.
    <br/>Tích hợp hệ thống VOIP giúp giao tiếp giữa các phòng ban và khách hàng một cách hiệu quả.
    </ul>
    </p>`
    ,PScategory : 19
    },
{
    id_lv2: 59,
    textItem: "Phần mềm quản lý Phòng công chứng",
    text: `<p>
    Hiện nay, khi mà số lượng người có nhu cầu công chứng giấy tờ càng nhiều, thì các tổ chức, văn phòng công chứng càng gặp khó khăn trong vấn đề sắp xếp, lưu trữ, quản lý, tra cứu thông tin các giao dich, hồ sơ, và hợp đồng của khách hàng.
    <br/><br/>
    <img src=${img59} alt="">
    <br/><br/>
    Bằng kinh nghiệm sẵn có trong việc phát triển các phần mềm quản lý doanh nghiệp và kiến thức trong lĩnh vực quản lý nghiệp vụ công chứng, Công ty Cổ phần dịch vụ và công nghệ HSV cung cấp giải pháp phần mềm quản lý phòng công chứng “Hitek” với công nghệ tiên tiến nhất, giúp giải quyết khó khăn mà các phòng công chứng đang gặp phải. Phầm mềm “HSV” của HSV hướng tới số hóa dữ liệu, tối giản hóa thao tác nghiệp vụ và tự động lưu lại các thông tin về hợp đồng, tài sản, lịch sử giao dịch. Phần mềm quản lý phòng công chứng của Hitek là cơ sở đáng tin cậy giúp công chứng viên dễ dàng tra cứu thông tin và xác định tính hợp lệ của tài sản, từ đó đẩy nhanh tiến độ công chứng hồ sơ và làm hài lòng các khách hàng .
    <h2>ƯU ĐIỂM CỦA PHẦN MỀM QUẢN LÝ PHÒNG CÔNG CHỨNG:</h2>
    <ul>
    - Cung cấp quy trình công chứng chuyên nghiệp, hiện đại, giúp nhà quản lý đưa ra những giải pháp thúc đẩy tiến độ, tiết kiệm thời gian công chứng cho khách hàng.
    <br/>- Thông tin được quản lý tập trung giúp dễ dàng tìm kiếm và phân loại. Từ đó, giảm thiểu thất thoát, dễ dàng tìm ra quy trình công chứng phù hợp.
    <br/>- Nhân viên ở các bộ phận có thể thường xuyên báo cáo quá trình, mức độ hoàn thiện công việc khi có yêu cầu của trưởng phòng công chứng mà không phải gặp mặt trực tiếp.
    <br/>- Trưởng phòngcó thể nắm bắt, quản lý được quy trình công chứng một cách chặt chẽ từ đó nhắc nhở công chứng viên đẩy nhanh tiến độ hoàn thành công việc.
    <br/>- Dễ dàng trao đổi và hỗ trợ xử lý công việc giữa các nhân viên và bộ phận.
    <br/>- Giúp tính toán khối lượng hồ sơ công chứng đã hoàn thành và khối lượng hồ sơ công chứng cần hoàn thành, giúp nhân viên công chứng nắm bắt và đưa ra kế hoạch hoàn thành công việc một cách cụ thể, chính xác.
    <br/>- Hệ thống báo cáo thời gian thực.
    <br/>- Dễ dàng tùy chỉnh phần mềm khi quy trình công việc thay đổi.
    </ul>
    <h2>CÁC TÍNH NĂNG NỔI BẬT CỦA PHẦN MỀM</h2>
    <h3>QUẢN LÝ THÔNG TIN HỒ SƠ</h3>
    <ul>
    - Khai báo nhóm hồ sơ (bảo lãnh, cầm cố, di chúc,…).
    <br/>- Khai báo loại hồ sơ (Cầm cố vay bổ sung, thanh lý cầm cố,…).
    <br/>- Quản lý giấy tờ các bên (hộ chiếu, CMTND, bằng lái xe,…).
    <br/>- Quản lý hồ sơ công chứng (động sản, bất động sản, tài sản,…).
    <br/>- Phân loại tình trạng hồ sơ (Tiếp nhận, hoàn trả, Hoàn thành,…).
    <br/>- Tích hợp các văn bản mẫu, biểu mẫu hợp đồng.
    </ul>
    <h3>QUẢN LÝ ĐƯƠNG SỰ CÁC BÊN</h3>
    <ul>
    - Quản lý phân nhóm khách hàng (khách buôn, khách lẻ, khu vực …).
    <br/>- Quản lý thông tin chi tiết khách hàng ( Mã khách, số thẻ, tên khách, điện thoại, địa chỉ, email, số mobile, website …).
    <br/>- Đối với khách hàng là công ty thì có thêm danh sách người liên hệ.
    <br/>- Một khách hàng có thể vừa là khách hàng và nhà cung cấp.
    <br/>- Quản lý thông tin chi tiết nhà cung cấp ( Mã nhà cung cấp, tên nhà cung cấp, điện thoại, địa chỉ, email, số mobile, website, tài khoản ngân hàng, mã số thuế …).
    <br/>- Quản lý tiếp nhận sản phẩm, đặt lịch hẹn thời gian bảo hành và bàn giao sản phẩm.
    </ul>
    <h3>QUẢN LÝ TÀI SẢN</h3>
    <ul>
    - Quản lý, lưu trữ tất cả các thông tin liên quan về phương tiện, bất động sản, động sản, giấy tờ có giá,…
    <br/>- Khai báo, thêm, sửa, xóa tài sản, nhóm tài sản, loại tài sản, tên tài sản,…
    <br/>- Hỗ trợ tìm kiếm đa dạng các thông tin liên quan đến tài sản, lịch sử giao dịch, công chứng, đóng dấu,…
    </ul>
    <h3>QUẢN LÝ THU PHÍ CÔNG CHỨNG</h3>
    <ul>
    - Tích hợp báo giá hồ sơ, giúp nhân viên dễ dàng tính được chính xác chi phí từng hồ sơ riêng biệt.
    <br/>- Tích hợp tiện ích sms tự động thông tin với khách hàng.
    <br/>- Hỗ trợ in biểu phí, hóa đơn, chứng từ xuất trực tiếp ra file excel, word, .pdf, mail.
    <br/>- Thống kê, báo cáo giao dịch và quản lý thu chi.
    <br/>- Quản lý các nghiệp vụ kế toán thông dụng.
    <br/>- Kết xuất các báo cáo kế toán theo yêu cầu.
    </ul>
    <h3>QUẢN LÝ NHÂN VIÊN</h3>
    <ul>
    - Quản lý nhân viên, hồ sơ nhân viên.
    <br/>- Quản lý hợp đồng nhân viên.
    <br/>- Kết nối với máy chấm công, quản lý chấm công.
    <br/>- Quản lý nghỉ phép.
    <br/>- Quản lý tiền lương theo chính sách của công ty, kết hợp với dữ liệu máy chấm công, nghỉ phép,..
    <br/>- Quản lý đánh giá nhân viên.
    </ul>
    <h3>QUẢN LÝ HỆ THỐNG, TÌM KIẾM, PHÂN QUYỀN, BẢO MẬT</h3>
    <strong>Bảo mật</strong>
    <ul>
    - Tự động backup và phục hồi số liệu theo ngày.
    <br/>- Dữ liệu luôn luôn được tự động backup dự phòng thường xuyên.
    <br/>- Chính sách bảo mật tuyệt đối dữ liệu của các doanh nghiệp, khách hàng sử dụng sản phẩm của công ty.
    <br/>- Chỉ Admin mới có quyền can thiệp hệ thống dữ liệu và phân quyền sử dụng cho người dùng.
    </ul>
    <strong>Phân quyền sử dụng</strong>
    <ul>
    - Phân quyền chức năng sử dụng theo nhóm người sử dụng hoặc từng người sử dụng.
    <br/>- Có thể phân quyền xem, sửa, xóa,… đến từng thành viên sử dụng phần mềm.
    </ul>
    <strong>Tiện ích</strong>
    <ul>
    - Phần mềm có thể xuất báo giá, hóa đơn, mẫu báo cáo trực tiếp ra excel, word, pdf, mail.
    <br/>- Có thể thể import các danh sách hàng hóa, khách hàng từ excel vào phần mềm.
    <br/>- Lưu nhật ký sử dụng phần mềm của tất cả các thành viên khi đăng nhập và sử dụng phần mềm.
    <br/>- Lọc dữ liệu, báo cáo tự động và nhanh chóng theo nhiều tiêu chí.
    <br/>- Tích hợp hệ thống VOIP giúp giao tiếp giữa các phòng ban và khách hàng một cách hiệu quả.
    <br/>- Phần mềm có khả năng tùy biến cao, xây dựng trên quy trình, yêu cầu của khách hàng
    <br/>- Hệ thống bảng biểu đa dạng giúp nhà quản lý có thể phân tích tổng quan về tình hình hoạt động của doanh nghiệp
    </ul>
    <h3>TỔNG KẾT PHẦN MỀM QUẢN LÝ PHÒNG CÔNG CHỨNG HSV</h3>
    Có thể nói phần mềm quản lý Hitek của Hitek là giải pháp phần mềm phù hợp nhất, toàn diện nhất để đáp ứng nhu cầu quản lý văn phòng công chứng. Phần mềm có rất nhiều tiện ích, đáp ứng gần như hoàn toàn các yêu cầu khắt khe nhất của khách hàng với mức giá cả vô cùng hợp lý trên phương châm “Ở đâu rẻ – Chúng tôi rẻ hơn“. Thêm vào đó, phần mềm rất thân thiện với người dùng, dễ sử dụng và có thể truy cập ở mọi lúc, mọi nơi, bằng mọi thiết bị, đáp ứng nhu cầu quản lý và giải quyết công việc một cách nhanh nhất cho các chủ doanh nghiệp.
    </p>`
    ,PScategory : 19
},
{
    id_lv2: 60,
    textItem: "PHẦN MỀM QUẢN LÝ DOANH NGHIỆP TỔNG THỂ ERP",
    text: `<p>
            Hiện nay rất nhiều công ty vừa và nhỏ ở Việt Nam đang phát triển rất mạnh mẽ và chiếm một vị trí không hề nhỏ trong nền kinh tế. Một trong những bí quyết tạo nên thành công đó là nhờ ứng dụng phần mềm quản lý tổng thể doanh nghiệp. Hầu hết các công ty kể cả các công ty lớn được hỏi đều sử dụng phần mềm quản lý tổng thể doanh nghiệp.
    <br/><br/>
    Thực tế cho thấy trong quy trình hoạt động của các doanh nghiệp, việc quản lý tổng thể toàn bộ doanh nghiệp gây khó khăn cho các nhà quản lý vì tốn rất nhiều thời gian trong việc tổng hợp và xử lý thông tin.
    <br/><br/>
    Đó là khi bạn cần đến một giải pháp ERP – một phần mềm quản lý tổng thể doanh nghiệp.
    <br/><br/>
    Technology in the hands of businessmen
    <h3>VẬY ERP – PHẦN MỀM QUẢN LÝ TỔNG THỂ DOANH NGHIỆP LÀ GÌ ?</h3>
    ERP (viết tắt của Enterprise Resource Planning), nguyên thuỷ ám chỉ một hệ thống dùng để hoạch định tài nguyên trong một tổ chức, một doanh nghiệp. Một hệ thống ERP điển hình là nó bao hàm tất cả những chức năng cơ bản của một tổ chức. Tổ chức đó có thể là doanh nghiệp, tổ chức phi lợi nhuận, tổ chức phi chính phủ… Một phần mềm ERP tích hợp những chức năng chung của một tổ chức vào trong một hệ thống duy nhất. Thay vì phải sử dụng phần mềm kế toán, phần mềm nhân sự-tiền lương, quản trị sản xuất… song song, độc lập lẫn nhau thì ERP gồm tất cả vào chung 1 gói phần mềm duy nhất mà giữa các chức năng đó có sự liên thông với nhau.
    <br/><br/>
    <img src=${img60} alt="">
    <h3>LỢI ÍCH CỦA ERP LÀ GIẢI PHÁP HIỆU QUẢ CHO DOANH NGHIỆP</h3>
    Không phải bỗng dưng mà ERP trở thành một giải pháp xu hướng và được ưa chuộng như hiện nay. Những lợi ích và ứng dụng mà nó mang lại là vô cùng to lớn.
    <br/><br/>
    Hệ thống ERP hạn chế tối đa các công việc trùng lặp và đặc biệt là giảm thiểu các thao tác nhập liệu thủ công. Nhờ ERP, hoạt động quản lý của doanh nghiệp được chuẩn hóa thành một quy trình thống nhất, giúp doanh nghiệp quản lý dữ liệu dễ dàng và hiệu quả hơn.
    <br/><br/>
    Thay vì lưu dữ liệu ở nhiều nơi riêng rẽ, tất cả dữ liệu giờ được lưu trữ trên một hệ thống duy nhất. Cơ sở dữ liệu được đảm bảo và cập nhật kịp thời.
    <br/><br/>
    Giải pháp ERP hỗ trợ các nhà quản trị dễ dàng tiếp cận các thông tin đáng tin cậy để có thể đưa ra các quyết định dựa trên cơ sở có đầy đủ thông tin. Giúp giảm trùng lặp và tăng tính thống nhất cho doanh nghiệp.
    <br/><br/>
    Lựa chọn giải pháp ERP có tính linh động cao có thể điều chỉnh dễ dàng khi doanh nghiệp thay đổi: Giao diện phần mềm ERP linh hoạt, dễ sử dụng, có khả năng tích hợp và mở rộng cao, có thể được vận hành ở bất cứ nơi đâu (làm việc mọi lúc, mọi nơi từ bất kỳ thiết bị nào).
    <br/><br/>
    Thông tin được cập nhập theo hệ thống thời gian thực, đảm bảo chính xác, đầy đủ giúp việc quản lý dễ dàng hơn và có thể kịp thời sửa chữa lỗi sai phát sinh trong quá trình sản xuất.
    <br/><br/>
    Phần mềm ERP khiến việc báo cáo trở nên dễ dàng và khả năng tùy chỉnh tốt hơn. Với khả năng báo cáo được cải thiện, công ty bạn có thể xử lý những yêu cầu dữ liệu phức tạp một cách dễ dàng. Người dùng cũng có thể lập báo cáo mà không phải phụ thuộc vào sự giúp đở của nhân viên IT.
    <br/><br/>
    Không giới hạn về quy mô doanh nghiệp, loại hình kinh doanh. Hệ thống ERP hoạt động linh hoạt, có thể dễ dàng tùy chỉnh quy trình theo từng ngành, từng nhu cầu của doanh nghiệp nên đảm bảo hoạt động tốt trong mọi quy trình nghiệp vụ của từng doanh nghiệp.
    <br/><br/>
    Sử dụng phần mềm ERP giúp doanh nghiệp giảm được chi phí đầu tư hơn nhiều so với việc đầu tư vào nhiều hệ thống rời rạc, không thống nhất.
    <br/><br/>
    Sử dụng ERP thì việc cung cấp cho khách hàng dịch vụ chăm sóc tối ưu trở nên dễ dàng hơn bao giờ hết. ERP giúp việc truy cập vào cơ sở thông tin cách khàng và lịch sử tư vấn dễ dàng và chính xác, nhờ đó, nhân viên kinh doanh và chăm sóc khách hàng có thể tương tác với khách hàng tốt hơn và điều đó giúp cải thiện mối quan hệ với khách hàng.
    <br/><br/>
    Hệ thống mới sẽ cải thiện độ chính xác, ổn định và bảo mật dữ liệu. Các thao tác hạn chế truy cập cũng có thể được tùy chỉnh nhờ hệ thống phân quyền.
    <h3>XU HƯỚNG ERP TẠI VIỆT NAM</h3>
    Những năm gần đây, thị trường giải pháp ERP ở Việt Nam diễn ra rất sôi động do ngày càng có nhiều doanh nghiệp muốn hoàn thiện công tác quản lý để sử dụng hiệu quả nhất các nguồn lực trong quá trình sản xuất kinh doanh. Tuy nhiên, một công ty có thể chỉ mua một số gói nhất định tùy theo khả năng và nhu cầu của mình chứ không cần phải mua hết cả bộ (vì có thể họ không cần đến).
    <br/><br/>
    Hiện nay có nhiều công ty đang áp dụng ERP trong công việc của mình. Ví dụ như Thế Giới Di Động. Trong một bài phỏng vấn hồi năm 2009, công ty này chia sẻ rằng họ đã tự xây dựng ERP của mình với nhiều chức năng, từ hành chính, nhân sự cho đến quản lý các món hàng cũng như việc bảo hành. Nếu không dùng giải pháp cây nhà lá vườn như TDGĐ thì các công ty có thể tìm kiếm những giải pháp được xây dựng sẵn, sau đó tùy nhu cầu mà tùy biến lại cho thích hợp (hoặc đi thuê một công ty bên thứ ba tùy biến).
    <br/><br/>
    Chúng ta có thể kể đến một số phần mềm ERP nối tiếng như SAP ERP, Infor ERP LN, Oracle E-Business Suite. Đây là 3 tên tuổi ERP “lừng danh” và bộ phần mềm của họ nhắm đến những công ty vừa và lớn. Tuy nhiên với môi trường phần đông là doanh nghiệp vừa và nhỏ ở Việt Nam hiện nay thì việc sử dụng những ERP lớn quá lãng phí và cũng không đủ khả năng. Thị trường cung cấp giải pháp ERP tại Việt Nam đang phát triển mạnh mẽ với giá cả phải chăng trong khi chất lượng không hề thua kém các công ty phần mềm lớn.
    <h3>PHẦN MỀM QUẢN LÝ DOANH NGHIỆP TÙY CHỈNH HSV</h3>
    HSV đang là đơn vị cung cấp giải pháp ERP dẫn đầu xu thế với phần mềm H SV có sự tích hợp cao các giải pháp hữu dụng nhất cho các doanh nghiệp. HSV là một bộ công cụ nền tảng. Theo đó, Hitek cho phép người dùng có thể tự thao tác lập ra các trường dữ liệu theo ý muốn, cấu hình nên hệ thống phần mềm một cách dễ dàng. Sự linh hoạt, dễ tùy chỉnh chính là đặc tính nội trội của HSV. Nhờ có HSV, doanh nghiệp không còn cần phải thay đổi quy trình làm việc vốn có nếu muốn áp dụng phần mềm quản lý.
    <h3>KẾT LẠI</h3>
    ERP là phần mềm quản lý tổng thể doanh nghiệp, cho phép doanh nghiệp tự kiểm soát được trạng thái năng lực của mình. Từ đó, họ có thể lên kế hoạch khai thác các nguồn tài nguyên này hợp lý nhờ vào các quy trình nghiệp vụ thiết lập trong hệ thống. Hơn nữa ERP còn cung cấp cho các doanh nghiệp một hệ thống quản lý với quy trình hiện đại theo chuẩn quốc tế, nhằm nâng cao khả năng quản lý điều hành doanh nghiệp cho lãnh đạo cũng như tác nghiệp của các nhân viên.
    <br/><br/>
    ERP cho thấy hiệu quả đáng kinh ngạc mang lại sự phát triển tối ưu, khoa học cho các doanh nghiệp. Vì thế để doanh nghiệp phát triển mạnh mẽ vững bền thì sử dụng phần mềm quản lý doanh nghiệp – ERP là một trong những ưu tiên hàng đầu.
</p>`
    ,PScategory : 20
},
{
    id_lv2: 61,
    textItem: "PHẦN MỀM QUẢN LÝ KHÁCH HÀNG",
    text: `<p>
    <h2>PHẦN MỀM QUẢN LÝ KHÁCH HÀNG</h2>
    Khách hàng chính là nguồn lợi nhuận lớn nhất của các doanh nghiệp và công ty, khách hàng càng nhiều thì nguồn lợi nhuận thu lại càng lớn. Tuy nhiên, khi số lượng khách hàng tăng lên nhiều lại xuất hiện một vài khó khăn mà các doanh nghiệp và công ty thường gặp phải, dưới đây là một vài lí do khiến doanh nghiệp cần công cụ phần mềm quản lý khách hàng:
    <br/><br/>
    Có quá nhiều khách hàng, mỗi nhân viên quản lý một kiểu, quản lý khách hàng không tập trung, khó khăn khi tìm kiếm thông tin khách cũ.
    <br/><br/>
    Nhân viên khi nghỉ việc thường mang theo khách hàng của công ty.
    <br/><br/>
    Thất lạc thông tin liên hệ với khách hàng cũ.
    <br/><br/>
    Sử dụng phần mềm quản lý khách hàng doanh nghiệp của bạn sẽ quản lý được các khách hàng tiềm năng cũng như quản lý được các cơ hội và còn hơn thế nữa
    <br/><br/>
    Duy trì mối quan hệ lâu dài với khách hàng nhằm tạo điều kiện cho các hoạt động kinh doanh khác.
    <br/><br/>
    Vậy làm thế nào để lưu trữ, tư vấn và quản lý thông tin khách hàng một cách hiệu quả nhất? Công ty Cổ phần và Dịch vụ Hitek chúng tôi đã cho ra đời sản phẩm phần mềm nhằm mang đến cho các nhà quản lý một giải pháp toàn diện về phần mềm quản lý khách hàng.
    <h2>CHỨC NĂNG CỦA PHẦN MỀM</h2>
    <h3>Quản lý khách hàng</h3>
    <ul>
    - Quản lý thông tin khách hàng như: tên, tuổi, số điện thoại, email, địa chỉ…
    <br/>- Phân loại khách hàng theo độ tuổi, giới tính, vùng miền…
    <br/>- Lọc dữ liệu khách hàng hiệu quả theo các tiêu chí: mã khách hàng, số điện thoại, người quản lý dữ liệu…
    <br/>- Dữ liệu khách hàng được bố trí khoa học, dễ dàng tìm kiếm theo nhiều bộ lọc khác nhau như khu vực, loại hình kinh doanh…
    <br/>- Quản lý hồ sơ giao dịch của khách hàng hay phân loại khách hàng theo các tiêu chí khác nhau.
    <br/>- Quản lý cuộc hẹn với khách hàng.     
    <br/>- Quản lý dịch vụ khách hàng sử dụng.
    <br/>- Chăm sóc khách hàng
    <br/>- Phân tích nhu cầu của khách hàng thông qua cơ hội, giao dịch, hợp đồng.
    <br/>- Gửi thư chăm sóc khách hàng, giới thiệu sản phẩm, chúc mừng sinh nhật.
    <br/>- Đặt lịch hẹn, lịch làm việc với khách hàng.
    </ul>
    <h3>Quản lý marketing</h3>
    <ul>
    - Tạo các chiến dịch marketing bằng email, SMS đến khách hàng.
    <br/>- Gửi email marketing.
    <br/>- Tự động hóa các chiến dịch marketing theo tuần hoặc theo tháng.
    <br/>- Đánh giá nguồn khách hàng tiềm năng, từ đó đánh giá hiệu quả từng chiến dịch marketing.
    </ul>
    <h3>Quản lý nhân viên</h3>
    <ul>
    - Quản lý danh sách thông tin nhân viên
    <br/>- Quản lý từng user tương ứng với từng nhân viên
    <br/>- Quản lý nhân viên nào chăm sóc khách hàng nào
    <br/>- Quản lý xem hợp đồng ký được có sự tham gia của các nhân viên nào
    <br/>- Quản lý hoa hồng
    </ul>
    <h3>Hệ thống báo cáo</h3>
    <ul>
    - Báo cáo danh sách khách hàng hiện có.
    <br/>- Báo cáo danh sách khách hàng mới trong ngày, tuần, tháng, năm.
    <br/>- Báo cáo lịch làm việc của nhân viên với khách hàng.
    <br/>- Báo cáo danh sách khách hàng tiềm năng.
    <br/>- Báo cáo công nợ khách hàng.
    <br/>- Báo cáo danh sách từng nhân viên chăm sóc .
    <br/>- Báo cáo sản phẩm khách hàng đang sử dụng.
    </ul>
    <h2>ƯU ĐIỂM NỔI BẬT CỦA PHẦN MỀM</h2>
    <img src=${img61} alt="">
    Phần mềm của HITEK có khả năng tùy biến cao, khi áp dụng phần mềm các bạn có thể tự tạo các module quản lý thích hợp với nhu cầu của mình mà không cần phải thay đổi quy trình hoạt động hiện có. Phần mềm giúp quản lý khách hàng chuyên nghiệp và đơn giản hơn, giảm thiểu thời gian cho nhân viên và tiết kiệm chi phí cho doanh nghiệp.
</p>`
    ,PScategory : 20
    },
{
    id_lv2: 62,
    textItem: "PHẦN MỀM QUẢN LÝ CÔNG VIỆC",
    text: `<p>
    <h2>PHẦN MỀM QUẢN LÝ CÔNG VIỆC</h2>
    Khi phát sinh nhu cầu công việc mọi người thường trao đổi miệng với nhau, tuy nhiên, nhiều khi khối lượng công việc quá đồ sộ, người làm phải ưu tiên việc này hay việc khác, khiến họ không chia sẻ được với nhau, làm việc này quên việc khác, không thể quản lý 100% công việc, khiến cho năng suất và hiệu quả công việc không được như kì vọng.
    <br/><br/>
    Vậy làm cách nào để bạn có thể quản lý toàn bộ công việc? Phần mềm quản lý công việc của HSV sẽ giúp người dùng quản lý công việc một cách chi tiết, hệ thống, cùng hệ thống nhắc nhở quả trang chủ hệ thống, SMS hay email vô cùng tiện lợi và nhanh chóng. Với phần mềm của chúng tôi, người dùng chỉ cần truy cập và xử lý công việc mọi lúc mọi nơi theo trình tự, thật dễ dàng phải không.
    <h2>LỢI ÍCH CỦA VIỆC SỬ DỤNG PHẦN MỀM QUẢN LÝ CÔNG VIỆC:</h2>
    <h3>Đối với những nhà quản lý</h3>
    <ul>
    - Quản lý thống nhất nhân viên và các phòng ban trong doanh nghiệp giúp cắt giảm thời gian làm việc và các khâu trung gian không cần thiết
    <br/>- Tất cả nhân viên và người quản lý đều làm việc và cập nhật trên hệ thống giúp nhà quản lý có thể dễ dàng theo dõi nhân viên cũng như tình hình của doanh nghiệp để đưa ra chỉ thị, điều chỉnh nhanh chóng, kịp thời ngay trên phần mềm .
    <br/>- Các tài liệu, hợp đồng, văn bản, công văn đều được lưu trữ lại một cách hệ thống để có thể tìm lại dễ dàng khi cần.
    <br/>- Lên lịch làm việc cho nhân viên, các phòng ban, lịch được cập nhật trên phần mềm nên nhân viên trong công ty đã được biết mà không cần phải thông báo.
    <br/>- Quản lý chi tiết, nhanh nhất và đầy đủ nhất các hạng mục công việc, nhiệm vụ phát sinh trong từng thời điểm và tiến độ thực hiện công việc.
    <br/>- Phần mềm có khả năng dùng trên mọi nền tảng, chỉ cần có thiết bị kết nối internet là chủ doanh nghiệp có thể quản lý mọi lúc mọi nơi.     
    <br/>- Cập nhật phản ánh hay sự cố, khúc mắc trong công việc một cách nhanh chóng, từ đó đưa ra điều chỉnh, xử lý kịp thời .
    <br/>- Có thể quản lý công việc của chính nhà quản lý, lên lịch hẹn, lịch làm việc chi tiết, thông qua hệ thống nhắc nhở cảnh báo , lịch trình làm việc sẽ được đưa đến chi tiết để không bỏ lỡ những điều quan trọng, từ đó nâng cao hiệu quả làm việc hơn.
    </ul>
    <h3>Đối với nhân viên</h3>
    <ul>
    - Giúp nhân viên không bỏ lỡ công việc nhờ hệ thống nhắc nhở cảnh báo khi đến ngày kế hoạch và hệ thống nhắc nhở khi sắp đến hạn hoàn thành
    <br/>- Có thể cập nhật được lịch làm việc chung của cả công ty, lịch làm việc của bản thân và phòng ban mình.
    <br/>- Cập nhật được các thông báo, chỉ đạo mới nhất nếu có
    <br/>- Quản lý khách hàng và các thông tin liên quan đến khách hàng một cách hệ thống, phân cấp khách hàng để xử lý.
    <br/>- Lên lịch làm việc hàng ngày của bản thân tuần tự, chi tiết và hệ thống để có thể làm việc hiệu quả hơn, cắt giảm thời gian làm việc.
    <br/>- Báo cáo công việc cho lãnh đạo một cách nhanh chóng, và nhận chỉ đạo một cách nhanh chóng ngay trên phần mềm.
    </ul>
    <h2>CÁC CHỨC NĂNG NỔI BẬT CỦA PHẦN MỀM</h2>
    <h3>Quản lý công việc của cá nhân</h3>
    <ul>
    - Hệ thống cảnh báo, nhắc nhở ngay tại trang chủ khi đến ngày cần thực hiện công việc
    <br/>- Hệ thống gửi mail nhắc nhớ sắp đến hạn hoàn thành công việc
    <br/>- Cảnh báo công việc trễ hạn theo từng cấp bậc
    <br/>- Lên lịch công việc
    <br/>- Sắp xếp công việc theo thứ tự ưu tiên
    <br/>- Hệ thống nhắc nhở cảnh báo qua trang chủ phần mềm hoặc SMS , email, sẽ thông tin đến người dùng khi sắp đến lịch làm việc
    <br/>- Quản lý tiến độ thực hiện công việc
    <br/>- Ghi chú các nội dung có liên quan
    <br/>- Lưu lại kết quả công việc
    </ul>
    <h3>Quản lý công việc của doanh nghiệp</h3>
    <ul>
    - Quản lý nhân viên và các phòng ban trong công ty
    <br/>- Thông tin phòng ban, nhân viên
    <br/>- Chức năng của từng phòng ban
    <br/>- Các hồ sơ, dữ liệu liên quan đến phòng ban, cá nhân đó
    <br/>- Quản lý đánh giá và tình hình các công việc liên quan đến phòng ban, nhân viên đó
    <br/>- Quản lý công việc mẫu, danh sách chi tiết công việc mẫu
    <br/>- Phân công công việc cho các phòng ban
    <br/>- Cập nhật tình hình tiến độ hoàn thành công việc mọi thời điểm, mọi lúc mọi nơi và đưa ra chỉ đạo tới nhân viên ngay trên phần mềm
    <br/>- Lưu lại toàn bộ lịch sử công việc và các thông tin liên quan để tìm lại khi cần
    <br/>- Quản lý giấy tờ hồ sơ tài liệu một cách hệ thống để có thể dễ dàng tìm lại khi cần
    <br/>- Chức năng giao việc cho cấp dưới ngay trên phần mềm.
</ul>
    <h3>Tính tiện ích của phần mềm</h3>
    <ul>
    - Hệ thống bảng biểu đa dạng giúp người dùng có thể nhanh chóng lập các loại báo cáo, hợp đồng, chứng từ việc làm,…một cách nhanh chóng
    <br/>- Cho phép import dữ liệu từ tệp hoặc export dữ liệu ra file excel nhanh chóng
    <br/>- Với chức năng tìm kiếm, người dùng có thể tìm kiếm nhanh chóng thông tin mình cần trong cơ sở dữ liệu theo đa dạng các tiêu chí khác nhau
    <br/>- Tính năng phân quyền cho người dùng rất chi tiết nhằm đáp ứng nhu cầu bảo mật thông tin của chủ doanh nghiệp mà vẫn làm việc đồng bộ trên cùng hệ thống
    </ul>
    <h2>KẾT LẠI</h2>
    Có thể nói phần mềm quản lý công việc là giải pháp phù hợp nhất để giải quyết núi công việc hiệu quả của người dùng với nhiều tính năng cao cấp, hữu ích mà giá cả lại hợp lý, vừa với túi tiền của người dùng. Phần mềm hỗ trợ trên mọi nền web, hỗ trợ truy cập từ xa trên mọi thiết bị rất tiện lợi. Bên cạnh đó, phần mềm có khả năng tùy biến của phần mềm cao, xây dựng trên quy trình và yêu cầu cụ thể của khách hàng nên có thể nói là phù hợp với mọi loại hình doanh nghiệp, phần mềm cũng dễ dàng thay đổi để phù hợp khi có sự thay đổi cả về quy mô lẫn cấu trúc doanh nghiệp. Phần mềm rất thân thiện và dễ dàng sử dụng ngay cả với những người chưa biết về máy tính, mọi thắc mắc khách hàng có thể liên hệ tới hotline của công ty chúng tôi, chúng tôi sẽ hỗ trợ các bạn 24/7.
</p>`
    ,PScategory : 20
    },
{
    id_lv2: 63,
    textItem: "PHẦN MỀM QUẢN LÝ NHÂN SỰ",
    text: `<p>
    Từ lâu nhân sự luôn là vấn đề sống còn của một tổ chức, doanh nghiệp. Doanh nghiệp muốn tồn tại và phát triển vững mạnh thì phải có chính sách chiêu mộ cũng như quản lý nhân sự hiệu quả. Quản lý nhân sự là công tác quản lý lực lượng lao động của một tổ chức, doanh nghiệp, công ty, nguồn nhân lực. Việc quản lý nguồn nhân lực đòi hỏi sự hiểu biết về con người ở nhiều khía cạnh khác nhau. Quản lý nhân sự gắn liền với mọi tổ chức, doanh nghiệp. Do đó, nguồn lao động trong doanh nghiệp có vai trò hết sức to lớn và là yếu tố đặc biệt quang trọng trong quá trình hoạt động của tổ chức. Để đảm bảo sự phát triển bền vững của doanh nghiệp thì hoạt động quản lý nhân sự phải được ưu tiên hàng đầu.
    <h2>TỔNG QUAN CHỨC NĂNG PHẦN MỀM QUẢN LÝ NHÂN SỰ</h2>
    <h3>Quản lý tuyển dụng</h3>
    Khi doanh nghiệp có nhu cầu tuyển dụng, phòng nhân sự sẽ lên kế hoạch tuyển dụng và chuyển sang phòng marketing để đăng tin tuyển dụng. Khi nhận hồ sơ đăng ký của các ứng viên ở trên mạng, dữ liệu sẽ được tự động truy xuất từ website tuyển dụng và lưu thông tin hồ sơ đã đăng ký vào trong phần mềm và chuyển đến phụ trách của phòng nhân sự. Sau khi tiếp nhận và lọc những hồ sơ hồ sơ không đạt yêu cầu, phòng nhân sự sẽ chuyển hồ sơ của ứng viên cho người phụ trách phỏng vấn. Sau khi phỏng vấn, những ứng viên được tuyển sẽ được cập nhập trên hệ thống và chuyển thông tin hồ sơ vào danh sách nhân viên trên phần mềm cùng với các hợp đồng ứng với từng ứng viên đó. Tất cả các quy trình đều được thực hiện trên cùng một hệ thống, sẽ giảm thiểu được tối đa thời gian cũng như có được sự chính xác khi chuyển giao hồ sơ giữa các phòng ban.
    <h3>Quản lý nhân viên</h3>
    <ul>
    - Phần mềm giúp quản lý danh sách nhân viên, thông tin cá nhân của từng nhân viên cũng như trình độ, bằng cấp chuyên môn, khen thưởng, kỷ luật, quá trình đóng bảo hiểm…
    <br/>- Các loại hồ sơ, hợp đồng cũng như ảnh nhân viên có thể upload lên phần mềm mà không phải lưu trữ bằng văn bản hay các file rời rạc, rất dễ thất lạc và mất dữ liệu.
    <br/>- Hệ thống cho phép quản lý, đánh giá nhân viên định kỳ trong doanh nghiệp, cho phép nhân viên tự đặt ra chỉ tiêu và đánh giá kết quả đã hoàn thành. Kết quả đánh giá là cơ sở để đưa ra quyết định khen thưởng, kỷ luật hay thuyên chuyển nhân viên kịp thời và hợp lý. Là công cụ hỗ trợ các nhà quản lý nhận định được chất lượng nhân sự, có kế hoạch điều chỉnh kịp thời và đúng đắn.
    </ul>
    <h3>Quản lý chính sách – bảo hiểm</h3>
    <ul>
    - Thiết lập định mức đóng bảo hiểm theo vùng, theo cá nhân, cho phép thay đổi linh hoạt các mức đóng theo sự điều chỉnh của nhà nước
    <br/>- Quản lý việc khai báo hồ sơ bảo hiểm
    </ul>
    <h3>Đánh giá nhân sự</h3>
    <ul>
    - Xây dựng tiêu chuẩn đánh giá theo vị trí công việc
    <br/>- Lập kế hoạch đánh giá và thực hiện đánh giá định kỳ
    <br/>- So sánh các nhân viên cùng vị trí và kết quả mỗi lần đánh giá
    <br/>- Báo cáo việc đánh giá nhân viên, xếp loại nhân viên theo các tiêu chí tạo ra.
    </ul>
    <h3>Quản lý chấm công, tiền lương</h3>
    <ul>
    - Phần mềm có khả năng tích hợp với máy chấm công, phần mềm sẽ cập nhật dữ liệu ở máy chấm công hàng ngày và lưu thông tin trên hệ thống phần mềm. Tổng hợp số ngày công nhân viên đi làm, nghỉ phép, nghỉ ốm để lập bảng chấm công tổng hợp.
    <br/>- Phần mềm có phân hệ <strong>Xin nghỉ làm</strong> để nhân viên có thể vào xin phép, báo cáo nghỉ làm trong phần mềm, giúp chấm công dễ dàng.
    <br/>- Phân hệ quản lý lương giúp doanh nghiệp tự động hóa trong quy trình tính lương, giảm thiểu được thời gian tính lương, thuế, bảo hiểm cho nhân viên hàng tháng. Khi sử dụng phần mềm nhà quản lý sẽ dễ dàng tính lương cho từng nhân viên theo mức lương cụ thể của từng người, tính lương theo ca làm, sản phẩm, lương làm thêm giờ căn cứ vào bảng chấm công hàng ngày. Tự động tính thuế TNCN, các khoản phạt, thưởng cho từng nhân viên. Theo dõi các khoản lương tạm ứng, phải trả, còn nợ của từng nhân viên.
    </ul>
    <h3>Quản lý đào tạo nhân sự</h3>
    Quản lý đào tạo giúp doanh nghiệp hoạch định các kế hoạch đào tạo, tổ chức thực hiện cũng như theo dõi kết quả đào tạo nguồn nhân lực trong công ty. Phần mềm cho phép doanh nghiệp thực hiện một quy trình khép kín từ lập kế hoạch đào tạo, chi phí đào tạo của từng nhân viên, lịch đào tạo đến kết quả đào tạo. Phần mềm giúp quản lý danh sách các nhân viên tham gia khóa học, lịch học, chi phí đào tạo của từng nhân viên trong từng khóa học. Phần mềm giúp tra cứu thông tin nhân viên, lịch sử học tập, đào tạo một cách dễ dàng. Đánh giá kết quả đào tạo của từng nhân viên để sắp xếp nguồn nhân lực hợp lý nhất.
    <h2>LỢI ÍCH CỦA PHẦN MỀM QUẢN LÝ NHÂN SỰ</h2>
    <ul>
    - Phần mềm của Hitek giúp lưu trữ toàn bộ hồ sơ của nhân viên, thông tin cá nhân, hợp đồng lao động…
    <br/>- Hồ sơ của nhân viên sẽ được phân theo các phòng ban, bộ phận giúp việc tra cứu thông tin trở nên dễ dàng, nhanh chóng, giúp đánh giá nhân viên một cách tập trung, hiệu quả.
    <br/>- Quy trình tuyển dụng được quản lý toàn bộ trên phần mềm giúp dễ dàng quản lý, đánh giá cũng như sàng lọc các ứng viên.
    <br/>- Các nhà quản lý cũng dễ dàng theo dõi kế hoạch đào tạo nhân viên, từ đó có thể dánh giá năng lực làm việc của từng nhân viên trong từng bộ phận.
    <br/>- Phần mềm giúp tự động hóa quy trình tính lương và bảo hiểm của doanh nghiệp. Cho phép tính lương linh hoạt theo từng nhu cầu của doanh nghiệp. Ngoài ra phần mềm còn giúp các doanh nghiệp tính lương theo giờ, theo sản phẩm một cách dễ dàng nhất.
    <br/>- Phần mềm cho phép nhà quản lý, lãnh đạo doanh nghiệp có thể theo dõi và nắm được tình hình nhân sự dưới mọi góc độ nhờ hệ thống báo cáo, từ các báo cáo đánh giá năng lực của nhân viên, biểu đồ biến động nhân sự, các kế hoạch đào tạo, tuyển dụng… Từ đó, lãnh đạo doanh nghiệp sẽ có cái nhìn tổng thể, chính xác nhất về tình hình nhân sự để có thể đưa ra các quyết định điều chỉnh đúng đắn, kịp thời.
    </ul>
    Phần mềm quản lý nhân sự giúp tăng cường khả năng quản lý, giám sát, điều hành doanh nghiệp, mở rộng khả năng truy cập thông tin giúp cho các nhà quản lý thực hiện công việc của mình một cách nhanh chóng, dễ dàng và thuận tiện. Sử dụng tối ưu nguồn lực trong sản xuất kinh doanh, giảm thiểu khối lượng công việc giấy tờ. Phân tích và đánh giá thông tin chính xác, kịp thời thông qua hệ thống các giải pháp lưu trữ thông tin, thực hiện theo một quy trình thống nhất và chuẩn hóa.
    <br /><br />
    Có thể nói phần mềm quản lý của HSV là giải pháp phần mềm phù hợp nhất, toàn diện nhất để đáp ứng nhu cầu quản lý điều hành thư viện của bạn. Phần mềm có rất nhiều tiện ích, đáp ứng gần như hoàn toàn các yêu cầu khắt khe nhất của khách hàng với mức giá cả vô cùng hợp lý trên phương châm “Ở đâu rẻ – Chúng tôi rẻ hơn “. Thêm vào đó, phần mềm rất thân thiện với người dùng, dễ sử dụng và có khả năng tùy biến cao. Sử dụng phần mềm giúp tiết kiệm thời gian, các khâu trong quá trình vận hành được thực hiện theo một quy trình nhất định, không để xảy ra nhầm lẫn, sai sót; truy cập ở mọi nơi, từ mọi thiết bị, đáp ững nhu cầu quản lý và giải quyết công việc một cách nhanh nhất của thư viện.
</p>`
    ,PScategory : 20
    },
{
    id_lv2: 64,
    textItem: "PHẦN MỀM QUẢN LÝ DỰ ÁN",
    text: `<p>
    Phần mềm quản lý dự án – là giải pháp hàng đầu giúp bạn quản lý thông tin dự án một cách hiệu quả. Phần mềm là công cụ đắc lực giúp người dùng lưu trữ, thống kê toàn bộ thông tin liên quan đến kế hoạch và báo cáo thực tế tiến độ làm việc như: gói thầu, chi phí đầu tư, hợp đồng theo thời gian. Chỉ cần nhập mẫu bảng biểu lần đầu và các thông tin đầu vào, hệ thống sẽ giúp người dùng xuất ra những bảng biểu, văn bản, hợp đồng, tờ trình… một cách nhanh chóng. Đặc biệt, hần mềm quản lý dự án có khả năng tùy chỉnh cao, không theo một khuôn mẫu nhất định. Được xây dựng dựa trên quy trình làm việc của khách hàng nên khi được đưa vào sử dụng, hần mềm quản lý dự án sẽ không làm ảnh hưởng đến tiến trình làm việc của doanh nghiệp, hơn thế có thể giúp doanh nghiệp hoạt động hiệu quả, tiết kiệm thời gian và chi phí.
    <h2>LỢI ÍCH CỦA VIỆC SỬ DỤNG PHẦN MỀM QUẢN LÝ DỰ ÁN</h2>
    <ul>
    - Sử dụng phần mềm quản lý dự án giúp người dùng có thể quản lý tổng thể dự án từ khi lập chủ trương quyết toán tới khi nghiệm thu, thậm chí là cả thông tin sau khi kết thúc dự án. Các bước thực hiện đều được cập nhật nhanh chóng và lưu lại trên hệ thống, rất thuận tiện cho nhà quản lý theo dõi các quá trình của dự án, từ đó đưa ra chỉ đạo kịp thời, hoặc tìm kiếm lại thông tin của dự án dễ dàng khi cần tới.
    <br/>- Đây là hệ thống làm việc tập trung nên tất cả chủ doanh nghiệp và nhân viên đầu tư đều làm việc trên một hệ thống duy nhất, điều này giúp chủ doanh nghiệp dễ dàng quản lý và cập nhật tình hình làm việc, đồng thời cũng có thể theo dõi được tiến độ dự án tới đâu, nhân viên nào quản ý để đốc thúc, hay ra quyết định nhanh chóng ngay trên phần mềm.
    <br/>- Phần mềm giúp doanh nghiệp cắt giảm được những công tác thừa trong quy trình làm viêc, thời gian và chi phí cho việc quản lý, từ đó có thể quản lý hiệu quả, tránh thất thoát và tăng năng suất làm việc cho toàn doanh nghiệp.
    <br/>- Hỗ trợ cho những người có chuyên môn, trách nhiệm dễ dàng cập nhật một cách có hệ thống, tra cứu, trao đổi những thông tin và báo cáo với lãnh đạo xử lý những vướng mắc để thực hiện đúng quy định về thủ tục trong đầu tư xây dựng (nội dung, thời gian, thẩm quyền…).
    <br/>- Hệ thống phân quyền xem và sửa dữ liệu của phần mềm giúp lãnh đạo làm việc ăn khớp với nhân viên nhưng vẫn đảm bảo bảo mật các thông tin nội bộ quan trọng.
     </ul>
     <h2>CÁC TÍNH NĂNG NỔI BẬT CỦA PHẦN MỀM QUẢN LÝ DỰ ÁN</h2>
    <h3>Quản lý thông tin dự án</h3>
    <ul>
    - Quản lý chi tiết thông tin dự án: thời gian dự toán, tổng mức đầu tư, thời gian thực hiện, lĩnh vực đầu tư , địa điểm, quy mô của dự án,…
    <br/>- Quản lý chi tiết các hạng mục con của dự án
    <br/>- Quản lý thông tin về đối tác và các liên hệ liên quan
    <br/>- Cho phép thêm người liên quan thực hiện dự án
    <br/>- Có tích hợp gửi SMS, Email,… tới các thành viên dự án để thông tin khi cần
    <br/>- Tìm kiếm và tra cứu các thông tin: tỷ lệ giải ngân, % hoàn thành dự án,… một cách dễ dàng
    </ul>
    <h3>Quản lý dự toán đầu tư</h3>
    <ul>
    - Quản lý các nguồn vốn của dự án
    <br/>- Kế hoạch sử dụng vốn
    <br/>- Các quyết định đầu tư của dự án
    <br/>- Phê duyệt về thiết kế của dự án
    <br/>- Quản lý dự toán về chi phí
    </ul>
    <h3>Lập kế hoạch công việc và quản lý</h3>
    <ul>
    - Phần mềm có thể phân cấp giúp người dùng lập kế hoạch công việc một cách chi tiết.
    <br/>- Dễ dàng tạo lập công việc và phân chia công việc.
    <br/>- Người được giao có thể ghi chú về công việc trên phần mềm vào báo cáo tình hình hay nhận chỉ thị cấp trên ngay trên hạng mục công việc đó qua hệ tống tin nhắn ở phía dưới.
    <br/>- Có thể đặt các mức trạng thái công việc: hoàn thành, chưa hoàn thành, hay đang hoàn thiện,…
    <br/>- Có thể tùy chỉnh nhắc nhở công việc hay thông báo cảnh báo trên trang chủ phần mềm hoặc qua Sms, Email,….
    </ul>
    <h3>Quản lý về tài chính cũng như nguồn lực của dự án</h3>
    <ul>
    - Thiết lập và quản lý chi tiết các nguồn tài chính liên quan đến dự án, các nguồn thu chi đầy đủ đều được ghi lại giúp người dùng dễ dàng quản lý.
    <br/>- Bố trí quản lý và phân bổ nhân viên.
    <br/>- Quản lý và theo dõi doanh thu, chi phí của dự án.
    </ul>
    <h3>Theo dõi tiến trình và quản lý rủi ro của dự án</h3>
    <ul>
    - Kiểm soát tiến độ hoàn thành công việc công việc chi tiết nhất, thậm chí là tiến độ hoàn thành theo ngày
    <br/>- Theo dõi những ghi chú quan trọng trong công việc.
    <br/>- Bằng việc thường xuyên theo dõi giúp nhà quản lý có thể cập nhật và đánh giá sự rủi ro cũng như cơ hội khi triển khai dự án.
    </ul>
    <h2>Hệ thống nhắc nhở cảnh báo</h2>
    <h3>Quản lý về tư liệu của dự án</h3>
    <ul>
    - Các tài liệu liên quan đến dự án hay hạng mục có thể upload và đính kèm trong mỗi hạng mục.
    <br/>- Số lượng và dung lượng, các dạng tài liệu không giới hạn.
    <br/>- Các tài liệu có liên quan đến dự án có thể lưu trữ một cách hệ thống dễ dàng tìm kiếm.
    <br/>- Nhà quản lý có thể phân quyền cho người có thể xem hoặc không xem tài liệu để đảm bảo tính bảo mật.
    </ul>
    <h3>Thống kê và báo cáo</h3>
    <ul>
    - Phần mềm cung cấp đa dạng các loại bảng biểu, biểu đồ, phân tích,… giúp nhà quản lý dễ dàng lập các báo cáo để phân tích và thống kê dự án chi tiết.
    <br/>- Người dùng chỉ cần nhập dữ liệu vào hệ thống sẽ tự động xuất ra các văn bản, hợp đồng, hay biểu mẫu,…
    <br/>- Hệ thống cũng tích hợp khả năng xuất dữ liệu ra file excel.
    </ul>
    <h2>KẾT LẠI</h2>
    Phần mềm quản lý dự án của HSV là giải pháp phần mềm toàn diện nhất, đáp ứng mọi nhu cầu quản lý dự án của doanh nghiệp. Với phần mềm của chúng tôi, mọi thông tin liên quan đến dự án được lưu trữ trên một hệ thống duy nhất để nhà quản lý có thể dễ dàng quản lý, theo dõi và tìm lại khi cần. Với việc toàn bộ nhân viên làm việc chung trên một hệ thống phần mềm sẽ giúp chủ doanh nghiệp dễ dàng theo dõi, quản lý, chỉ đạo và đốc thúc công việc cũng như xử lý kịp thời các sự cố, giúp giảm chi phí và thời gian cho công việc. Ngoài ra, với xuất phát điểm là một phần mềm trắng, ” Phần mềm có khả năng tùy biến cao không theo bất cứ khuôn mẫu nào – điều mà hầu hết các phần mềm trên thị trường chưa làm được, chúng tôi sẽ xây dựng phần mềm dựa trên quy trình cũng như yêu cầu của người dùng nên nó phù hợp với tất cả mọi doanh nghiệp.
</p>`
    ,PScategory : 20
    },
{
    id_lv2: 65,
    textItem: "PHẦN MỀM QUẢN LÝ SẢN XUẤT",
    text: `<p>
    Bằng kinh nghiệm sẵn có trong việc phát triển các phần mềm quản lý doanh nghiệp và kiến thức trong lĩnh vực quản lý nghiệp vụ công chứng, Công ty Cổ phần dịch vụ và công nghệ HSV cung cấp giải pháp phần mềm quản lý phòng công chứng “Hitek” với công nghệ tiên tiến nhất, giúp giải quyết khó khăn mà các phòng công chứng đang gặp phải. Phầm mềm “HSV” của HSV hướng tới số hóa dữ liệu, tối giản hóa thao tác nghiệp vụ và tự động lưu lại các thông tin về hợp đồng, tài sản, lịch sử giao dịch. Phần mềm quản lý phòng công chứng của Hitek là cơ sở đáng tin cậy giúp công chứng viên dễ dàng tra cứu thông tin và xác định tính hợp lệ của tài sản, từ đó đẩy nhanh tiến độ công chứng hồ sơ và làm hài lòng các khách hàng .
    <h2>CHỨC NĂNG CỦA PHẦN MỀM QUẢN LÝ SẢN XUẤT</h2>
    <h3>Quản lý dữ liệu</h3>
    <ul>
    - Quản lý danh mục vật tư, bán thành phẩm, thành phẩm
    <br/>- Quản lý hệ số quy đổi của từng loại vật tư
    <br/>- Quản lý đơn giá vật tư theo giai đoạn thời gian, mã vật tư
    <br/>- Quản lý đơn giá nhân công, hệ số nhân công theo thời gian
    <br/>- Quản lý định mức vật tư, nhân công
    <br/>- Quản lý danh mục công đoạn
    <br/>- Quản lý năng lực sản xuất của các bộ phận
    <br/>- Quản lý kế hoạch vật tư
    <br/>- Quản lý phân bổ dữ liệu
    <br/>- Quản lý yêu cầu sản suất, lệnh sản xuất
    <br/>- Quản lý kế hoạch sản xuất
    <br/>- Quản lý lô sản xuất
    <br/>- Quản lý lịch giao hàng
    <br/>- Quản lý yêu cầu mua hàng
    <br/>- Quản lý danh mục lỗi
    <br/>- Quản lý quy trình đản bảo chất lượng
    <br/>- Quản lý kho : Xuất, nhập, tồn, vị trí hàng hóa
     </ul>
    <h3>Hệ thống tự động tính toán, cảnh báo</h3>
    <ul>
    - Tự động tính toán kế hoạch vật tư
    <br/>- Hệ thống hỗ trợ import nhanh định mức vật tư
    <br/>- Tự động cân đối kế hoạch vật tư sau khi phân bổ
    <br/>- Tự động phân bổ vật tư cho yêu cầu sản xuất
    <br/>- Hệ thống tự động tạo lệnh sản xuất
    <br/>- Tự động tính toán giá thành sản phẩm theo từng giai đoạn
    <br/>- Hệ thống tự động cảnh báo tồn kho tổi thiểu
    <br/>- Hệ thống tự động tạo yêu cầu mua hàng
    <br/>- Hệ thống cảnh báo tình hình sử dụng vật tư
    <br/>- Hệ thống cân đối tồn kho lý thuyết
    <br/>- Hệ thống tự đông truy nguyên nguồn gốc vật tư
    <br/>- Hệ thống tự động cập nhật đánh giá tiến độ kế hoạch sản xuất
    <br/>- Hệ thống tùy chỉnh phần mềm giúp quản lý các thông số đặc trưng của mỗi công doạn một cách chi tiết
    </ul>
    <h3>Hệ thống biểu đồ và báo cáo</h3>
    <ul>
    - Định mức vật tư sản xuất
    <br/>- Biểu đồ định mức trực quan
    <br/>- Biểu đồ báo cáo thông kê năng xuất từng công đoạn
    <br/>- Báo cáo đầu vào vật tư sản xuất cho từng công đoạn
    <br/>- Hệ thống báo cáo tiến độ thực hiện yêu cầu sản xuất của từng công đoạn
    <br/>- Báo cáo quản lý chất lượng
    </ul>
    <h2>LỢI ÍCH CỦA PHẦN MỀM QUẢN LÝ SẢN XUẤT</h2>
    <ul>
    - Giúp nhà quản lý có thể đưa ra những giải pháp thúc đẩy doanh thu, giảm chi phí sản xuất cho doanh nghiệp cũng như đưa ra biện pháp xử lý kịp thời trong sản xuất và kinh doanh của doanh nghiệp.
    <br/>- Nắm bắt được tình hình sản xuất kinh doanh của doanh nghiệp một cách thường xuyên, liên tục, kịp thời và chính xác.
    <br/>- Thông tin được quản lý tập trung giúp dễ dàng tìm kiếm và phân loại. Từ đó dễ dàng tìm ra quy trình phù hợp nhất cho doanh nghiệp.
    <br/>- Dễ dàng trao đổi và hỗ trợ xử lý công việc giữa các nhân viên và bộ phận.
    <br/>- Nhân viên ở các bộ phận có thể báo cáo thường xuyên quá trình, mức độ hoàn thiện công việc khi có yêu cầu của quản lý mà không phải gặp mặt trực tiếp.
    <br/>- Giúp tính toán khối lượng công việc đã hoàn thành và khối lượng công việc cần hoàn thành giúp nhân viên trong doanh nghiệp nắm bắt và đưa ra kế hoạch hoàn thành công việc một cách cụ thể, chính xác.
    <br/>- Hệ thống báo cáo thời gian thực.
    <br/>-Dễ dàng tùy chỉnh phần mềm khi quy trình công việc thay đổi.
    </ul>
    <h2>TÍNH TIỆN ÍCH CỦA PHẦN MỀM</h2>
    Phần mềm quản lý sản xuất của HSV là giải pháp nhằm đáp ứng toàn diện nhu cầu quản trị sản xuất của doanh nghiệp. Hệ thống được xây dựng dựa trên quy trình khép kín từ khi thiết lập đơn hàng, nhu cầu sản xuất tới lập kế hoạch sản xuất, hoạch định và tính toán khả năng cung ứng nguyên vật liệu trong kho, khả năng thiết lập định mức nguyên vật liệu cho từng công đoạn sản xuất, tính giá thành, tỷ lệ khấu hao nguyên vật liệu cho tới việc theo dõi, giám sát tiến độ sản xuất của từng đơn hàng, từng công đoạn sản xuất…
</p>`
    ,PScategory : 20
},
{
    id_lv2: 66,
    textItem: "Phần mềm quản lý công trình xây dựng",
    text: `<p>
    Để quản lý thông tin dự án, ngoài các thông tin chung, người dùng cần cập nhật thông tin các quyết định đầu tư, quyết định đầu tư bổ sung, quyết định điều chỉnh…; thông tin thẩm định, phê duyệt thiết kế kỹ thuật – tổng dự toán; thông tin nhà thầu, gói thầu; thông tin quyết toán; các thông tin về cơ cấu chi phí dự án, cơ cấu nguồn vốn, các văn bản liên quan; thông tin toàn bộ dự án có thể gửi cho các cơ quan quản lý dưới dạng .xml để tổng hợp và làm căn cứ để theo dõi việc thanh quyết toán. Nhóm tính năng này rất quan trọng đối với các cơ quan quản lý nhiều dự án như các ban quản lý dự án, các cơ quan kế hoạch đầu tư hay các định chế tài chính, kho bạc.
    <br/><br/>
    Một dự án thường có nhiều gói thầu. Các đơn vị trúng thầu, thực hiện gói thầu nhiều khi cũng khác nhau. Một dự án cũng có thể có nhiều công trình, mỗi công trình có thể có nhiều hạng mục nhiều công việc. Quản lý dự án phải quản lý tới từng đơn vị thực hiện, từng phần công việc và chi phí. Chi phí gồm các loại như: chi phí công việc, chi phí công trình, chi phí tổng dự toán, chi phí tổng mức đầu tư. Từng loại chi phí lại gồm nhiều chi phí chi tiết. Nếu “quản lý thủ công”, sẽ rất khó để thiết lập thông tin, khai thác thông tin, trong đó, công việc tính toán sẽ rất khó đảm bảo. phần mềm quản lý công trình xây dựng Hitek chính là công cụ giúp người quản lý dự án tự động hoá công việc của mình.
    <br/><br/>
    <h2>CHỨC NĂNG CHÍNH CỦA PHẦN MỀM</h2>
    <ul>
    - Kế hoạch vật tư/hàng hóa
    <br/>- Yêu cầu hàng hóa/vật tư
    <br/>- Phân tích kho
    <br/>- Lệnh điều chuyển
    <br/>- Lệnh xuất kho
    <br/>- Yêu cầu báo giá NCC
    <br/>- Báo giá nhà cung cấp
    <br/>- Tra cứu giá hàng hóa
    <br/>- Danh mục nhóm hàng hóa
    <br/>- Hợp đồng cung cấp vật tư
    <br/>- Hợp đồng mua thiết bị
    <br/>- Hợp đồng thuê thiết bị
    <br/>- Hợp đồng thuê vận chuyển
    <br/>- Hợp đồng bán vật tư
    <br/>- Nghiệm thu hợp đồng
    <br/>- Hồ sơ thanh toán
    <br/>- Thu/chi
    <br/>- Đối trừ công nợ
    <br/>- Theo dõi công nợ phải thu
    <br/>- Theo dõi công nợ phải trả
    <br/>- Danh mục nhà cung cấp
    <br/>- Danh mục khách hàng
    <br/>- Hạn mức tín dụng được cấp
    <br/>- Cảnh báo hạn mức tín dụng
    <br/>- Danh mục khách hàng
    <br/>- Hạn mức tín dụng cấp
    <br/>- Đánh giá nhà cung cấp
    <br/>- Theo dõi tiến độ giao hàng
    </ul>
    <h2>TIỆN ÍCH PHẦN MỀM</h2>
    Có nhiều cách nhập danh mục công việc của dự án: từ bàn phím; từ file dữ liệu; sao chép cắt dán từ cửa sổ dự án khác hoặc thông qua ô mã đơn giá và cũng có nhiều cách để nhập khối lượng công việc… Trong phần mềm:
    <ul>
    - Mỗi công việc chỉ đơn giản được gán cho thời gian bắt đầu và kết thúc, chủ đầu tư chỉ quan tâm đến tiến độ triển khai dự án do nhà thầu cam kết và theo dõi thực hiện tiến độ.
    <br/>- Cho phép lập kế hoạch tiến độ và thể hiện thông qua biên bản nghiệm thu và các sơ đồ Gantt;
    <br/>- Có các biểu đồ vốn, vật liệu, nhân công, máy theo trục thời gian.
    <br/>- Có sẵn cơ sở dữ liệu các định mức đơn giá theo bảng giá hiện hành (luôn được cập nhật) dùng làm cơ sở tính các loại chi phí và thanh quyết toán.
    <br/>- Bao gồm tất cả các tính năng của một PM lập dự toán xây dựng công trình, có thể dùng phân tích và tổng hợp hao phí…
    <br/>- Lập đơn giá tổng hợp để tham gia đấu thầu. Bảng thẩm định dự toán hay bảng tổng hợp kinh phí quyết toán, tình hình thanh toán và công nợ của dự án…
    <br/>- Là những mẫu báo cáo phổ biến và quan trọng mà Hitek đều có thể trích xuất dưới dạng Excel hoặc Word.
    </ul>
    Với Hitek, mọi thứ trở nên dễ dàng hơn bao giờ hết! Còn chần chừ gì nữa hãy liên hệ ngay với chúng tôi để có giải pháp tốt nhất cho việc quản lý công trình xây dựng của bạn!
</p>`
    ,PScategory : 21
    },
{
    id_lv2: 67,
    textItem: "Phần mềm quản lý tiến độ",
    text: `<p>
    Tiêu chí để đánh giá một dự án thành công hay không đó chính là hiệu quả kinh tế mà dự án đó mang lại, chất lượng dự án, tiến độ thực hiện dự án,… Đa phần, các công trình xây dựng ở Việt Nam đều chậm tiến độ bàn giao và điều này ảnh hưởng không hề nhỏ đến lợi ích các bên liên quan. Để một dự án được bàn giao đúng tiến độ thì công tác Quản lý tiến độ thi công công trình phải được tổ chức một cách chặt chẽ, khoa học.
    <br/><br/>
    Phần mềm quản lý tiến độ thi công Hitek ra đời – là giải pháp toàn diện trong quản lý tiến độ thi công, giúp giải quyết được những khó khăn trong công tác quản lý tiến độ, đồng thời khiến công việc quản lý tiến độ trở nên dễ dàng hơn bao giờ hết.
    <br/><br/>
    <h2>CHỨC NĂNG QUẢN LÍ TIẾN ĐỘ CỦA PHẦN MỀM</h2>
    <h3>Quản lý công việc</h3>
    Hệ thống tương tác thông minh giúp cho những người tham gia xử lý công việc nắm rõ tất cả những thông tin chính của một công việc:
    <ul>
    - Ai là người giao việc
    <br/>- Tên và nội dung (có thể đính kèm văn bản hoặc file nội dung)
    <br/>- Ai là người chịu trách nhiệm chính
    <br/>- Bộ phận, cá nhân tham gia xử lý công việc
    <br/>- Bộ phận, cá nhân tham khảo công việc
    <br/>- Thời gian bắt đầu, thời gian kết thúc
    <br/>- Toàn bộ tiến trình công việc: sắp xếp theo thứ tự thời gian cập nhật
    <br/>- Trạng thái công việc: đang thực hiện, gần quá hạn, đã hết hạn, đề nghị kết thúc và kết thúc
    <br/>- Công cụ hỗ trợ thêm
    <br/>- Theo dõi, báo cáo, cho ý kiến hoặc chỉ đạo trên một công việc
    <br/>- Đính kèm nhiều file trong một dòng công việc
    <br/>- Ủy quyền công việc cho một cá nhân khi đi công tác
    <br/>- Tạo tên dự án : đưa tất cả công việc liên quan đến một dự án để dễ theo dõi, xử lý
    <br/>- Xem diễn tiến mới nhất
    <br/>- Tạo lịch cá nhân để theo dõi
    <br/>- Phân loại công việc : chưa hoàn thành, chuyển giao, kết thúc
    <br/>- Thống kê công việc tổng thể và của bản thân
    <br/>- Tìm kiếm công việc theo từ khóa
    <br/>- Lưu trữ tất cả công việc kết thúc để tiện việc tham khảo
    </ul>
    <h3>Trao đổi công việc</h3>
    <ul>
    - Công cụ tạo những phòng riêng cùng trao đổi các chủ đề công việc
    <br/>- Giới hạn cá nhân, bộ phận có thể truy cập chủ đề trao đổi chủ đề đã tạo
    <br/>- Đính kèm nhiều tập tin để tiện tham khảo
    </ul>
    <h3>Tủ hồ sơ cá nhân</h3>
    <ul>
    - Lưu trữ dữ liệu cá nhân
    <br/>- Chia sẻ cho những thành viên khác được phép truy xuất dữ liệu
    </ul>
    <h3>Trao đổi công việc</h3>
    <ul>
    - Cấp N báo cáo lên cấp N-1, cấp N-1 nhận được báo cáo dạng tổng hợp sẽ tiếp tục lập tiếp các báo cáo riêng của mình + Report tổng hợp từ cấp N, sau đó tiếp tục báo cáo đến cấp N-2, cứ thế cho đến Root, Root sẽ nhận được báo cáo tổng hợp toàn bộ tình hình công việc
    <br/>- Các thông tin cần báo cáo định kỳ:
    <br/>- Các công việc đã thực hiện, hoàn thành
    <br/>- Các công việc đang thực hiện
    <br/>- Các công việc chưa thực hiện
    <br/>- Các công việc dự kiến (Plan)
    <br/>- Các khó khăn, giải pháp đề xuất
    </ul>
    Với Hitek công việc quản lí tiến độ thi công trở nên dễ dàng hơn bao giờ hết! Hãy liên hệ với chúng tôi để có giải pháp hiệu quả chuyên nghiệp cho công việc quản lý tiến độ của mình!
</p>`
    ,PScategory : 21
    },
{
    id_lv2: 68,
    textItem: "Phần mềm quản lý công ty xây dựng",
    text: `<p>
    Tiêu chí để đánh giá một dự án thành công hay không đó chính là hiệu quả kinh tế mà dự án đó mang lại, chất lượng dự án, tiến độ thực hiện dự án,… Đa phần, các công trình xây dựng ở Việt Nam đều chậm tiến độ bàn giao và điều này ảnh hưởng không hề nhỏ đến lợi ích các bên liên quan. Để một dự án được bàn giao đúng tiến độ thì công tác Quản lý tiến độ thi công công trình phải được tổ chức một cách chặt chẽ, khoa học.
    <br/><br/>
    Phần mềm quản lý tiến độ thi công Hitek ra đời – là giải pháp toàn diện trong quản lý tiến độ thi công, giúp giải quyết được những khó khăn trong công tác quản lý tiến độ, đồng thời khiến công việc quản lý tiến độ trở nên dễ dàng hơn bao giờ hết.
    <br/><br/>
    <h2>CHỨC NĂNG QUẢN LÍ TIẾN ĐỘ CỦA PHẦN MỀM</h2>
    <h3>Quản lý công việc</h3>
    Hệ thống tương tác thông minh giúp cho những người tham gia xử lý công việc nắm rõ tất cả những thông tin chính của một công việc:
    <ul>
    - Ai là người giao việc
    <br/>- Tên và nội dung (có thể đính kèm văn bản hoặc file nội dung)
    <br/>- Ai là người chịu trách nhiệm chính
    <br/>- Bộ phận, cá nhân tham gia xử lý công việc
    <br/>- Bộ phận, cá nhân tham khảo công việc
    <br/>- Thời gian bắt đầu, thời gian kết thúc
    <br/>- Toàn bộ tiến trình công việc: sắp xếp theo thứ tự thời gian cập nhật
    <br/>- Trạng thái công việc: đang thực hiện, gần quá hạn, đã hết hạn, đề nghị kết thúc và kết thúc
    <br/>- Công cụ hỗ trợ thêm
    <br/>- Theo dõi, báo cáo, cho ý kiến hoặc chỉ đạo trên một công việc
    <br/>- Đính kèm nhiều file trong một dòng công việc
    <br/>- Ủy quyền công việc cho một cá nhân khi đi công tác
    <br/>- Tạo tên dự án : đưa tất cả công việc liên quan đến một dự án để dễ theo dõi, xử lý
    <br/>- Xem diễn tiến mới nhất
    <br/>- Tạo lịch cá nhân để theo dõi
    <br/>- Phân loại công việc : chưa hoàn thành, chuyển giao, kết thúc
    <br/>- Thống kê công việc tổng thể và của bản thân
    <br/>- Tìm kiếm công việc theo từ khóa
    <br/>- Lưu trữ tất cả công việc kết thúc để tiện việc tham khảo
    </ul>
    <h3>Trao đổi công việc</h3>
    <ul>
    - Công cụ tạo những phòng riêng cùng trao đổi các chủ đề công việc
    <br/>- Giới hạn cá nhân, bộ phận có thể truy cập chủ đề trao đổi chủ đề đã tạo
    <br/>- Đính kèm nhiều tập tin để tiện tham khảo
    </ul>
    <h3>Tủ hồ sơ cá nhân</h3>
    <ul>
    - Lưu trữ dữ liệu cá nhân
    <br/>- Chia sẻ cho những thành viên khác được phép truy xuất dữ liệu
    </ul>
    <h3>Trao đổi công việc</h3>
    <ul>
    - Cấp N báo cáo lên cấp N-1, cấp N-1 nhận được báo cáo dạng tổng hợp sẽ tiếp tục lập tiếp các báo cáo riêng của mình + Report tổng hợp từ cấp N, sau đó tiếp tục báo cáo đến cấp N-2, cứ thế cho đến Root, Root sẽ nhận được báo cáo tổng hợp toàn bộ tình hình công việc
    <br/>- Các thông tin cần báo cáo định kỳ:
    <br/>- Các công việc đã thực hiện, hoàn thành
    <br/>- Các công việc đang thực hiện
    <br/>- Các công việc chưa thực hiện
    <br/>- Các công việc dự kiến (Plan)
    <br/>- Các khó khăn, giải pháp đề xuất
    </ul>
    Với Hitek công việc quản lí tiến độ thi công trở nên dễ dàng hơn bao giờ hết! Hãy liên hệ với chúng tôi để có giải pháp hiệu quả chuyên nghiệp cho công việc quản lý tiến độ của mình!
</p>`
    ,PScategory : 21
},
{
    id_lv2: 69,
    textItem: "PHẦN MỀM QUẢN LÝ TRUNG TÂM ĐÀO TẠO",
    text: `<p>
    Rất nhiều trung tâm đào tạo đang mong muốn nâng cao chất lượng phục vụ học viên, tối ưu được nguồn lực về giảng viên, cơ sở vật chất và nhân viên, giúp việc tổ chức quản lý thuận tiện hơn. Đáp ứng nhu cầu đó phần mềm quản lý trung tâm đào tạo của HSV.EDU được xây dựng, phát triển với mục tiêu nâng cao tính chuyên nghiệp trong khâu quản lý, tiếp thị của các trung tâm đào tạo, quản lý toàn bộ quy trình chăm sóc và hỗ trợ học viên. Phần mềm quản lý trung tâm đào tạo HSV.EDU được ứng dụng trong việc quản lý hoạt động của các trung tâm đào tạo, nâng cao tính chuyên nghiệp của tổ chức với chi phí hợp lý.
    <h2>CHỨC NĂNG CỦA PHẦN MỀM QUẢN LÝ TRUNG TÂM ĐÀO TẠO</h2>
    <h3>Quản lý tuyển sinh và thi xếp lớp</h3>
    Hệ thống tương tác thông minh giúp cho những người tham gia xử lý công việc nắm rõ tất cả những thông tin chính của một công việc:
    <ul>
    - Khi học viên đăng ký trực tiếp trên website, dữ liệu sẽ được chọn lọc và lưu thông tin vào phần mềm.
    <br/>- Thống kê học viên từ các nguồn, chiến dịch tiếp thị, học viên đăng ký trực tiếp tại văn phòng.
    <br/>- Nhập thông tin về học viên đăng ký thi xếp lớp theo từng đợt thi.
    <br/>- Cập nhập kết quả xét tuyển
    <br/>- Đăng ký nhập học cho từng học viên theo từng trình độ.
    <br/>- Phân loại và xếp lớp phù hợp với trình độ thi đầu vào của học viên
    </ul>
    <h3>Quản lý học viên</h3>
    <ul>
    - Quản lý và lưu trữ hồ sơ, giấy tờ, sơ yếu lý lịch học sinh giúp tra cứu, thống kê nhanh chóng, dễ dàng
    <br/>- Quản lý thông tin cá nhân như Họ tên, ngày sinh, giới tính; thông tin liên hệ như số điện thoại (chính, phụ,…), e-mail (chính/phụ), địa chỉ; quản lý nguồn học viên (event, website, Facebook,…)
    <br/>- Quản lý thông tin của phụ huynh (nếu cần), tạo liên kết với dữ liệu học viên
    <br/>- Quản lý tư vấn: nội dung tư vấn, lưu lịch sử tư vấn, đặt lịch hẹn tư vấn
    <br/>- Hệ thống cảnh báo nhắc nhở khi đến ngày có lịch hẹn tư vấn học viên
    <br/>- Quản lý kết quả thi đầu vào, xếp lớp cho học viên
    <br/>- Quản lý học thử của học viên
    <br/>- Theo dõi danh sách học viên các lớp, danh sách học viên bảo lưu
    <br/>- Nhắc nhở khi học viên vắng nhiều
    <br/>- Quản lý, theo dõi kết quả học tập, điểm danh, khen thưởng, kỷ luật… của từng học viên trong toàn bộ khóa học.
    <br/>- Người quản lý theo dõi được các thay đổi liên quan đến học viên và lớp học như: bảo lưu, chuyển lớp, dồn lớp, chia lại lớp học theo từng trình độ của học viên.
    <br/>- Danh mục chức năng quản lý học viên
    <br/>- Quản lý điểm danh
    </ul>
    <h3>Quản lý giáo viên</h3>
    <ul>
    - Quản lý thông tin, trình độ, bằng cấp, hồ sơ của giáo viên.
    <br/>- Tuyển giáo viên mới.
    <br/>- Theo dõi quá trình giảng dạy của giáo viên.
    <br/>- Quản lý số giờ lên lớp của từng giáo viên.
    <br/>- Ghi nhận thông tin và số giờ có thể giảng dạy của giáo viên.
    <br/>- Quản lý khen thưởng, kỷ luật của từng giáo viên trong năm
    </ul>
    <h3>Quản lý đào tạo</h3>
    <ul>
    - Lập kế hoạch giảng dạy cho các khóa học sắp khai giảng.
    <br/>- Sắp xếp giáo viên và phòng học để đảm bảo không bị trùng lặp giờ dạy và phòng học.
    <br/>- Phân chia học sinh theo đúng trình độ và mong muốn của học viên.
    <br/>- Theo dõi kế hoạch chi tiết các buổi dạy của từng lớp học theo hệ thống phòng học và ca học.
    </ul>
    <h3>Quản lý lớp học</h3>
    <ul>
    - Phân chia lớp, xếp lớp
    <br/>- Phân loại theo từng cấp độ học
    <br/>- Quản lý thời khóa biểu
    <br/>- Quản lý các thông báo đến toàn lớp theo e-mail, SMS
    <br/>- Quản lý kết quả học tập của lớp
    <br/>- Chuyển lớp
    <br/>- Nhắc nhở khi lớp có học viên mới
    <br/>- Lưu trữ thông tin học viên bảo lưu và sắp xếp chuyển lớp khi học viên đi học lại.
    <br/>- Chức năng xem thời khóa biểu
    </ul>
    <h3>Quản lý thu chi</h3>
    <ul>
    - Quản lý thu học phí của học viên
    <br/>- Xử lý học phí khi chuyển lớp, bảo lưu, chuyển đổi cho học viên khác.
    <br/>- Lập và in phiếu thu tiền học phí cho từng học viên.
    <br/>- Theo dõi được các khoản thu, chi hàng ngày của trung tâm.
    <br/>- Quản lý tổng quát được tình hình thu chi cho hoạt động của trung tâm.
    </ul>
    <h3>Quản lý kết quả học tập</h3>
    <ul>
    - Quản lý điểm, kết quả học tập của học viên.
    <br/>- Tự động tính điểm trung bình của học viên.
    <br/>- Hệ thống sẽ nhắc nhở đối với các học viên đã hoành thành xong chương trình để chuyển tiếp lên các lớp có trình độ cao hơn.
    <br/>- Lưu thông tin và kết quả học tập của từng học viên trong hệ thống.
    </ul>
    <h3>Tiền lương, chấm công</h3>
    <ul>
    - Kết xuất bảng lương giáo viên theo kết quả chấm công và lịch giảng dạy
    <br/>- Chấm công cho nhân viên theo các hình thức: theo buổi, theo ca, theo tháng, mức lương và thời gian áp dụng
    <br/>- Lưu thời gian làm thêm giờ
    <br/>- Lưu thông tin thuế, bảo hiểm
    <br/>- Lập bảng thanh toán tiền lương dựa trên bảng lương đã tính để chuyển trả lương cho nhân viên
    <br/>- Theo dõi , xác nhận việc điều chỉnh tăng, giảm khi có phát sinh
    </ul>
    <h2>LỢI ÍCH CỦA PHẦN MỀM QUẢN LÝ TRUNG TÂM ĐÀO TẠO</h2>
    <ul>
    - Sử dụng phần mềm giúp nhà quản lý theo dõi được tình hình học tập của học sinh cũng như công tác quản lý nhân viên trong trung tâm. Tăng khả năng quản lý tập trung, tối ưu hóa nguồn lực, tăng khả năng phân tích, có thể chủ động can thiệp kịp thời và hiệu quả vào quá trình quản lý.
    <br/>- Quản lý hồ sơ, giấy tờ, sơ yếu lý lịch học sinh giúp tra cứu, thống kê nhanh chóng, dễ dàng. Quản lý, theo dõi kết quả học tập, điểm danh, khen thưởng, kỷ luật… của học sinh trong toàn bộ khóa học.
    <br/>- Quản lý lớp học, khóa học để phân chia ca học, lớp học, giáo viên giảng dạy một cách hợp lý.
    <br/>- Quản lý học phí, các khoản phải thu, phiếu thu, miễn giảm đối với từng học sinh, từng khóa học, lớp học.
    <br/>- Sử dụng phần mềm giúp nâng cao hiệu quả công việc, giảm bớt các thủ tục hành chính, xử lý công việc khoa học, tăng khả năng trao đổi giữa các nhân viên trong trung tâm. Dễ dàng thống kê báo cáo với nhà lãnh đạo các số liệu thực tế, được cập nhật theo hệ thống báo cáo thời gian thực.
    <br/>- Phần mềm có khả năng kết nối với website của trung tâm đào tạo, giúp thông tin của học viên đăng ký trên phần mềm tự động cập nhập vào hệ thống.
    <br/>- Hệ thống thông tin công khai và thông suốt giúp giảm tối đa thời gian quản lý công việc. Hệ thống phân quyền, bảo mật tốt, phổ biến thông tin nhanh chóng và chính xác đến từng người sử dụng phần mềm.
    <br/>- Phần mềm quản lý trung tâm đào tạo của HSV.EDU có khả năng tùy biến, có thể tùy chỉnh phần mềm theo những yêu cầu khác nhau của từng doanh nghiệp, dễ dàng tùy chỉnh khi có thay đổi về quy trình quản lý.
    </ul>
    Với phần mềm quản lý trung tâm đào tạo “HSV.EDU” các nhà quản lý sẽ dễ dàng quản lý học viên, giáo viên và các chương trình đào tạo của trung tâm. Thêm vào đó, phần mềm rất thân thiện với người dùng, dễ sử dụng và có khả năng tùy biến cao. Sử dụng phần mềm giúp tiết kiệm thời gian, các khâu trong quá trình vận hành được thực hiện theo một quy trình nhất định, không để xảy ra nhầm lẫn, sai sót; truy cập ở mọi nơi, từ mọi thiết bị, đáp ứng nhu cầu quản lý và giải quyết công việc một cách nhanh nhất
</p>`
    ,PScategory : 22
    },
{
    id_lv2: 70,
    textItem: "PHẦN MỀM QUẢN LÝ TRUNG TÂM TIẾNG ANH",
    text: `<p>
    Phần mềm Quản lý trung tâm Tiếng Anh HSV là giải pháp quản lý toàn diện một hay nhiều chi nhánh của các trung tâm ngoại ngữ. Với mục tiêu nâng cao tính hiệu quả và chuyên nghiệp trong quá trình quản lý, chăm sóc và hỗ trợ học viên của các trung tâm Tiếng Anh. Phần mềm hỗ trợ quản lý số lượng học viên, giáo viên, sắp xếp thời khóa biểu, khóa học, lớp học, lịch giảng dạy…Việc sử dụng phần mềm sẽ giúp trung tâm nâng cao chất lượng phục vụ học viên, tối ưu được nguồn lực về giảng viên, nhân viên, cơ sở vật chất của trung tâm và tổ chức quản lý thuận tiện hơn.
    <br/><br/>
    Sử dụng phần mềm quản lý trung tâm Tiếng Anh HSV sẽ giúp cho quá trình đào tạo được thực hiện theo một quy trình nhất định, nhanh chóng và chính xác. Giúp lưu giữ tất cả các thông tin học sinh, lớp học, khóa học, các nghiệp vụ thành một hệ thống, dữ liệu được quản lý tập trung, thống nhất, không xảy ra sai xót trong quá trình chuyển dữ liệu từ phòng ban này qua phòng ban khác.
    <br/><br/>
    Phần mềm hỗ trợ rất tốt cho việc giám sát, quản lý của cấp lãnh đạo. Với hệ thống báo cáo rất phong phú, đa dạng, báo cáo thời gian thực giúp các nhà quản lý nhanh chóng nắm được tình hình trung tâm nhằm đánh giá một cách chính xác, khách quan về tình hình học tập, giảng dạy của giáo viên và học sinh.
    <h2>LỢI ÍCH CỦA PHẦN MỀM QUẢN LÝ TRUNG TÂM TIẾNG ANH</h2>
    <ul>
    - Sử dụng phần mềm giúp nhà quản lý theo dõi được tình hình học tập của học sinh cũng như công tác quản lý nhân viên trong trung tâm. Tăng khả năng quản lý tập trung, tối ưu hóa nguồn lực, tăng khả năng phân tích, có thể chủ động can thiệp kịp thời và hiệu quả vào quá trình quản lý. Giúp các nhà lãnh đạo có phương án bố trí nhân sự và tổ chức hoạt động một cách dễ dàng.
    <br/>- Quản lý hồ sơ, giấy tờ, sơ yếu lý lịch học sinh giúp tra cứu, thống kê nhanh chóng, dễ dàng. Quản lý, theo dõi kết quả học tập, điểm danh, khen thưởng, kỷ luật… của học sinh trong toàn bộ khóa học.
    <br/>- Quản lý lớp học, khóa học để phân chia ca học, lớp học, giáo viên giảng dạy một cách hợp lý.
    <br/>- Quản lý học phí, các khoản phải thu, phiếu thu, miễn giảm đối với từng học sinh, từng khóa học, lớp học.
    <br/>- Sử dụng phần mềm giúp nâng cao hiệu quả công việc, giảm bớt các thủ tục hành chính, xử lý công việc khoa học, tăng khả năng trao đổi giữa các nhân viên trong trung tâm. Dễ dàng thống kê báo cáo với nhà lãnh đạo các số liệu thực tế, được cập nhập theo hệ thống báo cáo thời gian thực.
    <br/>- Phần mềm có khả năng kết nối với website của trung tâm đào tạo, giúp thông tin của học viên đăng ký trên phần mềm tự động cập nhập vào hệ thống.
    <br/>- Hệ thống thông tin công khai và thông suốt giúp giảm tối đa thời gian quản lý công việc. Hệ thống phân quyền, bảo mật tốt, phổ biến thông tin nhanh chóng và chính xác đến từng người sử dụng phần mềm.
    <br/>- Phần mềm quản lý trung tâm Tiếng Anh của HSV có khả năng tùy biến, có thể tùy chỉnh phần mềm theo những yêu cầu khác nhau của từng doanh nghiệp, dễ dàng tùy chỉnh khi có thay đổi về quy trình quản lý.
    </ul>
    <h2>CÁC CHỨC NĂNG CỦA PHẦN MỀM QUẢN LÝ TRUNG TÂM TIẾNG ANH</h2>
    <h3>Quản lý tuyển sinh và thi xếp lớp</h3>
    <ul>
    - Khi học viên đăng ký trực tiếp trên website, dữ liệu sẽ được chọn lọc và lưu thông tin vào phần mềm.
    <br/>- Thống kê học viên từ các nguồn, chiến dịch tiếp thị, học viên đăng ký trực tiếp tại văn phòng.
    <br/>- Nhập thông tin về học viên đăng ký thi xếp lớp theo từng đợt thi.
    <br/>- Cập nhập kết quả xét tuyển
    <br/>- Đăng ký nhập học cho từng học viên theo từng trình độ.
    <br/>- Phân loại và xếp lớp phù hợp với trình độ thi đầu vào của học viên.
    </ul>
    <h3>Quản lý học viên</h3>
    <ul>
    - Quản lý và lưu trữ hồ sơ, giấy tờ, sơ yếu lý lịch học sinh giúp tra cứu, thống kê nhanh chóng, dễ dàng.
    <br/>- Theo dõi danh sách học viên các lớp, danh sách học viên bảo lưu.
    <br/>- Nhắc nhở khi học viên vắng nhiều.
    <br/>- Quản lý, theo dõi kết quả học tập, điểm danh, khen thưởng, kỷ luật… của từng học viên trong toàn bộ khóa học.
    <br/>- Người quản lý theo dõi được các thay đổi liên quan đến học viên và lớp học như: bảo lưu, chuyển lớp, dồn lớp, chia lại lớp học theo từng trình độ của học viên.
    </ul>
    <h3>Quản lý giáo viên</h3>
    <ul>
    - Quản lý thông tin, trình độ, bằng cấp, hồ sơ của giáo viên.
    <br/>- Tuyển giáo viên mới.
    <br/>- Theo dõi quá trình giảng dạy của giáo viên.
    <br/>- Quản lý số giờ lên lớp của từng giáo viên.
    <br/>- Ghi nhận thông tin và số giờ có thể giảng dạy của giáo viên.
    <br/>- Quản lý khen thưởng, kỷ luật của từng giáo viên trong năm.
    </ul>
    <h3>Quản lý đào tạo</h3>
    <ul>
    - Lập kế hoạch giảng dạy cho các khóa học sắp khai giảng.
    <br/>- Sắp xếp giáo viên và phòng học để đảm bảo không bị trùng lặp giờ dạy và phòng học.
    <br/>- Phân chia học sinh theo đúng trình độ và mong muốn của học viên.
    <br/>- Theo dõi kế hoạch chi tiết các buổi dạy của từng lớp học theo hệ thống phòng học và ca học.
    </ul>
    <h3>Quản lý lớp học</h3>
    <ul>
    - Phân chia lớp
    <br/>- Phân loại theo từng cấp độ học
    <br/>- Quản lý lịch học, thời khóa biểu
    <br/>- Quản lý thông báo đến toàn lớp
    <br/>- Quản lý kết quả học tập của lớp
    <br/>- Chuyển lớp
    <br/>- Nhắc nhở khi lớp có học viên mới
    <br/>- Lưu trữ thông tin học viên bảo lưu và sắp xếp chuyển lớp khi học viên đi học lại
    </ul>
    <h3>Quản lý thu chi</h3>
    <ul>
    - Quản lý thu học phí của học viên
    <br/>- Xử lý học phí khi chuyển lớp, bảo lưu, chuyển đổi cho học viên khác.
    <br/>- Lập và in phiếu thu tiền học phí cho từng học viên.
    <br/>- Theo dõi được các khoản thu, chi hàng ngày của trung tâm.
    <br/>- Quản lý tổng quát được tình hình thu chi cho hoạt động của trung tâm.
    </ul>
    <h3>Quản lý kết quả học tập</h3>
    <ul>
    - Quản lý điểm, kết quả học tập của học viên.
    <br/>- Tự động tính điểm trung bình của học viên.
    <br/>- Hệ thống sẽ nhắc nhở đối với các học viên đã hoành thành xong chương trình để chuyển tiếp lên các lớp có trình độ cao hơn.
    <br/>- Lưu thông tin và kết quả học tập của từng học viên trong hệ thống.
    </ul>
    <h3>Tính lương</h3>
    <ul>
    - Kết xuất bảng tính lương giáo viên theo kết quả chấm công và lịch giảng dạy.
    <br/>- Tính lương cho nhân viên theo các hình thức trả lương: theo buổi, theo ca, theo tháng, mức lương và thời gian áp dụng.
    <br/>- Tự động tính lương làm thêm giờ căn cứ vào bảng tổng hợp chấm công thời gian.
    <br/>- Tự động tính thuế TNCN, trích BHXH của nhân viên theo biểu tính thuế.
    <br/>- Lập bảng thanh toán tiền lương dựa trên bảng lương đã tính để chuyển trả lương cho nhân viên.
    <br/>- Theo dõi , xác nhận việc điều chỉnh tăng, giảm khi có phát sinh.
    </ul>
    <h2>TỔNG QUAN VỀ PHẦN MỀM QUẢN LÝ TRUNG TÂM TIẾNG ANH</h2>
    Với phần mềm quản lý trung tâm tiếng anh HSV các nhà quản lý sẽ dễ dàng quản lý học viên, giáo viên và các chương trình đào tạo của trung tâm.
    <br/><br/>
    Phần mềm quản lý trung tâm Tiếng Anh HSV giúp các trung tâm nâng cao chất lượng phục vụ học viên, tối ưu được nguồn lực về giảng viên, cơ sở vật chất và nhân viên, giúp việc tổ chức quản lý thuận tiện hơn. Phần mềm được xây dựng, phát triển với mục tiêu nâng cao tính chuyên nghiệp trong khâu quản lý, tiếp thị của các trung tâm đào tạo Tiếng Anh, quản lý toàn bộ quy trình chăm sóc và hỗ trợ học viên. Phần mềm quản lý trung tâm Tiếng Anh của Hitek được ứng dụng trong việc quản lý hoạt động của các trung tâm đào tạo, nâng cao tính chuyên nghiệp của tổ chức với chi phí hợp lý
</p>`
    ,PScategory : 22
    },
{
    id_lv2: 71,
    textItem: "PHẦN MỀM QUẢN LÝ TRUNG TÂM DU HỌC",
    text: `<p>
    Hiện nay, một bộ phận các công ty, trung tâm tư vấn du học đang gặp khó khăn trong công tác quản lý của mình do sự xuất hiện ngày càng nhiều nhu cầu tư vấn, hay số lượng hồ sơ du học quá lớn, hoặc việc kiểm soát các đối tác khách hàng cũng như các đối tác nước ngoài- nơi mà các công ty, trung tâm gửi học sinh tới…Mô hình phần mềm quản lý du học của Công ty cổ phần và Dịch vụ HSV ra đời nhằm giải quyết được những khó khăn hiện nay của đại đa số công ty tư vấn du học vẫn quản lý theo cách thức truyền thống, đồng thời là mô hình phần mềm với nhiều chức năng vượt trội khiến công tác quản lý trở nên đơn giản, thuận tiện.
    <h2>CHỨC NĂNG CỦA PHẦN MỀM</h2>
    <h3>Quản lý thông tin khách hàng</h3>
    <ul>
    - Họ tên, địa chỉ, điện thoại, email1, email 2, facebook, trường đang học…
    <br/>- Upload ảnh, tài liệu liên quan
    <br/>- Ngành muốn học, trường mong muốn học, chương trình học, loại visa, nước đến
    <br/>- Nguồn khách hàng, tỉnh thành, quận huyện, cộng tác viên…
    <br/>- Thông tin bố, mẹ: Tên, điện thoại, email, địa chỉ nhà riêng, địa chỉ cơ quan, thắc mắc của phụ huynh…
    </ul>
    <h3>Phân loại khách hàng theo:</h3>
    <ul>
    - Loại khách hàng ( tiềm năng, visa, du học, dịch thuật…)
    <br/>- Nguồn ( KH cũ, KH gọi điện đến, cộng tác viên, hội nghi…)
    <br/>- Tỉnh thành, quận huyện
    <br/>- Chương trình học
    <br/>- Nhân viên chăm sóc khách hàng
    <br/>- Nước đến
    <br/>- Khâu làm hồ sơ: Visa, duyệt hồ sơ, sau visa, sau du học
    </ul>
    <h3>Quản lý các tài liệu liên quan đến khách hàng</h3>
    <ul>
    - Upload, xem và Download hình ảnh, File đính kèm của khách hàng
    <br/>- Upload, xem và Download hợp đồng của khách hàng
    </ul>
    <h3>Quản lý Hợp đồng</h3>
    <ul>
    - Thông tin hợp đồng, giá trị hợp đồng, chi phí ( cộng tác viên, đi lại…), hoa hồng, vé…
    <br/>- Nhắc nhở hợp đồng sắp hết hạn
    <br/>- Nhắc nhở công nợ phải thu
    <br/>- Báo cáo doanh thu, công nợ
    <br/>- Lọc danh sách hợp đồng theo tiêu chí: Trạng thái thanh toán ( đã thanh toán hết, chưa thanh toán, chưa thanh toán hết), trạng thái ( đang thực hiện, đã thực hiện, đã thanh lý), nhân viên chăm sóc…
    <br/>- Tìm kiếm HĐ theo từ khóa, theo khách hàng
    </ul>
    <h3>Quản lý quá trình chăm sóc và làm việc</h3>
    <ul>
    - Thông tin giao dịch: nội dung gọi điện, công việc, phản hồi của KH, kết quả làm việc
    <br/>- Đặt lịch gọi điện, làm việc với khách hàng theo thời gian
    <br/>- Nhắc nhở lịch hẹn khi đến hẹn
    <br/>- Quản lý công việc được giao, đã giao và tự giao
    <br/>- Tra cứu lịch sử giao dịch của 1 KH
    <br/>- Báo cáo công việc, cuộc gọi của khách hàng hay nhân viên theo thời gian
    </ul>
    <h3>Quản lý đại lý, cộng tác viên</h3>
    <ul>
    - Thông tin đại lý, công tác viên: họ tên, địa chỉ,email, điện thoại, tài khoản
    <br/>- Báo cáo danh sách KH đã giới thiệu thành công, số tiền nhận hoa hồng theo tên cộng tác viên
    <br/>- Tìm kiếm nhanh chóng, chính xác
    </ul>
    <h3>Quản lý danh sách chương trình học</h3>
    <ul>
    - Tên trường
    <br/>- Loại hình
    <br/>- Chi phí
    <br/>- Tìm kiếm, tra cứu thông tin nhanh, chính xác
    <br/>- Báo cáo danh sách KH, hợp đồng theo chương trình học
    </ul>
    <h3>Quản lý các mẫu dữ liệu chung của công ty, xuất dữ liệu theo mẫu</h3>
    <ul>
    - Báo cáo khách hàng theo nhiều tiêu chí
    <br/>- Báo cáo hợp đồng, doanh thu, công nợ, chi phí
    <br/>- Báo cáo cuộc gọi, công việc
    </ul>
    <h2>ƯU ĐIỂM NỔI BẬT</h2>
    <ul>
    - Dùng online, truy cập từ xa mọi lúc mọi nơi và kịp thời
    <br/>- Khả năng tùy biến linh hoạt, thay đổi theo nhu cầu nhằm đáp ứng nhu cầu của từng trung tâm với từng đặc thù riêng biệt.
    <br/>- Khả năng tích hợp với các loại máy chấm công, điện thoại, đặc biệt hệ thống SMS: gửi tin nhắn trực tiếp cho toàn bộ trung tâm hoặc học viên, khách hàng.
    <br/>- Hệ thống cơ sở dữ liệu thống nhất:toàn bộ hồ sơ của học viên cũng như là việc chấm công của nhân viên hay tiến trình chăm sóc khách hàng của tất cả các chi nhánh được lưu trữ trên cùng hệ thống, dễ dàng cho việc quản lý từ cấp lãnh đạo đến nhân viên.
    <br/>- Phân quyền hợp lý chặt chẽ theo mô hình của doanh nghiệp
    <br/>- Thông tin được tìm kiếm nhanh chóng: thay vì phải truy cập vào từng file excel riêng biệt cho mỗi mục thông tin để tìm kiếm thì chỉ cần truy cập vào hệ thống và thực hiện tìm kiếm nhanh theo các danh mục.
    </ul>
    Với phần mềm quản lý trung tâm tư vấn du học của công ty Cổ phần dịch vụ HSV, việc quản lý trở nên dễ dàng và hiệu quả hơn bao giờ hết. Hãy liên lạc ngay với chúng tôi để nhận được sự tư vấn chuyên nghiệp và gói giải pháp phần mềm tiện ích hơn bao giờ hết.
</p>`
    ,PScategory : 22
},
{
    id_lv2: 72,
    textItem: "Các dịch vụ của Cloud VNN",
    text: `<p>
    <img src=${img62} alt="">
    </p>`
    ,PScategory : 23
    },
{
    id_lv2: 73,
    textItem: "Tính năng dịch vụ",
    text: `<p>
    <strong>Tính sẵn sàng cao</strong>
    <br/><br/>
    Cloud VNN đáp ứng tính sẵn sàng cao ở mức:
    <br/><br/>
    <strong>Mạng lưới, lưu trữ, hạ tầng ảo hóa.</strong>
    <br/><br/>
    Tự động chuyển đổi cloud server giữa các máy chủ khi máy chủ gặp sự cố.
    <br/><br/>
    Tự động chuyển hướng lưu lượng khi có sự cố trong liên kết mạng.
    <br/><br/>
    Hạ tầng lưu trữ được thiết kế đáp ứng độ sẵn sàng cao nhất từ kết nối đến bản thân kiến trúc lưu trữ đảm bảo không mất mát dữ liệu của cloud server.
    <br/><br/>
    <strong>Khả năng mở rộng dễ dàng</strong>
    <br/><br/>
    Cloud VNN đáp ứng khả năng mở rộng linh hoạt cho phép khách hàng nâng cấp hệ thống tự động với thời gian nhanh chóng.
    <br/><br/>
    <strong>Hệ thống lưu trữ SAN (Storage Area Network)</strong>
    <br/><br/>
    Cloud VNN xây dựng hệ thống lưu trữ SAN với độ sẵn sàng cao đảm bảo tốc độ truy cấp vượt trội đáp ứng các ứng dụng đa dạng của khách hàng.
    <br/><br/>
    <strong>Mạng IP NGN vượt trội</strong>
    <br/><br/>
    Khách hàng có thể tận hưởng hạ tầng mạng IP NGN đạt giải thưởng Broadband Infovision Award, kết nối tốc độ cao và an toàn đến hạ tầng Cloud. Kết nối từ mạng các nhà cung cấp khác cũng được hỗ trợ một cách dễ dàng nhất.
    <br/><br/>
    Khách hàng có thể lựa chọn kết nối Internet hoặc VPN
    <br/><br/>
    <strong>Quản trị hệ thống linh hoạt</strong>
    <br/><br/>
    Khách hàng sử dụng Cloud VNN được cung cấp khả năng truy cập từ xa an toàn qua RDP, đồng thời truy cập portal quản lý theo dõi bật/tắt khởi động hệ thống. Khách hàng khi đặt mua tổ hợp tài nguyên sẽ được cung cấp portal với giao diện được tùy biến.
    <br/><br/>
    <strong>An toàn bảo mật tối đa</strong>
    <br/><br/>
    Cloud VNN hỗ trợ khả năng bảo mật đa lớp từ lớp vật lý đến lớp ảo hóa, sử dụng công nghệ bảo mật tối ưu của Cisco Systems: Firewall, Virtual Security Gateway đảm bảo luồng dữ liệu được bảo mật và tối ưu.
    <br/><br/>
    <strong>Khởi tạo dịch vụ nhanh chóng</strong>
    <br/><br/>
    Cloud VNN ứng dụng công nghệ tự động hóa của Cisco trên nền tảng ảo hóa Vmware, dựa trên hệ thống mạng chuyển mạch tốc độ cao. Sau khi chứng thực nhu cầu mua của khách hàng hệ thông sẽ tự động cung cấp Cloud Server đến khách hàng một cách nhanh chóng trong thời gian dưới 10 phút.
    <br/><br/>
    <strong>Hỗ trợ kỹ thuật 365x24x7</strong>
    <br/><br/>
    Bộ phận hỗ trợ kỹ thuật với nhiều năm kinh nghiệm. Cloud VNN hỗ trợ từ hạ tầng Cloud đến lớp mạng kết nối đảm bảo thời gian gián đoạn dịch vụ thấp với một đầu mối liên hệ hỗ trợ.
</p>`
    ,PScategory : 23
    },
{
    id_lv2: 74,
    textItem: "Hạ tầng dịch vụ",
    text: `<p>
    <strong>1. Kiến trúc tổng quan của dịch vụ Cloud VNN</strong>
    <br/><br/>
    <img src=${img63} alt="">
    <br/><br/>
    <strong>2. Kiến trúc hiện năng nổi bật</strong>
    <br/><br/>
    <strong>Chuyển mạch (Switching)</strong>
    <br/><br/>
    Nhận thấy hạ tầng mạng của hệ thống cloud là cực kỳ quan trong, Cloud VNN sử dụng hệ thống chuyển mạch cao cấp nhất của Cisco:
    <br/><br/>
    Nexus 7000: hỗ trợ chuyển mạch siêu cao lên đến 7.8Tbps, độ trễ thấp
    <br/><br/>
    Nexus 5000: hỗ trợ chuyển mạch siêu cao, hỗ trợ kết nối FCoE
    <br/><br/>
    Nexus 1000V + VSG: hỗ trợ ảo hóa hạ tầng switch, cho phép các mày chủ kết nối vào switch ảo để đảm bảo về QoS, Security,…, tăng cường tính bảo mật của Virtual Machine đảm bảo không xảy ra các hiện tượng như ARP broadcast, MAC Broadcast …
    <br/><br/>
    Các hệ thống này được thiết kế ở mức dự phòng cao nhất giúp đảm bảo khả năng an toàn thông tin và dữ liệu cho khách hàng sử dụng dịch vụ.
    <br/><br/>
    <strong>Hạ tầng ảo hỏa Vmware vSphere</strong>
    <br/><br/>
    Hãng dẫn đầu công nghệ ảo hóa server và đặc biệt Cloud VNN tham gia chương trình VSPP đảm bảo tuân thủ pháp lý trong việc sử dụng sản phẩm Vmware vSphere cho nhà cung cấp dịch vụ.
    <br/><br/>
    <strong>Kiểm soát hoàn toàn máy chủ</strong>
    <br/><br/>
    Việc ảo hóa dựa trên hypervisor cung cấp khả năng kiểm soát toàn diện tài nguyên tính toán, cho phép khách hàng tương tác với VM giống như với máy chủ vật lý. Cho phép truy cập vào root hay vào console của máy chủ.
    <br/><br/>
    <strong>Bảo mật</strong>
    <br/><br/>
    Cloud VNN được thiết kế để cung cấp bảo mật dữ liệu giữa các VM với nhau và giữa các khách hàng với nhau. Các dữ liệu này hoàn toàn tách biệt thông qua một hệ thống hạ tầng ảo hóa tách biệt nhau. Ngoài ra dịch vụ còn cung cấp thêm cho khách hàng bảo mật cho hệ thống hạ tầng ảo của khách hàng với hệ thống tường lửa ảo.
    <br/><br/>
    Tính sẵn sàng cao: các hệ thống bảo mật của Cloud VNN đều được thiết kế dự phòng, đảm bảo cung cấp an toàn và bảo mật dữ liệu cho khách hàng liên tục.
    <br/><br/>
    Bảo mật chống lại các tấn công giao thức, denial-of-service (DoS),…
    <br/><br/>
    <strong>Cloud Orchestration/Automation</strong>
    <br/><br/>
    Dịch vụ Cloud VNN cung cấp web portal tự phục vụ cho khách hàng. Cho phép khách hàng nhanh chóng bắt đầu sử dụng dịch vụ và thay đổi khi có nhu cầu. Trang web này cung cấp khả năng tương tác cho khách hàng đến hệ thống VM của mình cũng như các dịch vụ mà mình đang sử dụng.
    <br/><br/>
    <strong>Portal Administrator</strong>
    <br/><br/>
    Khách hàng có thể có quyền admin để gán và cho phép các nhân viên trong công ty của mình truy cập vào portal để quản lý và sử dụng VM.
    <br/><br/>
    <strong>Quản lý dịch vụ, tài khoản</strong>
    <br/><br/>
    Trang web cung cấp các tính năng quản trị liên quan đến tài khoản như: các dịch vụ đã và đang sử dụng, thông tin tính cước, quản lý VM,…
    <br/><br/>
    <strong>On-Demand Capacity</strong>
    <br/><br/>
    Cho phép khách hàng đặt hàng dịch vụ theo nhu cầu lên đến nhiều VM đồng thời.
    <br/><br/>
    <strong>Self Provisioning</strong>
    <br/><br/>
    Sử dụng portal, khách hàng có thể tự chọn loại OS mà mình muốn cài, loại VM, loại network, loại bảo mật cho VM của mình.
    <br/><br/>
    <strong>Hệ điều hành</strong>
    <br/><br/>
    Hỗ trợ đa dạng nhiều hệ điều hành cho máy chủ ảo và cho phép tải hệ điều hành trực tiếp lên máy chủ ngay sau khi khách hàng tiến hành đặt hàng
    <br/><br/>
    Khách hàng có thể chọn các loại hệ điều hành đa dạng sau:
    <br/><br/>
    Microsoft Windows Server 2003 (all editions)
    <br/><br/>
    Microsoft Windows Server 2008 (all editions)
    <br/><br/>
    RedHat Linux v5.x
    <br/><br/>
    <strong>Kết nối</strong>
    <br/><br/>
    Hệ thống được kết nối bảo mật thông qua IP VPN của VDC.
    <br/><br/>
    Hệ thống hạ tầng mạn trục backbone của VNPT cực mạnh và rộng khắp cả nước, giúp khách hàng có thể truy cập vào dịch vụ cloud ở bất kỳ đâu.
    <br/><br/>
    <strong>Cơ sở hạ tầng</strong>
    <br/><br/>
    Hệ thống Cloud VNN sử dụng hạ tầng IDC của VDC, được thiết kế, xây dựng đạt chuẩn. Tất cả hệ thống được đặt trong môi trường với hệ thống làm mát, sàn giả, rack, bảo mật,… chuẩn hóa.
    <br/><br/>
    <strong>Đội ngũ nhân viên kỹ thuật</strong>
    <br/><br/>
    Cloud VNN được hỗ trợ kỹ thuật bởi đội ngũ kỹ sư giỏi, giàu kinh nghiệm của VDC và đối tác.
    <br/><br/>
    Độ ngũ kỹ sư có kinh nghiệm triển khai, hỗ trợ thực tế với các bằng cấp cao cấp nhất của Cisco, Vmware, Hitachi.
    <br/><br/>
    <strong>Thương hiệu</strong>
    <br/><br/>
    VNPT, VDC, Cisco, Vmware, Hitachi đều là những tên tuổi hàng đầu.
    <br/><br/>
    <strong>3. Mô hình cung cấp dịch vụ Cloud VNN</strong>
    <br/><br/>
    <img src=${img64} alt="">
    </p>`
    ,PScategory : 23
    },
{
    id_lv2: 75,
    textItem: "Các gói dịch vụ",
    text: `<p>
    <img src=${img65} alt="">
    </p>`
    ,PScategory : 23
},
{
    id_lv2: 76,
    textItem: "Thách thức doanh nghiệp",
    text: `<p>
    Các doanh nghiệp phải có khả năng bảo vệ dữ liệu quan trọng của mình và cố gắng cải thiện khả năng phục hồi để chuẩn bị khi thảm họa không mong đợi và những sự gián đoạn lớn xảy ra.
    <br/><br/>
    Ngoài ra, việc doanh nghiệp chỉ sao lưu dữ liệu vào băng từ, và mang đi lưu trữ ở những nơi không an toàn, không có bất kỳ quy trình bảo mật thích hợp nào sẽ gây nguy hiểm dẫn đến mất mát dữ liệu hay dữ liệu bị lộ ra ngoài. Bên cạnh việc tác động trực tiếp do bị mất doanh thu, các công ty phải đối phó với việc thông tin bị lộ, mất mát dữ liệu, tài sản và năng suất nó còn làm ảnh hưởng đến mức độ tin cậy của khách hàng, nhà cung cấp và đối tác.
    </p>`
    ,PScategory : 24
    },
{
    id_lv2: 77,
    textItem: "Giải pháp của chúng tôi",
    text: `<p>
    EasyBackup - giải pháp an toàn, hiệu quả và tin cậy
    <br/><br/>
    Dịch vụ lưu trữ dữ liệu từ xa kết hợp những tính năng ưu việt của công nghệ sao lưu trực tuyến để chuyển dữ liệu đến các trung tâm dữ liệu an toàn của chúng tôi nhằm đáp ứng nhu cầu của các doanh nghiệp. Dịch vụ chúng tôi bao gồm một thiết bị lưu trữ dữ liệu kết nối mạng và đồng bộ hóa các tập tin vào một nơi lưu trữ từ xa an toàn, cung cấp giải pháp đơn giản, đáng tin cậy để bảo vệ và lưu trữ file cho các doanh nghiệp với 5-500 nhân viên.
    <br/><br/>
    Với tiện ích sao lưu tích hợp hỗ trợ máy tính, máy tính xách tay, và máy chủ, dịch vụ lưu trữ dữ liệu từ xa cung cấp một sự bảo vệ dữ liệu toàn diện cho doanh nghiệp của bạn. Ngoài ra, với khả năng truy cập từ xa thông qua web, làm thuận tiện hơn, cho phép truy cập đến tất cả các tập tin lưu trữ của bạn.
    <br/><br/>
    Để bảo vệ dữ liệu với trường hợp thiên tai, thảm họa xãy ra tại doanh nghiệp, các tập tin sẽ được tự động nén, mã hóa và đồng bộ kho lưu trữ dữ liệu từ xa. Trong trường hợp thảm họa xảy ra ở trung tâm dữ liệu của chính doanh nghiệp, dữ liệu được backup tại trung tâm dữ liệu của chúng tôi sẽ được tải về và trả về cho khách hàng.
    <br/><br/>
    <img src=${img66} alt="">
    </p>`
    ,PScategory : 24
    },
{
    id_lv2: 78,
    textItem: "Lợi ích của dịch vụ",
    text: `<p>
    1. Dịch vụ sao lưu trên đám mây đầu tiên tại Việt Nam;
    <br/><br/>
    2. Dữ liệu được sao lưu được mã hóa chuẩn 256-bit AES (khóa mã hóa do khách hàng sở hữu) và lưu trữ tại hai trung tâm dữ liệu (tier 3+) khác xa vị trí địa lý để phòng ngừa thảm họa;
    <br/><br/>
    3. Hỗ trợ tất cả các hệ điều hành và các ứng dụng của doanh nghiệp;
    <br/><br/>
    4. Gói cước linh hoạt với giá cước hợp lý.
    </p>`
    ,PScategory : 24
},
{
    id_lv2: 79,
    textItem: "Các sản phẩm dịch vụ",
    text: `<p>
    Bundle Public Cloud Dịch vụ Virtual Machine (VM) hay gọi là Cloud Server (máy chủ ảo trên nền điện toán đám mây) cho phép khách hàng đặt mua máy chủ ảo với cấu hình định sẵn hoặc tùy chọn. Khách hàng có thể đặt mua một hay nhiều VM tùy theo nhu cầu sử dụng. Việc thay đổi cấu hình được thực hiện một cách dễ dàng linh hoạt.
    <br/><br/>
    Virtual Private Cloud Dịch vụ Virtual Private Cloud (hay Virtual Data Center), trung tâm dữ liệu ảo, cung cấp hạ tầng ảo hóa gồm hạ tầng tính toán (computing) mạng lưới (network) và lưu trữ (storage). Khách hàng được cung cấp tài nguyên để có thể tùy ý sử dụng tài nguyên qua việc kích hoạt máy chủ ảo trên nền tài nguyên đã mua. Khách hàng được quyền truy cập vào portal với giao diện tùy biến.
    <br/><br/>
    Các dịch vụ khácDịch vụ kết nối MPLS, MetroNet hoặc khách hàng có thể đưa ra tùy chọn nguồn tài nguyên cần thiết dựa trên nhu cầu thực tế của doanh nghiệp (vCPU, vRAM, vHDD, IP Public, Bandwidth,…)
    </p>`
    ,PScategory : 25
    },
{
    id_lv2: 80,
    textItem: "Tính năng của dịch vụ",
    text: `<p>
    <strong>Tính sẵn sàng cao</strong>
    <br/><br/>
    360 Cloud đáp ứng tính sẵn sàng cao ở mức: Mạng lưới, lưu trữ, hạ tầng ảo hóa. Tự động chuyển đổi cloud server giữa các máy chủ khi máy chủ gặp sự cố.Tự động chuyển hướng lưu lượng khi có sự cố trong liên kết mạng. Hạ tầng lưu trữ được thiết kế đáp ứng độ sẵn sàng cao nhất từ kết nối đến bản thân kiến trúc lưu trữ đảm bảo không mất mát dữ liệu của cloud server.
    <br/><br/>
    <strong>Khả năng mở rộng dễ dàng</strong>
    <br/><br/>
    360 Cloud đáp ứng khả năng mở rộng linh hoạt cho phép khách hàng nâng cấp hệ thống tự động với thời gian nhanh chóng.
    <br/><br/>
    <strong>Hệ thống lưu trữ SAN (Storage Area Network)</strong>
    <br/><br/>
    360 Cloud xây dựng trên hệ thống lưu trữ hợp nhất của IBM Storwize v7000 Unified với độ sẵn sàng cao đảm bảo tốc độ truy cấp vượt trội đáp ứng các ứng dụng đa dạng của khách hàng.
    <br/><br/>
    <strong>Hạ tầng truyền dẫn ưu việt</strong>
    <br/><br/>
    Khách hàng có thể tận hưởng hạ tầng truyền dẫn vượt trội của VTN, kết nối tốc độ cao và an toàn đến hạ tầng Cloud. Kết nối từ mạng các nhà cung cấp khác cũng được hỗ trợ một cách dễ dàng nhất.
    <br/><br/>
    Khách hàng có thể lựa chọn kết nối Internet hoặc VPN
    <br/><br/>
    <strong>Quản trị hệ thống linh hoạt</strong>
    <br/><br/>
    Khách hàng sử dụng 360 Cloud được cung cấp khả năng truy cập từ xa an toàn qua IPSEC VPN bên cạnh kết nối truyền thống RDP/VNC/SSH, đồng thời truy cập portal quản lý theo dõi bật/tắt khởi động hệ thống. Khách hàng khi đặt mua tổ hợp tài nguyên sẽ được cung cấp portal với giao diện được tùy biến.
    <br/><br/>
    <strong>An toàn bảo mật tối đa</strong>
    <br/><br/>
    Cô lập giữa các khách hàng trên cùng hệ thống sử dụng kỹ thuật vlan (layer-2) đồng thời hệ thống firewall riêng cho từng KH được triển khai để bảo mật hệ thống cloud của từng KH với thế giới internet đầy rủi ro. Hệ thống firewall riêng do KH kiểm soát hoàn toàn nên đảm bảo được sự bảo mật riêng tư.
    <br/><br/>
    <strong>Khởi tạo dịch vụ nhanh chóng</strong>
    <br/><br/>
    360 Cloud ứng dụng công nghệ tự động hóa của CloudStack trên nền tảng ảo hóa Xen Server, dựa trên hệ thống mạng chuyển mạch tốc độ cao. Sau khi chứng thực nhu cầu mua của khách hàng hệ thông sẽ tự động cung cấp Cloud Server đến khách hàng một cách nhanh chóng trong thời gian dưới 10 phút.
    <br/><br/>
    <strong>Hỗ trợ kỹ thuật 365x24x7</strong>
    <br/><br/>
    Bộ phận hỗ trợ kỹ thuật với nhiều năm kinh nghiệm. 360 Cloud hỗ trợ từ hạ tầng Cloud đến lớp mạng kết nối đảm bảo thời gian gián đoạn dịch vụ thấp với một đầu mối liên hệ hỗ trợ.
    </p>`
    ,PScategory : 25
    },
{
    id_lv2: 81,
    textItem: "Hạ tầng dịch vụ",
    text: `<p>
    <strong>1. Mô hình kiến trúc tổng quan</strong>
    <br/><br/>
    <strong>2. Hạ tầng dịch vụ</strong>
    <br/><br/>
    <strong>Hạ tầng ảo hỏa Citrix Xen Server</strong>
    <br/><br/>
    Hãng dẫn đầu công nghệ ảo hóa server và đặc biệt Công nghệ Xen được các chuyên gia trong ngành thừa nhận rộng rãi là một phần mềm ảo hóa nhanh nhất và an toàn nhất.
    <br/><br/>
    <strong>Kiểm soát hoàn toàn máy chủ</strong>
    <br/><br/>
    Việc ảo hóa dựa trên hypervisor cung cấp khả năng kiểm soát toàn diện tài nguyên tính toán, cho phép khách hàng tương tác với VM giống như với máy chủ vật lý. Cho phép truy cập vào root hay vào console của máy chủ.
    <br/><br/>
    <strong>Bảo mật</strong>
    <br/><br/>
    360 Cloud được thiết kế để cung cấp bảo mật dữ liệu giữa các VM với nhau và giữa các khách hàng với nhau. Các dữ liệu này hoàn toàn tách biệt thông qua một hệ thống hạ tầng ảo hóa tách biệt nhau.
    <br/><br/>
    Ngoài ra dịch vụ còn cung cấp thêm cho khách hàng bảo mật cho hệ thống hạ tầng ảo của khách hàng với hệ thống tường lửa ảo.
    <br/><br/>
    Tính sẵn sàng cao: các hệ thống bảo mật của Cloud VNN đều được thiết kế dự phòng, đảm bảo cung cấp an toàn và bảo mật dữ liệu cho khách hàng liên tục.
    <br/><br/>
    Bảo mật chống lại các tấn công giao thức, denial-of-service (DoS),…
    <br/><br/>
    <strong>Cloud Orchestration/Automation</strong>
    <br/><br/>
    Dịch vụ 360 Cloud cung cấp web portal tự phục vụ cho khách hàng. Cho phép khách hàng nhanh chóng bắt đầu sử dụng dịch vụ và thay đổi khi có nhu cầu. Trang web này cung cấp khả năng tương tác cho khách hàng đến hệ thống VM của mình cũng như các dịch vụ mà mình đang sử dụng.
    <br/><br/>
    <strong>Portal Administrator</strong>
    <br/><br/>
    Khách hàng có thể có quyền admin để gán và cho phép các nhân viên trong công ty của mình truy cập vào portal để quản lý và sử dụng VM.
    <br/><br/>
    <strong>On-Demand Capacity</strong>
    <br/><br/>
    Cho phép khách hàng đặt hàng dịch vụ theo nhu cầu lên đến nhiều VM đồng thời.
    <br/><br/>
    <strong>Self Provisioning</strong>
    <br/><br/>
    Sử dụng portal, khách hàng có thể tự chọn loại OS mà mình muốn cài, loại VM, loại network, loại bảo mật cho VM của mình.
    <br/><br/>
    <strong>Hệ điều hành</strong>
    <br/><br/>
    Hỗ trợ đa dạng nhiều hệ điều hành cho máy chủ ảo và cho phép tải hệ điều hành trực tiếp lên máy chủ ngay sau khi khách hàng tiến hành đặt hàng
    <br/><br/>
    Khách hàng có thể chọn các loại hệ điều hành đa dạng sau:
    <ul>
    Microsoft Windows Server (all editions)
    <br/>Microsoft Windows XP/Vista/Seven/8.x
    <br/>Unix (FreeBSD, MAC OS X 10.6/10.7 server)
    <br/>Linux (all editions) and Novell Netware 5.1/6.x
    </ul>
    <strong>Kết nối</strong>
    <br/><br/>
    Hệ thống được kết nối bảo mật thông qua IP VPN của VDC.
    <br/><br/>
    Hệ thống hạ tầng mạn trục backbone của VNPT cực mạnh và rộng khắp cả nước, giúp khách hàng có thể truy cập vào dịch vụ cloud ở bất kỳ đâu.
    <br/><br/>
    <strong>Cơ sở hạ tầng</strong>
    <br/><br/>
    Hệ thống 360 Cloud sử dụng hạ tầng IDC của VDC, được thiết kế, xây dựng đạt chuẩn. Tất cả hệ thống được đặt trong môi trường với hệ thống làm mát, sàn giả, rack, bảo mật,… chuẩn hóa.
    <br/><br/>
    <strong>Đội ngũ nhân viên kỹ thuật</strong>
    <br/><br/>
    360 Cloud được hỗ trợ kỹ thuật bởi đội ngũ kỹ sư giỏi, giàu kinh nghiệm của VDC và đối tác.
    <br/><br/>
    Độ ngũ kỹ sư có kinh nghiệm triển khai, hỗ trợ thực tế với các bằng cấp cao cấp nhất của Cisco, Vmware, Hitachi.
    <br/><br/>
    <strong>Thương hiệu</strong>
    <br/><br/>
    VNPT, VDC, Cisco, Vmware, Hitachi đều là những tên tuổi hàng đầu.
    </p>`
    ,PScategory : 25
},
{
    id_lv2: 82,
    textItem: "Các sản phẩm dịch vụ",
    text: `<p>
    <img src=${img67} alt="">
    <br/><br/>
    Là mạng lưới gồm nhiều máy chủ lưu trữ đặt tại nhiều vị trí địa lý khác nhau, cùng làm việc chung để phân phối nội dung, truyền tải hình ảnh, CSS, Javascript, Video clip, Real-time media streaming, File download,.. đến người dùng cuối.
    <br/><br/>
    Cơ chế hoạt động của CDN giúp cho khách hàng truy cập nhanh vào dữ liệu máy chủ web gần họ nhất thay vì phải truy cập vào dữ liệu máy chủ web tại trung tâm dữ liệu.
    <br/><br/>
    Các sản phẩm dịch vụ: HTT, VoD, LiveTV
    </p>`
    ,PScategory : 26
    },
{
    id_lv2: 83,
    textItem: "Lợi ích của giải pháp",
    text: `<p>
    Tăng tốc độ phản hồi websites: Khi sử dụng dịch vụ CDN Việt, tốc độ tải trang web của bạn sẽ được cải thiện vượt trội dù khách hàng truy cập ở bất kì vị trí địa lý nào.
    <br/><br/>
    Không yêu cầu phần mềm, không thay đổi source code: Khách hàng không cần cài thêm bất kì phần mềm trên hệ thống hoặc thiết bị cá nhân. Không thay đổi cấu trúc website
    <br/><br/>
    Đáp ứng nhu cầu đa dạng về ứng dụng: từ các nhu hỗ trợ tăng tốc web tới những tính năng nâng cao trên truyền hình.
    <br/><br/>
    Thanh toán linh hoạt: cơ chế thanh toán linh hoạt, trả tiền theo dung lượng sử dụng
    <br/><br/>
    <img src=${img68} alt="">
    <br/><br/>
    </p>`
    ,PScategory : 26
},
{
    id_lv2: 84,
    textItem: "EasyLink Platform",
    text: `<p>
    Internet of Things (IoT), hiểu một cách đơn giản, là việc kết nối thông tin giữa các đồ vật và thiết bị, từ các vật dụng hàng ngày cho đến các máy móc công nghiệp. Các kết nối này làm thay đổi cách thức tương tác giữa con người và vật dụng, bây giờ được gọi là thiết bị thông minh, dễ dàng giám sát và điều khiển. IoT có thể được ứng dụng trong rất nhiều lĩnh vực khác nhau, từ giám sát đèn đường, tăng cường trải nghiệm khách hàng trong siêu thị, hay giám sát máy móc và tự động hóa quy trình sản xuất.
    <br/><br/>
    Để đón đầu xu thế công nghệ này, HSV đã tập trung phát triển một nền tảng IoT Platform, có khả năng tiếp nhận, lưu trữ và xử lý dữ liệu cho hàng triệu thiết bị. EasyLink Platform, giải pháp của HSV có rất nhiều tính năng vượt trội:
    <ul>
        - Giải pháp quản lý thiết bị hoàn chỉnh:
        <br/>- Cung cấp mô hình chuẩn cho các loại dữ liệu: dữ liệu đo lường, điều kiện cảnh báo, cập nhật vị trí
        <br/>- Có các cơ chế tự động đăng ký và giám sát thiết bị qua API
        <br/>- Kiểm soát số lượng lớn thiết bị qua lệnh điều khiển nhóm
        <br/>- Xử lý dữ liệu lớn:
        <br/>- Kết nối thiết bị chuẩn MQTT sẵn sàng xử lý hàng triệu bản tin
        <br/>- Phát triển trên nền tảng CSDL NoSQL
        <br/>- Hoạt động theo cluster, dễ dàng mở rộng theo chiều ngang
        <br/>- Nền tảng mở cho phát triển:
        <br/>- Cung cấp API và framework cho phát triển tùy biến
        <br/>- Giúp khách hàng giảm bớt thời gian phát triển ứng dụng
        <br/>- Giúp khách hàng tập trung vào các vấn đề thương mại
        <br/>- Quản lý dễ dàng
        <br/>- Quản lý theo tenant: kết nối thiết bị, tài sản, và tọa độ
        <br/>- Hỗ trợ tính năng đa người dùng: cơ chế tùy biến cho từng khách hàng
        <br/>- Cung cấp CSDL riêng biệt cho từng khách hàng
        <br/>- Triển khai mềm dẻo:
        <br/>- Có khả năng triển khai trên cả server và trên cloud
        <br/>- Hỗ trợ triển khai dự phòng và cân bằng tải
        <br/>- Hỗ trợ tích hợp và phát triển ứng dụng e2e
        </ul>
    </p>`
    ,PScategory : 27
},
{
    id_lv2: 85,
    textItem: "Giới thiệu giải pháp",
    text: `<p>
    Với sự phát triển của công nghệ về IoT, sản xuất nông nghiệp ngày nay đã có thể được quản lý và giám sát từ xa thông qua các hệ thống cảm biến phục vụ cho nhu cầu của đặc thù các ngành nông nghiệp.
    <br/><br/>
    Giải pháp quản lý nông nghiệp thông minh của HSV gồm các tính năng đo lường và giám sát về độ ẩm, nhiệt độ, hàm lượng phân bón, lượng nước tưới; tự động lập lịch tưới tiêu, bón phân,…. giúp nông dân thế hệ mới canh tác ở mọi quy mô và lĩnh vực đều có thể áp dụng khoa học công nghệ nhằm tăng năng suất mùa vụ và thu lợi nhiều hơn trên nông trại của mình.
    <br/><br/>
    <img src=${img69} alt="">
    </p>`
    ,PScategory : 28
    },
{
    id_lv2: 86,
    textItem: "Giải pháp triển khai",
    text: `<p>
    <img src=${img70} alt="">
    </p>`
    ,PScategory : 28
},
{
    id_lv2: 87,
    textItem: "Camera giám sát",
    text: `<p>
    Hệ thống camera giám sát là một thành phần không thể thiếu trong các bộ giải pháp quản trị, đặc biệt là cho môi trường sản xuất. Bên cạnh việc giám sát an ninh thời gian thực, hệ thống camera giám sát còn được tích hợp chặt chẽ với các quy trình sản xuất nhằm quản lý chất lượng cũng như tối ưu hóa dây chuyền.
    <br/><br/>
    Với sự phát triển của công nghệ xử lý hình ảnh, hệ thống camera giám sát còn có thể xử lý nhiều bài toán phức tạp hơn, ví dụ phân tích mật độ người từng khu vực hay giám sát hành vi.
    <br/><br/>
    Các tính năng chính của giải pháp camera giám sát:
    <ul>
        - Tương thích với nhiều loại IP Camera (hỗ trợ chuẩn onvif)
        <br/>
        - Giám sát an ninh: quản lý vào ra, bảo vệ tài sản
        <br/>
        - Cảnh báo sự kiện: báo khói, báo cháy, cảnh báo vùng cấm, xâm nhập trái phép
        <br/>
        - Tìm kiếm, xem lại hình ảnh, giám sát từ xa
        <br/>
        - Quản lý chất lượng sản phẩm
        <br/>
        - Phân tích video: mật độ người từng khu vực, giám sát hành vi
    </ul>
    Các tính năng chính của giải pháp camera giám sát:
    <ul>
        - Tương thích với nhiều loại IP Camera (hỗ trợ chuẩn onvif)
        <br/>
        - Giám sát an ninh: quản lý vào ra, bảo vệ tài sản
        <br/>
        - Cảnh báo sự kiện: báo khói, báo cháy, cảnh báo vùng cấm, xâm nhập trái phép
        <br/>
        - Tìm kiếm, xem lại hình ảnh, giám sát từ xa
        <br/>
        - Quản lý chất lượng sản phẩm
        <br/>
        - Phân tích video: mật độ người từng khu vực, giám sát hành vi
    </ul>
    <img src=${img71} alt="">
    </p>`
    ,PScategory : 29
    },
{
    id_lv2: 88,
    textItem: "Quản lý kho",
    text: `<p>
    Hệ thống quản lý kho hàng là một phần quan trọng của hệ thống quản lý sản xuất. Hệ thống này cần đảm bảo kiểm soát được các thông tin xuất nhập kho, hỗ trợ sắp xếp và tìm kiếm bên trong kho, cũng như kết nối với các hệ thống khác để đảm bảo quá trình hoạt động thông suốt, liên tục. Việc tận dụng ưu điểm của các công nghệ khác nhau trong từng phân đoạn sẽ giúp giảm thiệu sai sót và tăng hiệu suất cho quá trình quản lý.
    <br/><br/>
    <img src=${img72} alt="">
    <br/><br/>
    Các tính năng chính của giải pháp quản lý kho hàng:
    <ul>
        - Tương thích với hệ thống ERP và/hoặc phần mềm quản lý kho hiện tại
        <br/>
        - Sử dụng công nghệ cân, đo thể tích tự động để quản lý hàng nhập, xuất kho
        <br/>
        - Sử dụng công nghệ RFID để kiểm tra tư động hàng nhập xuất, kiểm kê các mặt hàng trong kho
        <br/>
        - Sử dụng công nghệ mã vạch (barcode) để tổ chức quản lý hàng trong kho
        <br/>
        - Xậy dựng bản đồ hàng hoá giúp tối ưu quá trình tìm hàng hoá
        <br/>
        - Ứng dụng Zigbee - Công nghệ IoT quản lý môi trường, an ninh của kho
        <br/>
        - Đưa ra các báo cáo về kho theo yêu cầu của khách hàng
        <br/>
        - Đưa ra cảnh báo qua email, tin nhắn, điện thoại, tuỳ theo kịch bản và nhu cầu khách hàng
    </ul>
    <img src=${img73} alt="">
    </p>`
    ,PScategory : 29
    },
{
    id_lv2: 89,
    textItem: "Quản lý chuyền",
    text: `<p>
    Hệ thống giám sát dây chuyền có nhiệm vụ tính toán và lưu trữ thông tin trong sản xuất, giúp nắm bắt nguồn lực, trạng thái của hệ thống cũng như hỗ trợ việc đưa ra quyết định sản xuất. MES giám sát thời gian thực các thông số kỹ thuật hệ thống, chi tiết đến từng máy móc. Ví dụ: trạng thái on/off, uptime, nhiệt độ, số vòng quay, lượng điện tiêu thụ… (tùy vào thông số kĩ thuật của từng máy). MES có khả năng phân tích tình trạng hệ thống. MES thu thập thông tin, lưu trữ, và có phần mềm xử lý, từ đó đưa ra các cảnh báo cũng như báo cáo theo yêu cầu. Dựa trên các cảnh báo và báo cáo của MES, người quản trị ở các cấp độ khác nhau có thể đưa ra các quyết định hợp lý liên quan đến công việc của mình.
    <br/><br/>
    <img src=${img74} alt="">
    <br/><br/>
    Các tính năng chính của giải pháp:
    <ul>
        - Bộ giải pháp hoàn thiện (phần cứng, phần mềm)
        <br/>
        - Có khả năng làm việc với nhiều chuẩn công nghiệp khác nhau
        <br/>
        - Không làm gián đoạn sản xuất
        <br/>
        - Hệ thống phân tích dữ liệu lớn (Big Data)
        <br/>
        - Giao diện quản trị tùy biến cao
        <br/>
        - Khả năng mở rộng mềm dẻo và dễ dàng
        <br/>
        - Có thể triển khai trên máy chủ tại chỗ hoặc trên điện toán đám mây
    </ul>
    <img src=${img75} alt="">
    </p>`
    ,PScategory : 29
},
{
    id_lv2: 90,
    textItem: "Giới thiệu giải pháp",
    text: `<p>
    Giải pháp quản lý giao thông thông minh được thiết kế và tư vấn bởi đội ngũ kỹ sư của HSV, giúp tăng mức độ an toàn, hiệu quả, cắt giảm thời gian phương tiện lưu thông trên đường. Đây là giải pháp tích hợp hợp nhất giữa công nghệ thông tin và truyền thông, cho phép các nhà quản lý giám sát, vận hành và điều khiển hệ thống giao thông một cách toàn diện nhất.
    <br/><br/>
    <img src=${img76} alt="">
    <br/><br/>
    <strong>ƯU ĐIỂM VƯỢT TRỘI:</strong>
    <br/><br/>
    <strong>Giải pháp trọn gói</strong>
    <br/><br/>
    Chúng tôi cung cấp giải pháp tổng thể, cho phép đồng bộ, quản lý giám sát và điều khiển tất cả các phân hệ quản lý hiện có tại Việt Nam như hệ thống cân tĩnh, hệ thống thu phí tự động, bán tự động, hệ thống giám sát giao thông…; cung cấp một góc nhìn duy nhất và thống nhất cho những người quản lý giao thông
    <br/><br/>
    <strong>Định nghĩa động các kịch bản giao thông</strong>
    <br/><br/>
    Không chỉ điều hành hoạt động giao thông hoàn toàn tự động, TMS cho phép cán bộ quản lý giao thông có thể tự định nghĩa các kịch bản giao thông cho phù hợp với quy mô và cơ sở hạ tầng tại nơi ứng dụng một cách nhanh chóng đơn giản trên giao diện đồ họa; Tăng tính linh hoạt và đơn giản hóa công tác điều hành giao thông.
    <br/><br/>
    <strong>Công nghệ tiên tiến</strong>
    <br/><br/>
    Chúng tôi không ngừng học hỏi áp dụng các công nghệ ITS mới nhất của thế giới vào sản phẩm của mình như RFID, E-tag…
    <br/><br/>
    <strong>Giảm thiểu tác động môi trường</strong>
    <br/><br/>
    Bằng cách quản lý lưu lượng xe, tốc độ, xử lý tắc nghẽn giao thông và đo lường khí thải
    <br/><br/>
    <strong>Nâng cao tính an toàn cho phương tiện và đường bộ</strong>
    <br/><br/>
    Cung cấp các công nghệ, các giải pháp đột phá về phòng tránh tai nạn hoặc xử lý thiên tai cho các phương tiện và cơ sở hạ tầng đường bộ như hệ thống biển báo động, cảnh báo trong xe…
    <br/><br/>
    <strong>Hỗ trợ chia sẻ thông tin giao thông</strong>
    <br/><br/>
    Phát triển các bộ tiêu chuẩn về tích hợp, hỗ trợ hầu hết các công nghệ kết nối hiện nay, giúp các hệ thống quản lý giao thông cũng như các phương tiện có thể kết nối và chia sẻ thông tin một cách dễ dàng
    <br/><br/>
    <strong>Quản lý tự động thời gian thực</strong>
    <br/><br/>
    Tự động giám sát và điều phối giao thông theo kịch bản đã được định nghĩa, dựa trên dữ liệu giao thông thời gian thực như lưu lượng xe, tốc độ, điều kiện thời tiết… nhằm đảm bảo tính cơ động và khả năng lưu thông của các phương tiện; đồng thời tự động xử lý thông minh các tình huống, sự cố giao thông như tai nạn, vi phạm luật giao thông như đi ngược chiều, xe quá kích thước, quá tải…
    </p>`
    ,PScategory : 30
    },
{
    id_lv2: 91,
    textItem: "Thành phần và chức năng chính",
    text: `<p>
    <strong>1. Hệ thống camera giám sát giao thông</strong>
    <br /><br />
    Hỗ trợ người vận hành quan sát hình ảnh giao thông từ xa trên các màn hình hoặc lưới màn hành hình khổ lớn tại trung tâm quản lý điều hành giao thông; cung cấp hình ảnh video giao thông cho các tổ chức và công chúng theo quy định của cơ quan có thẩm quyền.
    <br /><br />
    <img src=${img77} alt="">
    <br /><br />
    <strong>2. Hệ thống quản lý lưu lượng xe</strong>
    <br /><br />
    Thu thập, phân tích, báo cáo và lưu trữ thống kê các dữ liệu mô tả tình trạng và hiệu suất khai thác của hệ thống đường bộ; phát hiện tắc nghẽn hay các sự cố giao thông để tự động đưa ra cảnh báo cho người vận hành.
    <br /><br />
    <img src=${img78} alt="">
    <br /><br />
    <strong>3.Hệ thống quản lý sự kiện</strong>
    <br /><br />
    Hỗ trợ người vận hành quản lý theo dõi các sự kiện giao thông diễn ra trên đường cao tốc, đồng thời cung cấp các công cụ tự động tổng hợp dữ liệu từ các hệ thống dò xe, hệ thống kiểm tra tải trọng xe, hệ thống thông tin thời tiết để đưa ra các dữ liệu phục vụ cho việc tự động cảnh báo cho người vận hành các sự kiện ảnh hưởng đến an toàn giao thông đường cao tốc.
    <br /><br />
    <img src=${img79} alt="">
    <br /><br />
    <strong>4. Hệ thống kiểm tra tải trọng xe</strong>
    <br /><br />
    Sử dụng cân tải trọng động để kiểm soát các xe tải nặng, chở hàng cồng kềnh vào ra đường cao tốc; tự động đưa ra cảnh báo cho người vận hành khi phát hiện có xe vi phạm quy định về tải trọng để có các biện pháp cưỡng chế theo quy định.
    <br /><br />
    <img src=${img80} alt="">
    <br /><br />
    <strong>5. Hệ thống cung cấp thông tin giao thông</strong>
    <br /><br />
    Cung cấp thông tin về điều kiện đường bộ và tình trạng lưu thông cho người sử dụng đường cao tốc thông qua các biển báo giao thông điện tử, đài phát sóng radio (FM) hoặc trên mạng thông tin di động, Internet
    <br /><br />
    <img src=${img81} alt="">
    <br /><br />
    <strong>6. Hệ thống báo hiệu điều khiển giao thông</strong>
    <br /><br />
    Sử dụng các biển báo giao thông điện tử để điều khiển linh hoạt tốc độ giới hạn các làn xe, báo hiệu phân làn; đóng hoặc mở các làn đường thuận nghịch, đoạn tuyến trên đường cao tốc.
    <br /><br />
    <img src=${img82} alt="">
    <br /><br />
    <strong>7. Hệ thống thông tin thời tiết</strong>
    <br /><br />
    Thực hiện đo đạc/thu thập thông tin về trạng thái thời tiết và dự báo biến động thời tiết trong phạm vi đoạn đường nhằm mục đích cảnh báo cho người tham gia giao thông/người quản lý khi có điều kiện thời tiết xấu.
    <br /><br />
    <img src=${img83} alt="">
    <br /><br />
    <strong>8. Hệ thống thông tin liên lạc</strong>
    <br /><br />
    Nối kết thông tin giữa các bộ phận tham gia quản lý, vận hành đường cao tốc bằng điện thoại hữu tuyến hoặc vô tuyến.
    <br /><br />
    <img src=${img84} alt="">
    <br /><br />
    <strong>9. Hệ thống điện thoại khẩn cấp</strong>
    <br /><br />
    Bố trí hệ thống điện thoại để tiếp nhận và trả lời các cuộc gọi khẩn cấp của các cá nhân và tổ chức; ghi lại cuộc gọi và chuyển thông tin kịp thời tới các bộ phận để xử lý trong các trường hợp có tai nạn, sự cố trên đường.
    <br /><br />
    <img src=${img85} alt="">
    <br /><br />
    <strong>10. Hệ thống giám sát thiết bị</strong>
    <br /><br />
    Giám sát tự động hoạt động của toàn bộ hệ thống các thiết bị phục vụ giám sát, điều hành giao thông bao gồm cả các nguồn điện; đưa ra cảnh báo khi có sự cố bất thường hoặc hỏng hóc và ghi lại các diễn biến sửa chữa hoặc thay thế thiết bị.
    <br /><br />
    <img src=${img86} alt="">
    <br /><br />
    <strong>11. Hệ thống thu phí</strong>
    <br /><br />
    Bao gồm hệ thống thu phí tự động (ETC) và bán tự động (MTC) giúp cho việc thu phí đường bộ được nhanh chóng và hiệu quả, đảm bảo việc thống kê, kiểm soát tài chính và an ninh thu phí.
    <br /><br />
    <img src=${img87} alt="">
    <br /><br />
    <strong>12. Hệ thống thiết bị hầm</strong>
    <br /><br />
    Thực hiện đo đạc/thu thập thông tin về trạng thái các thiết bị trong hầm như quạt, van xả ngập nước,… Giúp quả lý trạng thái hoạt động của hầm, đồng thời điều khiển các thiết bị trong hầm.
    <br /><br />
    <img src=${img88} alt="">
    <br /><br />
    <strong>13. Trung tâm quản lý điều hành giao thông</strong>
    <br /><br />
    Thực hiện công việc thu thập, xử lý và lưu trữ dữ liệu tập trung; cung cấp giao tiếp người dùng để thực thi công tác quản lý, điều hành và kiểm soát giao thông tại các trung tâm quản lý QLĐHGT tuyến hoặc trung tâm QLĐHGT khu vực
    <br /><br />
    </p>`
    ,PScategory : 30
    },
{
    id_lv2: 92,
    textItem: "Giải pháp triển khai",
    text: `<p>
    <strong>Giải pháp giao thông thông minh HSV đã tư vấn và triên khai gồm:</strong>
    <ul>
    - Hệ thống Trung tâm trung tâm điều hành thông minh cho các Hầm (Tunnel Management System – TMS)
    <br/>- Hệ thống các trạm thu phí không dừng (Electronic Toll Collection – ETC)
    <br/>- Hệ thống cân động (Weighing-in-Motion – WIM)
    <br/>- Trung tâm điều hành giao thông thông minh ( ITC MC)
    <br/>- Hệ thống xe buýt nhanh ( Bus Rapid Transit –BRT)   
    </ul>
    <img src="src/images/product_solution/giaothongthongminh4.jpg" alt="">
    <br/><br/>
    <strong>Ngoài ra chúng tôi còn cùng hệ thống đối tác triển khai các giải pháp ITS sau:</strong>
    <ul>
    - Freeway Management Systems – Hệ thống quả lý đường cao tốc
    <br/>- Traffic Data Collection Stations – Trạm thu thập dữ liệu giao thông
    <br/>- Adaptive Traffic Signal Systems – Hệ thống biển báo giao thông thích ứng
    <br/>- Over-height Vehicle Detection Systems – Hệ thống phát hiện phương tiện quá khổ
    <br/>- Incident Detection Systems – Hệ thống phát hiện tai nạn
    <br/>- Parking Management & Guidance Systems – Hệ thống quản lý và hướng dẫn đỗ xe
    <br/>- Public Transport Management Systems – Hệ thống quản lý giao thông công cộng
    <br/>- CCTV Systems & Automatic Number Plate Recognition (ANPR) – Hệ thống camera giám sát và tự động nhận dạng biển số
    <br/>- Weather Stations – Trạm thông tin thời tiết
    </ul>
    <img src=${img89} alt="">
    </p>`
    ,PScategory : 30
},
{
    id_lv2: 93,
    textItem: "Tổng thể giải pháp",
    text: `<p>
    <img src=${img90} alt="">
    </p>`
    ,PScategory : 31
    },
{
    id_lv2: 94,
    textItem: "Chi tiết tính năng",
    text: `<p>
    <br/><br/>
    <img src=${img91} alt="">
    <br/><br/>
    <img src=${img92} alt="">
    <br/><br/>
    <img src=${img93} alt="">
    <br/><br/>
    <img src=${img94} alt="">
    <br/><br/>
    <img src=${img95} alt="">
    <br/><br/>
    <img src=${img96} alt="">
    </p>`
    ,PScategory : 31
},
{
    id_lv2: 95,
    textItem: "Lợi ích của giải pháp",
    text: `<p>
    <h2>LỢI ÍCH CỦA GIẢI PHÁP:</h2>
    <h3>Với nhà quản lý, nhân viên vận hành:</h3>
    <ul>
    - Hệ thống được tích hợp trong cùng một Platform để quản lý tập trung, đơn giản các ứng dụng cho xe 2 bánh, xe ô tô (bãi mở và kín)
    <br/>
    - Quản lý nhiều bãi xe cùng lúc trên nền Cloud, giúp nhà quản lý dễ dàng theo dõi, kiểm soát tình trạng bãi giữ xe và quản lý tài chính thu-chi.
    <br/>
    - Tạo và cấp thẻ nhanh cho khách hàng qua smartphone Cho phép xem báo cáo linh hoạt theo dạng tổng thể, lượng người dùng, lượng sử dụng, doanh thu, theo từng bãi và theo từng khung thời gian
    </ul>
    <h3>Với chủ phương tiện:</h3>
    <ul>
    - Giúp chủ phương tiện dễ dàng tìm kiếm bãi đỗ trống trong bãi xe, trên tuyến phố và các tiện ích quanh bãi đậu xe thông qua ứng dụng trên Android/IOS.
    <br/>
    - Thanh toán linh hoạt: Online hoặc office
    </ul>
    </p>`
    ,PScategory : 32
    },
    {
        id_lv2: 96,
        textItem: "Mô hình giải pháp",
        text: `<p>
        <strong>1. MÔ HÌNH BÃI GIỮ XE</strong>
        <strong>Hệ thống quản lý vào ra</strong>
        <br/><br/>
        <img src=${img97} alt="">
        <br/><br/>
        <strong>Hệ thống hướng dẫn đỗ xe</strong>
        Bảng điện tử thông báo số chỗ trống cho từng khu vực để đưa ra lựa chọn di chuyển hợp lí
        <br/><br/>
        Ứng dụng trên smartphone hoặc PC
        <br/><br/>
        Hệ thống đếm xe trong bãi
        <br/><br/>
        Thống kê số lượng xe vào ra theo từng khu vực
        <br/><br/>
        Hệ thống phần mềm giám sát
        <br/><br/>
        Phần mềm được thiết kế với giao diện đơn giản, dễ sử dụng
        <b>2. MÔ HÌNH GIẢI PHÁP BÃI GIỮ XE MỞ VÀ CHỨC NĂNG</b>
        <br/><br/>
        <strong>Sensor</strong>
        <br/><br/>
        Là thiết bị được gắn ở mỗi vị trí đỗ xe, có nhiệm vụ kiểm tra xem có phượng tiện đang đỗ hay không để gởi thông tin về thiết bị Gateway.
        <br/><br/>
        Hệ thống sử dụng kết nối không dây LORA/NB-IoT, cho khoảng cách truyền rất xa với độ chính xác cao, thu thập dữ liệu ở trong nhà và ngoài trời, thời lượng pin lên đến 3 năm và dễ dàng lắp đặt, tiết kiệm chi phí.
        <br/><br/>
        <b> Gateway</b>
        <br/><br/>
        Là thiết bị thu thập dữ liệu từ các điểm sensor, sau đó chuyển thông tin về Server, nhằm báo cáo tình trạng của tất cả các vị trí đỗ xe.
        <br/><br/>
        <b>Server</b>
        <br/><br/>
        Server được xem là bộ não của hệ thống, tiếp nhận báo cáo từ các điểm Sensor, sau đó tổng hợp lại và đưa ra các thông báo để giúp các lái xe có thể biết được ở đâu còn trống và có thể đỗ xe.
        </p>`
        ,PScategory : 32
    },
    {
        id_lv2: 97,
        textItem: "Mô Tả",
        text: `<p>
        <img src=${img98} alt="">
        <img src=${img99} alt="">
        </p>`
        ,PScategory : 33
    },
    {
        id_lv2: 98,
        textItem: "Mô Tả",
        text: `<p>
        <img src=${img100} alt="">
        </p>`
        ,PScategory : 34
    },
    {
        id_lv2: 99,
        textItem: "Mô Tả",
        text: `<p>
        <img src=${img101} alt="">
        </p>`
        ,PScategory : 35
    },
    {
        id_lv2: 100,
        textItem: "Mô Tả",
        text: `<p>
        <img src=${img102} alt="">
        </p>`
        ,PScategory : 36
    },
    {
        id_lv2: 101,
        textItem: "Mô Tả",
        text: `<p>
        <img src=${img103} alt="">
        <img src=${img104} alt="">
        </p>`
        ,PScategory : 37
    },
    {
        id_lv2: 102,
        textItem: "Mô Tả",
        text: `<p>
        Giao diện chính :
        <img src=${imgxh1} alt="">
        Giao diện lấy số :
        <img src=${imgxh2} alt="">
        Giao diện đăng kí khám bệnh :
        <img src=${imgxh3} alt="">
        Giao diện tra cứu thông tin bệnh án :
        <img src=${imgxh4} alt="">
        Giao diện sơ đồ bệnh viện :
        <img src=${imgxh5} alt="">
        Giao diện thanh toán viện phí :
        <img src=${imgxh6} alt="">
        Giao diện tra cứu dịch vụ :
        <img src=${imgxh7} alt="">
        Giao diện tra cứu hóa đơn :
        <img src=${imgxh8} alt="">
        Giao diện đánh giá dịch vụ :
        <img src=${imgxh9} alt="">
        </p>`
        ,PScategory : 38
    }
]





