import React from 'react'
import humanIcon from "../../images/icons/people.png";
import technologyIcon from "../../images/icons/technology.png"
import dataIcon from "../../images/icons/data.png"
import moneyIcon from "../../images/icons/save-money.png"
import imgSuMenh from "../../images/banner/sumenh.png";

function IntroduceOverview() {
    return (
        <div>
            <p style={{ marginBottom: "20px" }}>HSV Công ty Công nghệ thông tin với bề dày 15 năm hoạt động trong lĩnh vực Phần mềm, tích hợp hệ thống và là đối tác uy tín của các hãng công nghệ toàn cầu như: Cisco, IBM, Microsoft, Hitachi, HP, F5,… Cũng là đối tác công nghệ duy nhất của Cisco tại Việt Nam có đồng thời các chứng nhận: đối tác Vàng, đối tác về dịch vụ Cloud – Cloud and Managed Services, Master Cloud Builder.</p>

            <p>Đội ngũ kỹ sư trình độ cao đạt được chứng nhận quốc tế do các đối tác công nghệ lớn cấp (chứng chỉ CCIE, CISSP, CCDP, MCSE, VCI,…) Bước vào giai đoạn mới, giai đoạn của Sharing – Economy, SMAC- Blockchain, với khát khao xây dựng các ứng dụng gắn kết người dùng, cộng đồng, hệ sinh thái trở thành các Platform, HSV hướng đến Platform Revolution 4.0 với các sản phẩm mới hòa nhịp với hơi thở và nhịp đập thời cuộc.</p>
            <h5>CÁC HOẠT ĐỘNG KINH DOANH CHÍNH:</h5>

            <div className='introduce__content-img'>
                <img src={imgSuMenh} alt="banner su menh" />
            </div>
            <h5>Năng lực</h5>

            <div className='introduce__content-capacity'>
                <ul>
                    <li>
                        <div className='introduce__content-capacity-img'>
                            <img src={humanIcon} alt="" />
                        </div>
                        <div className='introduce__content-capacity-title'>
                            <p>Nhân lực</p>
                            <p>Nhân lực tạo tên sức mạnh</p>
                        </div>
                        <div className='introduce__content-capacity-text'>
                            <p>Đối với HSV, bài toán thu hút và giữ chân nhân sự giỏi là việc làm hết sức quan trọng và ưu tiên hàng đầu của công ty.
                                Ngoài chính sách về lương, thưởng rõ ràng còn ty còn tạo , trao cho cơ hội đào tạo và thăng tiến, những lợi ích dành cho nhân
                                sự cấp  cao , và quan trọng là xây dựng văn hóa, bản sắc riêng của công ty nhằm xây dựng đội ngũ nhân sự găn bó lâu dài với HSV <br></br>
                                Đội ngũ kỹ sư  giàu kinh nghiệm, được đào tạo các trường uy tín, và được đào tạo chứng nhận bởi các tổ chức  có uy tin quốc tế như Microsoft, Cisco, HP, DellEMC…
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className='introduce__content-capacity-img'>
                            <img src={technologyIcon} alt="" />
                        </div>
                        <div className='introduce__content-capacity-title'>
                            <p>Công nghệ</p>
                            <p>Công nghệ nền tảng tiên tiến</p>
                        </div>
                        <div className='introduce__content-capacity-text'>
                            <p>Với lợi thế là đối tác chiến lược của các hãng công nghệ thông tin hàng đầu thế giới tại Việt Nam như HP, Cisco, Microsoft, IBM, APC, Polycom,     Oracle…., HSV đem đến cho khách hàng sự thỏa mãn về giải pháp công nghệ và chất lượng Dịch vụ
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className='introduce__content-capacity-img'>
                            <img src={dataIcon} alt="" />
                        </div>
                        <div className='introduce__content-capacity-title'>
                            <p>Quy trình</p>
                            <p>Quy trình hoạt động chặt chẽ</p>
                        </div>
                        <div className='introduce__content-capacity-text'>
                            <p>
                                Hệ thống quy trình hoạt động theo tiêu chuẩn 9001-2008 do TUV cấp chứng nhận đảm bảo cho sự vận hành của HSV được thống nhất xuyên suốt, giúp nâng cao hiệu quả công việc và chất lượng dịch vụ chuyển giao cho khách hang.<br></br>
                                Bên cạnh đó, HSV còn áp dụng quản lý an ninh thông tin theo tiêu chuẩn ISO 27001 nhằm chuyên nghiệp hóa hoạt động, và đảm bảo an toàn tài sản thông tin cho chính mình cũng như khách hàng
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className='introduce__content-capacity-img'>
                            <img src={moneyIcon} alt="" />
                        </div>
                        <div className='introduce__content-capacity-title'>
                            <p>Tài chính</p>
                            <p>Tài chính nội lực ổn định</p>
                        </div>
                        <div className='introduce__content-capacity-text'>
                            <p>
                                Các kết quả hoạt động của HSV trong suốt 15 năm qua là minh chứng hiệu quả để đạt được sự tín nhiệm cao  từ các Bệnh viện, Ngân hàng, và các tổ chức chính phủ….<br></br>
                                Có được sự hỗ trợ dồi dào vè tài chính HSV hoàn toàn tự tin xây dựng và phát triển công ty lên tầm cao mới.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default IntroduceOverview